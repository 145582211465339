import React, { useCallback, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import ForbiddenPicture from 'svg/Error403';
import ErrorPage from 'components/lowLevel/ErrorController';
import { useDispatch } from 'react-redux';
import { metaActions } from '../../../store/meta';
import authService from '../../../services/Auth';
import { useFormatMessage } from '../../../locale';

const ForbiddenUserPage = () => {
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogoutClick = () => {
    dispatch(metaActions.setShowForbiddenPage(false));
    authService.processLogout();
  };

  useEffect(() => {
    const unsubscribe = history.listen(handleLogoutClick);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const renderButton = useCallback(() => {
    return (
      <Button variant="contained" color="primary" onClick={handleLogoutClick}>
        {formatMessage('logout')}
      </Button>
    );
    // eslint-disable-next-line
  }, []);

  return (
    <ErrorPage
      heading={formatMessage('noAccess')}
      description={formatMessage('forbiddenUserDescription')}
      image={<ForbiddenPicture />}
      renderButton={renderButton}
    />
  );
};

export default ForbiddenUserPage;
