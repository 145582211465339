import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { metaActions } from 'store/meta';

export const useHideLoadingScreen = (
  additionalDependency?: unknown,
  additionalCondition?: boolean
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (additionalCondition === undefined || additionalCondition) {
      dispatch(metaActions.hideLoadingScreen());
    }
  }, [dispatch, additionalDependency, additionalCondition]);
};
