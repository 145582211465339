import { FieldProps } from 'components/lowLevel/Field/types';
import getFormControlLabel from '../../../../utils/getFormControlLabel';
import { Field } from '../../../../services/Main/types.Field';

export const groupedLabelByFieldName = (
  rowDefinition: Field[],
  rowValidationConfigs?: Pick<FieldProps, 'name' | 'validationConfig'>[],
  arrayOfDisabled?: boolean
) => {
  return rowDefinition.reduce<
    Record<string, string | Element | JSX.Element | undefined>
  >((acc, { name: fieldName, label, disabled: fieldDisabled }) => {
    const fieldValidationConfig = rowValidationConfigs?.find(
      (rowValidationConfig) => rowValidationConfig.name === fieldName
    )?.validationConfig;

    // Если весь ArrayOf отключён или текущее поле отключено, то возвращаем
    // заголовок, который нам прилетел в пропсах к полю
    if (arrayOfDisabled || fieldDisabled) {
      return {
        ...acc,
        [fieldName]: label,
      };
    }

    return {
      ...acc,
      [fieldName]: fieldValidationConfig
        ? getFormControlLabel(label, fieldValidationConfig.validations)
        : label,
    };
  }, {});
};
