import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    fieldTabs: {
      '& > div:not(:last-child):after': {
        content: '", "',
      },
    },
    fileChip: {
      cursor: 'inherit',
      background: 'none',

      '&:first-child': {
        '& .MuiAvatar-root': {
          marginLeft: 0,
        },
      },
      '&.disabled': {
        opacity: 0.5,
      },
      '& .MuiAvatar-root': {
        width: 24,
        height: 24,
        background: 'none',
        margin: theme.spacing(0, 1),
      },
      '& .MuiSvgIcon-root': {
        width: 24,
        height: 24,
      },
      '& .MuiChip-label': {
        padding: theme.spacing(0),
      },
      '& .MuiTab-wrapper': {
        display: 'inline',
      },
    },
  })
);
