import React from 'react';
import Box from '@material-ui/core/Box';
import MuiGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Skeleton } from '@material-ui/lab';

const EntryPickerTableHeaderSkeleton = () => (
  <Box mb={1}>
    <MuiGrid container alignItems="center">
      <MuiGrid item xs={10} md={7}>
        <Typography>
          <Skeleton width="50%" />
        </Typography>
        <Typography>
          <Skeleton width="25%" />
        </Typography>
      </MuiGrid>
    </MuiGrid>
  </Box>
);

export default EntryPickerTableHeaderSkeleton;
