import { Page } from 'services/Main/types.Page';
import { OptionsType } from '../../../types.Field';

export const conditionalFieldFormPage: Page = {
  type: 'tabsView',
  title: 'Форма с условными полями',
  tabs: [
    {
      label: 'Подписка на поля',
      component: {
        id: 100,
        businessComponentId: 9,
        type: 'form',
        props: {
          actions: [
            {
              type: 'submitForm',
              color: 'primary',
              variant: 'contained',
              label: 'Отправить',
              icon: 'mui:Check',
              requestConfig: {
                url: '/api/fields/select',
                method: 'POST',
              },
              successResponseReaction: {
                type: 'reLoadModule',
              },
            },
          ],
          form: {
            type: 'markup',
            header: { heading: 'Форма с условными полями' },
            fieldGroups: [
              {
                label: 'Значение = "123"',
                fields: [
                  { type: 'text', name: 'field1', label: 'Комментарий' },
                  {
                    type: 'text',
                    name: 'field2',
                    label: 'Вспомогательный комментарий',
                    renderCondition: {
                      dependOn: 'field1',
                      condition: 'equals',
                      value: '123',
                    },
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле.'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'Значение != "123"',
                fields: [
                  { type: 'text', name: 'field3', label: 'Комментарий' },
                  {
                    type: 'text',
                    name: 'field4',
                    label: 'Вспомогательный комментарий',
                    renderCondition: {
                      dependOn: 'field3',
                      condition: 'except',
                      value: '123',
                    },
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле.'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'Не пустое значение',
                fields: [
                  { type: 'text', name: 'field5', label: 'Комментарий' },
                  {
                    type: 'text',
                    name: 'field6',
                    label: 'Вспомогательный комментарий',
                    renderCondition: {
                      dependOn: 'field5',
                      condition: 'notEmpty',
                    },
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле.'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'Пустое значение',
                fields: [
                  { type: 'text', name: 'field7', label: 'Комментарий' },
                  {
                    type: 'text',
                    name: 'field8',
                    label: 'Вспомогательный комментарий',
                    renderCondition: {
                      dependOn: 'field7',
                      condition: 'empty',
                    },
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле.'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'С чекбоксами',
                fields: [
                  { type: 'checkbox', name: 'field9', label: 'Выполнил' },
                  {
                    type: 'text',
                    name: 'field10',
                    label: 'Вспомогательный комментарий',
                    renderCondition: {
                      dependOn: 'field9',
                      condition: 'equals',
                      value: true,
                    },
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле.'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'С ComboBox',
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    name: 'field11',
                    label: 'Способ получения',
                    options: [
                      { value: 'self', label: 'Самовывоз' },
                      { value: 'delivery', label: 'Доставка' },
                    ],
                  },
                  {
                    type: 'text',
                    name: 'field12',
                    label: 'Адрес доставки',
                    renderCondition: {
                      dependOn: 'field11',
                      condition: 'equals',
                      value: 'delivery',
                    },
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле.'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    name: 'field13',
                    label: 'Магазин самовывоза',
                    options: [
                      {
                        value: 'fooParent',
                        label: 'Центральный округ',
                        parentValue: null,
                        selectable: false,
                      },
                      {
                        value: 'foo',
                        label: 'Красногорск',
                        parentValue: 'fooParent',
                        selectable: true,
                      },
                      {
                        value: 'barParent',
                        label: 'Юго-западный округ',
                        parentValue: null,
                        selectable: false,
                      },
                      {
                        value: 'bar',
                        label: 'Химки',
                        parentValue: 'barParent',
                        selectable: true,
                      },
                    ],
                    renderCondition: {
                      dependOn: 'field11',
                      condition: 'equals',
                      value: 'self',
                    },
                    validationConfig: {
                      validationType: 'mixed',
                      validations: [
                        { type: 'required', params: ['Обязательное поле.'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'С древовидным ведущим ComboBox',
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    name: 'field14',
                    label: 'Магазин',
                    options: [
                      {
                        value: 'fooParent',
                        label: 'Центральный округ',
                        parentValue: null,
                        selectable: false,
                      },
                      {
                        value: 'foo',
                        label: 'Красногорск',
                        parentValue: 'fooParent',
                        selectable: true,
                      },
                      {
                        value: 'barParent',
                        label: 'Юго-западный округ',
                        parentValue: null,
                        selectable: false,
                      },
                      {
                        value: 'bar',
                        label: 'Химки',
                        parentValue: 'barParent',
                        selectable: true,
                      },
                    ],
                  },
                  {
                    type: 'text',
                    name: 'field15',
                    label: 'Был выбран Красногорск',
                    renderCondition: {
                      dependOn: 'field14',
                      condition: 'equals',
                      value: 'foo',
                    },
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле.'] },
                      ],
                    },
                  },
                  {
                    type: 'text',
                    name: 'field16',
                    label: 'Был выбран Химки',
                    renderCondition: {
                      dependOn: 'field14',
                      condition: 'equals',
                      value: 'bar',
                    },
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле.'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'Пример с some. Срабатывает на Химки и Самару',
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    name: 'field17',
                    label: 'Магазин',
                    multiple: false,
                    options: [
                      {
                        value: '1',
                        label: 'Красногорск',
                      },
                      {
                        value: '2',
                        label: 'Химки',
                      },
                      {
                        value: '3',
                        label: 'Самара',
                      },
                      {
                        value: '4',
                        label: 'Ривьера',
                      },
                    ],
                  },
                  {
                    type: 'text',
                    name: 'field18',
                    label: 'Комментарий',
                    renderCondition: {
                      dependOn: 'field17',
                      condition: 'some',
                      value: ['2', '3'],
                    },
                  },
                ],
              },
            ],
          },
        },
      },
    },
    {
      label: 'Подписка на поля внутри ArrayOf',
      component: {
        id: 100,
        businessComponentId: 9,
        type: 'form',
        props: {
          actions: [
            {
              type: 'submitForm',
              color: 'primary',
              variant: 'contained',
              label: 'Отправить',
              icon: 'mui:Check',
              requestConfig: {
                url: '/api/fields/select',
                method: 'POST',
              },
              successResponseReaction: {
                type: 'reLoadModule',
              },
            },
          ],
          form: {
            type: 'markup',
            header: { heading: 'Форма с условными полями' },
            fieldGroups: [
              {
                label:
                  'Значение в поле сервис = ТОиР1 или ТОиР2 или Продажа вторсырья',
                fields: [
                  {
                    type: 'arrayOf',
                    name: 'services',
                    label: 'Сервисы',
                    onlyUnique: ['service'],
                    rowDefinition: [
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        label: 'Сервис',
                        name: 'service',
                        options: [
                          {
                            label: 'ТОиР 1',
                            value: '0',
                          },
                          {
                            label: 'ТОиР 2',
                            value: '1',
                          },
                          {
                            label: 'Продажа вторсырья',
                            value: '2',
                          },
                          {
                            label: 'ТОиР оборудование и помещений',
                            value: '3',
                          },
                        ],
                        mdCols: 6,
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        label: 'Уровень сервиса',
                        name: 'serviceLevel',
                        options: [
                          {
                            label: 'Новый',
                            value: '0',
                          },
                          {
                            label: 'В работе',
                            value: '1',
                          },
                          {
                            label: 'Устаревший',
                            value: '2',
                          },
                        ],
                        mdCols: 6,
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Если выбрано: ТОиР1 или ТОиР2',
                fields: [
                  {
                    type: 'arrayOf',
                    name: 'categories',
                    label: 'Категории',
                    renderCondition: {
                      dependOn: 'services.service',
                      condition: 'some',
                      value: ['0', '1'],
                    },
                    rowDefinition: [
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        label: 'Категория',
                        defaultValue: {
                          label: 'Доски',
                          value: '0',
                        },
                        name: 'category',
                        options: [
                          {
                            label: 'Доски',
                            value: '0',
                          },
                          {
                            label: 'Бетон',
                            value: '1',
                          },
                        ],
                        mdCols: 6,
                      },
                      {
                        type: 'text',
                        label: 'Комментарий',
                        name: 'comment',
                        mdCols: 6,
                      },
                    ],
                  },
                ],
              },
              {
                label: 'тестовая',
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    label: 'Вариант утилизации',
                    name: 'service',
                    options: [
                      {
                        label: 'Сжечь',
                        value: '0',
                      },
                      {
                        label: 'Утопить',
                        value: '1',
                      },
                    ],
                    renderCondition: {
                      dependOn: 'categories.category',
                      condition: 'some',
                      value: ['0', '1'],
                    },
                    mdCols: 6,
                  },
                ],
              },
              {
                label: 'Если выбрано: Продажа вторсырья',
                fields: [
                  {
                    type: 'arrayOf',
                    name: 'prices',
                    label: 'Смета',
                    renderCondition: {
                      dependOn: 'services.service',
                      condition: 'some',
                      value: ['2'],
                    },
                    rowDefinition: [
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        label: 'Тип отходов',
                        name: 'type',
                        options: [
                          {
                            label: 'Доски',
                            value: '0',
                          },
                          {
                            label: 'Бетон',
                            value: '1',
                          },
                        ],
                        mdCols: 6,
                      },
                      {
                        type: 'formattedNumber',
                        formattedNumberType: 'price',
                        currencySign: '₽',
                        label: 'Цена',
                        name: 'price',
                        mdCols: 6,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    },
  ],
};
