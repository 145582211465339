import faker from 'faker';
import { Page } from '../../../types.Page';
import { generateSelectOptions } from '../fields/ComboBox.stub';
import { OptionsType } from '../../../types.Field';

const comboBoxOptions = generateSelectOptions(20).sort((a, b) =>
  a.label.localeCompare(b.label)
);

export const fieldsScrollStubs: Page = {
  type: 'singleComponent',
  title: 'Форма со всеми полями. Демонстрация работы горизонтального скролла',
  component: {
    id: 100,
    businessComponentId: 10,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          variant: 'contained',
          color: 'primary',
          label: 'Сохранить',
          requestConfig: { url: '/api/fields/text', method: 'GET' },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      form: {
        type: 'markup',
        header: {
          heading:
            'Форма со всеми полями. Демонстрация работы горизонтального скролла',
        },
        fieldGroups: [
          {
            label: 'Text Field',
            accordion: {
              enabled: true,
              defaultExpanded: true,
            },
            fields: [
              {
                type: 'text',
                name: 'text2',
                label: 'Text field',
                defaultValue:
                  'Какой-то длинный текст, который не помещается в поле',
                mdCols: 3,
              },
              {
                type: 'text',
                name: 'text3',
                disabled: true,
                label: 'Disabled Text field',
                defaultValue:
                  'Какой-то длинный текст, который не помещается в поле',
                mdCols: 3,
              },
            ],
          },
          {
            label: 'Date Picker Field',
            accordion: {
              enabled: true,
              defaultExpanded: false,
            },
            fields: [
              {
                type: 'datePicker',
                pickerType: 'dateTimeRange',
                name: 'date1',
                label: 'Date time range picker',
                initialFocusedDate: 'now',
                defaultValue: {
                  from: faker.date.past().toISOString(),
                  to: faker.date.future().toISOString(),
                },
                mdCols: 2,
              },
              {
                type: 'datePicker',
                pickerType: 'dateTimeRange',
                name: 'date2',
                label: 'Disabled Date time range picker',
                disabled: true,
                initialFocusedDate: 'now',
                defaultValue: {
                  from: faker.date.past().toISOString(),
                  to: faker.date.future().toISOString(),
                },
                mdCols: 2,
              },
            ],
          },
          {
            label: 'Formatted Number Field',
            accordion: {
              enabled: true,
              defaultExpanded: false,
            },
            fields: [
              {
                type: 'formattedNumber',
                name: 'formattedNumber1',
                formattedNumberType: 'flexibleNumber',
                label: 'Formatted number',
                defaultValue: 12345678912345000000000000000000000,
                thousandSeparator: '🥔',
                mdCols: 3,
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber2',
                formattedNumberType: 'flexibleNumber',
                label: 'Disabled Formatted number',
                disabled: true,
                defaultValue: 12345678912345000000000000000000000,
                thousandSeparator: '🥔',
                mdCols: 3,
              },
            ],
          },
          {
            label: 'File upload Field',
            accordion: {
              enabled: true,
              defaultExpanded: false,
            },
            fields: [
              {
                type: 'file',
                name: 'clipFileUploaderWithFileDocument',
                label: 'File upload',
                viewConfig: {
                  viewStyle: 'textFieldWithClip',
                },
                mdCols: 6,
                defaultValue: [
                  {
                    id: '//lidev.ru/files/Microsoft%20Excel%20XLS.xls',
                    url: '//lidev.ru/files/Microsoft%20Excel%20XLS.xls',
                    name: 'Microsoft Excel XLS.xls',
                    size: 400000,
                    mimeType: 'application/vnd.ms-excel',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Excel%20XLSX.xlsx',
                    url: '//lidev.ru/files/Microsoft%20Excel%20XLSX.xlsx',
                    name: 'Microsoft Excel XLSX.xlsx',
                    size: 120000,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20PowerPoint%20PPT.ppt',
                    url: '//lidev.ru/files/Microsoft%20PowerPoint%20PPT.ppt',
                    name: 'Microsoft PowerPoint PPT.ppt',
                    size: 5540000,
                    mimeType: 'application/vnd.ms-powerpoint',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20PowerPoint%20PPTX.pptx',
                    url: '//lidev.ru/files/Microsoft%20PowerPoint%20PPTX.pptx',
                    name: 'Microsoft PowerPoint PPTX.pptx',
                    size: 4840000,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Word%20DOC.doc',
                    url: '//lidev.ru/files/Microsoft%20Word%20DOC.doc',
                    name: 'Microsoft Word DOC.doc',
                    size: 340000,
                    mimeType: 'application/msword',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Word%20DOCX.docx',
                    url: '//lidev.ru/files/Microsoft%20Word%20DOCX.docx',
                    name: 'Microsoft Word DOCX.docx',
                    size: 3200000,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  },
                ],
              },
              {
                type: 'file',
                name: 'clipFileUploaderWithFileDocument',
                label: 'Disabled File upload',
                disabled: true,
                viewConfig: {
                  viewStyle: 'textFieldWithClip',
                },
                mdCols: 6,
                defaultValue: [
                  {
                    id: '//lidev.ru/files/Microsoft%20Excel%20XLS.xls',
                    url: '//lidev.ru/files/Microsoft%20Excel%20XLS.xls',
                    name: 'Microsoft Excel XLS.xls',
                    size: 400000,
                    mimeType: 'application/vnd.ms-excel',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Excel%20XLSX.xlsx',
                    url: '//lidev.ru/files/Microsoft%20Excel%20XLSX.xlsx',
                    name: 'Microsoft Excel XLSX.xlsx',
                    size: 120000,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20PowerPoint%20PPT.ppt',
                    url: '//lidev.ru/files/Microsoft%20PowerPoint%20PPT.ppt',
                    name: 'Microsoft PowerPoint PPT.ppt',
                    size: 5540000,
                    mimeType: 'application/vnd.ms-powerpoint',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20PowerPoint%20PPTX.pptx',
                    url: '//lidev.ru/files/Microsoft%20PowerPoint%20PPTX.pptx',
                    name: 'Microsoft PowerPoint PPTX.pptx',
                    size: 4840000,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Word%20DOC.doc',
                    url: '//lidev.ru/files/Microsoft%20Word%20DOC.doc',
                    name: 'Microsoft Word DOC.doc',
                    size: 340000,
                    mimeType: 'application/msword',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Word%20DOCX.docx',
                    url: '//lidev.ru/files/Microsoft%20Word%20DOCX.docx',
                    name: 'Microsoft Word DOCX.docx',
                    size: 3200000,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  },
                ],
              },
            ],
          },
          {
            label: 'ComboBox Field',
            accordion: {
              enabled: true,
              defaultExpanded: false,
            },
            fields: [
              {
                type: 'comboBox',
                optionsType: OptionsType.flat,
                multiple: true,
                label: 'FlatComboBox Filed',
                name: 'flatComboBoxMultiple',
                defaultValue: comboBoxOptions,
                options: comboBoxOptions,
                mdCols: 6,
              },
              {
                type: 'comboBox',
                optionsType: OptionsType.flat,
                multiple: true,
                disabled: true,
                label: 'Disabled FlatComboBox Filed',
                name: 'flatDisabledComboBoxMultiple',
                defaultValue: comboBoxOptions,
                options: comboBoxOptions,
                mdCols: 6,
              },
            ],
          },
        ],
      },
    },
  },
};
