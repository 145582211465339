import { EntryPickerInterface } from './EntryPicker.interface';

let EntryPickerService: any;

if (
  process.env.REACT_APP_ENV === 'development' &&
  process.env.REACT_APP_IS_REMOTE !== 'true'
) {
  EntryPickerService = require('./EntryPicker.local').default;
} else {
  EntryPickerService = require('./EntryPicker.remote').default;
}

const entryPickerService = new EntryPickerService() as EntryPickerInterface;

export default entryPickerService;
