import React from 'react';
import Box from '@material-ui/core/Box';
import Header from '../../lowLevel/Header';
import {
  GroupedWidgetsPage,
  WidgetGroup,
} from '../../../services/Main/types.Page';
import Widgets from '../Widgets';

const GroupedWidgets = ({ groups, header }: GroupedWidgetsPage) => {
  return (
    <>
      {header && <Header {...header} />}
      {groups.map((props) => (
        <Box mb={2}>
          <Widgets {...(props as WidgetGroup)} />
        </Box>
      ))}
    </>
  );
};

export default GroupedWidgets;
