import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface SidebarWrapperContextProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  counter: number | null;
  setCounter: Dispatch<SetStateAction<number | null>>;
}

const SidebarWrapperContext = createContext<SidebarWrapperContextProps | null>(
  null
);

export const useSidebarWrapperContext = () => {
  const sidebarWrapperContext = useContext(SidebarWrapperContext);

  if (!sidebarWrapperContext) {
    throw new Error(
      'Не удалось получить доступ к контексту sidebarWrapperContext. Убедитесь, что хук useSidebarWrapperContext вызывается внутри SidebarWrapperProvider'
    );
  }

  return sidebarWrapperContext;
};

const SidebarWrapperProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  // Это ui-элемент для отображения счетчика примененного чего-то там в сайдбаре.
  const [counter, setCounter] = useState<number | null>(null);

  return (
    <SidebarWrapperContext.Provider
      value={{ isSidebarOpen, setIsSidebarOpen, counter, setCounter }}
    >
      {children}
    </SidebarWrapperContext.Provider>
  );
};

export default SidebarWrapperProvider;
