import React from 'react';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { DocRenderer } from 'react-doc-viewer';
import { useFormatMessage } from '../../../../../../locale';
import useStyles from '../MicrosoftRenderer/MicrosoftRenderer.styles';

const TiffRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const { locale } = useIntl();

  if (!currentDocument) return null;

  return (
    <iframe
      title="google-tiff-view"
      src={`https://docs.google.com/gview?url=${encodeURIComponent(
        currentDocument.uri
      )}&embedded=true&hl=${locale}`}
      className={classes.wrapper}
    >
      <Grid container alignItems="center" justify="center">
        <Typography align="center" variant="body2">
          {formatMessage('browserNotSupportFileViewing')}
        </Typography>
      </Grid>
    </iframe>
  );
};

export const TiffFileTypes = {
  tiff: ['tif', 'tiff', 'image/tif', 'image/tiff'],
};

TiffRenderer.weight = 3;
TiffRenderer.fileTypes = TiffFileTypes.tiff;
// Отключает дефолтную загрузку библиотеки
TiffRenderer.fileLoader = ({ fileLoaderComplete }) => fileLoaderComplete();

export default TiffRenderer;
