import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    attachments: {
      display: 'flex',
      gap: '7px',
      flexWrap: 'wrap',
    },
    attachment: {
      width: '117px',
    },
  })
);
