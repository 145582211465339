import faker from 'faker';
import { Page } from 'services/Main/types.Page';
import { allSimpleTablePageRows } from './Simple';
import { createAlert } from '../Form/WithAlerts';

export const withAlertsTablePage: Page = {
  type: 'singleComponent',
  title: 'Простая таблица',
  component: {
    id: 404,
    businessComponentId: 37,
    type: 'table',
    props: {
      actions: [
        {
          type: 'triggerEndpoint',
          label: 'Сохранить',
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/api/table/response-alerts',
            method: 'GET',
          },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      alerts: Array.from(
        { length: faker.datatype.number({ min: 1, max: 2 }) },
        createAlert
      ),
      heading: 'Простая таблица',
      requestConfig: { url: '/api/table/simple' },
      columns: [
        { title: 'Номер', name: 'code' },
        { title: 'Тип', name: 'type' },
        { title: 'Инициатор', name: 'author' },
      ],
      rows: allSimpleTablePageRows.slice(0, 5),
      options: {
        totalRows: allSimpleTablePageRows.length,
        currentPage: 0,
        pageSize: 20,
      },
    },
  },
};
