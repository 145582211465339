import React, { MouseEventHandler } from 'react';
import useStyles from './MessageQuote.styles';
import { QuotedTimelineElement } from '../../../../../services/Main/types.Component';
import useTimelineContext from '../../hooks/useTimelineContext';
import getAuthorColor from '../TimelineElement/helpers/getAuthorColor';
import getAuthorFullName from '../TimelineElement/helpers/getAuthorFullName';

function MessageQuote(props: QuotedTimelineElement) {
  const classes = useStyles();
  const { quotedElementId, body } = props;

  const { getTimelineElementById, timelineScrollManager } =
    useTimelineContext();

  const quotedTimelineElement = getTimelineElementById(quotedElementId);
  const color = getAuthorColor(quotedTimelineElement);
  const quotedAuthorFullName = getAuthorFullName(quotedTimelineElement);

  const handleQuoteClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    timelineScrollManager?.current?.smoothScrollAndHighlightToElementWithId(
      quotedElementId.toString()
    );
  };

  return (
    <div className={classes.quoteContainer} onClick={handleQuoteClick}>
      <div className={classes.divider} style={{ backgroundColor: color }} />
      <div className={classes.quote}>
        <div className={classes.quoteAuthor} style={{ color }}>
          {quotedAuthorFullName}
        </div>
        <div className={classes.quoteText}>{body.plainText}</div>
      </div>
    </div>
  );
}

export default MessageQuote;
