import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      maxWidth: 280,

      '&:focus': {
        outline: 'none',
      },
    },
    badge: {
      display: 'inline-block',
      width: 10,
      height: 10,
      borderRadius: '50%',
    },
  })
);
