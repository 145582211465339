import { Page } from 'services/Main/types.Page';

export const triggerEndpointActionPage: Page = {
  type: 'singleComponent',
  title: 'Форма с triggerEndpoint экшном',
  component: {
    id: 100,
    businessComponentId: 6,
    type: 'form',
    props: {
      actions: [
        {
          type: 'triggerEndpoint',
          label: 'Возобновить работу',
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/actions/trigger-endpoint',
          },
          successResponseReaction: {
            type: 'reLoadModule',
          },
        },
        {
          type: 'triggerEndpoint',
          label: 'С подтверждением',
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/actions/trigger-endpoint',
          },
          successResponseReaction: {
            type: 'reLoadModule',
          },
          confirmDialog: {
            body: 'Вы действительно хотите возобновить работу?',
          },
        },
        {
          type: 'triggerEndpoint',
          variant: 'contained',
          label: 'С динамическим подтвержением',
          color: 'primary',
          preSubmitRequestConfig: {
            url: '/actions/pre-submit-form',
          },
          requestConfig: {
            url: '/actions/trigger-endpoint',
          },
          successResponseReaction: {
            type: 'reLoadModule',
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с triggerEndpoint экшном' },
        fieldGroups: [
          {
            label: 'Название группы',
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
              },
            ],
          },
        ],
      },
    },
  },
};
