import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Logo: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276 37">
    <path
      d="M30.8549 8.62698C31.3213 8.47154 32.0207 8.00522 32.1762 7.84978C31.8653 7.69434 31.5544 7.46118 31.2435 7.1503C30.9327 6.83942 30.2332 5.36273 27.3575 5.36273C24.3264 5.36273 23.0052 7.46118 23.0052 9.0933C23.0052 9.32646 23.0052 9.55962 23.0052 9.79278C19.0415 11.6581 15.5441 13.7565 11.5803 18.5752H21.7617C21.2176 18.8083 20.285 19.1192 19.6632 19.741L20.5181 22.6166C21.9948 22.5389 23.4715 22.0726 24.4042 21.684C25.2591 21.2954 29.0674 19.5855 30.5441 14.7669C30.6995 14.3006 31.2435 11.8135 31.3213 11.4249C31.399 11.0363 31.6321 10.1814 32.3316 9.63734C32.0985 9.4819 31.5544 9.01558 30.9327 8.7047C30.6995 8.7047 30.6995 8.7047 30.8549 8.62698Z"
      fill="#D24A48"
    />
    <path
      d="M27.7461 7.07251C26.8912 7.07251 26.1917 7.77199 26.1917 8.62691C26.1917 9.48184 26.8912 10.1813 27.7461 10.1813C28.601 10.1813 29.3005 9.48184 29.3005 8.62691C29.3005 7.77199 28.601 7.07251 27.7461 7.07251Z"
      fill="#4DA162"
    />
    <path
      d="M24.4042 0H10.1814L5.44043 13.7565H12.8239C13.057 13.601 13.4456 13.2902 13.8342 12.9793L17.0985 2.48705L19.3523 9.71503C19.6632 9.55959 20.3627 9.17098 20.7513 9.01554C20.9068 6.1399 23.0829 4.50777 25.8808 4.19689L24.4042 0Z"
      fill="#D24A48"
    />
    <path
      d="M3.57513 19.2746L0 29.6114H8.62694L11.1917 21.4508H5.36269C5.59586 20.7513 5.98446 19.8186 6.45078 19.2746H3.57513Z"
      fill="#D24A48"
    />
    <path
      d="M21.9948 22.4611L24.171 29.6891H26.4249L24.0933 21.8394C23.5492 22.0725 22.772 22.3057 21.9948 22.4611Z"
      fill="#4DA162"
    />
    <path
      d="M30.3109 16.8653C29.5337 18.4197 28.057 20.5959 24.8705 22.1503L27.1244 29.6114H34.6632L30.3109 16.8653Z"
      fill="#D24A48"
    />
    <path
      d="M35.8291 8.54921C35.285 8.31605 34.3524 8.00517 33.1089 8.00517C32.7202 8.00517 32.4094 8.00517 32.2539 7.84973C32.0208 8.00517 31.399 8.39377 30.9327 8.62693C30.6995 8.70465 30.6995 8.78237 30.9327 8.86009C31.5544 9.17097 32.0985 9.55958 32.3316 9.79274C33.1866 9.17098 33.9638 9.71502 35.9068 8.86009C36.0622 8.70465 36.0622 8.62693 35.8291 8.54921Z"
      fill="#4DA162"
    />
    <path
      d="M2.72022 16.399C2.79794 16.399 2.79794 16.399 2.79794 16.4767C2.79794 16.5544 2.79794 16.5544 2.72022 16.5544C1.39897 16.5544 0.466331 17.4871 0.388611 18.5751H7.84975C7.30571 19.1192 6.68395 19.9741 6.29535 20.829H17.6425L20.285 29.6891H22.5389L20.4404 22.6943L19.5855 19.8187C20.285 19.1969 21.1399 18.886 21.6839 18.6529H11.7358C15.6218 13.8342 19.1969 11.7358 23.1606 9.87048C23.0829 9.63732 23.1606 9.40416 23.1606 9.171C23.1606 7.53887 24.4819 5.44043 27.513 5.44043C28.5233 5.44043 29.6114 5.67359 30.4663 6.21763C30 5.75131 28.3679 4.81866 26.7358 4.81866C23.3161 4.81866 21.2953 6.99483 21.4508 9.40416C19.2746 10.4145 15.5441 12.3575 13.1347 14.3005H0.388611C0.544051 15.5441 1.47669 16.399 2.72022 16.399Z"
      fill="#4DA162"
    />
    <path
      d="M27.6684 8.78239L29.145 8.08291C28.9896 7.69431 28.601 7.30571 28.2124 7.15027L27.5129 8.62695C27.4352 8.78239 27.5129 8.86011 27.6684 8.78239Z"
      fill="white"
    />
    <path
      d="M136.399 23.5492C136.399 26.8912 136.554 29.6114 136.554 29.6114H129.326V20.3627C129.326 17.2539 128.394 16.088 126.684 16.088C124.43 16.088 123.264 17.7979 123.264 21.373V29.6114H116.192C116.425 24.0932 116.192 15.8549 116.036 10.8031H122.409C122.409 10.8031 122.565 11.5803 122.565 12.8238V13.5233C123.42 12.2798 126.062 10.259 129.249 10.4145C132.435 10.5699 134.689 12.1243 135.699 14.145C136.244 15.3108 136.399 17.0984 136.477 18.4197L136.399 23.5492Z"
      fill="#D24A48"
    />
    <path
      d="M47.6425 27.2798C46.5544 28.5233 44.0674 30.2332 41.2695 30C37.3057 29.6891 34.1192 27.9015 34.1192 21.9948V10.8031H41.1917V20.0518C41.1917 23.1606 42.0467 24.3264 43.7565 24.3264C46.0104 24.3264 47.0985 22.3057 47.0985 19.0414V10.8031H54.0933C53.8601 16.3212 54.0933 24.5596 54.2487 29.6114H47.8757C47.5648 29.0673 47.6425 27.2798 47.6425 27.2798Z"
      fill="#D24A48"
    />
    <path
      d="M107.72 27.2798C107.72 28.9896 107.876 29.6891 107.876 29.6891H114.016C114.016 29.6891 113.86 26.5026 113.86 20.2073C113.86 20.1295 113.86 16.2435 113.394 15.1555C112.927 14.0674 111.839 11.5026 107.332 10.57C102.824 9.63732 98.0829 11.3472 97.3834 11.8912V16.5544C97.3834 16.5544 100.492 14.8446 102.902 14.8446C104.689 14.8446 107.254 15 107.409 17.6425C107.409 17.6425 106.477 17.6425 105.078 17.6425C100.725 17.6425 95.2073 18.4974 95.0518 23.8601C94.9741 28.057 98.6269 30 101.891 29.9223C101.813 29.8446 105.544 30 107.72 27.2798Z"
      fill="#D24A48"
    />
    <path
      d="M101.658 23.6269C101.503 20.9844 106.632 21.373 107.254 21.6062C107.332 26.0362 101.813 26.5025 101.658 23.6269Z"
      fill="white"
    />
    <path
      d="M86.5026 20.4404C86.5026 17.3316 85.4145 16.1658 83.9378 16.1658C81.6839 16.1658 80.5181 17.7979 80.5181 21.4508V29.6114H73.5233V5.28497H80.5959V12.6684C81.7617 11.658 83.8601 10.2591 86.5026 10.3368C89.6891 10.4922 93.6528 11.9689 93.6528 18.7306C93.6528 21.2176 93.6528 29.5337 93.6528 29.5337H86.5026V20.4404Z"
      fill="#D24A48"
    />
    <path
      d="M71.4249 16.943C68.8601 14.9223 62.7202 15.0777 62.7202 19.9741C62.7202 25.4145 69.4041 25.2591 71.8912 23.3938L72.0466 28.7565C68.4715 30.6218 55.5699 31.2435 55.5699 20.0518C55.5699 8.8601 68.3161 9.48186 71.8912 11.5803L71.4249 16.943Z"
      fill="#D24A48"
    />
    <path
      d="M149.736 29H152.868V19.748L155.208 17.084L163.164 29H166.872L157.404 15.032L166.188 4.73602H162.3L154.884 13.844C154.272 14.636 153.624 15.5 152.976 16.436H152.868V4.73602H149.736V29Z"
      fill="#D24A48"
    />
    <path
      d="M183.504 20.864C183.576 20.504 183.612 20.036 183.612 19.388C183.612 16.184 182.136 11.216 176.412 11.216C171.336 11.216 168.24 15.32 168.24 20.576C168.24 25.832 171.444 29.396 176.808 29.396C179.58 29.396 181.524 28.784 182.64 28.28L182.064 26.012C180.912 26.516 179.508 26.912 177.24 26.912C174.036 26.912 171.336 25.148 171.264 20.864H183.504ZM171.3 18.596C171.552 16.4 172.92 13.484 176.16 13.484C179.724 13.484 180.588 16.616 180.552 18.596H171.3Z"
      fill="#D24A48"
    />
    <path
      d="M201.899 20.864C201.971 20.504 202.007 20.036 202.007 19.388C202.007 16.184 200.531 11.216 194.807 11.216C189.731 11.216 186.635 15.32 186.635 20.576C186.635 25.832 189.839 29.396 195.203 29.396C197.975 29.396 199.919 28.784 201.035 28.28L200.459 26.012C199.307 26.516 197.903 26.912 195.635 26.912C192.431 26.912 189.731 25.148 189.659 20.864H201.899ZM189.695 18.596C189.947 16.4 191.315 13.484 194.555 13.484C198.119 13.484 198.983 16.616 198.947 18.596H189.695Z"
      fill="#D24A48"
    />
    <path
      d="M206.29 36.128H209.422V26.66H209.494C210.538 28.388 212.554 29.396 214.858 29.396C218.962 29.396 222.814 26.3 222.814 20.036C222.814 14.78 219.646 11.216 215.398 11.216C212.554 11.216 210.466 12.44 209.206 14.564H209.134L208.954 11.576H206.146C206.218 13.232 206.29 15.032 206.29 17.264V36.128ZM209.422 18.884C209.422 18.488 209.53 18.02 209.638 17.624C210.214 15.248 212.266 13.736 214.426 13.736C217.774 13.736 219.61 16.724 219.61 20.18C219.61 24.176 217.666 26.912 214.318 26.912C212.05 26.912 210.142 25.4 209.566 23.204C209.458 22.808 209.422 22.376 209.422 21.908V18.884Z"
      fill="#D24A48"
    />
    <path
      d="M235.207 4.73602V19.028C235.207 26.588 238.951 29.396 243.919 29.396C249.175 29.396 253.099 26.372 253.099 18.92V4.73602H249.931V19.136C249.931 24.464 247.591 26.84 244.027 26.84C240.787 26.84 238.375 24.536 238.375 19.136V4.73602H235.207Z"
      fill="#D24A48"
    />
    <path
      d="M258.804 36.128H261.936V26.66H262.008C263.052 28.388 265.068 29.396 267.372 29.396C271.476 29.396 275.328 26.3 275.328 20.036C275.328 14.78 272.16 11.216 267.912 11.216C265.068 11.216 262.98 12.44 261.72 14.564H261.648L261.468 11.576H258.66C258.732 13.232 258.804 15.032 258.804 17.264V36.128ZM261.936 18.884C261.936 18.488 262.044 18.02 262.152 17.624C262.728 15.248 264.78 13.736 266.94 13.736C270.288 13.736 272.124 16.724 272.124 20.18C272.124 24.176 270.18 26.912 266.832 26.912C264.564 26.912 262.656 25.4 262.08 23.204C261.972 22.808 261.936 22.376 261.936 21.908V18.884Z"
      fill="#D24A48"
    />
  </SvgIcon>
);
Logo.muiName = 'SvgIcon';

Logo = React.memo(Logo);
Logo.displayName = 'Logo';

const PureLogo = Logo;

export default PureLogo;
