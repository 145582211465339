export default {
  data: {
    jwtToken: {
      value:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IjEwa0hQejBJZmwzNnFmS251bGYybkt6RVRETU9PdjFWeVAvZ3A3ODh0NVE9IiwidW5pcXVlX25hbWUiOiJDT0ViOXI5V1dmRUlKdzN1eXZiV1VvbTN1d09jNDlQa3BmdFZNN3V6eVNuOHdMdUNCbkV4N0E9PSIsImZhbWlseV9uYW1lIjoiMTBrSFB6MElmbDM2cWZLbnVsZjJuS3pFVERNT092MVZ5UC9ncDc4OHQ1UT0iLCJleHAiOjE1OTQzMjAyMzgsImlzcyI6IktlZXBVcCIsImF1ZCI6IktlZXBVcFVzZXIifQ.YubjXC087OYZU9lwpszGpYcMW6mXJOatWwge8KVcl6I',
    },
    refreshToken: {
      value: 'ly1wz92A3KI+SOUibYzH+Dz7ddEB2R1FH2ThusyB+4U=',
    },
    isSuccess: true,
    exceptionString: '',
  },
};
