import { Page } from 'services/Main/types.Page';

export const showFormVar2ActionPage: Page = {
  type: 'singleComponent',
  title: 'Форма с showForm action с fullScreen modal',
  component: {
    id: 100,
    businessComponentId: 4,
    type: 'form',
    props: {
      actions: [
        {
          type: 'showForm',
          label: 'Подготовить деф. ведомость',
          variant: 'contained',
          color: 'primary',
          viewStyle: 'fullScreenModal',
          actions: [
            {
              type: 'submitForm',
              variant: 'contained',
              label: 'Согласовать с заказчиком',
              color: 'primary',
              requestConfig: {
                url: '/api/workflow/action/CreateDefectiveSheet?taskId=4ac09743-3b7b-41c8-879e-929563f1167b',
              },
              successResponseReaction: {
                type: 'reLoadModule',
              },
            },
          ],
          form: {
            type: 'markup',
            header: { heading: 'Создание дефектной ведомости' },
            fieldGroups: [
              {
                label: 'Информация о дефектах',
                fields: [
                  {
                    type: 'text',
                    name: 'defectList',
                    label: 'Введите дефекты',
                    helperText: 'Выявленные дефекты',
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'text',
                    name: 'defectReasonList',
                    label: 'Укажите причины',
                    helperText: 'Причины выявленных дефектов',
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'Требования для устранению дефектов',
                fields: [
                  {
                    type: 'arrayOf',
                    name: 'workList',
                    label: 'Заполните работу',
                    helperText: 'Провести следующие работы / мероприятия',
                    rowDefinition: [
                      {
                        type: 'text',
                        name: 'workList',
                        validationConfig: {
                          validationType: 'string',
                          validations: [
                            {
                              type: 'required',
                              params: ['Обязательное поле'],
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    type: 'arrayOf',
                    name: 'partsToReplaceList',
                    label: 'Укажите что нужно заменить',
                    helperText:
                      'Заменить следующие запасные части / материалы / оборудование',
                    rowDefinition: [
                      {
                        type: 'text',
                        name: 'partsToReplaceList',
                        validationConfig: {
                          validationType: 'string',
                          validations: [
                            {
                              type: 'required',
                              params: ['Обязательное поле'],
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Плановые сроки',
                fields: [
                  {
                    type: 'text',
                    inputType: 'numeric',
                    name: 'partsDeliveryDeadline',
                    label: 'Укажите количество дней',
                    helperText:
                      'Поставка запчастей / материалов, календарных дней',
                    validationConfig: {
                      validationType: 'number',
                      validations: [
                        {
                          type: 'positive',
                          params: ['Введите число больше 0'],
                        },
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'text',
                    inputType: 'numeric',
                    name: 'workDeadline',
                    label: 'Укажите количество дней',
                    helperText: 'Ремонтные работы, календарных дней',
                    validationConfig: {
                      validationType: 'number',
                      validations: [
                        {
                          type: 'positive',
                          params: ['Введите число больше 0'],
                        },
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'Приложенные файлы',
                fields: [
                  {
                    type: 'file',
                    name: 'files',
                    label: 'Файлы',
                  },
                ],
              },
            ],
          },
        },
      ],
      form: {
        type: 'markup',
        header: {
          heading: 'Форма с showForm action с fullScreen modal',
        },
        fieldGroups: [
          {
            label: 'Название группы',
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    { type: 'required', params: ['Обязательное поле'] },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  },
};
