import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    body: {
      whiteSpace: 'pre-wrap',
      overflowX: 'auto',

      '& p': {
        ...theme.typography.body2,
        margin: theme.spacing(0.5, 0),
      },
      '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'none',

        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  })
);
