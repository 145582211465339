import { Page } from '../../../types.Page';
import { OptionsType } from '../../../types.Field';

export const asyncFormPage: Page = {
  type: 'singleComponent',
  title: 'Простая форма с одним полем',
  component: {
    id: 100,
    businessComponentId: 31,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          label: 'Отправить',
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/api/high-level-components/form/with-validation',
          },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Простая форма с одним полем' },
        fieldGroups: [
          {
            label: 'Async Select Fields',
            fields: [
              {
                type: 'select',
                optionsLoader: 'async',
                selectType: 'single',
                name: 'equipment',
                label: 'Оборудование (single)',
                searchOptionsRequestConfig: {
                  url: '/api/equipments',
                  method: 'GET',
                },
              },
              {
                type: 'select',
                optionsLoader: 'async',
                selectType: 'multi',
                name: 'equipments',
                label: 'Оборудование (multi)',
                searchOptionsRequestConfig: {
                  url: '/api/equipments',
                  method: 'GET',
                },
              },
            ],
          },
          {
            label: 'Async Flat ComboBox Fields',
            fields: [
              {
                type: 'comboBox',
                optionsType: OptionsType.flat,
                optionsLoader: 'async',
                multiple: false,
                label: 'Оборудование (single)',
                name: 'flatComboBoxEquipment',
                searchOptionsRequestConfig: {
                  url: '/api/combobox-equipments',
                  method: 'GET',
                },
              },
              {
                type: 'comboBox',
                optionsType: OptionsType.flat,
                optionsLoader: 'async',
                multiple: true,
                label: 'Оборудование (multi)',
                name: 'flatComboBoxEquipments',
                searchOptionsRequestConfig: {
                  url: '/api/combobox-equipments',
                  method: 'GET',
                },
              },
            ],
          },
          {
            label: 'Async Tree ComboBox Fields',
            fields: [
              {
                type: 'comboBox',
                optionsType: OptionsType.radioTree,
                optionsLoader: 'async',
                label: 'Оборудование (radio)',
                name: 'treeComboBoxEquipment',
                searchOptionsRequestConfig: {
                  url: '/api/tree-radio-combobox',
                  method: 'GET',
                },
              },
              {
                type: 'comboBox',
                optionsType: OptionsType.checkboxTree,
                optionsLoader: 'async',
                label: 'Оборудование (checkbox)',
                name: 'treeComboBoxEquipments',
                searchOptionsRequestConfig: {
                  url: '/api/tree-checkbox-comboboxs',
                  method: 'GET',
                },
              },
            ],
          },
        ],
      },
    },
  },
};
