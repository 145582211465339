import { createMuiTheme } from '@material-ui/core/styles';

const primary = {
  main: '#109cf1',
  contrastText: '#fff',
};

const PublicRoutesTemplateTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 770,
      md: 960,
      lg: 1150,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 5,
  },
  palette: {
    primary,
    background: {
      default: '#f5f6f8',
    },
  },
});

export default PublicRoutesTemplateTheme;
