import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    elementContainer: {
      width: '100%',
    },
    OtherUserTimelineElementAvatar: {
      width: 56,
    },
  })
);
