import React, { useMemo, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import Table from '../Table';
import {
  SummaryRow,
  TableRow,
  TableWidgetProps,
} from '../../../services/Main/types.Component';
import useWidgetDataQuery, {
  checkEmptyStateFn,
} from '../../../utils/hooks/useWidgetDataQuery';
import WidgetContainer from '../../lowLevel/WidgetContainer';
import WidgetSkeletonContainer from '../../lowLevel/WidgetSkeletonContainer';
import TableWidgetSkeleton from '../../../svg/skeletons/TableWidgetSkeleton';
import useStyles from './TableWidget.styles';

type TableWidgetState = { rows: TableRow[]; summaryRow?: SummaryRow };

const checkEmptyState: checkEmptyStateFn<TableWidgetState> = (payload) => {
  return payload.rows.length === 0 || !Array.isArray(payload.rows);
};

const TableWidgetDataLoader = (props: TableWidgetProps) => {
  const classes = useStyles();
  const { requestConfig, heading, header, maxHeight } = props;

  const originalHeightRef = useRef<number | null>(null);

  const minHeight = 200;

  const { isFistTimeFetched, isFetching, widgetState, alert } =
    useWidgetDataQuery<TableWidgetState>(requestConfig, checkEmptyState);

  const skeleton = useMemo(
    () => (
      <TableWidgetSkeleton
        className={!isFistTimeFetched ? 'animate-flicker' : undefined}
        // 161, потому что: мы не знаем количество строк, которое прилетит с бэка
        // 53 высота строки * 3 строки = 161px
        // Если хотим считать динамически, то нужно с бэка получать ожидаемое
        // количество строк (rowsCountPrognosis: number) в TableWidgetProps
        style={{ height: 161, overflow: 'hidden' }}
      />
    ),
    [isFistTimeFetched]
  );

  const content = (() => {
    if (!isFistTimeFetched)
      return (
        <WidgetSkeletonContainer
          rootClassName={classes.skeleton}
          skeleton={skeleton}
        />
      );

    // Информация для пользователя, если данные запросили некорректно.
    if (alert)
      return (
        <WidgetSkeletonContainer
          rootClassName={classes.skeleton}
          skeleton={skeleton}
          alert={alert}
        />
      );

    if (!widgetState) throw new Error('Неизвестная ошибка');

    return (
      <AutoSizer disableWidth style={{ height: 'fit-content' }}>
        {({ height }) => {
          if (!originalHeightRef.current) {
            originalHeightRef.current = height;
          }

          const { current: originalHeight } = originalHeightRef;

          return (
            <Table
              {...props}
              {...widgetState}
              // Специально отключаем для табличного виджета
              heading={undefined}
              // Специально отключаем для табличного виджета
              header={undefined}
              // Специально отключаем для табличного виджета
              fullHeight={false}
              // Высчитываем максимальную высоту:
              // Если прилетел maxHeight и высота контейнера больше, чем
              // maxHeight, то устанавливаем высоту контейнера
              maxHeight={
                maxHeight && originalHeight > maxHeight
                  ? originalHeight
                  : maxHeight
              }
            />
          );
        }}
      </AutoSizer>
    );
  })();

  return (
    <WidgetContainer
      style={{ minHeight, height: '100%' }}
      showLoader={isFetching}
      heading={heading || header?.heading}
    >
      {content}
    </WidgetContainer>
  );
};

export default TableWidgetDataLoader;
