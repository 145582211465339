import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../../../theme';

export default makeStyles<Theme>((theme) =>
  createStyles({
    tags: {
      display: 'flex',
      gap: theme.spacing(0.5),

      '&.multiline': {
        flexWrap: 'wrap',
        maxWidth: 'calc(100% - 73px)',
      },
      marginRight: theme.spacing(1),
    },
    textTag: {
      display: 'inline-flex',
      verticalAlign: 'middle',
      content: '", "',
      '&:not(:last-of-type)::after': {
        content: '", "',
      },
    },
    chipTag: {
      display: 'inline-flex',
      verticalAlign: 'middle',
      color: theme.palette.text.secondary,
      '&.MuiChip-sizeSmall': {
        height: '100%',
        maxWidth: '100%',
      },
      '&.link': {
        color: theme.palette.primary.main,
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);
