import React, { PropsWithChildren } from 'react';
import { SidebarProps } from '../../../services/Main/types.Page';
import SidebarWrapperProvider from './SidebarLoader/SidebarWrapper/SidebarWrapper.provider';
import PageLayout from './PageLayout';

interface Props {
  sidebar?: SidebarProps;
}

const PageLayoutContainer = (props: PropsWithChildren<Props>) => {
  return (
    <SidebarWrapperProvider>
      <PageLayout {...props} />
    </SidebarWrapperProvider>
  );
};

export default PageLayoutContainer;
