import { TableContextValue } from '../../highLevel/Table/TableContext';

export default function getDefaultValue(
  fieldName: string,
  tableContextValue: TableContextValue
) {
  const filterFields =
    tableContextValue?.filterComponent?.props.fieldGroups[0].fields;

  const field = filterFields && filterFields.find((f) => f.name === fieldName);

  if (!field) return '';

  if (field.type === 'select' && field.selectType === 'multi') {
    return [];
  }

  if (
    field.type === 'datePicker' &&
    (field.pickerType === 'dateRange' || field.pickerType === 'dateTimeRange')
  ) {
    return null;
  }

  if (field.type === 'select') {
    return null;
  }

  if (field.type === 'comboBox') {
    return [];
  }

  return '';
}
