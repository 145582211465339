import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default (showEndAdornment?: boolean) =>
  makeStyles((theme: Theme) =>
    createStyles({
      badge: {
        '& .MuiBadge-badge': {
          fontSize: 9,
          letterSpacing: -1,
        },
      },
      drawer: {
        zIndex: 10000,
      },
      inputWrapper: {
        width: '60%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      endAdornment: {
        cursor: 'pointer',
        visibility: showEndAdornment ? 'visible' : 'hidden',
      },
    })
  );
