import React, { SetStateAction } from 'react';
import clsx from 'clsx';
import { Bar } from 'services/Main/types.Component';
import { BarChartState } from './types';
import useStyles from './Legend.styles';

type AdditionalProps = {
  hiddenBarKeys: string[];
  setHiddenBarKeys: React.Dispatch<SetStateAction<string[]>>;
};

const Legend = ({
  bars,
  hiddenBarKeys,
  setHiddenBarKeys,
}: BarChartState & AdditionalProps) => {
  const classes = useStyles();

  const handleItemClick = (bar: Bar) => () => {
    setHiddenBarKeys((prevState) => {
      let newState;

      if (prevState.includes(bar.key)) {
        newState = prevState.filter((key) => key !== bar.key);
      } else {
        newState = [...prevState, bar.key];
      }

      return newState;
    });
  };

  return (
    <div className={classes.root}>
      {bars.map((bar, i) => {
        return (
          <div
            className={clsx(classes.item, {
              inactive: hiddenBarKeys.includes(bar.key),
            })}
            onClick={handleItemClick(bar)}
            key={bar.key}
          >
            <span className={classes.badge} style={{ background: bar.fill }} />
            {bar.name}
          </div>
        );
      })}
    </div>
  );
};

export default Legend;
