import React from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Color } from '@material-ui/lab/Alert/Alert';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import useStyles from './PasswordSetupPage.styles';
import { useFormatMessage } from '../../../locale';

interface SetPasswordInvalidLinkResultProps {
  title?: string;
  message: string;
  color: Color;
}

export default function PasswordSetupPageCardWithAlert({
  title,
  message,
  color,
}: SetPasswordInvalidLinkResultProps) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Grid container justify="center">
      <Grid item xs={11} sm={8} md={6}>
        <Card raised className={classes.card}>
          <Grid container>
            <Grid item xs={12} className={classes.marginBottom}>
              {title && (
                <>
                  <Alert
                    className={clsx(classes.alertTitle, classes.title)}
                    variant="standard"
                    color={color}
                    severity={color}
                  >
                    {title}
                  </Alert>
                  <Divider />
                </>
              )}
            </Grid>
            <Grid item container xs={12} justify="center">
              <Grid item xs={10}>
                <Box className={classes.marginBottom}>
                  <Typography className={classes.alertText}>
                    {message}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item container xs={12} justify="center">
              <Grid
                item
                xs={10}
                container
                justify="flex-end"
                className={classes.marginBottom}
              >
                <Button
                  component={Link}
                  to="/login"
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {formatMessage('toLoginForm')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}
