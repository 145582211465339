import { Page } from '../../../types.Page';
import { OptionsType } from '../../../types.Field';

export const arrayOfUniqueStubs: Page = {
  title: '1',
  type: 'tabsView',
  tabs: [
    {
      label: 'SingleFlatComboBox ArrayOf с onlyUnique',
      component: {
        id: 100,
        businessComponentId: 11,
        type: 'form',
        props: {
          form: {
            type: 'markup',
            header: {
              heading: 'Простая форма',
            },
            fieldGroups: [
              {
                label: 'SingleFlatComboBox ArrayOf с onlyUnique',
                fields: [
                  {
                    type: 'arrayOf',
                    name: 'arrayOf',
                    onlyUnique: ['equipment'],
                    label: 'Список оборудования',
                    mdCols: 12,
                    rowDefinition: [
                      {
                        name: 'equipment',
                        type: 'comboBox',
                        multiple: false,
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        label: 'Оборудование',
                        mdCols: 6,
                        options: [
                          {
                            value: 'd9815d4b-29ff-455a-8109-82f9f810b0b3',
                            label: 'Слайсер',
                          },
                          {
                            value: 'f0112a8d-70cd-4bcc-8112-422d20bc44f7',
                            label: 'Хлеборезка',
                          },
                          {
                            value: 'd548bf3f-4b6a-4423-a1cd-54c791732251',
                            label: 'Принтер',
                          },

                          {
                            value: 'e0f50f63-a39f-4fdc-ab1e-1cd0d29d8b15',
                            label: 'Монитор',
                          },
                          {
                            value: 'a691707f-5fca-4215-b3d0-9949c38bee2a',
                            label: 'Стеллаж',
                          },
                          {
                            value: 'b9081907-6780-433e-b4a5-3d099b6050e1',
                            label: 'Тележка для ротационной печи',
                          },
                          {
                            value: 'dff985d7-53c3-43fb-92a6-378d37c51060',
                            label: 'Стерилизационный шкаф',
                          },
                          {
                            value: '3cb86c7e-4e3a-4972-b8ed-6afb1b4a6a89',
                            label: 'Тепловая витрина',
                          },
                        ],
                      },
                      {
                        name: 'shop',
                        type: 'comboBox',
                        multiple: false,
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        label: 'Магазин',
                        mdCols: 6,
                        options: [
                          {
                            value: '0fcdb972-1053-4403-9b6c-6aee48196940',
                            label: 'Красногорск',
                          },
                          {
                            value: '014a8b1e-508b-4209-b1d7-ae3adc1eb6c9',
                            label: 'Химки',
                          },
                        ],
                      },
                    ],
                    defaultRows: [
                      [
                        {
                          name: 'equipment',
                          defaultValue: {
                            value: 'd9815d4b-29ff-455a-8109-82f9f810b0b3',
                            label: 'Слайсер',
                          },
                        },
                        {
                          name: 'shop',
                          defaultValue: {
                            value: '0fcdb972-1053-4403-9b6c-6aee48196940',
                            label: 'Красногорск',
                          },
                        },
                      ],
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    },
    {
      label: 'SingleTreeComboBox ArrayOf с onlyUnique',
      component: {
        id: 100,
        businessComponentId: 13,
        type: 'form',
        props: {
          form: {
            type: 'markup',
            header: {
              heading: 'Простая форма',
            },
            fieldGroups: [
              {
                label: 'RadioTreeComboBox onlyUnique',
                fields: [
                  {
                    type: 'arrayOf',
                    name: 'arrayOf',
                    label: 'Список оборудования',
                    onlyUnique: ['radioTreeComboBox'],
                    mdCols: 12,
                    rowDefinition: [
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.radioTree,
                        optionsLoader: 'sync',
                        label: 'Список оборудования',
                        name: 'radioTreeComboBox',
                        mdCols: 6,
                        options: [
                          {
                            label: 'Холодильное оборудование',
                            value: 'e93ae1db-0f05-4e58-9fcf-a5a837cdfd61',
                            selectable: true,
                            parentValue: null,
                          },
                          {
                            label: 'Витрины холодильные',
                            value: '0785d55b-9b11-4dae-b6ac-07a29750a63e',
                            selectable: true,
                            parentValue: 'e93ae1db-0f05-4e58-9fcf-a5a837cdfd61',
                          },
                          {
                            label:
                              'Витрина холодильная CRYSPI ВПС Sonata Q 1200 (RAL 7016)',
                            value: '144a5a1e-bd8b-4f6f-b4fa-e142c2ff4c27',
                            selectable: true,
                            parentValue: '0785d55b-9b11-4dae-b6ac-07a29750a63e',
                          },
                          {
                            label:
                              'Витрина холодильная CRYSPI Sonata Quadro 1500 LED (RAL7016)',
                            value: '46833c3a-bc6b-4fa9-861c-49a1eeeafc3e',
                            selectable: true,
                            parentValue: '0785d55b-9b11-4dae-b6ac-07a29750a63e',
                          },
                          {
                            label:
                              'Витрина холодильная CRYSPI ВПС Sonata Q 1800 (RAL 7016)',
                            value: 'aa846568-b467-4fc4-a5a1-205330e5c1ea',
                            selectable: true,
                            parentValue: '0785d55b-9b11-4dae-b6ac-07a29750a63e',
                          },
                          {
                            label:
                              'Витрина холодильная CRYSPI ВПС Octava Q 1200 (RAL 7016)',
                            value: '049a6abc-b45a-4e82-9da6-e2540a84a670',
                            selectable: true,
                            parentValue: '0785d55b-9b11-4dae-b6ac-07a29750a63e',
                          },

                          {
                            label: 'Витрины морозильные',
                            value: 'f39ca215-f567-4ee4-aa77-9c87832fe9dd',
                            selectable: true,
                            parentValue: 'e93ae1db-0f05-4e58-9fcf-a5a837cdfd61',
                          },
                          {
                            label:
                              'Витрина морозильная CRYSPI ВПН Sonata Quadro М 1200 LED (RAL 7016)',
                            value: '69bd6b3f-0060-4905-9816-3add94da007a',
                            selectable: true,
                            parentValue: 'f39ca215-f567-4ee4-aa77-9c87832fe9dd',
                          },
                          {
                            label:
                              'Витрина морозильная CRYSPI Sonata Quadro 1500 LED (RAL7016)',
                            value: '1c4f911a-e24c-4dfe-964a-eb5b5fb7e895',
                            selectable: true,
                            parentValue: 'f39ca215-f567-4ee4-aa77-9c87832fe9dd',
                          },
                          {
                            label:
                              'Витрина морозильная CRYSPI ВПС Sonata Q 1800 (RAL 7016)',
                            value: '40c38b2e-2096-4ccf-b8f1-658e55bd67c7',
                            selectable: true,
                            parentValue: 'f39ca215-f567-4ee4-aa77-9c87832fe9dd',
                          },
                          {
                            label:
                              'Витрина морозильная CRYSPI ВПС Octava Q 1200 (RAL 7016)',
                            value: '4951ab66-0a43-4c33-8638-0b0945316c11',
                            selectable: true,
                            parentValue: 'f39ca215-f567-4ee4-aa77-9c87832fe9dd',
                          },

                          {
                            label: 'Витрины универсальные',
                            value: '6bf7fb6c-7433-4e82-86ef-0cf7298c7c7b',
                            selectable: true,
                            parentValue: 'e93ae1db-0f05-4e58-9fcf-a5a837cdfd61',
                          },
                          {
                            label: 'Витрины кондитерские',
                            value: '4a0e6bbe-ef09-4766-a348-07f845555412',
                            selectable: true,
                            parentValue: 'e93ae1db-0f05-4e58-9fcf-a5a837cdfd61',
                          },
                        ],
                      },
                      {
                        name: 'textField',
                        type: 'text',
                        label: 'Комментарий',
                        mdCols: 6,
                      },
                    ],
                    defaultRows: [
                      [
                        {
                          name: 'radioTreeComboBox',
                          defaultValue: {
                            label: 'Витрины кондитерские',
                            value: '4a0e6bbe-ef09-4766-a348-07f845555412',
                            selectable: true,
                            parentValue: 'e93ae1db-0f05-4e58-9fcf-a5a837cdfd61',
                          },
                        },
                        {
                          name: 'textField',
                          defaultValue: '',
                        },
                      ],
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    },
  ],
};
