import {
  DirtyFormValues,
  SortingObject,
} from '../../../../services/Main/types.Component';
import { Field } from '../../../../services/Main/types.Field';
import { isEmptyFieldValue } from './isEmptyFieldValue';
import { getFieldValueLabel } from './getFieldValueLabel';

interface PreparedSortingArrayOfProps {
  field: Field;
  arrayOfValues: DirtyFormValues[];
  sortingConfig: SortingObject;
}

export const preparedSortingArrayOf = ({
  field,
  arrayOfValues,
  sortingConfig: { columnName },
}: PreparedSortingArrayOfProps) => {
  return arrayOfValues.reduce(
    (acc, fieldValues) => {
      if (isEmptyFieldValue(fieldValues[columnName], field)) {
        acc.emptyArrayOfFields.push(fieldValues);
      } else {
        acc.filledArrayOfFields.push(fieldValues);
      }

      return acc;
    },
    {
      filledArrayOfFields: [],
      emptyArrayOfFields: [],
    } as {
      filledArrayOfFields: DirtyFormValues[];
      emptyArrayOfFields: DirtyFormValues[];
    }
  );
};

interface SortArrayOfByColumnProps {
  field: Field;
  arrayOfFields: DirtyFormValues[];
  sortingConfig: SortingObject;
}

export const sortArrayOfByColumn = ({
  field,
  sortingConfig: { columnName, direction },
  arrayOfFields,
}: SortArrayOfByColumnProps): DirtyFormValues[] => {
  return arrayOfFields.sort((fieldValues1, fieldValues2) => {
    const a = getFieldValueLabel(fieldValues1[columnName], field);
    const b = getFieldValueLabel(fieldValues2[columnName], field);

    let comparison = 0;

    if (a !== null && b !== null) {
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
    }

    return direction === 'asc' ? comparison : -comparison;
  });
};
