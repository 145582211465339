import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import get from 'lodash.get';
import { useFormatMessage } from '../../../../../../locale';
import useStyles from './CommentField.styles';

interface Props {
  name: string;
}

function CommentField({ name }: Props) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const {
    field: { onChange, onBlur, ref, value },
  } = useController({
    name,
    defaultValue: '',
    rules: {
      required: formatMessage('requiredField'),
    },
  });
  const { errors } = useFormContext();
  const error = get(errors, name);

  return (
    <TextField
      name={name}
      variant="outlined"
      fullWidth
      className={classes.field}
      size="small"
      multiline
      rowsMax={10}
      placeholder="Написать сообщение..."
      error={Boolean(error)}
      helperText={error?.message}
      onChange={onChange}
      onBlur={onBlur}
      inputRef={ref}
      value={value}
    />
  );
}

export default CommentField;
