import { createStyles, darken, makeStyles } from '@material-ui/core';
import { Theme } from '../../../theme';

export const TIMELINE_ELEMENT_CARD_WIDTH_XL_PLUS = '1200px';
export const TIMELINE_ELEMENT_CARD_WIDTH_LG_PLUS = '800px';
export const TIMELINE_ELEMENT_CARD_WIDTH_MD_PLUS = '600px';
export const TIMELINE_ELEMENT_CARD_WIDTH_SM_PLUS = '280px';
export const TIMELINE_ELEMENT_CARD_WIDTH_XS_PLUS = '220px';

export default makeStyles((theme: Theme) =>
  createStyles({
    TimelineHeader: {
      color: theme.additionalPalette.title,
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    CommonTimelineElement: {
      width: TIMELINE_ELEMENT_CARD_WIDTH_XS_PLUS,
      [theme.breakpoints.up('sm')]: {
        width: TIMELINE_ELEMENT_CARD_WIDTH_SM_PLUS,
      },
      [theme.breakpoints.up('md')]: {
        width: TIMELINE_ELEMENT_CARD_WIDTH_MD_PLUS,
      },
      [theme.breakpoints.up('lg')]: {
        width: TIMELINE_ELEMENT_CARD_WIDTH_LG_PLUS,
      },
      [theme.breakpoints.up('xl')]: {
        width: TIMELINE_ELEMENT_CARD_WIDTH_XL_PLUS,
      },
    },
    CommonTimelineElementBody: {
      marginBottom: theme.spacing(1),
      whiteSpace: 'pre-wrap',
      overflowX: 'auto',
    },
    CommonTimelineElementBodyContainer: {
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing(0),
      },
    },
    CommonTimelineElementSidebar: {},
    CommonTimelineElementCreatedAt: {
      color: '#9a99a2',
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
    },
    CommonTimelineElementQuoteButton: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.additionalPalette.lightGray,
      cursor: 'pointer',
      alignSelf: 'flex-end',

      '&:hover': {
        color: darken(theme.additionalPalette.lightGray, 0.2),
      },
    },
    CommonTimelineElementIcon: {
      fontSize: theme.typography.pxToRem(16),
      color: theme.additionalPalette.title,
      cursor: 'pointer',
    },
    TimelineElementWithBorder: {
      border: '1px solid',
      borderColor: theme.additionalPalette.border,
      borderRadius: 3,
    },
  })
);
