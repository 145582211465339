import { DownloadFileReaction } from '../../services/Main/types.Component';

const downloadFileReaction = (
  reaction: DownloadFileReaction,
  payload: string
) => {
  const link = document.createElement('a');
  link.href = payload;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export default downloadFileReaction;
