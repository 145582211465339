import { Page } from 'services/Main/types.Page';

export const ratingFieldFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами RatingField',
  component: {
    id: 100200300,
    businessComponentId: 28822828,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          variant: 'contained',
          color: 'primary',
          label: 'Сохранить',
          requestConfig: { url: '/api/fields/rating', method: 'GET' },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами RatingField' },
        fieldGroups: [
          {
            label: 'rating group',
            fields: [
              {
                type: 'rating',
                name: 'rating-field-example',
                defaultValue: 3.5,
                label: 'Оценка',
              },
              {
                type: 'rating',
                name: 'rating-field-example2',
                defaultValue: 4,
                label: 'Оценка (disabled)',
                disabled: true,
              },
            ],
          },
        ],
      },
    },
  },
};
