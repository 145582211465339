import React, { useContext } from 'react';
import {
  DragDropProvider,
  Grid,
  Table,
  TableColumnVisibility,
  TableColumnResizing,
  TableColumnReordering,
  TableHeaderRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  IntegratedSelection,
  PagingState,
  SelectionState,
  SortingState as GridSortingState,
} from '@devexpress/dx-react-grid';
import { Skeleton } from '@material-ui/lab';
import StickyTable from 'components/lowLevel/StickyTable';
import TableContainer from 'components/lowLevel/TableContainer';
import {
  ALL_FORMATS,
  SortingState,
} from '../../../../../services/Main/types.Component';
import TableHeaderTooltip from '../../../TableHeaderTooltip';
import { useFormatMessage } from '../../../../../locale';
import { findDataTypes } from '../../../../highLevel/Table/helpers';
import DataTypeProviders from '../../../../highLevel/Table/TypeProviders/DataTypeProviders';
import TableSelectRow from './components/TableSelectRow';
import { SortingColumnExtension } from './hooks/useSorting';
import EntryPickerTableContext from './EntryPickerTable.context';
import TableSelectCell from './components/TableSelectCell';
import TableSelectAllCell from './components/TableSelectAllCell';
import EntryPickerTableColumnVisibilityMessage from './components/EntryPickerTableColumnVisibilityMessage';
import DragDropContainer from './components/DragDropContainer';

interface EntryPickerTableViewProps {
  onSelectionChange: (newSelection: Array<number | string>) => void;
  someColumnSortable: boolean;
  sortingStateColumnExtensions: SortingColumnExtension[];
}

const EntryPickerTableView = ({
  onSelectionChange,
  someColumnSortable,
  sortingStateColumnExtensions,
}: EntryPickerTableViewProps) => {
  const formatMessage = useFormatMessage();
  const {
    rows,
    pageSize,
    currentPage,
    selectedRowsId,
    setSorting,
    sorting,
    loadRows,
    filterOptions,
    entryPickerProps,
    tableProps,
    columnWidths,
    setColumnWidths,
    hiddenColumnNames,
    columnOrder,
    setColumnOrder,
  } = useContext(EntryPickerTableContext);

  const { multiple } = entryPickerProps;

  const handleSortingChange = (newSortingState: SortingState) => {
    setSorting(newSortingState);
    loadRows({
      pageSize,
      currentPage,
      filter: filterOptions,
      sort: newSortingState,
    });
  };

  if (!tableProps || columnOrder.length === 0) {
    return <Skeleton variant="rect" width="100%" height="100%" />;
  }

  const dataTypeProvidersProps = findDataTypes(
    tableProps.columns,
    Array.from(ALL_FORMATS)
  );

  return (
    <Grid getRowId={(row) => row.id} rows={rows} columns={tableProps.columns}>
      <GridSortingState
        sorting={sorting}
        columnExtensions={sortingStateColumnExtensions}
        onSortingChange={handleSortingChange}
      />
      <PagingState currentPage={currentPage} pageSize={pageSize} />
      <SelectionState
        selection={selectedRowsId}
        onSelectionChange={onSelectionChange}
      />
      <DataTypeProviders {...dataTypeProvidersProps} />
      {tableProps.enableColumnReordering && (
        <DragDropProvider containerComponent={DragDropContainer} />
      )}
      <Table
        messages={{
          noData: formatMessage('noData'),
        }}
        tableComponent={StickyTable}
        containerComponent={TableContainer}
      />
      {tableProps.enableColumnReordering && (
        <TableColumnReordering
          order={columnOrder}
          onOrderChange={setColumnOrder}
        />
      )}
      {tableProps.columnVisibilityConfig?.enabled && (
        <TableColumnVisibility
          hiddenColumnNames={hiddenColumnNames}
          messages={{
            noColumns: formatMessage('allColumnsAreHidden'),
          }}
          emptyMessageComponent={EntryPickerTableColumnVisibilityMessage}
        />
      )}
      {tableProps.enableColumnResizing && (
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={setColumnWidths}
        />
      )}
      <TableHeaderRow
        sortLabelComponent={TableHeaderTooltip}
        showSortingControls={someColumnSortable}
      />
      {multiple && <IntegratedSelection />}
      <TableSelection
        showSelectionColumn={multiple}
        showSelectAll={multiple}
        selectByRowClick
        highlightRow
        rowComponent={TableSelectRow}
        cellComponent={TableSelectCell}
        headerCellComponent={TableSelectAllCell}
      />
    </Grid>
  );
};

export default EntryPickerTableView;
