import React, { useContext } from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { TableHeaderRow as TableHeaderRowBase } from '@devexpress/dx-react-grid';
import { TableColumn } from '../../../../../services/Main/types.Component';
import { TableContext } from '../../TableContext';

const alignToFlexMatch = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const TableHeaderCellContent = (props: TableHeaderRowBase.CellProps & any) => {
  const { column, style, children } = props;

  const tableContext = useContext(TableContext);

  const { tableColumnViewConfig } = column as TableColumn;

  const columnExtension = tableContext?.columnExtensions?.find(
    (extension) => extension.columnName === column.name
  );

  return (
    <TableHeaderRow.Content
      {...props}
      style={{
        ...style,
        // headerCellTextAlign выше по приоритету, чем alignAll
        justifyContent:
          alignToFlexMatch[
            tableColumnViewConfig?.headerCellTextAlign ||
              columnExtension?.alignAll ||
              'left'
          ],
      }}
    >
      {children}
    </TableHeaderRow.Content>
  );
};

export default TableHeaderCellContent;
