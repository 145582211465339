import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';
import { getMenuItemStyles } from '../MenuItem/MenuItem.styles';

export default makeStyles((theme: Theme) => {
  const menuItemStyles = getMenuItemStyles(theme);

  return createStyles({
    menuItem: {
      ...menuItemStyles,
      stroke: theme.additionalPalette.title,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      '& .chevron': {
        fill: menuItemStyles.color,
        stroke: menuItemStyles.color,
        fontSize: theme.typography.pxToRem(14),
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      '&.open .chevron': {
        transform: 'rotate(90deg)',
      },
    },
    label: {
      whiteSpace: 'normal',
      lineHeight: 1.2,
      fontSize: '0.85rem',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  });
});
