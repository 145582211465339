import { AuthServiceBase } from './AuthService.base';

let AuthService: any;

if (
  process.env.REACT_APP_ENV === 'development' &&
  process.env.REACT_APP_IS_REMOTE !== 'true'
) {
  AuthService = require('./local/AuthService.local').default;
} else {
  AuthService = require('./remote/AuthService.remote').default;
}

const authService = new AuthService() as AuthServiceBase;
export default authService;
