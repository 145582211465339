import { Page } from 'services/Main/types.Page';

export const twoLevelBreadcrumbsPage: Page = {
  type: 'singleComponent',
  title: 'Простая форма с одним полем',
  breadcrumbs: {
    items: [
      {
        icon: 'mui:Home',
        url: '/',
      },
      {
        label: 'Контрагенты',
        icon: 'mui:PeopleOutline',
        url: '/breadcrumbs/1-level',
      },
      {
        label: 'Договоры',
        icon: 'mui:FolderOutlined',
      },
    ],
  },
  component: {
    id: 100,
    businessComponentId: 31,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        header: { heading: 'Простая форма с одним полем' },
        fieldGroups: [
          {
            label: 'Название группы',
            fields: [{ type: 'text', name: 'comment', label: 'Комментарий' }],
          },
        ],
      },
    },
  },
};
