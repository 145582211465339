import faker from 'faker';
import { Page } from 'services/Main/types.Page';

faker.locale = 'ru';

const createRow = () => {
  return {
    id: faker.datatype.uuid(),
    code: `${faker.datatype.number({
      min: 1000,
      max: 9999,
    })}-${faker.datatype.number({
      min: 100,
      max: 999,
    })}/${faker.datatype.number({
      min: 10,
      max: 99,
    })}`,
    createdAt: faker.date.past().toISOString(),
    type: 'Заглушка',
    comment: faker.lorem.words(4),
    author: `${faker.name.lastName()} ${faker.name.firstName()}`,
    assignee: `${faker.name.lastName()} ${faker.name.firstName()}`,
    deadline: faker.date.future().toISOString(),
  };
};

const tableLength = 529;
const pageSize = 20;
const currentPage = 0;

export const allWithFiltersTablePageRows = Array.from(
  { length: tableLength },
  createRow
);

export const withFiltersTablePage: Page = {
  type: 'singleComponent',
  title: 'Пример таблицы с фильтрами',
  component: {
    id: 200,
    businessComponentId: 39,
    type: 'table',
    props: {
      heading: 'Пример таблицы с фильтрами',
      requestConfig: { url: '/high-level-components/table/with-filters' },
      columns: [
        { title: 'Номер', name: 'code' },
        {
          title: 'Создано',
          name: 'createdAt',
          options: {
            formatAs: 'dateTime',
          },
        },
        { title: 'Тип', name: 'type' },
        {
          title: 'Тема',
          name: 'comment',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        {
          title: 'Инициатор',
          name: 'author',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        {
          title: 'Ответственный',
          name: 'assignee',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        {
          title: 'Срок выполнения',
          name: 'deadline',
          options: {
            formatAs: 'dateTime',
          },
        },
      ],
      rows: allWithFiltersTablePageRows.slice(
        currentPage * pageSize,
        currentPage * pageSize + pageSize
      ),
      options: {
        totalRows: tableLength,
        currentPage,
        pageSize,
        sorting: [{ columnName: 'code', direction: 'desc' }],
      },
      filterComponent: {
        type: 'tableFilter',
        id: 10000,
        businessComponentId: 23,
        props: {
          requestOn: 'change',
          fieldGroups: [
            {
              fields: [
                {
                  type: 'entryPicker',
                  name: 'entity',
                  label: 'Объект',
                  gridRequestConfig: {
                    url: '/api/entry-picker/grid',
                    method: 'POST',
                  },
                  searchRequestConfig: {
                    url: '/api/entry-picker/search',
                    method: 'GET',
                  },
                  defaultValue: {
                    label: '004 Красногорск',
                    value: 'fee09067-c17e-4c32-a4be-75f24258d00a',
                  },
                  multiple: false,
                  disableClearable: true,
                  mdCols: 6,
                },
                {
                  type: 'datePicker',
                  pickerType: 'year',
                  name: 'yearPicker',
                  label: 'Год',
                  disableClearable: true,
                  mdCols: 6,
                },
                {
                  type: 'datePicker',
                  pickerType: 'dateRange',
                  name: 'dateRange',
                  label: 'Date range',
                  initialFocusedDate: 'now',
                },
                {
                  type: 'datePicker',
                  pickerType: 'dateTimeRange',
                  name: 'dateTimeRange',
                  label: 'Date Time range',
                  initialFocusedDate: '2020-04-15T16:33:23.3004811+03:00',
                },
                { type: 'text', name: 'text', label: 'Text' },
                {
                  type: 'formattedNumber',
                  formattedNumberType: 'price',
                  name: 'formattedNumber',
                  label: 'Formatted number',
                  currencySign: '₽',
                  min: '0',
                },
                {
                  type: 'select',
                  selectType: 'single',
                  name: 'singleSelect',
                  label: 'Select single',
                  options: [
                    { value: 'Critical', label: 'Критичный' },
                    { value: 'High', label: 'Высокий' },
                    { value: 'Low', label: 'Низкий' },
                  ],
                },
                {
                  type: 'select',
                  selectType: 'multi',
                  name: 'multiSelect',
                  label: 'Select multi',
                  options: [
                    { value: 'Draft', label: 'Черновик' },
                    { value: 'Pause', label: 'Отложена' },
                    { value: 'Closing', label: 'Сдача-приемка' },
                    { value: 'Canceled', label: 'Прекращена' },
                    { value: 'Done', label: 'Выполнено' },
                    { value: 'Approval', label: 'На согласовании' },
                    { value: 'InWork', label: 'В работе' },
                    { value: 'Queue', label: 'В очереди' },
                  ],
                },
              ],
            },
          ],
        },
      },
    },
  },
};
