import faker from 'faker';
import { format } from 'date-fns';
import { FilterOptions, SortingState, TableRow } from '../Main/types.Component';
import {
  FetchTablePropsRequestBody,
  FetchTablePropsResponsePayload,
} from './EntryPicker.interface';
import { ComboBoxOption, OptionsType } from '../Main/types.Field';

const createRow = (): TableRow => {
  return {
    id: faker.datatype.uuid(),
    status: faker.random.arrayElement(['Исправно', 'Неисправно']),
    equipment: {
      href: `/equipments/${faker.datatype.uuid()}`,
      label: `${faker.commerce.productMaterial()} ${faker.commerce.color()} ${faker.commerce.product()}`,
    },
    shop: faker.random.arrayElement(['004 Красногорск', '005 Химки']),
    count: `${faker.datatype.number({ min: 1, max: 99 })} шт.`,
    entryDate: format(
      faker.date.between(
        '2020-01-01T00:00:00.000Z',
        '2021-01-01T00:00:00.000Z'
      ),
      'dd.MM.yyyy'
    ),
    equipmentCategory: faker.commerce.productMaterial(),
  };
};

const getEntryPickerRows = (length: number) =>
  Array.from({ length }, createRow);

const entryPickerRows = getEntryPickerRows(529).sort((a, b) =>
  a.equipment.label.localeCompare(b.equipment.label)
);

const entryPickerOptions: ComboBoxOption[] = entryPickerRows.map((r) => ({
  value: r.id as string,
  label: r.equipment.label,
  href: r.equipment.href,
}));

const entryPickerOptionByValue = new Map<string | number, ComboBoxOption>(
  [...entryPickerOptions].map((o) => [o.value, o])
);

const memoizedGetSortEntryPickerRows = () => {
  const cache: Record<string, TableRow[]> = {};
  return (sort?: SortingState) => {
    if (!Array.isArray(sort)) return entryPickerRows;

    const { direction, columnName } = sort[0];

    const cacheName = `${columnName}_${direction}`;

    if (cacheName in cache) {
      // Fetching from cache
      return cache[cacheName];
    }

    // Calculating result
    const res = entryPickerRows.sort((row1, row2) => {
      if (direction === 'asc') {
        return (row1[columnName] as string).localeCompare(
          row2[columnName],
          undefined,
          { numeric: true, sensitivity: 'base' }
        );
      }
      return (row2[columnName] as string).localeCompare(
        row1[columnName],
        undefined,
        { numeric: true, sensitivity: 'base' }
      );
    });

    cache[cacheName] = res;

    return res;
  };
};

const getSortEntryPickerRows = memoizedGetSortEntryPickerRows();

export const getEntryPickerStubs = (
  params?: FetchTablePropsRequestBody
): FetchTablePropsResponsePayload => {
  const preparedEntryPickerRows = (() => {
    const sortedEntryPickerRows = getSortEntryPickerRows(params?.sort);

    if (params?.filter && Object.keys(params?.filter).length > 0) {
      return sortedEntryPickerRows.filter((row) => {
        return Object.entries(params.filter as FilterOptions).every(
          ([fieldName, fieldValue]) => {
            const currentRowLabel: string =
              typeof row[fieldName] === 'object'
                ? row[fieldName].label
                : row[fieldName];

            if (typeof fieldValue === 'string') {
              return currentRowLabel
                .toLowerCase()
                .includes(fieldValue.toLowerCase());
            }

            // Жесткая проверка ComboBoxOption[]
            if (Array.isArray(fieldValue)) {
              // @ts-ignore
              return (fieldValue as ComboBoxOption[]).some((fv) =>
                currentRowLabel.includes(fv.value)
              );
            }

            return false;
          }
        );
      });
    }

    return sortedEntryPickerRows;
  })();

  const currentPage = params?.currentPage ?? 0;
  const pageSize = params?.pageSize ?? 25;

  const start = currentPage * pageSize;
  const end = start + pageSize;
  const resultEntryPickerRows = preparedEntryPickerRows.slice(start, end);

  return {
    header: {
      heading: 'Оборудование',
    },
    columns: [
      {
        title: 'Статус',
        name: 'status',
        options: {
          sortable: true,
        },
      },
      {
        title: 'Оборудование',
        name: 'equipment',
        options: {
          formatAs: 'link',
        },
      },
      {
        title: 'Магазин',
        name: 'shop',
        options: {
          sortable: true,
        },
      },
      {
        title: 'Количество',
        name: 'count',
        options: {
          sortable: true,
        },
      },
      {
        title: 'Дата ввода',
        name: 'entryDate',
      },
      { title: 'Категория оборудования', name: 'equipmentCategory' },
    ],
    columnExtensions: [
      {
        columnName: 'status',
        width: 130,
      },
      {
        columnName: 'equipment',
        width: 300,
      },
      {
        columnName: 'shop',
        width: 150,
      },
      {
        columnName: 'count',
        width: 140,
      },
      {
        columnName: 'entryDate',
        width: 190,
      },
      {
        columnName: 'equipmentCategory',
        width: 300,
      },
    ],
    enableColumnResizing: true,
    columnVisibilityConfig: {
      enabled: true,
    },
    enableColumnReordering: true,
    options: {
      currentPage,
      pageSize,
      pageSizeOptions: [10, 25, 50, 100, 250, 500],
    },
    rows: resultEntryPickerRows,
    filterComponent: {
      type: 'tableFilter',
      id: 12345,
      businessComponentId: 23,
      props: {
        requestOn: 'change',
        fieldGroups: [
          {
            fields: [
              {
                type: 'text',
                name: 'equipment',
                label: 'Оборудование',
              },
              {
                type: 'comboBox',
                optionsType: OptionsType.flat,
                name: 'status',
                multiple: true,
                label: 'Статус',
                options: [
                  { value: 'Исправно', label: 'Исправно' },
                  { value: 'Неисправно', label: 'Неисправно' },
                ],
              },
              {
                type: 'comboBox',
                optionsType: OptionsType.flat,
                name: 'shop',
                // @ts-ignore
                multiple: true,
                label: 'Магазин',
                options: [
                  { value: '004 Красногорск', label: '004 Красногорск' },
                  { value: '005 Химки', label: '005 Химки' },
                ],
              },
            ],
          },
        ],
      },
    },

    totalRows: preparedEntryPickerRows.length,

    sort: params?.sort,
  };
};

export function filterOptions(query: string) {
  const output = [];

  for (let i = 0; i < entryPickerOptions.length; i++) {
    if (output.length === 11) break;

    if (
      entryPickerOptions[i].label.toLowerCase().includes(query.toLowerCase())
    ) {
      output.push(entryPickerOptions[i]);
    }
  }

  return output;
}

export function getOptions(optionValues: (string | number)[]) {
  return optionValues.reduce((acc, value) => {
    const match = entryPickerOptionByValue.get(value);

    if (match) {
      return [...acc, match];
    }

    return acc;
  }, [] as ComboBoxOption[]);
}
