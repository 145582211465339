import { PageContext } from 'components/creational/PageLoader/PageLoader';

/**
 * Функция для реализации перезапроса данных для модуля
 */
const reLoadModule = (pageContext: PageContext) => {
  pageContext.reload();
};

export default reLoadModule;
