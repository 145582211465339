import { Page, PageId } from '../types.Page';
import { repairTaskForm } from './stubs/repairTaskForm';
import { issuesPage } from './stubs/issuesPage';
import { issuesElementPage } from './stubs/issuesElementPage';
import { redirectReactionPage } from './stubs/redirectReactionPage';
import { dynamicRedirectReactionPage } from './stubs/dynamicRedirectReactionPage';
import { reLoadModuleReactionPage } from './stubs/reLoadModuleReactionPage';
import { asyncRedirectReactionPage } from './stubs/asyncRedirectReactionPage';
import { showAlertAndRedirectReactionPage } from './stubs/showAlertAndRedirectReactionPage';
import { simpleTablePage } from './stubs/highLevelComponents/Table/Simple';
import { withCellFormattersTablePage } from './stubs/highLevelComponents/Table/WithCellFormatters';
import { withFiltersTablePage } from './stubs/highLevelComponents/Table/WithFilters';
import { withRowClickReactionTablePage } from './stubs/highLevelComponents/Table/WithRowClickReaction';
import { simpleFormPage } from './stubs/highLevelComponents/Form/Simple';
import { textFieldFormPage } from './stubs/fields/TextField';
import { selectFieldFormPage } from './stubs/fields/Select';
import { dateFieldFormPage } from './stubs/fields/Date';
import { formattedNumberFieldFormPage } from './stubs/fields/FormattedNumber';
import { fileUploadFormPage } from './stubs/fields/FileUpload';
import { arrayOfFormPage } from './stubs/fields/ArrayOf';
import { radioGroupFormPage } from './stubs/fields/RadioGroup';
import { allFieldsFormPage } from './stubs/highLevelComponents/Form/AllFields';
import { singleComponentExamplePage } from './stubs/pageViews/SingleComponent';
import { tabsViewExamplePage } from './stubs/pageViews/TabsView';
import { notAllowedPageExample } from './stubs/pageViews/403';
import { notFoundPageExample } from './stubs/pageViews/404';
import { headerFormPage } from './stubs/highLevelComponents/Form/Header';
import { submitFormActionPage } from './stubs/actions/SubmitForm';
import { triggerEndpointActionPage } from './stubs/actions/TriggerEndpoint';
import { showFormVar1ActionPage } from './stubs/actions/ShowForm.variant1';
import { showFormVar2ActionPage } from './stubs/actions/ShowForm.variant2';
import { calendarSTMPage } from './stubs/complex/calendarSTM';
import { checkboxFormPage } from './stubs/fields/Checkbox';
import { headerTablePage } from './stubs/highLevelComponents/Table/WithHeader';
import { validationErrorReactionPage } from './stubs/validationErrorReactionPage';
import { withAlertsFormPage } from './stubs/highLevelComponents/Form/WithAlerts';
import { redirectActionPage } from './stubs/actions/Redirect';
import { approveServiceWorkForm } from './stubs/complex/approveServiceWorkForm';
import { withValidationFormPage } from './stubs/highLevelComponents/Form/WithValidation';
import { asyncShowComponentReactionPage } from './stubs/reactions/asyncShowComponentReactionPage';
import { downloadFileReactionPage } from './stubs/reactions/downloadFileReaction';
import { widgetsExamplePage } from './stubs/pageViews/Widgets.stub';
import { groupOfTextWidgetPage } from './stubs/widgets/groupOfTextWidget.stub';
import { HTMLPrinterPage } from './stubs/highLevelComponents/HTMLPrinter/HTMLPrinter';
import { conditionalFieldFormPage } from './stubs/featureDemo/Conditional.stub';
import { matchesValidationPage } from './stubs/highLevelComponents/validations/matchesValidationPage';
import { separateRowClickReactionPage } from './stubs/highLevelComponents/Table/SeparateRowClickReaction';
import { goBackActionPage } from './stubs/actions/GoBack';
import { comboBoxFieldFormPage } from './stubs/fields/ComboBox.stub';
import { fieldsScrollStubs } from './stubs/featureDemo/FieldsScroll.stubs';
import { withAlertsTablePage } from './stubs/highLevelComponents/Table/WithAlerts';
import { interactiveFormPage } from './stubs/featureDemo/InteractiveForm';
import { entryPickerFormPage } from './stubs/fields/EntryPicker';
import { removedPageExample } from './stubs/pageViews/removedPage.stubs';
import { issuesCreatePageStep1 } from './stubs/issuesCreatePage';
import { someTasksPage } from './stubs/router-testing/someTasksPage';
import { someTasksCreatePage } from './stubs/router-testing/someTasksCreatePage';
import { someTasksElementPage } from './stubs/router-testing/someTasksElementPage';
import { someTasksSubTaskElementPage } from './stubs/router-testing/someTasksSubTaskElementPage';
import { someTasksCreateSubtaskPage } from './stubs/router-testing/someTasksCreateSubtaskPage';
import { tinTestPage } from './stubs/featureDemo/TinTest.stubs';
import { arrayOfUniqueStubs } from './stubs/featureDemo/arrayOfUnique.stubs';
import { hideFieldGroupsFormPage } from './stubs/featureDemo/HideFieldGroups.stub';
import { arrayOfTableStubs } from './stubs/fields/ArrayOfTable.stubs';
import { groupingTableStubs } from './stubs/highLevelComponents/Table/Grouping';
import { tableWithRowActionsPage } from './stubs/highLevelComponents/Table/WithRowActions';
import { calendarSTMPageV2 } from './stubs/complex/calendarSTMv2';
import { calendarMonthlySTMPage } from './stubs/complex/calendarMonthlySTM';
import { asyncFormPage } from './stubs/featureDemo/AsyncFields';
import { oneLevelBreadcrumbsPage } from './stubs/breadcrumbs/OneLevel.breadcrumbs';
import { twoLevelBreadcrumbsPage } from './stubs/breadcrumbs/TwoLevel.breadcrumbs';
import { threeLevelBreadcrumbsPage } from './stubs/breadcrumbs/ThreeLevel.breadcrumbs';
import { fourLevelBreadcrumbsPage } from './stubs/breadcrumbs/FourLevel.breadcrumbs';
import { fiveLevelBreadcrumbs } from './stubs/breadcrumbs/FiveLevel.breadcrumbs';
import { sixLevelBreadcrumbs } from './stubs/breadcrumbs/SixLevel.breadcrumbs';
import { sevenLevelBreadcrumbsPage } from './stubs/breadcrumbs/SevenLevel.breadcrumbs';
import { groupedWidgetsExamplePage } from './stubs/pageViews/GroupedWidgets.stub';
import { lineChartWidgetsStubs } from './stubs/widgets/lineChartWidgets.stubs';
import { calendarMonthlySTMWithTrucksPage } from './stubs/complex/calendarMonthlySTMWithTrucks';
import { barChartWidgetsStubs } from './stubs/widgets/barChartWidgets.stubs';
import { ratingFieldFormPage } from './stubs/fields/RatingField';
import { recycleManagement } from './stubs/complex/recycleManagement';
import { homeObjectResponsible } from './stubs/complex/homeObjectResponsible';
import { calendarMonthlySTMChipValueMetric } from './stubs/complex/calendarMonthlySTMWithChipValueMetric';

type PageMap = {
  [key in PageId]: Page;
};

const pages: PageMap = {
  65535: { type: 'preset', presetName: 'home' },
  1: repairTaskForm,
  2: issuesPage,
  3: issuesElementPage,
  8: issuesPage,
  11: notAllowedPageExample,
  15: redirectReactionPage,
  16: dynamicRedirectReactionPage,
  17: reLoadModuleReactionPage,
  18: asyncRedirectReactionPage,
  19: showAlertAndRedirectReactionPage,
  20: simpleTablePage,
  21: withCellFormattersTablePage,
  22: withFiltersTablePage,
  23: withRowClickReactionTablePage,
  24: issuesElementPage,
  25: simpleFormPage,
  26: textFieldFormPage,
  27: selectFieldFormPage,
  28: dateFieldFormPage,
  29: formattedNumberFieldFormPage,
  30: fileUploadFormPage,
  31: arrayOfFormPage,
  32: radioGroupFormPage,
  33: allFieldsFormPage,
  34: singleComponentExamplePage,
  35: tabsViewExamplePage,
  36: notFoundPageExample,
  37: notAllowedPageExample,
  38: headerFormPage,
  39: submitFormActionPage,
  40: triggerEndpointActionPage,
  41: showFormVar1ActionPage,
  42: showFormVar2ActionPage,
  43: widgetsExamplePage,
  44: calendarSTMPage,
  45: checkboxFormPage,
  46: headerTablePage,
  47: validationErrorReactionPage,
  48: withAlertsFormPage,
  49: redirectActionPage,
  50: approveServiceWorkForm,
  51: withValidationFormPage,
  55: asyncShowComponentReactionPage,
  56: downloadFileReactionPage,
  57: groupOfTextWidgetPage,
  58: HTMLPrinterPage,
  60: conditionalFieldFormPage,
  62: matchesValidationPage,
  65: separateRowClickReactionPage,
  66: goBackActionPage,
  67: comboBoxFieldFormPage,
  68: fieldsScrollStubs,
  69: withAlertsTablePage,
  70: entryPickerFormPage,
  71: removedPageExample,
  72: issuesCreatePageStep1,
  73: someTasksPage,
  74: someTasksElementPage,
  75: someTasksSubTaskElementPage,
  76: someTasksCreatePage,
  77: someTasksCreateSubtaskPage,
  78: tinTestPage,
  79: interactiveFormPage,
  80: arrayOfUniqueStubs,
  81: hideFieldGroupsFormPage,
  82: arrayOfTableStubs,
  83: groupingTableStubs,
  84: tableWithRowActionsPage,
  85: calendarSTMPageV2,
  86: calendarMonthlySTMPage,
  87: asyncFormPage,
  88: oneLevelBreadcrumbsPage,
  89: twoLevelBreadcrumbsPage,
  90: threeLevelBreadcrumbsPage,
  91: fourLevelBreadcrumbsPage,
  92: fiveLevelBreadcrumbs,
  93: sixLevelBreadcrumbs,
  94: sevenLevelBreadcrumbsPage,
  95: groupedWidgetsExamplePage,
  96: lineChartWidgetsStubs,
  492300029: calendarMonthlySTMWithTrucksPage,
  97: barChartWidgetsStubs,
  98: ratingFieldFormPage,
  99: recycleManagement,
  100: homeObjectResponsible,
  101: calendarMonthlySTMChipValueMetric,
};

export const getPage = (pageId: PageId): Page => {
  if (pages.hasOwnProperty(pageId)) return pages[pageId];

  return { type: 'notFound' };
};
