import React, { forwardRef, useContext } from 'react';
import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import objectHash from 'object-hash';
import { TableFilterFormProps } from '../TableModules';
import { DirtyFormValue } from '../../../services/Main/types.Component';
import { SelectOption } from '../../../services/Main/types.Field';
import getDefaultValue from '../TableFilters/getDefaultValue';
import useStyles from './TableFilterChips.styles';
import { TableContext } from '../../highLevel/Table';

const TableFilterChips = ({
  filter,
  filterChips,
  setFilter,
}: TableFilterFormProps) => {
  const classes = useStyles();
  const tableContextValue = useContext(TableContext);

  const handleDelete = (
    fieldName: string,
    dirtyValue: DirtyFormValue
  ) => () => {
    setFilter({
      ...filter,
      [fieldName]:
        filter && Array.isArray(filter[fieldName])
          ? (filter[fieldName] as SelectOption[]).filter(
              (o) => o.value !== (dirtyValue as SelectOption).value
            )
          : getDefaultValue(fieldName, tableContextValue),
    });
  };

  return (
    <>
      {filterChips && filterChips.length > 0 && (
        <MuiTabs
          value={false} // см. аннотацию свойства
          className={classes.chips}
          variant="scrollable"
        >
          {filterChips.map(({ printValue, fieldName, dirtyValue }) => (
            <Tab
              key={`${fieldName}_${printValue}_${objectHash(dirtyValue)}`}
              label={printValue}
              component={forwardRef<HTMLDivElement | null>(
                ({ children }, ref) => (
                  <Chip
                    ref={ref}
                    className={classes.chip}
                    label={children}
                    color="primary"
                    variant="outlined"
                    onDelete={handleDelete(fieldName, dirtyValue)}
                  />
                )
              )}
            />
          ))}
        </MuiTabs>
      )}
    </>
  );
};

export default TableFilterChips;
