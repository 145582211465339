import React, { FunctionComponent, MouseEventHandler } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Component,
  DialogWithComponent,
  ShowAsyncModalOnCellClickReaction,
  STMCell as STMCellProps,
} from '../../../../../services/Main/types.Component';
import STMChip from '../../components/STMChip';
import mainService from '../../../../../services/Main';
import useTableContext from '../../useTableContext';
import useTableDialogWithComponentContext from '../../components/TableDialogWithComponent/useTableDialogWithComponentContext';

const Formatter: FunctionComponent<DataTypeProvider.ValueFormatterProps> = ({
  value,
  row,
  column,
}) => {
  const { setDialogWithComponent } = useTableDialogWithComponentContext();
  const tableContext = useTableContext();
  const { cellClickReaction } = tableContext.options;
  if (!value || (Array.isArray(value) && value.length === 0)) return null;

  const isClickableCell = (
    cellClickReaction as ShowAsyncModalOnCellClickReaction
  )?.columnNames.includes(column.name);

  const handleClick =
    (valueIndex: number): MouseEventHandler =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (!isClickableCell) return;

      const STMCell = value[valueIndex];

      mainService
        .makeActionRequest<Component | DialogWithComponent>(
          (cellClickReaction as ShowAsyncModalOnCellClickReaction)
            .fetchModalDataRequestConfig,
          { column, row, STMCell }
        )
        .then(({ isSuccess, payload }) => {
          if (isSuccess) {
            const isDialogWithComponent =
              'component' in payload && 'viewStyle' in payload;

            if (!isDialogWithComponent) {
              setDialogWithComponent({
                component: payload as Component,
                viewStyle: 'modal',
              });
            } else {
              // if (
              //   process.env.REACT_APP_ENV === 'production' ||
              //   process.env.REACT_APP_IS_REMOTE === 'true'
              // ) {
              //   sendMessageToBot(
              //     'Похоже, бек освоил isDialogWithComponent для ППРов и можно выпилить поддержку обратной совместимости. В файле getTableCell.tsx'
              //   );
              // }

              // TODO TODO: Разобраться с комментом ниже (ниже идёт старый текст)
              // TODO: После освоения бэка оставить только строку ниже, остальное удалить.
              //  Также нужно поправить типизацию у makeActionRequest
              setDialogWithComponent(payload as DialogWithComponent);
            }
          }
        });
    };

  if (value.length > 4) {
    throw new Error(
      `В ячейки типа STMCells не может быть более 4 значений! Прилетело: ${value.length}`
    );
  }

  return (
    <>
      {(value as STMCellProps[]).map((SMTValue, index) => (
        <STMChip
          className={isClickableCell ? 'clickable' : undefined}
          onClick={handleClick(index)}
          key={`${Object.values(SMTValue).join('')}_${index}`}
          value={SMTValue}
        />
      ))}
    </>
  );
};

interface IconTypeProviderProps {
  for: string[];
}

const STMCellsTypeProvider = (props: IconTypeProviderProps) => {
  return <DataTypeProvider formatterComponent={Formatter} {...props} />;
};

export default STMCellsTypeProvider;
