import { useMemo } from 'react';
import * as yup from 'yup';
import { useFormatMessage } from '../../../locale';

export default function usePasswordValidationSchema() {
  const formatMessage = useFormatMessage();

  return useMemo(
    () =>
      yup.object({
        password: yup
          .string()
          .required(formatMessage('requiredField'))
          .validatePassword(formatMessage),
        passwordConfirmation: yup
          .string()
          .required(formatMessage('requiredField'))
          .oneOf([yup.ref('password')], formatMessage('passwordsDoNotMatch')),
      }),
    [formatMessage]
  );
}
