import { AutocompleteRenderOptionState } from '@material-ui/lab/Autocomplete/Autocomplete';
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { ComboBoxOption } from '../../../../../services/Main/types.Field';
import highlightFoundedText from '../../helpers/highlightFoundedText';

export const renderOption = (
  option: ComboBoxOption,
  { inputValue }: AutocompleteRenderOptionState
) => {
  if (!inputValue) {
    return option.label;
  }

  return highlightFoundedText(option.label, inputValue);
};

export const handleFilterOptions = (
  options: ComboBoxOption[],
  { inputValue }: FilterOptionsState<ComboBoxOption>
) => {
  if (!inputValue) {
    return options;
  }

  return options.filter((o) =>
    o.label.toLowerCase().includes(inputValue.toLowerCase())
  );
};
