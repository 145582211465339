import React from 'react';
import Icon from '../Icon/Icon';
import useIsSomeActionExecuting from '../FormAction/useIsSomeActionExecuting';
import { TableActionButtonProps } from './types';
import ActionButton from '../ActionButton';

function TableActionButton({ action, onClick }: TableActionButtonProps) {
  const { variant, color, label, icon } = action;

  const { isSomeActionExecuting } = useIsSomeActionExecuting();

  return (
    <ActionButton
      variant={variant}
      color={color}
      type="button"
      onClick={onClick}
      disabled={isSomeActionExecuting}
      startIcon={icon && <Icon definition={icon} />}
    >
      {label}
    </ActionButton>
  );
}

export default TableActionButton;
