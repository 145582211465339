import React, { SetStateAction } from 'react';
import clsx from 'clsx';
import { LineChartState } from './types';
import useStyles from './Legend.styles';
import { LINE_COLORS } from './constants';
import { Line } from '../../../services/Main/types.Component';

type AdditionalProps = {
  hiddenLineKeys: string[];
  setHiddenLineKeys: React.Dispatch<SetStateAction<string[]>>;
};

const Legend = ({
  lines,
  hiddenLineKeys,
  setHiddenLineKeys,
}: LineChartState & AdditionalProps) => {
  const classes = useStyles();

  const handleItemClick = (line: Line) => () => {
    setHiddenLineKeys((prevState) => {
      let newState;

      if (prevState.includes(line.key)) {
        newState = prevState.filter((key) => key !== line.key);
      } else {
        newState = [...prevState, line.key];
      }

      return newState;
    });
  };

  return (
    <div className={classes.root}>
      {lines.map((line, index) => {
        return (
          <div
            className={clsx(classes.item, {
              inactive: hiddenLineKeys.includes(line.key),
            })}
            onClick={handleItemClick(line)}
            key={line.key}
          >
            <span
              className={classes.badge}
              style={{ background: LINE_COLORS[index % LINE_COLORS.length] }}
            />
            {line.name}
          </div>
        );
      })}
    </div>
  );
};

export default Legend;
