import { SocketConfig, DirtyFormValue } from '../Main/types.Component';
import SocketServiceIo from '../Socket/SocketService.io';
import SocketServiceSignalR from '../Socket/SocketService.signalR';
import { SelectOption } from '../Main/types.Field';
import authService from '../Auth';

const FORM_CHANGED = 'formChanged';

interface EmitFormChangedProps {
  prevFormState: InteractiveFormState[];
  formState: InteractiveFormState[];
  businessComponentId: string | number;
  componentId: number;
  changedField: string;
}

export interface FieldChangeState {
  name: string;
  value?: DirtyFormValue;
  options?: SelectOption[];
}

export type FormChangedResponse = FieldChangeState[];

export interface InteractiveFormState {
  name: string;
  value?: DirtyFormValue;
}

class GlobalSocketService {
  client: SocketServiceIo | SocketServiceSignalR;

  config: SocketConfig;

  constructor(config: SocketConfig) {
    this.config = config;

    authService.subscribe(() => {
      this.client.close();
      this.client = this.initializeConnection();
    });

    this.client = this.initializeConnection();
  }

  initializeConnection = () => {
    if (!this.config)
      throw new Error('Не обнаружен config для старта Socket-соединения');

    return this.config.client === 'io'
      ? new SocketServiceIo(this.config)
      : new SocketServiceSignalR(this.config);
  };

  emitFormChanged = (props: EmitFormChangedProps) => {
    return this.client.invoke<FormChangedResponse>(FORM_CHANGED, props);
  };
}

export default GlobalSocketService;
