import axios, { CancelTokenSource } from 'axios';
import faker, { datatype, random } from 'faker';
import {
  addMonths,
  format,
  formatISO,
  getWeekOfMonth,
  parse,
  parseISO,
  setDate,
} from 'date-fns';
import { UpdateCellResponsePayload } from 'responseReactions/reLoadCells/reLoadCellsReaction';
import { MainService } from '../Main.interface';
import { ActionServerResponse, RootResponse, ServerResponse } from '../types';
import { rootResponse } from './rootResponse.stub';
import { getPage } from './getPage.stub';
import { Page, PageId } from '../types.Page';
import {
  ChipWithValueAndLabel,
  DialogWithComponent,
  FetchTableDataOptions,
  PreSubmitResponse,
  RequestConfig,
  STMCell,
  TableOnDropRequestBody,
  TableRow,
} from '../types.Component';
import { getTableData } from './stubs/getTableData';
import { getEditCellModal } from './stubs/getEditCellModal';
import { allFieldsFormPage } from './stubs/highLevelComponents/Form/AllFields';
import { issuesPage } from './stubs/issuesPage';
import { arrayOfFormPage } from './stubs/fields/ArrayOf';
import { get, post } from '../../Api';
import store from '../../../store';
import { createAlert } from './stubs/highLevelComponents/Form/WithAlerts';
import {
  issuesCreatePageStep1,
  issuesCreatePageStep2,
  issuesCreatePageStep3,
} from './stubs/issuesCreatePage';
import {
  allCalendarSTMPageRowsV2,
  allCalendarSTMPageRowsV2Map,
  createSTMCellRow,
} from './stubs/complex/calendarSTMv2';
import {
  calendarMonthlySTMRows,
  calendarMonthlySTMRowsMap,
  createMonthlySTMCellRow,
} from './stubs/complex/calendarMonthlySTM';
import { getEditCellV2 } from './stubs/complex/calendarSTMv2.getEditCellModal';
import { ComboBoxOption, OptionsType } from '../types.Field';
import { DATE_FORMAT } from '../../../commonConstants';
import getAutocompleteOptions from './stubs/getAutocompleteOptions';
import {
  checkboxTreeOptions,
  generateSelectOptions,
} from './stubs/fields/ComboBox.stub';
import { allWidgetTasksRows } from './stubs/pageViews/GroupedWidgets.stub';
import {
  dynamicLineChartDatum,
  generateLineChartData,
  generateLines,
  getIncomeSecondRawByMonthChartDatum,
  getIncomeSecondRawCumulatively,
} from './stubs/widgets/lineChartWidgets.stubs';
import {
  getVolumeSecondRawByMonthChartDatum,
  getVolumeSecondRawCumulatively,
} from './stubs/complex/recycleManagement';
import {
  contractsByType,
  myMonthRequestSummaryRow,
  myMonthRequestTableRows,
  openRequestTableRows,
  tasksByMonth,
  tasksByMonthSummaryRow,
  tasksCompletedMonthlyByStatus,
  tasksCompletedMonthlyByStatusSummaryRow,
} from './stubs/complex/homeObjectResponsible';
import { getEditCell } from './stubs/complex/calendarSTMWithChipValueMetric.getEditCellModal';
import { calendarMonthlySTMChipValueMetricRowsMap } from './stubs/complex/calendarMonthlySTMWithChipValueMetric';

const delay = 300;

const errorAlert = {
  color: 'info',
  severity: 'info',
  title: 'Не удаётся сформировать график',
  body: [
    {
      text: 'Выбраны типы вторсырья с разными единицами измерения',
    },
  ],
};

export default class MainServiceLocal implements MainService {
  fetchRootData = (): Promise<RootResponse> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(rootResponse);
      }, delay);
    });
  };

  fetchPageData = (
    pageId: PageId,
    urlParts: object,
    params?: object,
    componentUrlPrefix?: string,
    method: 'GET' | 'POST' = 'GET'
  ): { request: Promise<Page>; source: CancelTokenSource } => {
    console.log(
      `Getting page with page id: ${pageId} and urlParts:`,
      urlParts,
      'params:',
      params,
      'componentUrlPrefix:',
      componentUrlPrefix,
      'method:',
      method
    );

    return {
      request: new Promise((resolve) => {
        setTimeout(() => {
          resolve(getPage(pageId));
        }, delay);
      }),
      source: axios.CancelToken.source(),
    };
  };

  fetchTableData = (params: FetchTableDataOptions) => {
    console.log('Fetching table data with params:', params);
    return {
      request: getTableData(params, `${params.requestConfig.url}`),
      source: axios.CancelToken.source(),
    };
  };

  makeRequest = <Response>(
    requestConfig: RequestConfig,
    initialParams?: object
  ): Promise<Response> => {
    return this.makeActionRequest(
      requestConfig,
      initialParams
    ) as unknown as Promise<Response>;
  };

  makeActionRequest = <Payload>(
    requestConfig: RequestConfig,
    initialParams?: object
  ): Promise<ActionServerResponse<Payload>> => {
    const additionalPayload =
      store.getState().meta.additionalPayloadToSendWithFormStore[
        requestConfig.url
      ] || {};
    const params = { ...initialParams, ...additionalPayload };

    // На странице /response-reactions/validation-error
    // с 80% вероятностью будет падать ошибка валидации при
    // запросе с сервера и можно посмотреть как фронт себя ведёт.
    if (
      requestConfig.url.includes('validationErrorReaction') &&
      Math.random() * 1000 < 800
    ) {
      return axios.get('https://lidev.ru/api-422-error/');
    }

    if (
      requestConfig.url.includes('localhost') ||
      requestConfig.url.includes('backend')
    ) {
      return requestConfig.method === 'POST'
        ? post<ServerResponse<Payload>>(requestConfig.url, params).then(
            ({ data }) => data
          )
        : get<ServerResponse<Payload>>(requestConfig.url, {
            data: params,
          }).then(({ data }) => data);
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        console.log(
          'Making request with requestConfig:',
          requestConfig,
          'params:',
          params
        );

        if (requestConfig.url.includes('/api/widgets/table/600200')) {
          resolve({
            isSuccess: true,
            payload: {
              rows: allWidgetTasksRows.sort(
                () => Math.random() - Math.random()
              ),
              summaryRow: {
                cells: [
                  {
                    columnName: 'count',
                    label: '66',
                  },
                ],
              },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/table/600300')) {
          resolve({
            isSuccess: true,
            payload: {
              rows: openRequestTableRows.sort(
                () => Math.random() - Math.random()
              ),
              summaryRow: {
                cells: [
                  {
                    columnName: 'count',
                    label: '26',
                  },
                ],
              },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/table/600400')) {
          resolve({
            isSuccess: true,
            payload: {
              rows: myMonthRequestTableRows.sort(
                () => Math.random() - Math.random()
              ),
              summaryRow: myMonthRequestSummaryRow,
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/table/600500')) {
          resolve({
            isSuccess: true,
            payload: {
              rows: tasksCompletedMonthlyByStatus.sort(
                () => Math.random() - Math.random()
              ),
              summaryRow: tasksCompletedMonthlyByStatusSummaryRow,
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/table/600600')) {
          resolve({
            isSuccess: true,
            payload: {
              rows: tasksByMonth.sort(() => Math.random() - Math.random()),
              summaryRow: tasksByMonthSummaryRow,
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/table/600800')) {
          resolve({
            isSuccess: true,
            payload: {
              rows: contractsByType.sort(() => Math.random() - Math.random()),
            },
          } as any);
        }

        // <editor-fold desc="LineChart">
        if (
          requestConfig.url.includes(
            '/api/widgets/line-chart/income-second-raw-by-month'
          )
        ) {
          resolve({
            isSuccess: true,
            payload: getIncomeSecondRawByMonthChartDatum(),
          } as any);
        }

        if (
          requestConfig.url.includes(
            '/api/widgets/line-chart/income-second-raw-cumulatively'
          )
        ) {
          resolve({
            isSuccess: true,
            payload: getIncomeSecondRawCumulatively(),
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/line-chart/dynamic-6')) {
          resolve({
            isSuccess: true,
            payload: dynamicLineChartDatum,
          } as any);
        }

        if (
          requestConfig.url.includes('/api/widgets/line-chart/dynamic-random')
        ) {
          resolve({
            isSuccess: true,
            payload: generateLineChartData(
              generateLines(
                faker.datatype.number({
                  min: 1,
                  max: 10,
                })
              )
            ),
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/line-chart/empty')) {
          resolve({
            isSuccess: true,
            payload: {
              data: [],
              lines: [],
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/line-chart/error')) {
          resolve({
            isSuccess: false,
            payload: errorAlert,
          } as any);
        }
        // </editor-fold>

        // <editor-fold desc="TextWidget">
        if (requestConfig.url.includes('/api/widgets/text-widget/100101')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '60%',
                href: '/',
              },
              // trend: { value: '1600', dynamic: 'negative' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100102')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '4%',
              },
              // trend: { value: '70', dynamic: 'positive' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100103')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '88%',
                href: '/',
              },
              // trend: { value: '0', dynamic: 'withoutChanges' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100104')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '33%',
              },
              // trend: { value: '7', dynamic: 'positive' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100105')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '12%',
                href: '/',
              },
              // trend: { value: '120', dynamic: 'positive' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100106')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '60%',
                href: '/',
              },
              // trend: { value: '1600', dynamic: 'negative' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100107')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '43,65%',
              },
              // trend: { value: '100', dynamic: 'positive' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100108')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '0%',
                href: '/',
              },
              // trend: { value: '0', dynamic: 'withoutChanges' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100109')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '4700',
              },
              // trend: { value: '800%', dynamic: 'positive' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100110')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '6030',
              },
              // trend: { value: '16%', dynamic: 'negative' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100111')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '0',
                href: '/',
              },
              // trend: { value: '0%', dynamic: 'withoutChanges' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100112')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '110',
                href: '/',
              },
              // trend: { value: '10%', dynamic: 'negative' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100113')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '43%',
              },
              // trend: { value: '7%', dynamic: 'positive' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/100114')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '60%',
              },
              // trend: { value: '16%', dynamic: 'negative' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/empty')) {
          resolve({
            isSuccess: true,
            payload: {
              // trend: { value: '16%', dynamic: 'negative' },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/error')) {
          resolve({
            isSuccess: false,
            payload: errorAlert,
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/600105')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '11',
                href: '/',
              },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/600106')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '5',
                href: '/',
              },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/600107')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '16',
                href: '/',
              },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/600108')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '9',
                href: '/',
              },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/600109')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '3',
                href: '/',
              },
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/text-widget/600110')) {
          resolve({
            isSuccess: true,
            payload: {
              metric: {
                label: '2',
                href: '/',
              },
            },
          } as any);
        }

        // </editor-fold>

        // <editor-fold desc="BarChart">
        if (requestConfig.url.includes('/api/widgets/bar/4000100')) {
          // if (true) {
          // if (Math.random() > 0.95) {
          //   resolve({
          //     isSuccess: false,
          //     payload: errorAlert,
          //   } as any);
          // }

          resolve({
            isSuccess: true,
            payload: {
              bars: [
                {
                  key: 'done',
                  name: 'Запланировано',
                  fill: '#8865cc',
                  stackId: 1,
                },
                {
                  key: 'inProgress',
                  name: 'В работе',
                  fill: '#1976d2',
                  stackId: 1,
                },
                {
                  key: 'accepted',
                  name: 'Приемка',
                  fill: '#3fb0ed',
                  stackId: 1,
                },
                {
                  key: 'overdue',
                  name: 'Просроченные',
                  fill: '#67d386',
                  stackId: 1,
                },
              ],
              data: [
                {
                  name: '0004 Красногорск',
                  description: '0004 Красногорск (с description)',
                  done: {
                    value: 78,
                    href: '/',
                  },
                  inProgress: {
                    value: 5,
                  },
                  accepted: {
                    value: 13,
                    href: '/',
                  },
                  overdue: {
                    value: 16,
                  },
                },
                {
                  name: '0734 Ривьера',
                  done: {
                    value: 46,
                  },
                  inProgress: {
                    value: 2,
                  },
                  accepted: {
                    value: 5,
                    href: '/',
                  },
                  overdue: {
                    value: 8,
                  },
                },
                {
                  name: '0075 Пушкино',
                  done: {
                    value: 30,
                  },
                  inProgress: {
                    value: 5,
                    href: '/',
                  },
                  accepted: {
                    value: 5,
                  },
                  overdue: {
                    value: 6,
                    href: '/',
                  },
                },
                ...Array.from({ length: 20 }, (_, k) => ({
                  name: `000${k} ${faker.address.cityName()}`,
                  done: {
                    value: faker.datatype.number({ min: 0, max: 100 }),
                  },
                  inProgress: {
                    value: faker.datatype.number({ min: 0, max: 100 }),
                    href: '/',
                  },
                  accepted: {
                    value: faker.datatype.number({ min: 0, max: 100 }),
                  },
                  overdue: {
                    value: faker.datatype.number({ min: 10, max: 100 }),
                    href: '/',
                  },
                })),
              ],
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/bar/4000101')) {
          resolve({
            isSuccess: true,
            payload: {
              bars: [
                {
                  key: 'done',
                  name: 'Запланировано',
                  fill: '#8865cc',
                  stackId: 1,
                },
                {
                  key: 'inProgress',
                  name: 'В работе',
                  fill: '#1976d2',
                  stackId: 1,
                },
                {
                  key: 'accepted',
                  name: 'Приемка',
                  fill: '#3fb0ed',
                  stackId: 1,
                },
                {
                  key: 'overdue',
                  name: 'Просроченные',
                  fill: '#67d386',
                  stackId: 1,
                },
              ],
              data: [
                {
                  name: '0004 Красногорск',
                  done: {
                    value: 78,
                    href: '/',
                  },
                  inProgress: {
                    value: 5,
                  },
                  accepted: {
                    value: 13,
                    href: '/',
                  },
                  overdue: {
                    value: 0,
                  },
                },
                {
                  name: '0734 Ривьера',
                  done: {
                    value: 0,
                  },
                  inProgress: {
                    value: 24,
                  },
                  accepted: {
                    value: 0,
                    href: '/',
                  },
                  overdue: {
                    value: 0,
                  },
                },
                {
                  name: '0075 Пушкино',
                  done: {
                    value: 30,
                  },
                  inProgress: {
                    value: 5,
                    href: '/',
                  },
                  accepted: {
                    value: 1,
                  },
                  overdue: {
                    value: 0,
                    href: '/',
                  },
                },
              ],
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/bar/4000102')) {
          resolve({
            isSuccess: true,
            payload: {
              bars: [
                {
                  key: 'done',
                  name: 'Запланировано',
                  fill: '#8865cc',
                  stackId: 0,
                },
                {
                  key: 'inProgress',
                  name: 'В работе',
                  fill: '#1976d2',
                  stackId: 0,
                },
                {
                  key: 'accepted',
                  name: 'Приемка',
                  fill: '#3fb0ed',
                },
                {
                  key: 'overdue',
                  name: 'Просроченные',
                  fill: '#67d386',
                },
              ],
              data: [
                {
                  name: '0004 Красногорск',
                  done: {
                    value: 78,
                    href: '/',
                  },
                  inProgress: {
                    value: 5,
                  },
                  accepted: {
                    value: 13,
                    href: '/',
                  },
                  overdue: {
                    value: 0,
                  },
                },
                {
                  name: '0734 Ривьера',
                  done: {
                    value: 46,
                  },
                  inProgress: {
                    value: 0,
                  },
                  accepted: {
                    value: 5,
                    href: '/',
                  },
                  overdue: {
                    value: 8,
                  },
                },
                {
                  name: '0075 Пушкино',
                  done: {
                    value: 30,
                  },
                  inProgress: {
                    value: 5,
                    href: '/',
                  },
                  accepted: {
                    value: 1,
                  },
                  overdue: {
                    value: 0,
                    href: '/',
                  },
                },
              ],
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/bar-chart/100101')) {
          resolve({
            isSuccess: true,
            payload: {
              bars: [
                { key: 'done', name: 'Завершено', fill: '#2ed47a', stackId: 1 },
                {
                  key: 'pending',
                  name: 'В ожидании',
                  fill: '#ffb946',
                  stackId: 1,
                },
                {
                  key: 'canceled',
                  name: 'Отменено',
                  fill: '#f7685b',
                  stackId: 1,
                },
              ],
              data: [
                {
                  name: 'Пн',
                  done: {
                    value: 200,
                  },
                  pending: {
                    value: 104,
                  },
                  canceled: {
                    value: 25,
                  },
                },
                {
                  name: 'Вт',
                  done: {
                    value: 220,
                  },
                  pending: {
                    value: 144,
                  },
                  canceled: {
                    value: 35,
                  },
                },
                {
                  name: 'Ср',
                  done: {
                    value: 246,
                  },
                  pending: {
                    value: 108,
                  },
                  canceled: {
                    value: 5,
                  },
                },
                {
                  name: 'Чт',
                  done: {
                    value: 250,
                  },
                  pending: {
                    value: 154,
                  },
                  canceled: {
                    value: 61,
                  },
                },
                {
                  name: 'Пт',
                  done: {
                    value: 280,
                  },
                  pending: {
                    value: 184,
                  },
                  canceled: {
                    value: 4,
                  },
                },
                {
                  name: 'Сб',
                  done: {
                    value: 331,
                  },
                  pending: {
                    value: 133,
                  },
                  canceled: {
                    value: 0,
                  },
                },
                {
                  name: 'Вс',
                  done: {
                    value: 364,
                  },
                  pending: {
                    value: 10,
                  },
                  canceled: {
                    value: 50,
                  },
                },
              ],
            },
          } as any);
        }

        if (
          requestConfig.url.includes(
            '/api/widgets/bar-chart/volume-second-raw-by-month'
          )
        ) {
          resolve({
            isSuccess: true,
            payload: getVolumeSecondRawByMonthChartDatum(),
          } as any);
        }

        if (
          requestConfig.url.includes(
            '/api/widgets/bar-chart/volume-second-raw-cumulatively'
          )
        ) {
          resolve({
            isSuccess: true,
            payload: getVolumeSecondRawCumulatively(),
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/bar-chart/empty')) {
          resolve({
            isSuccess: true,
            payload: {
              bars: [],
              data: [],
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/bar-chart/error')) {
          resolve({
            isSuccess: false,
            payload: errorAlert,
          } as any);
        }
        // </editor-fold>

        if (requestConfig.url.includes('/api/widgets/donut-chart/100101')) {
          const a = faker.datatype.number({ min: 100, max: 1000 });
          const b = faker.datatype.number({ min: 10, max: 700 });
          const c = faker.datatype.number({ min: 0, max: 300 });

          resolve({
            isSuccess: true,
            payload: {
              data: [
                {
                  name: 'Выполнено',
                  value: a,
                  fill: '#2ed47a',
                  printValue: `${a} заданий`,
                  href: '/',
                },
                {
                  name: 'В ожидании',
                  value: b,
                  fill: '#ffb946',
                  printValue: `${b} заданий`,
                  href: '/',
                },
                {
                  name: 'Отменено',
                  value: c,
                  fill: '#f7685b',
                  printValue: `${c} заданий`,
                  href: '/',
                },
              ],
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/widgets/donut-chart/300101')) {
          resolve({
            isSuccess: true,
            payload: {
              data: [
                {
                  name: 'В работе непросроченные',
                  value: 27,
                  fill: '#3fb0ee',
                  href: '/',
                },
                {
                  name: 'В работе просроченные',
                  value: 34,
                  fill: '#8865cc',
                  href: '/',
                },
              ],
            },
          } as any);
        }

        if (requestConfig.url.includes('/api/table/update-row/')) {
          const rowId = requestConfig.url.replace('/api/table/update-row/', '');

          const result = {
            isSuccess: true,
            payload: {
              cells: [
                {
                  rowId,
                  columnName: 'code',
                  cell: `${faker.datatype.number({
                    min: 1000,
                    max: 9999,
                  })}-${faker.datatype.number({
                    min: 100,
                    max: 999,
                  })}/${faker.datatype.number({
                    min: 10,
                    max: 99,
                  })}`,
                },
                {
                  rowId,
                  columnName: 'type',
                  cell: faker.lorem.word(),
                },
                {
                  rowId,
                  columnName: 'author',
                  cell: `${faker.name.lastName()} ${faker.name.firstName()}`,
                },
              ],
            },
          } as any;

          console.log('Update cells result:', result);

          resolve(result);
        }

        if (
          requestConfig.url.includes('/api/table/stm/show-form/update-row/')
        ) {
          const rowId = requestConfig.url.replace(
            '/api/table/stm/show-form/update-row/',
            ''
          );

          resolve({
            isSuccess: true,
            payload: {
              viewStyle: 'modal',
              component: {
                type: 'form',
                id: 12,
                businessComponentId: 12,
                props: {
                  actionsYPosition: 'bottom',
                  actions: [
                    {
                      type: 'closeDialog',
                      label: 'Отмена',
                      variant: 'outlined',
                    },
                    {
                      type: 'submitForm',
                      label: 'Сформировать',
                      variant: 'contained',
                      color: 'primary',
                      requestConfig: {
                        url: `/api/table/stm/update-row/${rowId}`,
                        method: 'POST',
                      },
                      successResponseReaction: {
                        type: 'reLoadModule',
                      },
                    },
                  ],
                  form: {
                    type: 'markup',
                    header: {
                      heading: 'Сформировать график',
                    },
                    fieldGroups: [
                      {
                        label: 'Параметры',
                        fields: [
                          {
                            type: 'datePicker',
                            pickerType: 'date',
                            name: 'date',
                            label: 'Начальная дата планирования',
                            defaultValue: formatISO(new Date(), {
                              representation: 'date',
                            }),
                            validationConfig: {
                              validationType: 'date',
                              validations: [
                                {
                                  type: 'required',
                                  params: ['Обязательное поле!'],
                                },
                              ],
                            },
                          },
                          {
                            type: 'comboBox',
                            optionsType: OptionsType.flat,
                            optionsLoader: 'sync',
                            name: 'to',
                            label: 'Максимальный тип ТО',
                            disabled: true,
                            defaultValue: faker.random.arrayElement([
                              {
                                label: 'ТО-0',
                                value: '0',
                              },
                              {
                                label: 'ТО-1',
                                value: '1',
                              },
                              {
                                label: 'ТО-2',
                                value: '2',
                              },
                              {
                                label: 'ТО-3',
                                value: '3',
                              },
                              {
                                label: 'ТО-4',
                                value: '4',
                              },
                              {
                                label: 'ТО-5',
                                value: '5',
                              },
                            ]),
                            options: [
                              {
                                label: 'ТО-0',
                                value: '0',
                              },
                              {
                                label: 'ТО-1',
                                value: '1',
                              },
                              {
                                label: 'ТО-2',
                                value: '2',
                              },
                              {
                                label: 'ТО-3',
                                value: '3',
                              },
                              {
                                label: 'ТО-4',
                                value: '4',
                              },
                              {
                                label: 'ТО-5',
                                value: '5',
                              },
                            ],
                          },
                          {
                            type: 'comboBox',
                            optionsType: OptionsType.flat,
                            optionsLoader: 'sync',
                            name: 'week',
                            label: 'Неделя',
                            renderCondition: {
                              condition: 'some',
                              value: ['2', '3', '4', '5'],
                              dependOn: 'to',
                            },
                            options: [
                              {
                                label: '1',
                                value: '1',
                              },
                              {
                                label: '2',
                                value: '2',
                              },
                              {
                                label: '3',
                                value: '3',
                              },
                              {
                                label: '4',
                                value: '4',
                              },
                            ],
                            validationConfig: {
                              validationType: 'mixed',
                              validations: [
                                {
                                  type: 'required',
                                  params: ['Обязательное поле!'],
                                },
                              ],
                            },
                            mdCols: 6,
                          },
                          {
                            type: 'comboBox',
                            optionsType: OptionsType.flat,
                            optionsLoader: 'sync',
                            name: 'months',
                            label: 'Месяц',
                            renderCondition: {
                              condition: 'some',
                              value: ['2', '3', '4', '5'],
                              dependOn: 'to',
                            },
                            options: [
                              { label: 'Январь', value: 'January' },
                              { label: 'Февраль', value: 'February' },
                              { label: 'Март', value: 'March' },
                              { label: 'Апрель', value: 'April' },
                              { label: 'Май', value: 'May' },
                              { label: 'Июнь', value: 'June' },
                              { label: 'Июль', value: 'July' },
                              { label: 'Август', value: 'August' },
                              { label: 'Сентябрь', value: 'September' },
                              { label: 'Октябрь', value: 'October' },
                              { label: 'Ноябрь', value: 'November' },
                              { label: 'Декабрь', value: 'December' },
                            ],
                            validationConfig: {
                              validationType: 'mixed',
                              validations: [
                                {
                                  type: 'required',
                                  params: ['Обязательное поле!'],
                                },
                              ],
                            },
                            mdCols: 6,
                          },
                        ],
                      },
                    ],
                  },
                },
              },
            } as DialogWithComponent,
          } as any);
        }

        if (requestConfig.url.includes('/api/table/stm/update-row/')) {
          const rowId = requestConfig.url.replace(
            '/api/table/stm/update-row/',
            ''
          );

          const row = allCalendarSTMPageRowsV2.find(({ id }) => id === rowId);

          if (!row) {
            console.error(`Не удалось найти строку с id ${rowId}`);
          }

          const newRow = createSTMCellRow();

          const result = {
            isSuccess: true,
            payload: {
              cells: Object.keys(newRow).map((columnName) => ({
                rowId,
                columnName,
                cell: newRow[columnName],
              })),
            },
          } as any;

          console.log('Update cells result:', result);

          resolve(result);
        }

        if (requestConfig.url.includes('/api/table/stm-monthly/update-row/')) {
          const rowId = requestConfig.url.replace(
            '/api/table/stm-monthly/update-row/',
            ''
          );

          const row = calendarMonthlySTMRows.find(({ id }) => id === rowId);

          if (!row) {
            console.error(`Не удалось найти строку с id ${rowId}`);
          }

          const result = {
            isSuccess: true,
            payload: {
              row: {
                ...row,
                ...createMonthlySTMCellRow(),
              },
            },
          } as any;

          console.log('Update row result:', result);

          resolve(result);
        }

        if (
          requestConfig.url.includes('RenewIssue') ||
          requestConfig.url.includes('AsyncRedirectReaction')
        ) {
          resolve({
            isSuccess: true,
            payload: {
              redirectTo: `/tasks/${datatype.uuid()}`,
            },
            snackbar: {
              text: 'Получилось!',
              options: { variant: 'success' },
            },
          } as any);
        }

        if (requestConfig.url.includes('DynamicRedirectReaction')) {
          resolve({
            isSuccess: true,
            payload: {
              id: datatype.uuid(),
            },
          } as any);
        }

        if (requestConfig.url.includes('AsyncShowComponentReaction')) {
          const payload = [
            {
              viewStyle: 'modal',
              component: (allFieldsFormPage as any).component,
            },
            {
              viewStyle: 'fullScreenModal',
              component: (issuesPage as any).component,
            },
            {
              viewStyle: 'fullScreenModal',
              component: (arrayOfFormPage as any).component,
            },
          ][random.arrayElement([0, 1, 2])];

          resolve({
            isSuccess: true,
            payload: {
              ...payload,
              component: {
                ...payload.component,
                props: {
                  ...payload.component.props,
                  actions: [
                    {
                      type: 'closeDialog',
                      variant: 'outlined',
                      label: 'Отменить',
                    },
                    ...(payload.component.props?.actions || []),
                  ],
                },
              },
            } as DialogWithComponent,
          } as any);
        }

        if (requestConfig.url.includes('chip-value-metric-get-modal-info')) {
          resolve({
            isSuccess: true,
            payload: getEditCell({ requestConfig, params }),
          } as any);
        }

        if (
          requestConfig.url.includes('/api/complex/chip-value-metric/edit-cell')
        ) {
          const url = new URLSearchParams(requestConfig.url);
          const rowId = url.get('row-id') as string;
          const columnName = url.get('column-name') as string;

          const rows = calendarMonthlySTMChipValueMetricRowsMap;
          const row = rows.get(rowId) as TableRow;

          const cell: Partial<ChipWithValueAndLabel> = row[columnName] || {
            label: undefined,
            value: undefined,
          };

          if (params.metric) {
            // @ts-ignore
            cell.label = params.metric.label;
          }

          cell.value = params.value as number;

          const newRow: TableRow = {
            ...row,
            [columnName]: cell,
          };

          const updatedCells: UpdateCellResponsePayload[] = [
            {
              rowId,
              columnName,
              cell,
            },
          ];

          rows.set(newRow.id, newRow);

          const result = {
            isSuccess: true,
            payload: {
              cells: updatedCells,
            },
            snackbar: {
              text: ['Успешно!', 'Получилось!', 'Всё хорошечно!'][
                random.arrayElement([0, 1, 2])
              ],
              options: {
                variant: 'success',
              },
            },
          } as any;

          console.log('Update cells result:', result);

          resolve(result);
        }

        if (requestConfig.url.includes('v2-get-modal-info')) {
          resolve({
            isSuccess: true,
            payload: getEditCellV2({ requestConfig, params }),
          } as any);
        }

        if (requestConfig.url.includes('monthly/drag-and-drop')) {
          const { from, to } = params as unknown as TableOnDropRequestBody;

          const row = calendarMonthlySTMRowsMap.get(from.rowId) as TableRow;

          const fromCells = row[from.columnName] as STMCell[];
          const toCells = row[to.columnName] as STMCell[] | null;

          fromCells.forEach((fromCell) => {
            fromCell.tooltip.content = fromCell.tooltip.content.map(
              ({ title, text }) => {
                if (title === 'Плановая дата') {
                  return {
                    title,
                    text: format(
                      setDate(
                        parse(text, DATE_FORMAT, new Date()),
                        +to.columnName.replace('day', '')
                      ),
                      DATE_FORMAT
                    ),
                  };
                }

                return {
                  title,
                  text,
                };
              }
            );
          });

          if (toCells) {
            toCells.forEach((toCell) => {
              toCell.tooltip.content = toCell.tooltip.content.map(
                ({ title, text }) => {
                  if (title === 'Плановая дата') {
                    return {
                      title,
                      text: format(
                        setDate(
                          parse(text, DATE_FORMAT, new Date()),
                          +from.columnName.replace('day', '')
                        ),
                        DATE_FORMAT
                      ),
                    };
                  }

                  return {
                    title,
                    text,
                  };
                }
              );
            });
          }

          [row[from.columnName], row[to.columnName]] = [
            row[to.columnName],
            row[from.columnName],
          ];

          calendarMonthlySTMRowsMap.set(from.rowId, row);

          const result = {
            isSuccess: true,
            payload: {
              cells: [
                {
                  rowId: from.rowId,
                  columnName: from.columnName,
                  cell: toCells,
                },
                {
                  rowId: to.rowId,
                  columnName: to.columnName,
                  cell: fromCells,
                },
              ],
            },
            // preventSuccessResponseReactionReasons:
            //   Math.random() < 0.5
            //     ? undefined
            //     : Array.from({ length: datatype.number(4) + 1 }, createAlert),
            snackbar: {
              text: ['Успешно!', 'Получилось!', 'Всё хорошечно!'][
                random.arrayElement([0, 1, 2])
              ],
              options: {
                variant: 'success',
              },
            },
          };

          console.log('Update cell (dnd) result:', result);

          resolve(result as any);
        }

        if (
          requestConfig.url.includes('/api/complex/v2-calendar-stm/edit-cell')
        ) {
          const url = new URLSearchParams(requestConfig.url);
          const rowId = url.get('row-id') as string;
          const columnNameFromUrl = url.get('column-name') as string;
          const chipIndex = +(url.get('index') as string);
          const SMTType = url.get('smt-type') as string;

          const rows =
            SMTType === 'monthly'
              ? calendarMonthlySTMRowsMap
              : allCalendarSTMPageRowsV2Map;
          const row = rows.get(rowId) as TableRow;

          const cells: Partial<STMCell>[] = row[columnNameFromUrl];

          let columnName = columnNameFromUrl;

          if ((params?.assignee as ComboBoxOption)?.label) {
            const [firstName, lastName] = (
              params.assignee as ComboBoxOption
            ).label.split(' ');

            cells[chipIndex].avatar = {
              firstName,
              lastName,
            };
          }

          if (cells[chipIndex].tooltip) {
            // @ts-ignore
            cells[chipIndex].tooltip.content = [
              ...(cells[chipIndex]?.tooltip?.content || []).filter(
                ({ title }) =>
                  title !== 'Исполнитель' && title !== 'Плановая дата'
              ),
            ];
          }

          if ((params?.assignee as ComboBoxOption)?.label) {
            cells[chipIndex]?.tooltip?.content.push({
              title: 'Исполнитель',
              text: (params?.assignee as ComboBoxOption).label,
            });
          }

          if (typeof params.planedDate === 'string') {
            const plannedDateText = cells[chipIndex]?.tooltip?.content.find(
              ({ title }) => title === 'Плановая дата'
            )?.text;

            if (plannedDateText !== params.planedDate) {
              const newPlannedDate = parseISO(params.planedDate);

              if (SMTType === 'monthly') {
                columnName = `day${format(newPlannedDate, 'd')}`;
              }

              if (SMTType === 'year') {
                let weekIndex = getWeekOfMonth(newPlannedDate);
                const monthShortName = format(
                  newPlannedDate,
                  'MMM'
                ).toLocaleLowerCase();

                if (weekIndex > 4) {
                  weekIndex = 4;
                }

                columnName = `${monthShortName}${weekIndex}`;
              }
            }

            cells[chipIndex]?.tooltip?.content.push({
              title: 'Плановая дата',
              text: format(parseISO(params.planedDate), DATE_FORMAT),
            });
          }

          const newRow: TableRow = {
            ...row,
            [columnName]: cells,
          };

          const updatedCells: UpdateCellResponsePayload[] = [
            {
              rowId,
              columnName,
              cell: cells,
            },
          ];

          if (columnName !== columnNameFromUrl) {
            const newCells: STMCell[] | null = row[columnName];

            if (newCells) {
              newCells.forEach((newCell) => {
                newCell.tooltip.content = [
                  ...(newCell?.tooltip?.content || []).filter(
                    ({ title }) => title !== 'Плановая дата'
                  ),
                ];

                const plannedDay = columnNameFromUrl.replace('day', '');
                const plannedDate = parse(
                  plannedDay,
                  'd',
                  addMonths(new Date(), 1)
                );

                newCell.tooltip.content.push({
                  title: 'Плановая дата',
                  text: format(plannedDate, DATE_FORMAT),
                });
              });
            }

            updatedCells.push({
              rowId,
              columnName: columnNameFromUrl,
              cell: newCells,
            });

            newRow[columnNameFromUrl] = row[columnName];
          }

          rows.set(newRow.id, newRow);

          const result = {
            isSuccess: true,
            payload: {
              cells: updatedCells,
            },
            snackbar: {
              text: ['Успешно!', 'Получилось!', 'Всё хорошечно!'][
                random.arrayElement([0, 1, 2])
              ],
              options: {
                variant: 'success',
              },
            },
          } as any;

          console.log('Update cells result:', result);

          resolve(result);
        }

        if (requestConfig.url.includes('get-modal-info')) {
          resolve({
            isSuccess: true,
            payload: getEditCellModal({ requestConfig, params }),
          } as any);
        }

        if (requestConfig.url.includes('DownloadFileReaction')) {
          resolve({
            isSuccess: true,
            payload: 'https://lidev.ru/cat.jpg',
          } as any);
        }

        if (requestConfig.url.includes('response-alerts')) {
          resolve({
            isSuccess: true,
            preventSuccessResponseReactionReasons:
              Math.random() < 0.5
                ? undefined
                : Array.from({ length: datatype.number(4) + 1 }, createAlert),
          } as any);
        }

        if (requestConfig.url.includes('/issues/create')) {
          if (requestConfig.url.includes('step=1')) {
            resolve({
              isSuccess: true,
              payload: issuesCreatePageStep1,
            } as any);
          }

          if (requestConfig.url.includes('step=2')) {
            resolve({
              isSuccess: true,
              payload: issuesCreatePageStep2,
            } as any);
          }

          if (requestConfig.url.includes('step=3')) {
            resolve({
              isSuccess: true,
              payload: issuesCreatePageStep3,
            } as any);
          }

          if (requestConfig.url.includes('step=4')) {
            resolve({
              isSuccess: true,
            } as any);
          }
        }

        if (requestConfig.url.includes('/actions/pre-submit-form')) {
          resolve({
            isSuccess: true,
            payload: {
              confirmDialog: {
                body: `Вы действительно хотите сохранить, не смотря на ${faker.animal.cat()}? 😱🐱`,
              },
            },
          } as ServerResponse<PreSubmitResponse> as any);
        }

        if (requestConfig.url.includes('/api/equipments')) {
          const response = {
            isSuccess: true,
            payload: getAutocompleteOptions(requestConfig.url),
          } as any;

          console.log('Async options request result:', response);

          resolve(response);
        }

        if (requestConfig.url.includes('/api/combobox-equipments')) {
          const response = {
            isSuccess: true,
            payload: generateSelectOptions(10),
          } as any;

          console.log('Async options request result:', response);

          resolve(response);
        }

        if (requestConfig.url.includes('/api/tree-checkbox-combobox')) {
          const response = {
            isSuccess: true,
            payload: checkboxTreeOptions,
          } as any;

          console.log('Async options request result:', response);

          resolve(response);
        }

        if (requestConfig.url.includes('/api/tree-radio-combobox')) {
          const response = {
            isSuccess: true,
            payload: checkboxTreeOptions,
          } as any;

          console.log('Async options request result:', response);

          resolve(response);
        }

        resolve({
          isSuccess: true,
          snackbar: {
            text: ['Успешно!', 'Получилось!', 'Всё хорошечно!'][
              random.arrayElement([0, 1, 2])
            ],
            options: {
              variant: 'success',
              link: {
                label: 'Перейти',
                href: '/issues/9d6b7889-fe0a-4350-bfdb-f27337313954',
              },
            },
          },
        } as any);
      }, faker.datatype.number(1000));
      // }, delay;
    });
  };
}
