import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { VirtualTable, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { TableColumn } from '../../../services/Main/types.Component';
import { ComponentContext } from '../../creational/ComponentLoader';
import { useSelector } from '../../../store';

const DEFAULT_WIDTH = 200;

export default (
  columns: TableColumn[],
  columnExtensions?: VirtualTable.ColumnExtension[],
  enableColumnResizing?: boolean
): [
  TableColumnWidthInfo[],
  Dispatch<SetStateAction<TableColumnWidthInfo[]>>,
  () => void
] => {
  const componentContext = useContext(ComponentContext);
  const user = useSelector((state) => state.user);
  const localStorageKey = `${user?.id}_tableResizeState_${componentContext?.id}`;
  const localStorageJSONState =
    enableColumnResizing && localStorage.getItem(localStorageKey);
  const localStorageState: TableColumnWidthInfo[] | false =
    localStorageJSONState && JSON.parse(localStorageJSONState);

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>(
    localStorageState || getDefaultWidths(columns, columnExtensions)
  );

  useEffect(() => {
    enableColumnResizing &&
      localStorage.setItem(localStorageKey, JSON.stringify(columnWidths));
  }, [columnWidths, enableColumnResizing, localStorageKey]);

  const resetColumnWidthsToDefault = () => {
    setColumnWidths(getDefaultWidths(columns, columnExtensions));
  };

  return [columnWidths, setColumnWidths, resetColumnWidthsToDefault];
};

function getDefaultWidths(
  columns: TableColumn[],
  columnExtensions?: VirtualTable.ColumnExtension[]
): TableColumnWidthInfo[] {
  return columns.map(({ name }) => ({
    columnName: name,
    width: getWidth(name, columnExtensions),
  }));
}

function getWidth(
  name: string,
  columnExtensions?: VirtualTable.ColumnExtension[]
): number | string {
  if (!columnExtensions) return DEFAULT_WIDTH;

  const colExtension = columnExtensions.find(
    ({ columnName }) => columnName === name
  );

  return colExtension?.width || DEFAULT_WIDTH;
}
