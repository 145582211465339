import { MainService } from './Main.interface';

let MainServiceModule: any;

if (
  process.env.REACT_APP_ENV === 'development' &&
  process.env.REACT_APP_IS_REMOTE !== 'true'
) {
  MainServiceModule = require('./local/Main.local').default;
} else {
  MainServiceModule = require('./remote/Main.remote').default;
}

const mainService = new MainServiceModule() as MainService;

export default mainService;
