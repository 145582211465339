import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Sector } from 'recharts';
import { Popper } from '@material-ui/core';
import { Coordinate } from 'recharts/types/util/types';
import { getDonutTooltipData } from '../helpers/getDonutTooltipData';
import ChartsTooltip from '../../../lowLevel/ChartsTooltip';

interface SectorWithTooltipProps {
  tooltipPosition: Coordinate;
  active: boolean;
}

const SectorWithTooltip = (
  props: PropsWithChildren<SectorWithTooltipProps>
) => {
  const tooltipPathRef = useRef<SVGGElement>(null);
  const { tooltipPosition, active, children } = props;

  return (
    <>
      <g>
        <Sector {...props} />
        <g ref={tooltipPathRef}>
          <path d={`M ${tooltipPosition.x}, ${tooltipPosition.y}`} />
        </g>
      </g>
      <Popper open={active} anchorEl={tooltipPathRef.current}>
        {children}
      </Popper>
    </>
  );
};

type DonutSectorTooltipProps = {
  setActiveIndex: Dispatch<SetStateAction<number | undefined>>;
  activeIndex?: number;
} & any;

function DonutSectorTooltip(props: DonutSectorTooltipProps) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const { activeIndex, setActiveIndex } = props;
  // Получаем пропсы из группы секторов. Взят по примеру из библиотеки
  const { sectors, data } = props.formattedGraphicalItems[0].props;

  const tooltipData = useMemo(() => getDonutTooltipData(data), [data]);

  const handleMouseEnter = (index: number) => () => {
    setShowTooltip(true);
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    setActiveIndex(undefined);
  };

  return (
    <g onMouseLeave={handleMouseLeave}>
      {(sectors as any[]).map((sector, index) => (
        <SectorWithTooltip
          {...sector}
          key={index}
          fill="transparent"
          stroke="transparent"
          fillOpacity={0}
          innerRadius={0}
          active={showTooltip && activeIndex === index}
          onMouseEnter={handleMouseEnter(index)}
        >
          <ChartsTooltip
            {...tooltipData}
            activeTooltipItemIndex={activeIndex}
          />
        </SectorWithTooltip>
      ))}
    </g>
  );
}

export default DonutSectorTooltip;
