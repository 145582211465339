import React, { Dispatch, SetStateAction, useContext } from 'react';
import MuiGrid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Header from '../../../../../Header';
import TableFilterChips from '../../../../../TableFilterChips';
import {
  DirtyFormValues,
  TableFilterFormComponent,
} from '../../../../../../../services/Main/types.Component';
import useStyles from '../../EntryPickerTable.styles';
import { useFormatMessage } from '../../../../../../../locale';
import { TableFilterFormProps } from '../../../../../TableModules';
import TableFilters from '../../../../../TableFilters';
import EntryPickerTableContext from '../../EntryPickerTable.context';
import EntryPickerTableHeaderSkeleton from './EntryPickerTableHeader.skeleton';
import { mapValuesToFilterOptions } from '../../../../../TableFilterForm/TableFilterForm';
import { mapValuesToPlain } from '../../../../../FormBuilder/helpers';
import EntryPickerTableActions from '../EntryPickerTableActions';
import EntryPickerTableColumnVisibilityButton from '../EntryPickerTableColumnVisibilityButton';

const EntryPickerTableHeader = () => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  const {
    filterChips,
    setFilterChips,
    filter,
    setFilter,
    totalRows,
    tableProps,
    loadRows,
    pageSize,
    sorting,
    setCurrentPage,
    setFilterOptions,
    selectedRowsId,
    setSelectedRowsId,
    entryPickerProps,
  } = useContext(EntryPickerTableContext);

  const { multiple } = entryPickerProps;

  const handleFilterChange = (newFilterState: DirtyFormValues | undefined) => {
    const preparedFilterOptions =
      newFilterState &&
      mapValuesToFilterOptions(
        mapValuesToPlain(
          newFilterState,
          tableProps?.filterComponent?.props?.fieldGroups[0].fields || []
        )
      );

    // Игнорирует вызов loadRows при монтировании TableFilters
    // filter: undefined -> filter: { comment: '', shop: [], status: []}
    if (filter) {
      loadRows({
        filter: preparedFilterOptions,
        currentPage: 0,
        pageSize,
        sort: sorting,
      });
    }

    if (multiple) {
      setSelectedRowsId([]);
    }

    setCurrentPage(0);
    setFilter(newFilterState);
    setFilterOptions(preparedFilterOptions);
  };

  if (!tableProps) {
    return <EntryPickerTableHeaderSkeleton />;
  }

  const tableFilterFormProps:
    | Omit<TableFilterFormProps, 'filterChips' | 'setFilterChips'>
    | undefined = tableProps.filterComponent && {
    ...(tableProps.filterComponent as TableFilterFormComponent),
    filter,
    setFilter: handleFilterChange as Dispatch<
      SetStateAction<DirtyFormValues | undefined>
    >,
  };

  return (
    <Box mb={filterChips?.length ? 0 : 1}>
      <MuiGrid container>
        <MuiGrid item xs={10} md={7}>
          {tableProps.header && <Header {...tableProps.header} />}
          {multiple ? (
            <Typography className={classes.quantity}>
              {`${formatMessage('selected')}: ${
                selectedRowsId.length
              } / ${totalRows}`}
            </Typography>
          ) : (
            <Typography className={classes.quantity}>
              {`${formatMessage('total')}: ${totalRows}`}
            </Typography>
          )}
        </MuiGrid>
        <MuiGrid item container xs={2} md={5} justify="flex-end">
          {tableProps.columnVisibilityConfig?.enabled && (
            <MuiGrid item>
              <EntryPickerTableColumnVisibilityButton />
            </MuiGrid>
          )}
          {tableFilterFormProps && (
            <TableFilters
              {...tableFilterFormProps}
              filterChips={filterChips}
              setFilterChips={setFilterChips}
            />
          )}
          <MuiGrid item>
            <EntryPickerTableActions />
          </MuiGrid>
        </MuiGrid>
        {tableFilterFormProps && (
          <TableFilterChips
            {...tableFilterFormProps}
            filterChips={filterChips}
            setFilterChips={setFilterChips}
          />
        )}
      </MuiGrid>
    </Box>
  );
};

export default EntryPickerTableHeader;
