import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type VersionState = Record<string, string> | null;

export const { reducer, actions } = createSlice({
  name: 'version',
  initialState: null as VersionState,
  reducers: {
    set: (state, { payload }: PayloadAction<VersionState>) => payload,
  },
});

export default reducer;
