import * as faker from 'faker';
import { Page } from 'services/Main/types.Page';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import { ChartDatum, Line } from '../../../types.Component';
import { LineChartState } from '../../../../../components/highLevel/LineChartWidget/types';

const generateLine = (): Line => {
  return {
    key: faker.datatype.uuid(),
    name: `${faker.commerce.productMaterial()} ${faker.commerce.product()}`,
  };
};
const generateChartDatum = (name: string, lineKeys: string[]): ChartDatum => {
  return {
    name,
    ...Object.fromEntries(
      lineKeys.map((key) => {
        const generatedIntegerValue = Math.floor(
          faker.datatype.number({ min: 50, max: 250 })
        );
        const noise = Math.round(Math.random() * 20 - 10); // Генерация случайного шума от -10 до 10
        const integerValueWithNoise = generatedIntegerValue + noise;

        return [
          key,
          {
            value: integerValueWithNoise,
          },
        ];
      })
    ),
  };
};

export const generateLines = (count: number) => {
  return Array.from({ length: count }, generateLine);
};

export const generateLineChartData = (lines: Line[]) => {
  const lineKeys = lines.map((l) => l.key);

  const months = Array.from({ length: 12 }, (_, i) => {
    return format(new Date(0, i), 'LLLL', { locale: ru });
  });

  const data = months.map((month) => {
    const capitalizedMonthName = month.charAt(0).toUpperCase() + month.slice(1);

    return generateChartDatum(capitalizedMonthName, lineKeys);
  });

  return {
    lines,
    data,
  };
};

export const dynamicLineChartDatum = generateLineChartData(generateLines(6));
export const getIncomeSecondRawByMonthChartDatum = (): LineChartState => {
  return {
    xAxisLabel: 'Мес.',
    yAxisLabel: 'Тыс. дол.',
    lines: [
      {
        key: '2022',
        name: '2022',
      },
      {
        key: '2023',
        name: '2023',
      },
      {
        key: '2024',
        name: '2024',
      },
    ],
    data: [
      {
        name: 'Янв',
        description: 'Январь',
        totalPrintValue: '608 000 р.',
        2022: {
          value: 221,
          printValue: '221 000 р.',
          href: '/',
        },
        2023: {
          value: 205,
          printValue: '205 000 р.',
          href: '/',
        },
        2024: {
          value: 182,
          printValue: '182 000 р.',
          href: '/',
        },
      },
      {
        name: 'Фев',
        description: 'Февраль',
        totalPrintValue: '478 000 р.',
        2022: {
          value: 185,
          printValue: '185 000 р.',
          href: '/',
        },
        2023: {
          value: 150,
          printValue: '150 000 р.',
          href: '/',
        },
        2024: {
          value: 143,
          printValue: '143 000 р.',
          href: '/',
        },
      },
      {
        name: 'Мар',
        description: 'Март',
        totalPrintValue: '477 000 р.',
        2022: {
          value: 170,
          printValue: '170 000 р.',
          href: '/',
        },
        2023: {
          value: 160,
          printValue: '160 000 р.',
          href: '/',
        },
        2024: {
          value: 147,
          printValue: '147 000 р.',
          href: '/',
        },
      },
      {
        name: 'Апр',
        description: 'Апрель',
        totalPrintValue: '584 000 р.',
        2022: {
          value: 254,
          printValue: '254 000 р.',
          href: '/',
        },
        2023: {
          value: 125,
          printValue: '125 000 р.',
          href: '/',
        },
        2024: {
          value: 205,
          printValue: '205 000 р.',
          href: '/',
        },
      },
      {
        name: 'Май',
        totalPrintValue: '589 000 р.',
        2022: {
          value: 224,
          printValue: '224 000 р.',
          href: '/',
        },
        2023: {
          value: 190,
          printValue: '190 000 р.',
          href: '/',
        },
        2024: {
          value: 175,
          printValue: '175 000 р.',
          href: '/',
        },
      },
      {
        name: 'Июн',
        description: 'Июнь',
        totalPrintValue: '346 000 р.',
        2022: {
          value: 146,
          printValue: '146 000 р.',
          href: '/',
        },
        2023: {
          value: 130,
          printValue: '130 000 р.',
          href: '/',
        },
        2024: {
          value: 70,
          printValue: '70 000 р.',
          href: '/',
        },
      },
      {
        name: 'Июл',
        description: 'Июль',
        totalPrintValue: '564 000 р.',
        2022: {
          value: 200,
          printValue: '200 000 р.',
          href: '/',
        },
        2023: {
          value: 164,
          printValue: '164 000 р.',
          href: '/',
        },
        2024: {
          value: 200,
          printValue: '200 000 р.',
          href: '/',
        },
      },
      {
        name: 'Авг',
        description: 'Август',
        totalPrintValue: '521 000 р.',
        2022: {
          value: 175,
          printValue: '175 000 р.',
          href: '/',
        },
        2023: {
          value: 150,
          printValue: '150 000 р.',
          href: '/',
        },
        2024: {
          value: 196,
          printValue: '196 000 р.',
          href: '/',
        },
      },
      {
        name: 'Сен',
        description: 'Сентябрь',
        totalPrintValue: '441 000 р.',
        2022: {
          value: 160,
          printValue: '160 000 р.',
          href: '/',
        },
        2023: {
          value: 155,
          printValue: '155 000 р.',
          href: '/',
        },
        2024: {
          value: 126,
          printValue: '126 000 р.',
          href: '/',
        },
      },
      {
        name: 'Окт',
        description: 'Октябрь',
        totalPrintValue: '675 000 р.',
        2022: {
          value: 250,
          printValue: '250 000 р.',
          href: '/',
        },
        2023: {
          value: 235,
          printValue: '235 000 р.',
          href: '/',
        },
        2024: {
          value: 190,
          printValue: '190 000 р.',
          href: '/',
        },
      },
      {
        name: 'Ноя',
        description: 'Ноябрь',
        totalPrintValue: '494 000 р.',
        2022: {
          value: 160,
          printValue: '160 000 р.',
          href: '/',
        },
        2023: {
          value: 150,
          printValue: '150 000 р.',
          href: '/',
        },
        2024: {
          value: 184,
          printValue: '184 000 р.',
          href: '/',
        },
      },
      {
        name: 'Дек',
        description: 'Декабрь',
        totalPrintValue: '313 000 р.',
        2022: {
          value: 95,
          printValue: '95 000 р.',
          href: '/',
        },
        2023: {
          value: 70,
          printValue: '70 000 р.',
          href: '/',
        },
        2024: {
          value: 148,
          printValue: '148 000 р.',
          href: '/',
        },
      },
    ],
  };
};
export const getIncomeSecondRawCumulatively = (): LineChartState => {
  return {
    lines: [
      {
        key: '2022',
        name: '2022',
      },
      {
        key: '2023',
        name: '2023',
      },
      {
        key: '2024',
        name: '2024',
      },
    ],
    data: [
      {
        name: 'Янв',
        description: 'Январь',
        totalPrintValue: '192 000 р.',
        2022: {
          value: 74,
        },
        2023: {
          value: 68,
        },
        2024: {
          value: 50,
        },
      },
      {
        name: 'Фев',
        description: 'Февраль',
        2022: {
          value: 88,
        },
        2023: {
          value: 64,
        },
        2024: {
          value: 54,
        },
      },
      {
        name: 'Мар',
        description: 'Март',
        2022: {
          value: 90,
        },
        2023: {
          value: 75,
        },
        2024: {
          value: 63,
        },
      },
      {
        name: 'Апр',
        description: 'Апрель',
        2022: {
          value: 90,
        },
        2023: {
          value: 78,
        },
        2024: {
          value: 64,
        },
      },
      {
        name: 'Май',
        2022: {
          value: 113,
        },
        2023: {
          value: 102,
        },
        2024: {
          value: 87,
        },
      },
      {
        name: 'Июн',
        description: 'Июнь',
        2022: {
          value: 123,
        },
        2023: {
          value: 113,
        },
        2024: {
          value: 98,
        },
      },
      {
        name: 'Июл',
        description: 'Июль',
        2022: {
          value: 137,
        },
        2023: {
          value: 133,
        },
        2024: {
          value: 117,
        },
      },
      {
        name: 'Авг',
        description: 'Август',
        2022: {
          value: 143,
        },
        2023: {
          value: 138,
        },
        2024: {
          value: 124,
        },
      },
      {
        name: 'Сен',
        description: 'Сентябрь',
        2022: {
          value: 156,
        },
        2023: {
          value: 145,
        },
        2024: {
          value: 130,
        },
      },
      {
        name: 'Окт',
        description: 'Октябрь',
        2022: {
          value: 170,
        },
        2023: {
          value: 156,
        },
        2024: {
          value: 158,
        },
      },
      {
        name: 'Ноя',
        description: 'Ноябрь',
        2022: {
          value: 176,
        },
        2023: {
          value: 165,
        },
        2024: {
          value: 165,
        },
      },
      {
        name: 'Дек',
        description: 'Декабрь',
        2022: {
          value: 220,
        },
        2023: {
          value: 197,
        },
        2024: {
          value: 186,
        },
      },
    ],
  };
};

export const lineChartWidgetsStubs: Page = {
  type: 'grid',
  title: 'Линейный графики',
  header: { heading: 'Линейный графики' },
  markup: [
    {
      mdCols: 6,
      component: {
        id: 100,
        businessComponentId: 51,
        type: 'lineChartWidget',
        props: {
          heading: 'Доход за реализацию вторсырья за месяц (УО-02)',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/line-chart/income-second-raw-by-month',
            method: 'GET',
          },
          xAxisLabel: 'Мес.',
          yAxisLabel: 'Тыс. руб.',
        },
      },
    },
    {
      mdCols: 6,
      component: {
        id: 100,
        businessComponentId: 52,
        type: 'lineChartWidget',
        props: {
          heading: 'Доход за реализацию вторсырья накопительно (УО-03)',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/line-chart/income-second-raw-cumulatively',
            method: 'GET',
          },
          xAxisLabel: 'Мес.',
          yAxisLabel: 'Тыс. руб.',
        },
      },
    },
    {
      mdCols: 6,
      component: {
        id: 100,
        businessComponentId: 53,
        type: 'lineChartWidget',
        props: {
          heading: 'Динамически сгенерированный график',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/line-chart/dynamic-6',
            method: 'GET',
          },
          xAxisLabel: 'Мес.',
          yAxisLabel: 'Тыс. руб.',
        },
      },
    },
    {
      mdCols: 6,
      component: {
        id: 100,
        businessComponentId: 54,
        type: 'lineChartWidget',
        props: {
          heading: 'Динамически сгенерированный график',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/line-chart/dynamic-random',
            method: 'GET',
          },
          xAxisLabel: 'Мес.',
          yAxisLabel: 'Тыс. руб.',
        },
      },
    },
    {
      mdCols: 6,
      component: {
        id: 100,
        businessComponentId: 55,
        type: 'lineChartWidget',
        props: {
          heading: 'График без данных',
          requestConfig: {
            url: '/api/widgets/line-chart/empty',
            method: 'GET',
          },
        },
      },
    },
    {
      mdCols: 6,
      component: {
        id: 100,
        businessComponentId: 55,
        type: 'lineChartWidget',
        props: {
          heading: 'График с ошибкой',
          requestConfig: {
            url: '/api/widgets/line-chart/error',
            method: 'GET',
          },
        },
      },
    },
  ],
};
