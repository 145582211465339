import React, { useContext, useCallback } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import Icon from '../Icon/Icon';
import { TableContext } from '../../highLevel/Table';
import { useFormatMessage } from '../../../locale';

const TableWithClosedButton = () => {
  const formatMessage = useFormatMessage();

  const tableContext = useContext(TableContext);
  const isActive = tableContext?.withClosed;

  const handleClick = useCallback(() => {
    tableContext?.setWithClosed((prevState) => !prevState);
  }, [tableContext]);

  return (
    <Tooltip
      title={
        isActive ? formatMessage('hideClosed') : formatMessage('showClosed')
      }
    >
      <IconButton
        color={isActive ? 'primary' : 'default'}
        onClick={handleClick}
      >
        <Icon definition="mui:Archive" />
      </IconButton>
    </Tooltip>
  );
};

export default TableWithClosedButton;
