import React, { ReactElement, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import QrReaderComponent from './QrReaderComponent';
import { useFormatMessage } from '../../../locale';

type QrReaderButtonProps = {
  renderButton: (handleOpen: () => void) => ReactElement;
};

const QrReaderButton = ({ renderButton }: QrReaderButtonProps) => {
  const formatMessage = useFormatMessage();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {renderButton(handleClickOpen)}
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="qr-reader">
        <DialogTitle id="qr-reader">
          {formatMessage('QRCodeDescription')}
        </DialogTitle>
        <DialogContent>
          <QrReaderComponent />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QrReaderButton;
