import { Page } from '../../../types.Page';

export const approveServiceWorkForm: Page = {
  type: 'singleComponent',
  title: 'Выполнение ТО',
  component: {
    id: 100,
    businessComponentId: 7,
    type: 'form',
    props: {
      actions: [
        {
          type: 'showForm',
          label: 'Отправить на доработку',
          viewStyle: 'modal',
          variant: 'outlined',
          color: 'primary',
          actions: [
            {
              type: 'submitForm',
              label: 'Отправить',
              variant: 'contained',
              color: 'primary',
              requestConfig: {
                url: '/api/complex/approve-service-work',
                method: 'POST',
              },
              successResponseReaction: {
                type: 'showAlertAndRedirect',
                alertText: 'Отправлено на доработку!',
                redirectTo: '/issues',
              },
            },
          ],
          form: {
            type: 'markup',
            header: { heading: 'Отправить на доработку' },
            fieldGroups: [
              {
                fields: [
                  { type: 'text', name: 'comment', label: 'Комментарий' },
                  {
                    type: 'file',
                    name: 'files',
                    label: 'Файлы',
                  },
                ],
              },
            ],
          },
        },
        {
          type: 'submitForm',
          color: 'primary',
          variant: 'contained',
          label: 'Подтвердить выполнение',
          requestConfig: {
            url: '/api/orders/repairs',
            method: 'POST',
          },
          successResponseReaction: {
            type: 'showAlertAndRedirect',
            alertText:
              'Вы подтвердили выполнение и будете перенаправлены на список заявок.',
            redirectTo: '/issues',
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Выполнение ТО-3' },
        fieldGroups: [
          {
            label: 'Работа 1',
            fields: [
              {
                type: 'text',
                name: 'work1',
                label: 'Работы',
                defaultValue: `Проверьте режимы работы:
  1. При низкой нагрузке.
  2. При давлении в 4.5 бар.
  3. Клапан спуска давления.
  4. Термодатчик.`,
                disabled: true,
                multiline: true,
                mdCols: 12,
              },
              {
                type: 'text',
                name: 'comment1',
                label: 'Комментарий',
                defaultValue: 'Готово.',
                mdCols: 8,
              },
              {
                type: 'file',
                name: 'files1',
                viewConfig: { viewStyle: 'textFieldWithClip' },
                label: 'Файлы',
                defaultValue: [
                  {
                    id: 'e34bda21bf-51c5-49be-9dd5-7dbce3f06e51',
                    url: '/e34bda21bf-51c5-49be-9dd5-7dbce3f06e51',
                    name: 'singleComponent.docx',
                    size: 1,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  },
                ],
                mdCols: 3,
              },
              {
                label: 'Выполнено',
                type: 'checkbox',
                name: 'checkbox1',
                defaultValue: true,
                mdCols: 1,
              },
            ],
          },
          {
            label: 'Работа 2',
            fields: [
              {
                type: 'text',
                name: 'work2',
                label: 'Работы',
                defaultValue: 'Визуальный осмотр и контроль списка',
                disabled: true,
                multiline: true,
                mdCols: 12,
              },
              {
                type: 'text',
                name: 'comment2',
                label: 'Комментарий',
                defaultValue: 'Проверил, всё работает.',
                mdCols: 8,
              },
              {
                type: 'file',
                name: 'files2',
                viewConfig: { viewStyle: 'textFieldWithClip' },
                label: 'Файлы',
                defaultValue: [
                  {
                    id: 'e34bda21bf-51c5-49be-9dd5-7dbce3f06e51',
                    url: '/e34bda21bf-51c5-49be-9dd5-7dbce3f06e51',
                    name: 'singleComponent.docx',
                    size: 1,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  },
                ],
                mdCols: 3,
              },
              {
                label: 'Выполнено',
                type: 'checkbox',
                name: 'checkbox2',
                mdCols: 1,
              },
            ],
          },
          {
            label: 'Общая информация по проведённому ТО',
            fields: [
              {
                type: 'text',
                name: 'comment3',
                label: 'Комментарий',
                multiline: true,
              },
            ],
          },
        ],
      },
    },
  },
};
