import { Page } from 'services/Main/types.Page';

export const entryPickerFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами EntryPicker',
  component: {
    id: 100,
    businessComponentId: 22,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          variant: 'contained',
          color: 'primary',
          label: 'Сохранить',
          requestConfig: { url: '/api/fields/entry-picker', method: 'GET' },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами EntryPicker' },
        fieldGroups: [
          {
            label: 'Entry picker',
            fields: [
              {
                type: 'entryPicker',
                name: 'entryPickerSingle',
                label: 'Оборудование (Пустое)',
                gridRequestConfig: {
                  url: '/api/entry-picker/grid',
                  method: 'POST',
                },
                searchRequestConfig: {
                  url: '/api/entry-picker/search',
                  method: 'GET',
                },
                includeFormStateWithRequests: true,
                multiple: false,
              },
              {
                type: 'entryPicker',
                name: 'entryPickerSingleRequired',
                label: 'Оборудование (Обязательное)',
                gridRequestConfig: {
                  url: '/api/entry-picker/grid',
                  method: 'POST',
                },
                searchRequestConfig: {
                  url: '/api/entry-picker/search',
                  method: 'GET',
                },
                multiple: false,
                validationConfig: {
                  validationType: 'mixed',
                  validations: [
                    { type: 'required', params: ['Обязательное поле'] },
                  ],
                },
              },
              {
                type: 'entryPicker',
                name: 'entryPickerSingleDefaultValue',
                label: 'Оборудование (C DefaultValue)',
                gridRequestConfig: {
                  url: '/api/entry-picker/grid',
                  method: 'POST',
                },
                searchRequestConfig: {
                  url: '/api/entry-picker/search',
                  method: 'GET',
                },
                defaultValue: {
                  value: '4680c1d7-a921-4427-8921-e70f4ccd7447',
                  label: 'Печь',
                  href: '/equipments/4680c1d7-a921-4427-8921-e70f4ccd7447',
                },
                multiple: false,
                disabled: true,
              },
              {
                type: 'entryPicker',
                name: 'entryPickerSingleDisabledDefaultValue',
                label: 'Оборудование (Disabled с DefaultValue',
                gridRequestConfig: {
                  url: '/api/entry-picker/grid',
                  method: 'POST',
                },
                searchRequestConfig: {
                  url: '/api/entry-picker/search',
                  method: 'GET',
                },
                multiple: false,
                disabled: true,
                defaultValue: {
                  value: '4680c1d7-a921-4427-8921-e70f4ccd7447',
                  label: 'Холодильник',
                  href: '/equipments/4680c1d7-a921-4427-8921-e70f4ccd7447',
                },
              },
              {
                type: 'entryPicker',
                name: 'entryPickerSingleDisableClearable',
                label: 'Оборудование (DisableClearable)',
                gridRequestConfig: {
                  url: '/api/entry-picker/grid',
                  method: 'POST',
                },
                searchRequestConfig: {
                  url: '/api/entry-picker/search',
                  method: 'GET',
                },
                multiple: false,
                disableClearable: true,
                defaultValue: {
                  value: '4680c1d7-a921-4427-8921-e70f4ccd7447',
                  label: 'Холодильник',
                  href: '/equipments/4680c1d7-a921-4427-8921-e70f4ccd7447',
                },
              },
            ],
          },
          {
            label: 'Entry picker (multi)',
            fields: [
              {
                type: 'entryPicker',
                name: 'entryPickerMulti',
                label: 'Список оборудования (Пустое)',
                gridRequestConfig: {
                  url: '/api/entry-picker/grid',
                  method: 'POST',
                },
                searchRequestConfig: {
                  url: '/api/entry-picker/search',
                  method: 'GET',
                },
                includeFormStateWithRequests: true,
                multiple: true,
              },
              {
                type: 'entryPicker',
                name: 'entryPickerMultiRequired',
                label: 'Список оборудования (Обязательное)',
                gridRequestConfig: {
                  url: '/api/entry-picker/grid',
                  method: 'POST',
                },
                searchRequestConfig: {
                  url: '/api/entry-picker/search',
                  method: 'GET',
                },
                multiple: true,
                validationConfig: {
                  validationType: 'array',
                  validations: [
                    { type: 'required', params: ['Обязательное поле'] },
                  ],
                },
              },
              {
                type: 'entryPicker',
                name: 'entryPickerMultiDefaultValue',
                label: 'Список оборудования (С DefaultValue)',
                gridRequestConfig: {
                  url: '/api/entry-picker/grid',
                  method: 'POST',
                },
                searchRequestConfig: {
                  url: '/api/entry-picker/search',
                  method: 'GET',
                },
                multiple: true,
                defaultValue: [
                  {
                    value: '4680c1d7-a921-4427-8921-e70f4ccd7447',
                    label: 'Холодильник',
                    href: '/equipments/4680c1d7-a921-4427-8921-e70f4ccd7447',
                  },
                  {
                    value: '28dc43cb-e7f3-4d26-bcc9-250dae9aa0eb',
                    label: 'Печь',
                    href: '/equipments/28dc43cb-e7f3-4d26-bcc9-250dae9aa0eb"',
                  },
                ],
              },
              {
                type: 'entryPicker',
                name: 'entryPickerMultiDisabledDefaultValue',
                label: 'Список оборудования (Disabled с DefaultValue)',
                gridRequestConfig: {
                  url: '/api/entry-picker/grid',
                  method: 'POST',
                },
                searchRequestConfig: {
                  url: '/api/entry-picker/search',
                  method: 'GET',
                },
                multiple: true,
                disabled: true,
                defaultValue: [
                  {
                    value: '4680c1d7-a921-4427-8921-e70f4ccd7447',
                    label: 'Холодильник',
                    href: '/equipments/4680c1d7-a921-4427-8921-e70f4ccd7447',
                  },
                  {
                    value: '28dc43cb-e7f3-4d26-bcc9-250dae9aa0eb',
                    label: 'Печь',
                    href: '/equipments/28dc43cb-e7f3-4d26-bcc9-250dae9aa0eb"',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};
