import faker from 'faker';
import { Page } from 'services/Main/types.Page';
import { DatePicker } from 'services/Main/types.Field';
import { formatISO, parseISO } from 'date-fns';

export const simpleDatePicker: DatePicker = {
  type: 'datePicker',
  pickerType: 'date',
  name: 'date1',
  label: 'простой date picker',
  initialFocusedDate: 'now',
};

export const dateFieldFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами date field',
  component: {
    id: 100,
    businessComponentId: 17,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          label: 'Сохранить',
          color: 'primary',
          variant: 'contained',
          requestConfig: { url: '/api/fields/date' },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами date field' },
        fieldGroups: [
          {
            label: 'Date pickers 📅',
            fields: [
              simpleDatePicker,
              {
                type: 'datePicker',
                pickerType: 'dateTime',
                name: 'date2',
                label: 'простой date time picker',
                initialFocusedDate: 'now',
              },
              {
                type: 'datePicker',
                pickerType: 'dateRange',
                name: 'date3',
                label: 'date range picker',
                initialFocusedDate: 'now',
                defaultValue: {
                  from: formatISO(faker.date.past(), {
                    representation: 'date',
                  }),
                  to: formatISO(faker.date.future(), {
                    representation: 'date',
                  }),
                },
              },
              {
                type: 'datePicker',
                pickerType: 'dateTimeRange',
                name: 'date4',
                label: 'date time range picker',
                initialFocusedDate: 'now',
              },
              {
                type: 'datePicker',
                pickerType: 'date',
                name: 'date5',
                label: 'date picker, с явно указанной initialFocusedDate',
                initialFocusedDate: formatISO(faker.date.past(), {
                  representation: 'date',
                }),
              },
              {
                type: 'datePicker',
                pickerType: 'date',
                name: 'date6',
                label: 'Disabled date picker',
                disabled: true,
                defaultValue: formatISO(faker.date.future(), {
                  representation: 'date',
                }),
              },
              {
                type: 'datePicker',
                pickerType: 'date',
                name: 'date7',
                minDate: '2020-12-07',
                maxDate: '2020-12-09',
                initialFocusedDate: '2020-12-08',
                label: 'Ограничение по возможности выбора дат',
              },
              {
                type: 'datePicker',
                pickerType: 'date',
                name: 'date8',
                mdCols: 3,
                label: 'Можно стакать пикеры',
              },
              {
                type: 'datePicker',
                pickerType: 'date',
                name: 'date9',
                mdCols: 6,
                label: 'В одну строку',
              },
              {
                type: 'datePicker',
                pickerType: 'date',
                name: 'date10',
                mdCols: 3,
                label: 'На десктопе',
              },
              {
                type: 'datePicker',
                pickerType: 'date',
                name: 'date11',
                label:
                  'Валидация минимум 1 ноября 2020 года. НО! Это можно не делать, т.к. есть возможность ограничить minDate maxDate.',
                validationConfig: {
                  validationType: 'date',
                  validations: [
                    {
                      type: 'required',
                      params: ['Обязательное поле'],
                    },
                    {
                      type: 'min',
                      params: [
                        parseISO('2020-11-01'),
                        'Дата должна быть позже 1 ноября 2020',
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            label: 'YearPicker',
            fields: [
              {
                name: 'yearPicker',
                label: 'Year picker',
                type: 'datePicker',
                pickerType: 'year',
                disabled: false,
              },
              {
                label: 'Year picker (disabled)',
                name: 'yearPickerDisabled',
                type: 'datePicker',
                pickerType: 'year',
                defaultValue: '2023',
                disabled: true,
              },
              {
                label: 'Year picker (минимум: 2020 г., максимум: 2040 г.)',
                name: 'yearPickerValidation',
                type: 'datePicker',
                pickerType: 'year',
                disabled: false,
                minDate: '2020-01-01',
                maxDate: '2040-12-31',
                validationConfig: {
                  validationType: 'number',
                  validations: [
                    {
                      type: 'required',
                      params: ['Обязательное поле'],
                    },
                    {
                      type: 'min',
                      params: [2020, 'Минимум 2020 год'],
                    },
                    {
                      type: 'max',
                      params: [2040, 'Максимум 2040 год'],
                    },
                  ],
                },
              },
              {
                name: 'yearPickerDisableClearable',
                label: 'Year picker (DisableClearable)',
                type: 'datePicker',
                pickerType: 'year',
                disabled: false,
                disableClearable: true,
              },
            ],
          },
        ],
      },
    },
  },
};
