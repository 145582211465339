import faker from 'faker';
import { Page } from 'services/Main/types.Page';

faker.locale = 'ru';

const createRow = () => {
  return {
    id: faker.datatype.uuid(),
    code: `${faker.datatype.number({
      min: 1000,
      max: 9999,
    })}-${faker.datatype.number({
      min: 100,
      max: 999,
    })}/${faker.datatype.number({
      min: 10,
      max: 99,
    })}`,
    type: faker.lorem.word(),
    author: `${faker.name.lastName()} ${faker.name.firstName()}`,
  };
};

export const allSimpleTablePageRows = Array.from({ length: 5 }, createRow);

export const simpleTablePage: Page = {
  type: 'singleComponent',
  title: 'Простая таблица',
  component: {
    id: 404,
    businessComponentId: 36,
    type: 'table',
    props: {
      heading: 'Простая таблица',
      requestConfig: { url: '/api/table/simple' },
      columns: [
        { title: 'Номер', name: 'code' },
        { title: 'Тип', name: 'type' },
        { title: 'Инициатор', name: 'author' },
      ],
      rows: allSimpleTablePageRows.slice(0, 5),
      options: {
        totalRows: allSimpleTablePageRows.length,
        currentPage: 0,
        pageSize: 20,
      },
    },
  },
};
