import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    quantity: {
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.grey[500],
    },
    select: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(3),
      textAlign: 'right',
      // Align <select> on Chrome.
      textAlignLast: 'right',
    },
    input: {
      color: 'inherit',
      fontSize: 'inherit',
      flexShrink: 0,
    },
    selectRoot: {
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(1),
    },
    messageContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    pseudoLink: {
      color: theme.palette.text.primary,
      cursor: 'pointer',
    },
    menuItem: {
      padding: theme.spacing(0.5, 2),
    },
    listItemIcon: {
      minWidth: 0,
    },
    checkbox: {
      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    dragDropContainer: {
      position: 'fixed',
      zIndex: theme.zIndex.modal + 1,
      left: 0,
      top: 0,
      display: 'inline-block',
    },
    pagingPanelContainer: {
      justifyContent: 'flex-end',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
    },
  })
);
