import React from 'react';
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'store';
import ComponentLoader from '../../creational/ComponentLoader';
import { dialogWithComponentActions } from '../../../store/dialogWithComponent';

function DialogWithComponent() {
  const dispatch = useDispatch();
  const dialogWithComponent = useSelector((state) => state.dialogWithComponent);

  const handleClose = () => {
    dispatch(dialogWithComponentActions.close());
  };

  if (!dialogWithComponent) return null;

  const { component, viewStyle } = dialogWithComponent;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={viewStyle === 'fullScreenModal'}
      onClose={handleClose}
      aria-labelledby="dialog-with-component"
      open={!!component}
    >
      <Box p={[2, 3]}>
        <ComponentLoader component={component} />
      </Box>
    </Dialog>
  );
}

export default DialogWithComponent;
