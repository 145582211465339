import { Page } from '../../../types.Page';
import { OptionsType } from '../../../types.Field';
import { generateSelectOptions } from '../fields/ComboBox.stub';
import { SummaryRow, TableRow } from '../../../types.Component';

export const homeObjectResponsible: Page = {
  type: 'gridWithGroups',
  title: 'Ответственный объекта (МТП)',
  sidebar: {
    type: 'filter',
    header: 'Список фильтров',
    filterComponent: {
      type: 'tableFilter',
      id: 30,
      businessComponentId: 12,
      props: {
        requestOn: 'change',
        fieldGroups: [
          {
            fields: [
              {
                name: 'store-object',
                type: 'comboBox',
                optionsType: OptionsType.flat,
                label: 'Объект',
                multiline: true,
                multiple: true,
                chipTagViewStyle: 'chip',
                options: generateSelectOptions(6),
              },
              {
                name: 'type-of-recyclable-materials',
                type: 'comboBox',
                optionsType: OptionsType.flat,
                label: 'Тип вторсырья',
                multiline: true,
                multiple: true,
                chipTagViewStyle: 'chip',
                options: generateSelectOptions(6),
              },
            ],
          },
        ],
      },
    },
  },
  groups: [
    {
      header: {
        heading: 'Внеплановые заявки',
      },
      markup: [
        {
          lgCols: 4,
          // @ts-ignore
          component: {
            id: 600300,
            businessComponentId: 45,
            type: 'tableWidget',
            // @ts-ignore
            props: {
              header: { heading: 'Открытые заявки в разрезе статуса (В3-01)' },
              requestConfig: {
                url: '/api/widgets/table/600300',
              },
              options: {
                totalRows: 4,
                currentPage: 0,
                pageSize: 4,
              },
              columns: [
                {
                  name: 'jobType',
                  title: 'Статус',
                  options: { formatAs: 'link' },
                },
                {
                  name: 'count',
                  title: 'Кол-во',
                },
              ],
              columnExtensions: [
                { columnName: 'count', width: 100, alignAll: 'center' },
              ],
              showTotalRows: false,
              fullHeight: false,
            },
          },
        },
        {
          lgCols: 4,
          component: {
            id: 200,
            businessComponentId: 123,
            type: 'donutChartWidget',
            props: {
              heading: 'Мои заявки (В3-05)',
              showLegend: true,
              showTooltip: true,
              requestConfig: {
                url: '/api/widgets/donut-chart/300101',
                method: 'GET',
              },
            },
          },
        },
        {
          lgCols: 4,
          component: {
            id: 100,
            businessComponentId: 23,
            type: 'groupOfTextWidget',
            props: [
              {
                title: 'Открытые просроченные заявки (ВЗ-04)',
                requestConfig: {
                  url: '/api/widgets/text-widget/600105',
                  method: 'GET',
                },
              },
              {
                title: 'Заявки с просрочен. тек. заданиями (ВЗ-03)',
                requestConfig: {
                  url: '/api/widgets/text-widget/600106',
                  method: 'GET',
                },
              },
            ],
          },
        },
        {
          lgCols: 8,
          // @ts-ignore
          component: {
            id: 600400,
            businessComponentId: 45,
            type: 'tableWidget',
            // @ts-ignore
            props: {
              header: { heading: 'Мои задания за месяц на выполнение (ВЗ-02)' },
              requestConfig: {
                url: '/api/widgets/table/600400',
              },
              options: {
                totalRows: 4,
                currentPage: 0,
                pageSize: 4,
              },
              columns: [
                { name: 'jobType', options: { formatAs: 'link' } },
                { name: 'completedCount', options: { formatAs: 'link' } },
                { name: 'inWorkCount', options: { formatAs: 'link' } },
              ],
              columnExtensions: [
                { columnName: 'inWorkCount', width: 80 },
                { columnName: 'completedCount', width: 80 },
              ],
              showTotalRows: false,
              fullHeight: false,
            },
          },
        },
      ],
    },
    {
      header: {
        heading: 'Плановое ТО ',
      },
      markup: [
        {
          mdCols: 8,
          component: {
            id: 4000100,
            businessComponentId: 23,
            type: 'barChartWidget',
            props: {
              heading: 'Открытые задания по объекту (ПТ-01)',
              showLegend: true,
              showTooltip: true,
              orientation: 'vertical',
              requestConfig: {
                url: '/api/widgets/bar/4000100',
                method: 'POST',
              },
            },
          },
        },
        {
          lgCols: 4,
          // @ts-ignore
          component: {
            id: 600500,
            businessComponentId: 45,
            type: 'tableWidget',
            // @ts-ignore
            props: {
              header: {
                heading:
                  'Задания по выполнению ТО за месяц в разрезе статуса (ПТ-02)',
              },
              requestConfig: {
                url: '/api/widgets/table/600500',
              },
              options: {
                totalRows: 4,
                currentPage: 0,
                pageSize: 4,
              },
              columns: [
                { name: 'jobType', options: { formatAs: 'link' } },
                { name: 'count' },
              ],
              columnExtensions: [{ columnName: 'count', width: 100 }],
              showTotalRows: false,
              fullHeight: false,
            },
          },
        },
        {
          lgCols: 4,
          component: {
            id: 100,
            businessComponentId: 23,
            type: 'groupOfTextWidget',
            props: [
              {
                title: 'Утверждение ежегодных ППР (ПТ-03)',
                requestConfig: {
                  url: '/api/widgets/text-widget/600106',
                  method: 'GET',
                },
              },
            ],
          },
        },
        {
          lgCols: 4,
          component: {
            id: 100,
            businessComponentId: 23,
            type: 'groupOfTextWidget',
            props: [
              {
                title: 'Согласование ежегодных ППР (ПТ-04)',
                requestConfig: {
                  url: '/api/widgets/text-widget/600107',
                  method: 'GET',
                },
              },
            ],
          },
        },
        {
          lgCols: 4,
          component: {
            id: 100,
            businessComponentId: 23,
            type: 'groupOfTextWidget',
            props: [
              {
                title: 'Согласование ежемесячных ППР (ПТ-05)',
                requestConfig: {
                  url: '/api/widgets/text-widget/600108',
                  method: 'GET',
                },
              },
            ],
          },
        },
      ],
    },
    {
      header: {
        heading: 'Управление отходами',
      },
      markup: [
        {
          lgCols: 4,
          // @ts-ignore
          component: {
            id: 600400,
            businessComponentId: 45,
            type: 'tableWidget',
            // @ts-ignore
            props: {
              header: { heading: 'Заявки в разрезе статуса (УО-01)' },
              requestConfig: {
                url: '/api/widgets/table/600600',
              },
              options: {
                totalRows: 4,
                currentPage: 0,
                pageSize: 4,
              },
              columns: [
                { name: 'jobType', options: { formatAs: 'link' } },
                { name: 'count' },
              ],
              columnExtensions: [{ columnName: 'count', width: 100 }],
              showTotalRows: false,
              fullHeight: false,
            },
          },
        },
        {
          lgCols: 4,
          component: {
            id: 100,
            businessComponentId: 23,
            type: 'groupOfTextWidget',
            props: [
              {
                title: 'Согласование графиков отгрузки (УО-06)',
                requestConfig: {
                  url: '/api/widgets/text-widget/600110',
                  method: 'GET',
                },
              },
            ],
          },
        },
      ],
    },
    {
      header: {
        heading: 'Контрагенты',
      },
      markup: [
        {
          lgCols: 8,
          // @ts-ignore
          component: {
            id: 6004021,
            businessComponentId: 45,
            type: 'tableWidget',
            // @ts-ignore
            props: {
              header: {
                heading:
                  'Договоры с истекающими сроком действия по направлению (КА-01)',
              },
              requestConfig: {
                url: '/api/widgets/table/600800',
              },
              options: {
                totalRows: 4,
                currentPage: 0,
                pageSize: 4,
              },
              columns: [
                { name: 'jobType', options: { formatAs: 'link' } },
                { name: 'currentCount', options: { formatAs: 'link' } },
                { name: 'expireCount', options: { formatAs: 'link' } },
              ],
              columnExtensions: [{ columnName: 'count', width: 100 }],
              showTotalRows: false,
              fullHeight: false,
            },
          },
        },
      ],
    },
  ],
};

export const openRequestTableRows: TableRow[] = [
  {
    id: 'faca0035-e390-4ec2-b383-fd58f4c5634f',
    jobType: {
      href: '/',
      label: 'Уточнение',
    },
    count: '0',
  },
  {
    id: 'b4443ccd-ce70-41c6-90c6-ca5d021a856c',
    jobType: {
      href: '/',
      label: 'В очереди',
    },
    count: '7',
  },
  {
    id: '387821b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'В работе',
    },
    count: '12',
  },
  {
    id: '5d37c204-44c4-4d9b-8ac5-3ee394968730',
    jobType: {
      href: '/',
      label: 'Проведение закупки',
    },
    count: '3',
  },
  {
    id: '321sc204-44c4-4d9b-8ac5-3ee394968730',
    jobType: {
      href: '/',
      label: 'Приемка',
    },
    count: '4',
  },
];

export const myMonthRequestTableRows: TableRow[] = [
  {
    id: 'faca0035-e390-4ec2-b383-fd58f4c5634f',
    jobType: {
      href: '/',
      label: 'Уточнение заявки',
    },
    completedCount: {
      href: '/',
      label: '9',
    },
    inWorkCount: {
      href: '/',
      label: '5',
    },
  },
  {
    id: 'b4443ccd-ce70-41c6-90c6-ca5d021a856c',
    jobType: {
      href: '/',
      label: 'Приемка закрытия заявки ответственным',
    },
    completedCount: {
      href: '/',
      label: '18',
    },
    inWorkCount: {
      href: '/',
      label: '11',
    },
  },
  {
    id: '387821b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'Согласование переноса срока заявки',
    },
    completedCount: {
      href: '/',
      label: '56',
    },
    inWorkCount: {
      href: '/',
      label: '32',
    },
  },
];

export const myMonthRequestSummaryRow: SummaryRow = {
  cells: [
    {
      columnName: 'completedCount',
      label: '83',
    },
    {
      columnName: 'inWorkCount',
      label: '48',
    },
  ],
};

export const tasksCompletedMonthlyByStatus: TableRow[] = [
  {
    id: 'faca0035-e390-4ec2-b383-fd58f4c5634f',
    jobType: {
      href: '/',
      label: 'Выполненные',
    },
    count: '10',
  },
  {
    id: 'b4443ccd-ce70-41c6-90c6-ca5d021a856c',
    jobType: {
      href: '/',
      label: 'Невыполненные',
    },
    count: '7',
  },
  {
    id: '387821b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'Выполненные просроченные',
    },
    count: '2',
  },
  {
    id: '32121b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'Невыполненные просроченные',
    },
    count: '0',
  },
];

export const tasksCompletedMonthlyByStatusSummaryRow: SummaryRow = {
  cells: [
    {
      columnName: 'count',
      label: '19',
    },
  ],
};

export const tasksByMonth: TableRow[] = [
  {
    id: 'faca0035-e390-4ec2-b383-fd58f4c5634f',
    jobType: {
      href: '/',
      label: 'Черновик',
    },
    count: '0',
  },
  {
    id: 'b4443ccd-ce70-41c6-90c6-ca5d021a856c',
    jobType: {
      href: '/',
      label: 'Направлено контрагенту',
    },
    count: '5',
  },
  {
    id: '387821b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'В работе',
    },
    count: '16',
  },
  {
    id: '32121b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'Отменена',
    },
    count: '4',
  },
  {
    id: '42121b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'Приемка',
    },
    count: '1',
  },
  {
    id: '52121b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'Закрыто',
    },
    count: '6',
  },
];

export const tasksByMonthSummaryRow: SummaryRow = {
  cells: [
    {
      columnName: 'count',
      label: '32',
    },
  ],
};

export const contractsByType: TableRow[] = [
  {
    id: 'faca0035-e390-4ec2-b383-fd58f4c5634f',
    jobType: {
      href: '/',
      label: 'Складское оборудование',
    },
    currentCount: {
      href: '/',
      label: '5',
    },
    expireCount: {
      href: '/',
      label: '1',
    },
  },
  {
    id: 'b4443ccd-ce70-41c6-90c6-ca5d021a856c',
    jobType: {
      href: '/',
      label: 'Вертикальных связей',
    },
    currentCount: {
      href: '/',
      label: '12',
    },
    expireCount: {
      href: '/',
      label: '0',
    },
  },
  {
    id: '387821b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'Холодильного оборудования',
    },
    currentCount: {
      href: '/',
      label: '7',
    },
    expireCount: {
      href: '/',
      label: '2',
    },
  },
];
