import { Page } from 'services/Main/types.Page';

export const HTMLPrinterPage: Page = {
  type: 'singleComponent',
  title: 'HTMLPrinter',
  component: {
    id: 957,
    businessComponentId: 34,
    type: 'HTMLPrinter',
    props: {
      html: '<h1>Hello!</h1><p>Some text</p><p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab asperiores consectetur deleniti dignissimos doloremque ducimus eligendi esse iure libero, maiores minus modi neque odit officiis quam quasi repellat soluta suscipit!</p>',
    },
  },
};
