// О заявке
import { Page, Tab } from '../../../types.Page';
import { Header } from '../../../types.Component';

export const issuesElementPageHeader: Header = {
  heading: 'ПОДЗАДАЧА. Привет, я тут 🌑',
  preHeadingIcon: {
    type: 'icon',
    name: 'local:Record',
    props: {
      fill: '#ff0000',
      stroke: '#ff0000',
    },
  },
  subHeadings: [
    ['Номер', '0077-004/20'],
    ['Создано', '2020-04-15T16:33:23.3004811+03:00'],
  ],
};

const Tab1: Tab = {
  label: 'О заявке',
  component: {
    id: 300,
    businessComponentId: 48,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        disabled: true,
        header: issuesElementPageHeader,
        historyConfig: {
          requestConfig: {
            url: 'http://localhost:3000/api/history',
            method: 'GET',
          },
        },
        fieldGroups: [
          {
            label: 'Информация по заявке',
            fields: [
              {
                type: 'select',
                selectType: 'single',
                name: 'object',
                label: 'Объект обслуживания',
                defaultValue: {
                  value: '43fdg4-df23h-gdf5v',
                  label: 'Печь для пиццы ярусная',
                },
                options: [
                  {
                    value: '43fdg4-df23h-gdf5v',
                    label: 'Печь для пиццы ярусная',
                  },
                ],
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'issueType',
                label: 'Тип заявки',
                defaultValue: { value: '0000-0000-000000', label: 'Заглушка' },
                options: [{ value: '0000-0000-000000', label: 'Заглушка' }],
                mdCols: 6,
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'shop',
                label: 'Магазин',
                defaultValue: {
                  value: '234fd-sadf32-a4gmfd',
                  label: 'Красногорск',
                },
                options: [
                  {
                    value: '234fd-sadf32-a4gmfd',
                    label: 'Красногорск',
                  },
                ],
                mdCols: 6,
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'room',
                label: 'Помещение',
                defaultValue: {
                  value: 'baaf69a1-1914-495d-acfd-0e9b078a00df',
                  label:
                    'Кондитерская - Производственная зона, 2 этаж 0-1 / b\'-b"',
                },
                options: [
                  {
                    value: 'baaf69a1-1914-495d-acfd-0e9b078a00df',
                    label:
                      'Кондитерская - Производственная зона, 2 этаж 0-1 / b\'-b"',
                  },
                ],
              },
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
                defaultValue: 'Привет, я тут 🌑',
              },
            ],
          },
          {
            label: 'Участники',
            accordion: { enabled: true, defaultExpanded: false },
            fields: [
              {
                type: 'select',
                selectType: 'single',
                name: 'author',
                label: 'Инициатор',
                defaultValue: {
                  value: 'd360ddae-da05-4d20-b4e7-988a4013a02f',
                  label: 'Титов Владимир',
                },
                options: [
                  {
                    value: 'd360ddae-da05-4d20-b4e7-988a4013a02f',
                    label: 'Титов Владимир',
                  },
                ],
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'assignee',
                label: 'Ответственный',
                defaultValue: {
                  value: '22dc48cb-4f2e-4012-82d8-196fa2d2cd9a',
                  label: 'Малогин Андрей (ООО "БЕКАР-ЭКСПЛУАТАЦИЯ")',
                },
                options: [
                  {
                    value: '22dc48cb-4f2e-4012-82d8-196fa2d2cd9a',
                    label: 'Малогин Андрей (ООО "БЕКАР-ЭКСПЛУАТАЦИЯ")',
                  },
                ],
              },
            ],
          },
          {
            label: 'Прикрепленные файлы',
            fields: [
              {
                type: 'file',
                name: 'files',
                label: 'Файлы',
                defaultValue: [
                  {
                    id: 'ebda21bf-51c5-49be-9dd5-7dbce3f06e52',
                    url: '/ebda21bf-51c5-49be-9dd5-7dbce3f06e52',
                    name: 'Estimate.docx',
                    size: 26696,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};

export const someTasksSubTaskElementPage: Page = {
  type: 'tabsView',
  tabs: [Tab1],
  title: 'Подзадача на ремонт',
};
