import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles<Theme>(() =>
  createStyles({
    swiperContainer: {
      display: 'inline',
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      userSelect: 'none',

      // Отключение скроллбара во всех браузерах
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },

      '&.isSwiping *': {
        cursor: 'grabbing',
      },
    },
  })
);
