// import faker from 'faker';
import { SelectOption } from '../../types.Field';

// interface GetAutocompleteOptionsPayload {
//   value: string;
// }
//
// interface GetDependentAutocompleteOptionsPayload {
//   value: string;
//   dependentFieldsValues: Record<string, string | string[] | undefined>;
// }

// faker.locale = 'ru';

// const createOption = (): SelectOption => {
//   const user = faker.name.lastName();
//
//   return {
//     value: user,
//     label: user,
//     disabled: Math.random() * 100 < 10,
//   };
// };

// const options: SelectOption[] = Array.from({ length: 1500 }, createOption);

// const shops = [
//   {
//     value: '176735b1-82c8-4f56-a6d5-06a75d517cef',
//     label: '006 Красногорск',
//     href: '/page-views/widgets',
//     rooms: [
//       'f1ca02f4-30b1-4ab8-a56b-f2ec0cf5a7c5',
//       '03b578ff-ed57-4656-acb4-720cdfcbe2b2',
//     ],
//     equipments: [
//       '22ee2376-df35-4cc7-bdc3-409f817438c4',
//       'd3d3840d-d7da-4854-a612-e13bf989388f',
//       '8c09512c-e8c3-404a-b305-7ae521222064',
//     ],
//   },
//   {
//     value: '071b4991-afac-4364-a326-26c82a1eb947',
//     label: '002 Химки',
//     rooms: [
//       '3322d494-cee6-46bf-a830-3e52c993eb4f',
//       '75741fa0-953f-4c44-89f6-83adc99a7b4c',
//     ],
//     equipments: [
//       '76fc48a7-ad16-416c-98a1-d97667b5bfc3',
//       'b2cbb7d5-fd01-4167-a788-04104758e666',
//       'dbc67fbf-17d7-489d-96bb-0415c230d6a1',
//     ],
//   },
// ];
//
// const rooms = [
//   {
//     value: 'f1ca02f4-30b1-4ab8-a56b-f2ec0cf5a7c5',
//     label: 'Торговый зал К',
//     href: '/page-views/tabs',
//     shopId: '176735b1-82c8-4f56-a6d5-06a75d517cef',
//     equipments: [
//       '22ee2376-df35-4cc7-bdc3-409f817438c4',
//       '8c09512c-e8c3-404a-b305-7ae521222064',
//     ],
//   },
//   {
//     value: '03b578ff-ed57-4656-acb4-720cdfcbe2b2',
//     label: 'Офисное помещение К',
//     shopId: '176735b1-82c8-4f56-a6d5-06a75d517cef',
//     equipments: ['d3d3840d-d7da-4854-a612-e13bf989388f'],
//   },
//
//   {
//     value: '3322d494-cee6-46bf-a830-3e52c993eb4f',
//     label: 'Торговый зал Х',
//     shopId: '071b4991-afac-4364-a326-26c82a1eb947',
//     equipments: [
//       '76fc48a7-ad16-416c-98a1-d97667b5bfc3',
//       'dbc67fbf-17d7-489d-96bb-0415c230d6a1',
//     ],
//   },
//   {
//     value: '75741fa0-953f-4c44-89f6-83adc99a7b4c',
//     label: 'Офисное помещение Х',
//     shopId: '071b4991-afac-4364-a326-26c82a1eb947',
//     equipments: ['b2cbb7d5-fd01-4167-a788-04104758e666'],
//   },
// ];

const equipments = [
  {
    value: '22ee2376-df35-4cc7-bdc3-409f817438c4',
    label: 'Холодильник Красногорский',
    shopId: '176735b1-82c8-4f56-a6d5-06a75d517cef',
    roomId: 'f1ca02f4-30b1-4ab8-a56b-f2ec0cf5a7c5',
    disabled: true,
  },
  {
    value: 'd3d3840d-d7da-4854-a612-e13bf989388f',
    label: 'Слайсер Красногорский',
    shopId: '176735b1-82c8-4f56-a6d5-06a75d517cef',
    roomId: '03b578ff-ed57-4656-acb4-720cdfcbe2b2',
    disabled: true,
  },
  {
    value: '8c09512c-e8c3-404a-b305-7ae521222064',
    label: 'Губозакатка Красногорская',
    shopId: '176735b1-82c8-4f56-a6d5-06a75d517cef',
    roomId: 'f1ca02f4-30b1-4ab8-a56b-f2ec0cf5a7c5',
  },

  {
    value: '76fc48a7-ad16-416c-98a1-d97667b5bfc3',
    label: 'Холодильник Химки',
    shopId: '071b4991-afac-4364-a326-26c82a1eb947',
    roomId: '3322d494-cee6-46bf-a830-3e52c993eb4f',
  },
  {
    value: 'b2cbb7d5-fd01-4167-a788-04104758e666',
    label: 'Слайсер Химки',
    shopId: '071b4991-afac-4364-a326-26c82a1eb947',
    roomId: '75741fa0-953f-4c44-89f6-83adc99a7b4c',
  },
  {
    value: 'dbc67fbf-17d7-489d-96bb-0415c230d6a1',
    label: 'Губозакатка Химки',
    shopId: '071b4991-afac-4364-a326-26c82a1eb947',
    roomId: '3322d494-cee6-46bf-a830-3e52c993eb4f',
  },
];

export default function (
  initialSearchUrl: string
  // payload?:
  //   | GetAutocompleteOptionsPayload
  //   | GetDependentAutocompleteOptionsPayload
) {
  // const value = (payload?.value as string) || '';
  const value = '';
  const searchUrl = initialSearchUrl.replaceAll(
    new RegExp(/someArrayOf\[\d+\]\./, 'g'),
    ''
  );
  console.log('GET AUTOCOMPLETE OPTIONS REQUEST', { searchUrl });

  return equipments
    .filter((option: SelectOption) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    )
    .slice(0, 20);
}
