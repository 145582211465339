import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CodeIcon from '@material-ui/icons/Code';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ReactJson from 'react-json-view';
import { Page } from 'services/Main/types.Page';
import { rootResponse } from 'services/Main/local/rootResponse.stub';
import useStyles from './PageJSONViewer.styles';

const PageJSONViewer = ({ page }: { page: Page }) => {
  const [isModalOpen, setIsModalOpen] = useState<number | false>(false);
  const classes = useStyles();

  if (process.env.REACT_APP_ENV !== 'development') return null;

  const openModal = (id: number) => () => setIsModalOpen(id);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <Tooltip
        className={classes.tip}
        title="JSON страницы. Интерфейс Page."
        interactive
        placement="bottom"
        arrow
      >
        <Fab
          className={classes.fab1}
          color="primary"
          onClick={openModal(1)}
          size="small"
        >
          <CodeIcon />
        </Fab>
      </Tooltip>
      <Dialog open={isModalOpen === 1} maxWidth="lg" onClose={closeModal}>
        <DialogContent className={classes.dialogContent}>
          <ReactJson
            src={page}
            displayDataTypes={false}
            theme="monokai"
            collapseStringsAfterLength={70}
          />
        </DialogContent>
      </Dialog>

      <Tooltip
        className={classes.tip}
        title="JSON rootResponse. Интерфейс RootResponse."
        interactive
        placement="bottom"
        arrow
      >
        <Fab
          className={classes.fab2}
          color="primary"
          onClick={openModal(2)}
          size="small"
        >
          <AccountTreeIcon />
        </Fab>
      </Tooltip>
      <Dialog open={isModalOpen === 2} maxWidth="lg" onClose={closeModal}>
        <DialogContent className={classes.dialogContent}>
          <ReactJson
            src={rootResponse}
            displayDataTypes={false}
            theme="monokai"
            collapseStringsAfterLength={70}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PageJSONViewer;
