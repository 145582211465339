import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { SingleSelect } from 'services/Main/types.Field';
import Icon from '../../Icon/Icon';
import Link from '../../Link';
import useStyles from '../Select.styles';
import { useFormatMessage } from '../../../../locale';

interface OtherProps {
  value?: any;
  onChange?: any;
}

export default ({
  disabled,
  options,
  label,
  value,
  name,
  helperText,
  onChange,
  highlightColor,
}: SingleSelect & OtherProps) => {
  const classes = useStyles(highlightColor, !!value?.href)();
  const formatMessage = useFormatMessage();

  const { errors } = useFormContext();
  const error = get(errors, name);
  const href = value?.href || null;

  const handleChange = (event: any, option: any) => {
    onChange(option);
  };

  return (
    <Autocomplete
      className={classes.autocompleteRoot}
      disabled={disabled}
      value={value || null}
      noOptionsText={formatMessage('noOptions')}
      clearText={formatMessage('clear')}
      closeText={formatMessage('close')}
      openText={formatMessage('open')}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(o, v) => o.value === v.value}
      getOptionDisabled={(o) => o.disabled}
      classes={{
        loading: 'animate-flicker',
      }}
      options={options}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.textField}
          name={name}
          variant="standard"
          helperText={error?.message || helperText}
          error={!!error}
          label={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: href && (
              <InputAdornment position="start">
                <Link to={href} className={classes.link}>
                  <Icon definition="mui:Launch" className={classes.hrefIcon} />
                </Link>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
