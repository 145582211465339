import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      color: '#ACB0BE',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1.5),
    },
    badge: {
      display: 'inline-block',
      width: 13,
      height: 10,
      marginRight: theme.spacing(0.5),
      borderRadius: theme.spacing(2),
    },
  })
);
