import { Dispatch } from 'redux';
import history from '../../utils/history';
import authService from '../../services/Auth';

export default (dispatch: Dispatch) => (isSuccess: boolean) => {
  if (isSuccess) {
    const redirectUrl =
      authService.returnUrl && authService.returnUrl !== '/login'
        ? authService.returnUrl
        : '/';

    history.push(redirectUrl);
  } else {
    throw new Error('Непредвиденная ошибка');
  }
};
