import queryString from 'query-string';

export const getQueryParam = (
  searchString: string,
  paramName: string,
  toInteger: boolean = false
): string | number | null => {
  const queryParams = queryString.parse(searchString);

  if (!(paramName in queryParams)) return null;

  const param = queryParams[paramName] as string;

  if (!toInteger) return param;

  const paramInteger = parseInt(param, 10);

  return !Number.isNaN(paramInteger) ? paramInteger : null;
};
