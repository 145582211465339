import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { SidebarProps } from '../../../services/Main/types.Page';
import SidebarLoader from './SidebarLoader';
import useStyles from './PageLayout.styles';
import { useSidebarWrapperContext } from './SidebarLoader/SidebarWrapper/SidebarWrapper.provider';

interface Props {
  sidebar?: SidebarProps;
}

const PageLayout = ({ children, sidebar }: PropsWithChildren<Props>) => {
  const { isSidebarOpen } = useSidebarWrapperContext();
  const classes = useStyles();

  return (
    <>
      <div
        className={clsx(classes.pageContainer, {
          isSidebarOpen,
          hasSidebar: !!sidebar,
        })}
      >
        {children}
      </div>
      {sidebar && <SidebarLoader sidebar={sidebar} />}
    </>
  );
};

export default PageLayout;
