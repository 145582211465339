import {
  EntryPickerInterface,
  FetchOptionsResponsePayload,
  FetchTablePropsResponsePayload,
  FetchTablePropsRequestBody,
  FetchOptionsRequestBody,
} from './EntryPicker.interface';
import { RequestConfig } from '../Main/types.Component';
import { ServerResponse } from '../Main/types';
import {
  filterOptions,
  getOptions,
  getEntryPickerStubs,
} from './EntryPicker.stubs';

class EntryPickerLocale implements EntryPickerInterface {
  fetchOptions = (
    { url }: RequestConfig,
    {
      query,
      optionValues,
      formState,
      currentFieldName,
    }: FetchOptionsRequestBody
  ) => {
    console.log('[EntryPicker]: Fetching options data with params:', {
      query,
      optionValues,
      formState,
      currentFieldName,
    });

    if (query) {
      const options = filterOptions(query);
      return new Promise<ServerResponse<FetchOptionsResponsePayload>>(
        (resolve) => {
          setTimeout(
            () =>
              resolve({
                isSuccess: true,
                payload: {
                  options: options.slice(0, 10),
                  hasMoreOptionsThanLimit: options.length > 10,
                },
              }),
            500
          );
        }
      );
    }

    if (optionValues) {
      return Promise.resolve<ServerResponse<FetchOptionsResponsePayload>>({
        isSuccess: true,
        payload: {
          options: getOptions(optionValues),
        },
      });
    }

    throw new Error('Не передано ни query, ни optionValues');
  };

  fetchTableProps = (
    { url }: RequestConfig,
    params?: FetchTablePropsRequestBody
  ) => {
    console.log('Fetching table data with params:', params);
    const payload = getEntryPickerStubs(params);

    return new Promise<ServerResponse<FetchTablePropsResponsePayload>>(
      (resolve) => {
        setTimeout(() => {
          resolve({
            isSuccess: true,
            payload,
          });
        }, 250);
      }
    );
  };
}

export default EntryPickerLocale;
