import faker from 'faker';
import { Page } from 'services/Main/types.Page';
import { FormattedNumber } from 'services/Main/types.Field';

export const simpleFormattedNumber: FormattedNumber = {
  type: 'formattedNumber',
  name: 'formattedNumber1',
  formattedNumberType: 'price',
  label: 'Price formattedNumber',
  currencySign: '₽',
};

export const formattedNumberFieldFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами formatted number поля',
  component: {
    id: 100,
    businessComponentId: 24,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          label: 'Сохранить',
          color: 'primary',
          variant: 'contained',
          requestConfig: { url: '/api/fields/formatted-number' },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами formatted number поля' },
        fieldGroups: [
          {
            label: 'Formatted Number Field',
            fields: [
              simpleFormattedNumber,
              {
                type: 'formattedNumber',
                name: 'formattedNumber122',
                formattedNumberType: 'price',
                label: 'Цена без валюты',
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber2',
                label: 'Кастомный formattedNumber',
                formattedNumberType: 'flexibleNumber',
                thousandSeparator: '🥔',
                allowNegative: false,
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber3',
                formattedNumberType: 'price',
                label: 'Price formattedNumber с дефолтным значением',
                currencySign: '₽',
                defaultValue: faker.datatype.number(),
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber4',
                label: 'Кастомный formattedNumber с дефолтным значением',
                formattedNumberType: 'flexibleNumber',
                thousandSeparator: '🥔',
                allowNegative: false,
                defaultValue: faker.datatype.number() * faker.datatype.number(),
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber5',
                formattedNumberType: 'price',
                label: 'Price formattedNumber с ограничением max 100',
                currencySign: '₽',
                max: 100,
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber6',
                formattedNumberType: 'price',
                label:
                  'Price formattedNumber с ограничением min 500 (will be soon)',
                currencySign: '₽',
                min: 500,
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber7',
                formattedNumberType: 'price',
                label: 'Компоновка форматтеров',
                mdCols: 6,
                currencySign: '₽',
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber8',
                label: 'В одной строке на десктопе',
                mdCols: 6,
                formattedNumberType: 'flexibleNumber',
                thousandSeparator: '🥔',
                allowNegative: false,
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber9',
                formattedNumberType: 'price',
                label: 'Disabled форматтер',
                defaultValue: faker.datatype.number(),
                disabled: true,
                currencySign: '₽',
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber10',
                formattedNumberType: 'price',
                label: 'Price formattedNumber с валидациями',
                currencySign: '₽',
                validationConfig: {
                  validationType: 'number',
                  validations: [
                    { type: 'required', params: ['Обязательное поле'] },
                    {
                      type: 'positive',
                      params: ['Только положительные числа'],
                    },
                  ],
                },
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber11',
                label: 'Число с точкой в качестве разделителя',
                formattedNumberType: 'flexibleNumber',
                thousandSeparator: ' ',
                decimalSeparator: '.',
                allowNegative: false,
              },
              {
                type: 'formattedNumber',
                name: 'formattedNumber12',
                label: 'Число с запятой в качестве разделителя',
                formattedNumberType: 'flexibleNumber',
                decimalSeparator: ',',
                thousandSeparator: ' ',
                allowNegative: false,
              },
            ],
          },
        ],
      },
    },
  },
};
