// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
  YEAR_FORMAT,
} from 'commonConstants';
import isValidDate from './isValidDate';

type FormatAs = 'date' | 'dateTime' | 'year' | 'time';

export const formatDateFromISOstring = (
  ISOstring: string,
  formatAs?: FormatAs
) => formatDate(parseISO(ISOstring), formatAs);

export const formatDate = (date: Date, formatAs?: FormatAs) =>
  isValidDate(date)
    ? format(date, getFormatAs(formatAs), {
        locale: ru,
      })
    : null;

const getFormatAs = (formatAs?: FormatAs) => {
  switch (formatAs) {
    case 'dateTime':
      return DATE_TIME_FORMAT;
    case 'year':
      return YEAR_FORMAT;
    case 'time':
      return TIME_FORMAT;
    case 'date':
    default:
      return DATE_FORMAT;
  }
};
