import { useContext } from 'react';
import TableDialogWithComponentContext from './TableDialogWithComponent.context';

export default function useTableDialogWithComponentContext() {
  const tableDialogWithComponent = useContext(TableDialogWithComponentContext);

  if (!tableDialogWithComponent) {
    throw new Error('TableDialogWithComponentContext was not initialized!');
  }

  return tableDialogWithComponent;
}
