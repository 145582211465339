import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.disabled,
      position: 'relative',
      textDecoration: 'none',
      userSelect: 'none',
      cursor: 'pointer',

      '&:hover': {
        '& $hoverButtonGroupContainer': {
          opacity: 1,
          transition: 'opacity 0.15s',
        },
      },
    },
    icon: {
      fontSize: theme.typography.pxToRem(36),
      color: 'inherit',
    },
    name: {
      fontSize: theme.typography.pxToRem(11),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'inherit',
      lineHeight: 1.25,
    },
    size: {
      color: 'inherit',
      fontSize: theme.typography.pxToRem(8),
      fontStyle: 'italic',
    },
    fileInfo: {
      minWidth: 0,
    },
    hoverButtonGroupContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: theme.palette.background.paper,
      opacity: 0,
    },
    button: {
      borderRadius: theme.spacing(0.5),
    },
  })
);
