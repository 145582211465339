import React from 'react';
import { TreeSelectOption } from 'services/Main/types.Field';
import TreeComboBox from './components/TreeComboBox';
import FlatComboBox from './components/FlatComboBox';
import { ComboBoxProps, FlatComboBoxProps, TreeComboBoxProps } from './types';
import { useSelectOptions } from './useSelectOptions';

export default (props: ComboBoxProps) => {
  const options = useSelectOptions(props.name);

  switch (props.optionsType) {
    case 'checkboxTree':
    case 'radioTree':
      return (
        // @ts-ignore
        <TreeComboBox
          {...(props as unknown as TreeComboBoxProps)}
          // TODO: Typescript Хочет видеть RadioTreeSelectOption[]
          options={options as TreeSelectOption[]}
        />
      );
    case 'flat':
      return (
        <FlatComboBox {...(props as FlatComboBoxProps)} options={options} />
      );
    default:
      throw new Error('Unknown optionsType');
  }
};
