import { TrendDynamic } from '../../../services/Main/types.Component';

export const colors: Record<TrendDynamic, 'error' | 'success' | 'secondary'> = {
  positive: 'success',
  negative: 'error',
  withoutChanges: 'secondary',
};

export const signs: Record<TrendDynamic, string> = {
  positive: '+',
  negative: '-',
  withoutChanges: '',
};
