import { Page } from 'services/Main/types.Page';
import faker from 'faker';
import { createAlert } from '../highLevelComponents/Form/WithAlerts';

export const showFormVar1ActionPage: Page = {
  type: 'singleComponent',
  title: 'Форма с showForm экшном с маленькой модалкой',
  component: {
    id: 100,
    businessComponentId: 3,
    type: 'form',
    props: {
      actions: [
        {
          type: 'showForm',
          label: 'Показать модалку',
          variant: 'outlined',
          viewStyle: 'modal',
          actions: [
            {
              type: 'submitForm',
              variant: 'contained',
              label: 'Отправить (submitForm)',
              color: 'primary',
              requestConfig: {
                url: '/actions/show-form-variant-1/response-alerts',
              },
              successResponseReaction: {
                type: 'reLoadModule',
              },
            },
          ],
          alerts: Array.from(
            { length: faker.datatype.number({ min: 1, max: 2 }) },
            createAlert
          ),
          form: {
            type: 'markup',
            header: { heading: 'Форма в модалке' },
            fieldGroups: [
              {
                fields: [
                  {
                    type: 'text',
                    name: 'comment',
                    label: 'Комментарий',
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        {
                          type: 'required',
                          params: ['Обязательное поле'],
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
      form: {
        type: 'markup',
        header: {
          heading: 'Форма с showForm экшном с маленькой модалкой',
        },
        fieldGroups: [
          {
            label: 'Название группы',
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    { type: 'required', params: ['Обязательное поле'] },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  },
};
