import React from 'react';
import MuiAvatar, { AvatarProps } from '@material-ui/core/Avatar';
import clsx from 'clsx';
import useStyles from './Avatar.styles';

function Avatar({ color, className, ...rest }: AvatarProps) {
  const classes = useStyles();

  return (
    <MuiAvatar
      {...rest}
      className={clsx(classes.root, className)}
      style={{
        ...rest.style,
        color,
        borderColor: color,
      }}
    />
  );
}

export default Avatar;
