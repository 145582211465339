import React, { ReactElement } from 'react';
import { useInitialAppData } from 'utils/hooks/useInitialAppData';
import GlobalSocketConnectionContext from '../../../contexts/GlobalSocketConnectionContext';

/**
 * Компонент для инициализации redux-хранилища данными с бека
 *
 * @constructor
 */
const RootLoader = ({ children }: { children: ReactElement }) => {
  const { globalSocketConnection } = useInitialAppData();

  return (
    <GlobalSocketConnectionContext.Provider
      value={{ connection: globalSocketConnection }}
    >
      {children}
    </GlobalSocketConnectionContext.Provider>
  );
};

export default RootLoader;
