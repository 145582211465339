import React, { PropsWithChildren } from 'react';
import { ThemeProvider, Grid, Divider, Container } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PureLogo from '../../../svg/Logo';
import PublicRoutesTemplateTheme from './PublicRoutesTemplate.theme';
import useStyles from './PublicRoutesTemplate.styles';
import { useHideLoadingScreen } from '../../../utils/hooks/useHideLoadingScreen';

/**
 * Обертка для страниц, связаных с аунтетификацией пользователя:
 * /login, /forgot-password, /set-password.
 *
 */
const PublicRoutesTemplate = ({ children }: PropsWithChildren<{}>) => {
  const classes = useStyles();

  useHideLoadingScreen();

  return (
    <ThemeProvider theme={PublicRoutesTemplateTheme}>
      <Grid className={classes.root}>
        <Container maxWidth="lg">
          <Grid container justify="center">
            <Grid item xs={11} sm={8} md={12}>
              <Link to="/login">
                <PureLogo className={classes.logo} />
              </Link>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Container maxWidth="lg">
          <main className={classes.main}>{children}</main>
        </Container>
      </Grid>
    </ThemeProvider>
  );
};

export default PublicRoutesTemplate;
