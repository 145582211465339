import React, {
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useState,
} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Icon from 'components/lowLevel/Icon';
import { File } from 'services/Main/types.Field';
import useStyles from './FileListItem.styles';
import { getFileSize } from './helpers';
import FileIcon from './FileIcon';
import FileViewer from '../FileUpload/FileViewer';
import { supportedFileViewType } from '../FileUpload/FileViewer/plugins';

interface FileListItemProps {
  file: File;
  handleRemove?: MouseEventHandler;
  viewSize?: 'small' | 'normal';
}

const FileListItem = ({ file, handleRemove, viewSize }: FileListItemProps) => {
  const classes = useStyles(viewSize)();
  const { name, size, url, mimeType } = file;
  const hasRemoveButton = handleRemove && typeof handleRemove === 'function';
  const isSupportedFileView = supportedFileViewType.includes(mimeType);
  const [isPreviewModeOpen, setIsPreviewModeOpen] = useState<boolean>(false);

  const handlePreviewAreaClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (isSupportedFileView) {
        e.preventDefault();
        setIsPreviewModeOpen(true);
      }
    },
    [isSupportedFileView]
  );

  const handlePreviewClose = useCallback(() => {
    setIsPreviewModeOpen(false);
  }, []);

  return (
    <>
      <a href={url} className={classes.link} download>
        <ListItem
          alignItems="center"
          className={classes.listItem}
          ContainerComponent="div"
        >
          <div onClick={handlePreviewAreaClick}>
            <ListItemAvatar
              className={clsx(classes.avatarRoot, {
                isSupportedFileView,
              })}
            >
              <FileIcon
                url={url}
                name={name}
                mimeType={mimeType}
                classes={classes}
              />
            </ListItemAvatar>
          </div>
          <ListItemText
            className={clsx(classes.listItemText, {
              withRightPadding: hasRemoveButton,
            })}
            primary={name}
            primaryTypographyProps={{ variant: 'body2', display: 'inline' }}
            secondary={
              <Typography className={classes.fontItalic}>
                {getFileSize(+size)}
              </Typography>
            }
          />
          {hasRemoveButton && (
            <ListItemSecondaryAction onClick={handleRemove}>
              <IconButton edge="end" aria-label="delete">
                <Icon definition="mui:Delete" />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </a>
      <FileViewer
        file={file}
        open={isPreviewModeOpen}
        handleClose={handlePreviewClose}
      />
    </>
  );
};

export default FileListItem;
