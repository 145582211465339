import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import {
  Chip,
  InputAdornment,
  TextField as MuiTextField,
} from '@material-ui/core';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import { MoreHoriz } from '@material-ui/icons';
import useStyles from './getInput.styles';
import { EntryPickerProps } from '../../types';

export default (props: EntryPickerProps, openDialog: () => void) =>
  function renderInput(params: AutocompleteRenderInputParams): ReactNode {
    return (
      <TextField
        params={params}
        entryPickerProps={props}
        openDialog={openDialog}
      />
    );
  };

interface TextFieldProps {
  params: AutocompleteRenderInputParams;
  entryPickerProps: EntryPickerProps;
  openDialog: () => void;
}

function TextField({ params, entryPickerProps, openDialog }: TextFieldProps) {
  const classes = useStyles();
  const { errors } = useFormContext();
  const { disabled, helperText, label, name, multiple, value } =
    entryPickerProps;
  const error = get(errors, name);

  return (
    <MuiTextField
      {...params}
      disabled={disabled}
      name={name}
      label={label}
      error={!!error}
      helperText={error?.message || helperText}
      InputProps={{
        ...params.InputProps,
        readOnly: disabled,
        classes: {
          root: classes.inputRoot,
        },
        endAdornment: (
          <InputAdornment position="end">
            {multiple && Array.isArray(value) && value.length > 0 && (
              <Chip label={value.length} disabled={disabled} size="small" />
            )}
            {!disabled && (
              <>
                {params.InputProps.endAdornment}
                <IconButton
                  size="small"
                  onClick={openDialog}
                  disabled={disabled}
                >
                  <MoreHoriz />
                </IconButton>
              </>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
}
