import { Page } from '../../../types.Page';
import { OptionsType } from '../../../types.Field';
import { generateSelectOptions } from '../fields/ComboBox.stub';
import { BarChartState } from '../../../../../components/highLevel/BarChartWidget/types';

export const recycleManagement: Page = {
  type: 'grid',
  title: 'Модуль "Управление отходами"',
  sidebar: {
    type: 'filter',
    header: 'Список фильтров',
    filterComponent: {
      type: 'tableFilter',
      id: 30,
      businessComponentId: 12,
      props: {
        requestOn: 'change',
        fieldGroups: [
          {
            fields: [
              {
                name: 'store-object',
                type: 'comboBox',
                optionsType: OptionsType.flat,
                label: 'Объект',
                multiline: true,
                multiple: true,
                chipTagViewStyle: 'chip',
                options: generateSelectOptions(6),
              },
              {
                name: 'type-of-recyclable-materials',
                type: 'comboBox',
                optionsType: OptionsType.flat,
                label: 'Тип вторсырья',
                multiline: true,
                multiple: true,
                chipTagViewStyle: 'chip',
                options: generateSelectOptions(6),
              },
            ],
          },
        ],
      },
    },
  },
  header: {
    heading: 'Управление отходами',
  },
  markup: [
    {
      lgCols: 6,
      component: {
        id: 100,
        businessComponentId: 51,
        type: 'lineChartWidget',
        props: {
          heading: 'Доход за реализацию вторсырья за месяц (УО-02)',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/line-chart/income-second-raw-by-month',
            method: 'GET',
          },
          xAxisLabel: 'Мес.',
          yAxisLabel: 'Тыс. руб.',
        },
      },
    },
    {
      lgCols: 6,
      component: {
        id: 100,
        businessComponentId: 51,
        type: 'barChartWidget',
        props: {
          heading: 'Объем реализации вторсырья за месяц (УО-04)',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/bar-chart/volume-second-raw-by-month',
            method: 'GET',
          },
          xAxisLabel: 'Мес.',
          yAxisLabel: 'Тонн',
        },
      },
    },
    {
      lgCols: 6,
      component: {
        id: 100,
        businessComponentId: 52,
        type: 'lineChartWidget',
        props: {
          heading: 'Доход за реализацию вторсырья накопительно (УО-03)',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/line-chart/income-second-raw-cumulatively',
            method: 'GET',
          },
          yAxisLabel: 'Тыс. руб.',
        },
      },
    },
    {
      lgCols: 6,
      component: {
        id: 100,
        businessComponentId: 51,
        type: 'barChartWidget',
        props: {
          heading: 'Объем реализации вторсырья за месяц (УО-04)',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/bar-chart/volume-second-raw-cumulatively',
            method: 'GET',
          },
          yAxisLabel: 'Тонн',
        },
      },
    },
  ],
};

export const getVolumeSecondRawByMonthChartDatum = (): BarChartState => {
  return {
    xAxisLabel: 'Мес.',
    yAxisLabel: 'Кг.',
    bars: [
      {
        key: '2022',
        fill: '#8865cc',
        name: '2022',
      },
      {
        key: '2023',
        fill: '#3fb0ed',
        name: '2023',
      },
      {
        key: '2024',
        fill: '#67d386',
        name: '2024',
      },
    ],
    data: [
      {
        name: 'Янв',
        description: 'Январь',
        totalPrintValue: '3052 тонны',
        2022: {
          value: 1012,
          printValue: '1012,43 тонн',
          href: '/',
        },
        2023: {
          value: 1035,
          printValue: '1035,21 тонн',
          href: '/',
        },
        2024: {
          value: 1005,
          printValue: '1005,13 тонн',
          href: '/',
        },
      },
      {
        name: 'Фев',
        description: 'Февраль',
        2022: {
          value: 1163,
          printValue: '1163,23 тонн',
          href: '/',
        },
        2023: {
          value: 1184,
          printValue: '1184 тонн',
          href: '/',
        },
        2024: {
          value: 1141,
          printValue: '1141 тонн',
          href: '/',
        },
      },
      {
        name: 'Мар',
        description: 'Март',
        2022: {
          value: 967,
          printValue: '967 тонн',
          href: '/',
        },
        2023: {
          value: 991,
          printValue: '991 тонн',
          href: '/',
        },
        2024: {
          value: 923,
          printValue: '923 тонн',
          href: '/',
        },
      },
      {
        name: 'Апр',
        description: 'Апрель',
        2022: {
          value: 930,
          printValue: '930 тонн',
          href: '/',
        },
        2023: {
          value: 951,
          printValue: '951 тонн',
          href: '/',
        },
        2024: {
          value: 880,
          printValue: '880 тонн',
          href: '/',
        },
      },
      {
        name: 'Май',
        2022: {
          value: 750,
          printValue: '750 тонн',
          href: '/',
        },
        2023: {
          value: 1012,
          printValue: '1012 тонн',
          href: '/',
        },
        2024: {
          value: 1035,
          printValue: '1035 тонн',
          href: '/',
        },
      },
      {
        name: 'Июн',
        description: 'Июнь',
        2022: {
          value: 1005,
          printValue: '1005 тонн',
          href: '/',
        },
        2023: {
          value: 972,
          printValue: '972 тонн',
          href: '/',
        },
        2024: {
          value: 942,
          printValue: '942 тонн',
          href: '/',
        },
      },
      {
        name: 'Июл',
        description: 'Июль',
        2022: {
          value: 972,
          printValue: '972 тонн',
          href: '/',
        },
        2023: {
          value: 942,
          printValue: '942 тонн',
          href: '/',
        },
        2024: {
          value: 953,
          printValue: '953 тонн',
          href: '/',
        },
      },
      {
        name: 'Авг',
        description: 'Август',
        2022: {
          value: 1092,
          printValue: '1092 тонн',
          href: '/',
        },
        2023: {
          value: 1087,
          printValue: '1087 тонн',
          href: '/',
        },
        2024: {
          value: 1137,
          printValue: '1137 тонн',
          href: '/',
        },
      },
      {
        name: 'Сен',
        description: 'Сентябрь',
        2022: {
          value: 950,
          printValue: '950 тонн',
          href: '/',
        },
        2023: {
          value: 986,
          printValue: '986 тонн',
          href: '/',
        },
        2024: {
          value: 860,
          printValue: '860 тонн',
          href: '/',
        },
      },
      {
        name: 'Окт',
        description: 'Октябрь',
        2022: {
          value: 1122,
          printValue: '1122 тонн',
          href: '/',
        },
        2023: {
          value: 1135,
          printValue: '1135 тонн',
          href: '/',
        },
        2024: {
          value: 990,
          printValue: '990 тонн',
          href: '/',
        },
      },
      {
        name: 'Ноя',
        description: 'Ноябрь',
        2022: {
          value: 1012,
          printValue: '1012 тонн',
          href: '/',
        },
        2023: {
          value: 962,
          printValue: '962 тонн',
          href: '/',
        },
        2024: {
          value: 930,
          printValue: '930 тонн',
          href: '/',
        },
      },
      {
        name: 'Дек',
        description: 'Декабрь',
        2022: {
          value: 1192,
          printValue: '1192 тонн',
          href: '/',
        },
        2023: {
          value: 1181,
          printValue: '1181 тонн',
          href: '/',
        },
        2024: {
          value: 1174,
          printValue: '1174 тонн',
          href: '/',
        },
      },
    ],
  };
};
export const getVolumeSecondRawCumulatively = (): BarChartState => {
  return {
    bars: [
      {
        key: '2022',
        fill: '#8865cc',
        name: '2022',
      },
      {
        key: '2023',
        fill: '#3fb0ed',
        name: '2023',
      },
      {
        key: '2024',
        fill: '#67d386',
        name: '2024',
      },
    ],
    data: [
      {
        name: 'Янв',
        description: 'Январь',
        2022: {
          value: 699,
          printValue: '699 тонн',
          href: '/',
        },
        2023: {
          value: 708,
          printValue: '708 тонн',
          href: '/',
        },
        2024: {
          value: 701,
          printValue: '701 тонн',
          href: '/',
        },
      },
      {
        name: 'Фев',
        description: 'Февраль',
        2022: {
          value: 748,
          printValue: '748 тонн',
          href: '/',
        },
        2023: {
          value: 730,
          printValue: '730 тонн',
          href: '/',
        },
        2024: {
          value: 710,
          printValue: '710 тонн',
          href: '/',
        },
      },
      {
        name: 'Мар',
        description: 'Март',
        2022: {
          value: 772,
          printValue: '772 тонн',
          href: '/',
        },
        2023: {
          value: 754,
          printValue: '754 тонн',
          href: '/',
        },
        2024: {
          value: 758,
          printValue: '758 тонн',
          href: '/',
        },
      },
      {
        name: 'Апр',
        description: 'Апрель',
        2022: {
          value: 802,
          printValue: '802 тонн',
          href: '/',
        },
        2023: {
          value: 793,
          printValue: '793 тонн',
          href: '/',
        },
        2024: {
          value: 788,
          printValue: '788 тонн',
          href: '/',
        },
      },
      {
        name: 'Май',
        2022: {
          value: 810,
          printValue: '810 тонн',
          href: '/',
        },
        2023: {
          value: 803,
          printValue: '803 тонн',
          href: '/',
        },
        2024: {
          value: 795,
          printValue: '795 тонн',
          href: '/',
        },
      },
      {
        name: 'Июн',
        description: 'Июнь',
        2022: {
          value: 842,
          printValue: '842 тонн',
          href: '/',
        },
        2023: {
          value: 835,
          printValue: '835 тонн',
          href: '/',
        },
        2024: {
          value: 842,
          printValue: '842 тонн',
          href: '/',
        },
      },
      {
        name: 'Июл',
        description: 'Июль',
        2022: {
          value: 892,
          printValue: '892 тонн',
          href: '/',
        },
        2023: {
          value: 901,
          printValue: '901 тонн',
          href: '/',
        },
        2024: {
          value: 896,
          printValue: '896 тонн',
          href: '/',
        },
      },
      {
        name: 'Авг',
        description: 'Август',
        2022: {
          value: 929,
          printValue: '929 тонн',
          href: '/',
        },
        2023: {
          value: 940,
          printValue: '940 тонн',
          href: '/',
        },
        2024: {
          value: 953,
          printValue: '953 тонн',
          href: '/',
        },
      },
      {
        name: 'Сен',
        description: 'Сентябрь',
        2022: {
          value: 974,
          printValue: '974 тонн',
          href: '/',
        },
        2023: {
          value: 1002,
          printValue: '1002 тонн',
          href: '/',
        },
        2024: {
          value: 982,
          printValue: '982 тонн',
          href: '/',
        },
      },
      {
        name: 'Окт',
        description: 'Октябрь',
        2022: {
          value: 1003,
          printValue: '1003 тонн',
          href: '/',
        },
        2023: {
          value: 1024,
          printValue: '1024 тонн',
          href: '/',
        },
        2024: {
          value: 1036,
          printValue: '1036 тонн',
          href: '/',
        },
      },
      {
        name: 'Ноя',
        description: 'Ноябрь',
        2022: {
          value: 1123,
          printValue: '1123 тонн',
          href: '/',
        },
        2023: {
          value: 1112,
          printValue: '1112 тонн',
          href: '/',
        },
        2024: {
          value: 1086,
          printValue: '1086 тонн',
          href: '/',
        },
      },
      {
        name: 'Дек',
        description: 'Декабрь',
        2022: {
          value: 1192,
          printValue: '1192 тонн',
          href: '/',
        },
        2023: {
          value: 1181,
          printValue: '1181 тонн',
          href: '/',
        },
        2024: {
          value: 1174,
          printValue: '1174 тонн',
          href: '/',
        },
      },
    ],
  };
};
