import React from 'react';
import MuiChip from '@material-ui/core/Chip';
import clsx from 'clsx';
import { ComboBoxOption } from '../../../../../services/Main/types.Field';
import Link from '../../../Link';
import useStyles from './Tags.styles';

interface ChipProps extends ComboBoxOption {
  handleDelete?: () => void;
}

const Chip = ({ href, label, handleDelete }: ChipProps) => {
  const classes = useStyles();

  const labelContent = href ? <Link to={href}>{label}</Link> : label;

  return (
    <MuiChip
      className={clsx(classes.chipTag, {
        link: !!href,
      })}
      label={labelContent}
      variant="outlined"
      size="small"
      color={href ? 'primary' : 'default'}
      onDelete={handleDelete}
    />
  );
};

export default Chip;
