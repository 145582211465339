import React, { useContext } from 'react';
import { Box, LinearProgress } from '@material-ui/core';
import { TimelineComponentProps } from '../../../services/Main/types.Component';
import useContainerHeight from './hooks/useContainerHeight';
import { ComponentContext } from '../../creational/ComponentLoader';
import useStyles from './Timeline.styles';
import Header from '../../lowLevel/Header';
import useTimelineContext from './hooks/useTimelineContext';
import AddTimelineElementForm from './components/AddTimelineElementForm';
import TimelineElements from './components/TimelineElements';

function Timeline(props: TimelineComponentProps) {
  const classes = useStyles();

  const { header, addCommentUrl } = props;

  // Автоматическое высчитывание высоты контейнера.
  const componentContext = useContext(ComponentContext);
  const timelineDOMId = `Timeline_${componentContext?.id}`;
  const containerHeight = useContainerHeight(timelineDOMId);

  const { isTimelineInitialized } = useTimelineContext();

  return (
    <div
      id={timelineDOMId}
      className={classes.timelineContainer}
      style={{ height: containerHeight }}
    >
      <Box className={classes.header}>
        <Header {...header} />
      </Box>
      {!isTimelineInitialized ? (
        <LinearProgress />
      ) : (
        <>
          <TimelineElements />
          <AddTimelineElementForm addCommentUrl={addCommentUrl} />
        </>
      )}
    </div>
  );
}

export default Timeline;
