import faker from 'faker';
import { Component, RequestConfig } from '../../types.Component';

type Props = {
  requestConfig: RequestConfig;
  params: any;
};

const options = [
  { value: '184ec504-7bf7-47b8-9293-8cda494309c8', label: '0' },
  { value: 'a9e7189e-9b5a-46bd-b1de-edd51df6a42d', label: '1' },
  { value: '0791cf2b-321c-479c-8233-ff9a2bced8ee', label: '2' },
  { value: 'eeb1c377-3bbf-4bbb-8337-e6bf8f9ef39d', label: '3' },
  { value: 'c1eebeee-2d8d-4274-96c3-797468762931', label: '4' },
];

export const getEditCellModal = ({ params }: Props): Component => {
  return {
    id: 100,
    businessComponentId: 21,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          label: 'Сохранить',
          requestConfig: {
            url: '/api/complex/calendar-stm/edit-cell',
            method: 'POST',
          },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      actionsYPosition: 'bottom',
      form: {
        type: 'markup',
        header: {
          heading: `Редактирование ${params?.column?.title || 'ячейки'}`,
        },
        fieldGroups: [
          {
            fields: [
              {
                type: 'select',
                selectType: 'single',
                name: 'workType',
                label: 'Тип обслуживания',
                options,
                defaultValue: options.find(
                  (option) =>
                    option.label === params?.row[params?.column?.name]?.label
                ),
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'assignee',
                label: 'Исполнитель',
                options: Array.from({ length: 15 }, () => ({
                  value: faker.datatype.uuid(),
                  label: faker.name.lastName(),
                })),
              },
            ],
          },
        ],
      },
    },
  };
};
