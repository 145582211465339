import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import TableMUI from '@material-ui/core/Table';
import useStyles from './StickyTable.styles';
import TableSummaryRowFooter from '../../highLevel/Table/components/TableSummaryRowFooter';

// В типизации object по факту приходит след.:
// StickyTable.propTypes = {
//   use: PropTypes.oneOf(['head', 'foot']),
//   children: PropTypes.node.isRequired,
//   className: PropTypes.string,
//   tableRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
// };

const StickyTable = ({
  children,
  className,
  use,
  tableRef,
  ...restProps
}: PropsWithChildren<any>) => {
  const classes = useStyles();

  return (
    <TableMUI
      stickyHeader
      ref={tableRef}
      className={clsx(classes.table, className)}
      {...restProps}
    >
      {children}
      <TableSummaryRowFooter />
    </TableMUI>
  );
};

export default StickyTable;
