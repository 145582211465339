import { OptionsObject, SnackbarMessage, useSnackbar } from 'notistack';
import { Link } from '../../services/Main/types.Component';
import SnackbarLink from '../../components/lowLevel/SnackbarProvider/SnackbarLink';

export default function useEnqueueSnackbar() {
  const { enqueueSnackbar } = useSnackbar();

  return (
    message: SnackbarMessage,
    options?: OptionsObject & { link?: Link }
  ) => {
    const actualOptions: OptionsObject = { ...options };

    if (options?.link) {
      actualOptions.action = SnackbarLink({ link: options.link });
    }

    return enqueueSnackbar(message, actualOptions);
  };
}
