import React from 'react';
import { DocRenderer } from 'react-doc-viewer';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './PDFRenderer.styles';
import { useFormatMessage } from '../../../../../../locale';

const PDFRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();

  if (!currentDocument) return null;

  return (
    <object
      data={currentDocument.uri}
      type="application/pdf"
      className={classes.wrapper}
    >
      <iframe
        src={currentDocument.uri}
        title="pdf-iframe"
        className={classes.wrapper}
      >
        <Grid container alignItems="center" justify="center">
          <Typography align="center" variant="body2">
            {formatMessage('browserNotSupportFileViewing')}
          </Typography>
        </Grid>
      </iframe>
    </object>
  );
};

export const PDFFileTypes = {
  pdf: ['pdf', 'application/pdf'],
};

PDFRenderer.weight = 3;
PDFRenderer.fileTypes = PDFFileTypes.pdf;
// Отключает дефолтную загрузку библиотеки
PDFRenderer.fileLoader = ({ fileLoaderComplete }) => fileLoaderComplete();

export default PDFRenderer;
