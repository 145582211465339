import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import axios, { CancelTokenSource } from 'axios';
import debounce from 'lodash.debounce';
import { TableProps } from '@devexpress/dx-react-grid-material-ui';
import objectHash from 'object-hash';
import {
  ALL_FORMATS,
  DirtyFormValues,
  FetchTableDataOptions,
  ShowAsyncModalOnCellClickReaction,
  SortingState,
  TableComponentProps,
  TableFilterFormComponent,
  TableRow,
} from 'services/Main/types.Component';
import TableView from './Table.view';
import getTableRow from './core/getTableRow';
import getTableCell from './core/getTableCell';
import mainService from '../../../services/Main';
import { findDataTypes } from './helpers';
import { TableContext } from './TableContext';
import { mapValuesToFilterOptions } from '../../lowLevel/TableFilterForm/TableFilterForm';
import { mapValuesToPlain } from '../../lowLevel/FormBuilder/helpers';
import useColumnResizingState from './useColumnResizingState';
import { useFormatMessage } from '../../../locale';
import useEnqueueSnackbar from '../../../utils/hooks/useEnqueueSnackbar';
import useColumnReordering from './useColumnReordering';
import useColumnVisibilityState from './useColumnVisibilityState';
import useDidUpdateEffect from '../../../utils/hooks/useDidUpdateEffect';
import useGetParams from './useGetParams';
import useSerializeStateToGetParams from './useSerializeStateToGetParams';
import { useGroupingColumnExtensions } from './useGroupingColumnExtensions';
import TableDialogWithComponent from './components/TableDialogWithComponent';
import TableDialogWithComponentProvider from './components/TableDialogWithComponent/TableDialogWithComponent.provider';
import TableCellDragAndDropProvider from './components/TableCellDragAndDropProvider';
import Cell from './components/Cell';
import useTableScrollManager from './useTableScrollManager';

let prevRequest: {
  request: Promise<Partial<TableComponentProps>>;
  source: CancelTokenSource;
} | null = null;

export default (props: TableComponentProps) => {
  const formatMessage = useFormatMessage();
  const {
    options,
    columnExtensions,
    columns,
    rows: propsRows,
    summaryRow,
    requestConfig,
    enableColumnResizing,
    enableColumnReordering,
    columnVisibilityConfig,
    groupingConfig,
    filterComponent: propsFilterComponent,
  } = props;

  // Состояние из props.
  const {
    rowClickReaction,
    cellClickReaction,
    totalRows: propsTotalRows,
    allowClickOnEmptyCell,
  } = options;

  const enqueueSnackbar = useEnqueueSnackbar();

  const stateFromGetParams: Partial<FetchTableDataOptions> = useGetParams();

  const fetchTableDataOptions = {
    filter: stateFromGetParams?.filter || {},
    query: stateFromGetParams?.query,
    sort: stateFromGetParams?.sort || props.options.sorting,
    quickFilters: stateFromGetParams?.quickFilters || [],
    withClosed: stateFromGetParams?.withClosed || false,
    currentPage: props.options.currentPage,
    pageSize: props.options.pageSize,
  };

  const [filterComponent, setFilterComponent] = useState<
    TableFilterFormComponent | undefined
  >(propsFilterComponent);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<TableRow[]>(propsRows);
  const [totalRows, setTotalRows] = useState<number>(propsTotalRows);

  const [pageSize, setPageSize] = useState<number>(
    fetchTableDataOptions.pageSize
  );
  const [currentPage, setCurrentPage] = useState<number>(
    fetchTableDataOptions.currentPage
  );

  const [filter, setFilter] = useState<DirtyFormValues | undefined>(
    fetchTableDataOptions.filter
  );
  const [quickFilters, setQuickFilters] = useState<string[]>(
    fetchTableDataOptions.quickFilters || []
  );
  const [query, setQuery] = useState<string | undefined>(
    fetchTableDataOptions.query
  );
  const [sorting, setSorting] = useState<SortingState | undefined>(
    fetchTableDataOptions.sort
  );
  const [withClosed, setWithClosed] = useState<boolean>(
    fetchTableDataOptions.withClosed || false
  );

  const preparedFilterStateToRequest = useMemo(
    () =>
      filter &&
      mapValuesToFilterOptions(
        mapValuesToPlain(
          filter,
          propsFilterComponent?.props?.fieldGroups[0].fields || []
        )
      ),
    [filter, propsFilterComponent]
  );

  // Хак для получения актуальных значений state-переменных из callback
  const tableStateRef = useRef<FetchTableDataOptions>({
    requestConfig,
    filter: preparedFilterStateToRequest,
    query,
    sort: sorting,
    currentPage,
    quickFilters,
    withClosed,
    pageSize,
  });

  tableStateRef.current = {
    requestConfig,
    filter: preparedFilterStateToRequest,
    query,
    sort: sorting,
    currentPage,
    quickFilters,
    withClosed,
    pageSize,
  };

  // Хук для упаковки react-состояния в GET-параметры.
  useSerializeStateToGetParams({
    filter: preparedFilterStateToRequest,
    query,
    sort: sorting,
    quickFilters,
    withClosed,
    pageSize,
    currentPage,
  });

  const { resetScrollPosition, tableContentRef } = useTableScrollManager([
    rows,
  ]);

  const loadRows = () => {
    resetScrollPosition();
    setIsLoading(true);

    const requestWithSource = mainService.fetchTableData(tableStateRef.current);

    if (prevRequest) {
      prevRequest.source.cancel('Request canceled. 🙅🏼‍');
      prevRequest = null;
    }

    prevRequest = requestWithSource;

    requestWithSource.request
      .then((response) => {
        if (response.filterComponent) {
          setFilterComponent(response.filterComponent);
        }

        // Сейчас не обрабатывается ответ SummaryRow.
        // props.summaryRow => нужно вынести в state, если хотим добавить обработку
        // if (response.summaryRow) {
        //   setSummaryRow(response.summaryRow);
        // }

        setRows(response.rows as TableRow[]);
        setTotalRows(
          (response.options?.totalRows || response.options?.totalRows === 0) &&
            response.options?.totalRows >= 0
            ? response.options?.totalRows
            : propsTotalRows
        );
      })
      .catch((reason) => {
        // Если отменен со стороны клиента.
        if (axios.isCancel(reason)) return;

        enqueueSnackbar(formatMessage('errorOccurredWhileRequestingData'), {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const debouncedLoadRows = useCallback(debounce(loadRows, 250), []);

  // Инициализируем запрос rows при изменении фильтров или поиска.
  // Пропускаем:
  // Первый рендер - создание эффекта.
  const hashFilterState = objectHash(preparedFilterStateToRequest);
  useDidUpdateEffect(() => {
    setIsLoading(true);
    debouncedLoadRows();
    // eslint-disable-next-line
  }, [hashFilterState, withClosed, query, sorting]);

  const handleQuickFiltersChange = (newQuickFilters: string[]) => {
    setCurrentPage(0);
    setQuickFilters(newQuickFilters);

    setIsLoading(true);
    debouncedLoadRows();
  };

  // Обновляем rows в случае, если rows из props изменились (reLoadModule).
  const propsHash = useMemo(
    () => objectHash({ propsRows, propsTotalRows }),
    [propsRows, propsTotalRows]
  );

  useEffect(() => {
    setRows(propsRows);
    setTotalRows(propsTotalRows);
    // eslint-disable-next-line
  }, [propsHash]);

  const dataTypeProvidersProps = findDataTypes(
    columns,
    Array.from(ALL_FORMATS)
  );

  const tableProps: TableProps = useMemo(
    () => ({
      columnExtensions,
    }),
    [columnExtensions]
  );

  tableProps.rowComponent = useMemo(
    () => getTableRow(rowClickReaction),
    [rowClickReaction]
  );

  tableProps.cellComponent = useMemo(() => {
    if (
      cellClickReaction &&
      cellClickReaction.type === 'showAsyncModalOnCellClickReaction'
    ) {
      return getTableCell(
        cellClickReaction as ShowAsyncModalOnCellClickReaction,
        allowClickOnEmptyCell
      );
    }

    return Cell;
  }, [cellClickReaction, allowClickOnEmptyCell]);

  const sortingStateColumnExtensions = useMemo(
    () =>
      columns?.reduce(
        (acc, column) => [
          ...acc,
          {
            columnName: column.name,
            sortingEnabled: !!column.options?.sortable,
          },
        ],
        [] as any[]
      ),
    [columns]
  );

  const groupingStateColumnExtensions = useGroupingColumnExtensions(
    columns,
    groupingConfig
  );

  const someColumnSortable = useMemo(
    () => columns && columns.some((column) => column.options?.sortable),
    [columns]
  );

  const someColumnHasTitle = useMemo(
    () => columns && columns.some((column) => column.title),
    [columns]
  );

  const [columnWidths, setColumnWidths, resetColumnWidthsToDefault] =
    useColumnResizingState(columns, columnExtensions, enableColumnResizing);

  const { columnOrder, setColumnOrder, resetColumnOrderToDefault } =
    useColumnReordering(columns, enableColumnReordering);

  const [hiddenColumnNames, setHiddenColumnNames, resetColumnWidthToDefault] =
    useColumnVisibilityState(columns, columnVisibilityConfig);

  return (
    <TableDialogWithComponentProvider>
      <TableContext.Provider
        value={{
          ...props,
          resetColumnWidthsToDefault,
          resetColumnOrderToDefault,
          quickFiltersState: quickFilters,
          onQuickFiltersChange: handleQuickFiltersChange,
          withClosed,
          setWithClosed,
          setHiddenColumnNames,
          hiddenColumnNames,
          resetColumnWidthToDefault,
          rowsFromState: rows,
          setRows,
          filterState: preparedFilterStateToRequest,
          sortingState: sorting,
        }}
      >
        <TableCellDragAndDropProvider>
          <TableDialogWithComponent />
          <TableView
            {...props}
            filterComponent={filterComponent}
            options={{
              ...props.options,
              totalRows,
              sorting,
              currentPage,
              pageSize,
            }}
            rows={rows}
            summaryRow={summaryRow}
            isLoading={isLoading}
            tableProps={tableProps}
            dataTypeProvidersProps={dataTypeProvidersProps}
            setSorting={setSorting}
            sortingStateColumnExtensions={sortingStateColumnExtensions}
            groupingStateColumnExtensions={groupingStateColumnExtensions}
            someColumnSortable={someColumnSortable}
            someColumnHasTitle={someColumnHasTitle}
            query={query}
            setQuery={setQuery}
            filter={filter}
            setFilter={setFilter}
            loadRows={debouncedLoadRows}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            columnWidths={columnWidths}
            setColumnWidths={setColumnWidths}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            hiddenColumnNames={hiddenColumnNames}
            tableContentRef={tableContentRef}
          />
        </TableCellDragAndDropProvider>
      </TableContext.Provider>
    </TableDialogWithComponentProvider>
  );
};
