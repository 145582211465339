import React, { ComponentType } from 'react';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './EntryPickerPaper.styles';
import { useFormatMessage } from '../../../../../locale';

const getEntryPickerPaper =
  (
    displayMoreOptionsText: boolean,
    openDialog: () => void
  ): ComponentType<React.HTMLAttributes<HTMLElement>> =>
  ({ className, children }) => {
    const classes = useStyles();
    const formatMessage = useFormatMessage();

    return (
      <Paper className={className}>
        {children}
        <Box
          className={clsx(classes.box, {
            displayMoreOptionsText,
          })}
        >
          {formatMessage('firstTenRecordsFoundAreDisplayed')}
          <u className={classes.pseudoLink} onMouseDown={openDialog}>
            {formatMessage('allEntries')}
          </u>
          .
        </Box>
      </Paper>
    );
  };

export default getEntryPickerPaper;
