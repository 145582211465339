import { useMemo } from 'react';
import { Route } from '../services/Main/types';

/**
 * Алгоритм сортировки роутов:
 *
 * Изначальные роуты:
 * /issues/:id,
 * /issues/create,
 * /issues/:id/:subId,
 * /issues/:id/create/:subId,
 *
 * Результат:
 * /issues/create,
 * /issues/:id,
 * /issues/:id/create/:subId,
 * /issues/:id/:subId,
 *
 * Словарь:
 * DynamicRoute - динамические роуты которые содержат `:`;
 * StaticRoute - статические роуты, которые НЕ содержат `:`;
 * SplatsRoute - роуты ловушки (Название взято с офф. документации: Also known as "catchall" and "star" segments), которые содержат `*`;
 *
 * Констаны:
 * const emptySegmentValue = 1;
 * const dynamicSegmentValue = 3; // DynamicRoute
 * const staticSegmentValue = 10; // StaticRoute
 * const splatPenalty = -2; // SplatsRoute
 *
 * Для того чтобы приоритизировать роуты, мы вводим систему очков у каждого роута:
 *
 * -- 1 шаг. Пробегаемся по всем роутам и собираем очки:
 *
 * 1. Разбиваем path на сегменты;
 * Пример:
 * '/issues/:id' -> ['', 'issues', ':id'];
 * '/issues/create' -> ['', 'issues', 'create'];
 * '/issues/:id/create/:subId' -> ['', 'issues', ':id', 'create', ':subId'];
 * '/issues/:id/:subId' -> ['', 'issues', ':id', ':subId'];
 *
 * 2.В качестве начального значения очков берем количество сегментов, которое
 * получилось после 1 шага;
 * Пример:
 * ['', 'issues', ':id'] -> initialScore = 3;
 * ['', 'issues', 'create'] -> initialScore = 3;
 * ['', 'issues', ':id', 'create', ':subId'] -> initialScore = 5;
 * ['', 'issues', ':id', ':subId'] -> initialScore = 4;
 *
 * 3. (Можно удалить, т.к. мы не используем маршруты с '*', но я оставил возможно в будущем будут). Проверяем каждый
 * сегмент на наличие '*' в маршруте:
 * - Если есть, то прибавляем -2 к начальному значению очков (`initialScore`);
 * - Если нет - пропускаем;
 *
 * 4. Затем в цикле (reduce) пробегаемся по сегментами и изменяем initialScore:
 * - Игнорируем сегменты c `*`;
 * - Если сегмент содержит `:`, то добавляем dynamicSegmentValue;
 * - Иначе Если сегмент пустой `''`, то добавляем emptySegmentValue;
 * - Иначе - добавляем staticSegmentValue;
 *
 * Пример:
 * // ['', 'issues', ':id'] -> initialScore = 3
 * '' - пустой сегмент, initialScore + emptySegmentValue // 3 + 1 = 4;
 * 'issues' - статичный сегмент, initialScore + staticSegmentValue // 4 + 10 = 14;
 * ':id' - динамичный сегмент, initialScore + dynamicSegmentValue // 14 + 3 = 17;
 *
 * -- 2 шаг. Пробегаемся по массиву роутов и у каждого роута вызываем `шаг 1`
 *
 */
export default function useSoringRoutes(routes: Route[]): Route[] {
  return useMemo(() => {
    return [...routes].sort((a, b) => computeScore(b) - computeScore(a));
  }, [routes]);
}

const paramRe = RegExp(/^:\w+$/);
const dynamicSegmentValue = 3;
const emptySegmentValue = 1;
const staticSegmentValue = 10;
const splatPenalty = -2;
const isSplat = (s: string) => s === '*';

function computeScore(route: Route): number {
  const { path } = route;

  const segments = path.split('/');
  let initialScore = segments.length;

  if (segments.some(isSplat)) {
    initialScore += splatPenalty;
  }

  return segments.reduce((score, segment) => {
    if (isSplat(segment)) return initialScore;

    if (paramRe.test(segment)) {
      return initialScore + dynamicSegmentValue;
    }

    if (segment === '') {
      return initialScore + emptySegmentValue;
    }

    return initialScore + staticSegmentValue;
  }, initialScore);
}
