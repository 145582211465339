export default {
  data: {
    shops: [
      {
        value: '0fcdb972-1053-4403-9b6c-6aee48196940',
        label: 'Красногорск',
      },
      {
        value: '014a8b1e-508b-4209-b1d7-ae3adc1eb6c9',
        label: 'Химки',
      },
    ],

    rooms: [
      {
        value: 'fb31e051-c0e6-4fbf-8084-9a834f0608db',
        label: 'Торговый зал',
      },
      {
        value: '22c8eb91-3992-45dd-a994-30a24fa8ed54',
        label: 'Офисное помещение',
      },

      {
        value: 'c0ce4c82-97a8-4faf-b681-0858fcf5cd2b',
        label: 'Склад',
      },
      {
        value: 'd07185da-ff25-4b60-b354-99b7b4fde53c',
        label: 'Холл',
      },
    ],

    equipments: [
      {
        value: 'd9815d4b-29ff-455a-8109-82f9f810b0b3',
        label: 'Слайсер',
      },
      {
        value: 'f0112a8d-70cd-4bcc-8112-422d20bc44f7',
        label: 'Хлеборезка',
      },
      {
        value: 'd548bf3f-4b6a-4423-a1cd-54c791732251',
        label: 'Принтер',
      },

      {
        value: 'e0f50f63-a39f-4fdc-ab1e-1cd0d29d8b15',
        label: 'Монитор',
      },
      {
        value: 'a691707f-5fca-4215-b3d0-9949c38bee2a',
        label: 'Стеллаж',
      },
      {
        value: 'b9081907-6780-433e-b4a5-3d099b6050e1',
        label: 'Тележка для ротационной печи',
      },
      {
        value: 'dff985d7-53c3-43fb-92a6-378d37c51060',
        label: 'Стерилизационный шкаф',
      },
      {
        value: '3cb86c7e-4e3a-4972-b8ed-6afb1b4a6a89',
        label: 'Тепловая витрина',
      },
    ],
  },

  relations: {
    'd9815d4b-29ff-455a-8109-82f9f810b0b3': {
      entry: {
        value: 'd9815d4b-29ff-455a-8109-82f9f810b0b3',
        label: 'Слайсер',
      },
      parentValue: 'fb31e051-c0e6-4fbf-8084-9a834f0608db',
      children: null,
    },
    'f0112a8d-70cd-4bcc-8112-422d20bc44f7': {
      entry: {
        value: 'f0112a8d-70cd-4bcc-8112-422d20bc44f7',
        label: 'Хлеборезка',
      },
      parentValue: 'fb31e051-c0e6-4fbf-8084-9a834f0608db',
      children: null,
    },
    'd548bf3f-4b6a-4423-a1cd-54c791732251': {
      entry: {
        value: 'd548bf3f-4b6a-4423-a1cd-54c791732251',
        label: 'Принтер',
      },
      parentValue: '22c8eb91-3992-45dd-a994-30a24fa8ed54',
      children: null,
    },
    'e0f50f63-a39f-4fdc-ab1e-1cd0d29d8b15': {
      entry: {
        value: 'e0f50f63-a39f-4fdc-ab1e-1cd0d29d8b15',
        label: 'Монитор',
      },
      parentValue: '22c8eb91-3992-45dd-a994-30a24fa8ed54',
      children: null,
    },
    'a691707f-5fca-4215-b3d0-9949c38bee2a': {
      entry: {
        value: 'a691707f-5fca-4215-b3d0-9949c38bee2a',
        label: 'Стеллаж',
      },
      parentValue: 'c0ce4c82-97a8-4faf-b681-0858fcf5cd2b',
      children: null,
    },
    'b9081907-6780-433e-b4a5-3d099b6050e1': {
      entry: {
        value: 'b9081907-6780-433e-b4a5-3d099b6050e1',
        label: 'Тележка для ротационной печи',
      },
      parentValue: 'c0ce4c82-97a8-4faf-b681-0858fcf5cd2b',
      children: null,
    },
    'dff985d7-53c3-43fb-92a6-378d37c51060': {
      entry: {
        value: 'dff985d7-53c3-43fb-92a6-378d37c51060',
        label: 'Стерилизационный шкаф',
      },
      parentValue: 'd07185da-ff25-4b60-b354-99b7b4fde53c',
      children: null,
    },
    '3cb86c7e-4e3a-4972-b8ed-6afb1b4a6a89': {
      entry: {
        value: '3cb86c7e-4e3a-4972-b8ed-6afb1b4a6a89',
        label: 'Тепловая витрина',
      },
      parentValue: 'd07185da-ff25-4b60-b354-99b7b4fde53c',
      children: null,
    },
    'fb31e051-c0e6-4fbf-8084-9a834f0608db': {
      entry: {
        value: 'fb31e051-c0e6-4fbf-8084-9a834f0608db',
        label: 'Торговый зал',
      },
      parentValue: '0fcdb972-1053-4403-9b6c-6aee48196940',
      children: [
        'd9815d4b-29ff-455a-8109-82f9f810b0b3',
        'f0112a8d-70cd-4bcc-8112-422d20bc44f7',
      ],
    },
    '22c8eb91-3992-45dd-a994-30a24fa8ed54': {
      entry: {
        value: '22c8eb91-3992-45dd-a994-30a24fa8ed54',
        label: 'Офисное помещение',
      },
      parentValue: '0fcdb972-1053-4403-9b6c-6aee48196940',
      children: [
        'd548bf3f-4b6a-4423-a1cd-54c791732251',
        'e0f50f63-a39f-4fdc-ab1e-1cd0d29d8b15',
      ],
    },
    'c0ce4c82-97a8-4faf-b681-0858fcf5cd2b': {
      entry: {
        value: 'c0ce4c82-97a8-4faf-b681-0858fcf5cd2b',
        label: 'Склад',
      },
      parentValue: '014a8b1e-508b-4209-b1d7-ae3adc1eb6c9',
      children: [
        'a691707f-5fca-4215-b3d0-9949c38bee2a',
        'b9081907-6780-433e-b4a5-3d099b6050e1',
      ],
    },
    'd07185da-ff25-4b60-b354-99b7b4fde53c': {
      entry: {
        value: 'd07185da-ff25-4b60-b354-99b7b4fde53c',
        label: 'Холл',
      },
      parentValue: '014a8b1e-508b-4209-b1d7-ae3adc1eb6c9',
      children: [
        'dff985d7-53c3-43fb-92a6-378d37c51060',
        '3cb86c7e-4e3a-4972-b8ed-6afb1b4a6a89',
      ],
    },
    '0fcdb972-1053-4403-9b6c-6aee48196940': {
      entry: {
        value: '0fcdb972-1053-4403-9b6c-6aee48196940',
        label: 'Красногорск',
      },
      parentValue: null,
      children: [
        'fb31e051-c0e6-4fbf-8084-9a834f0608db',
        '22c8eb91-3992-45dd-a994-30a24fa8ed54',
      ],
    },
    '014a8b1e-508b-4209-b1d7-ae3adc1eb6c9': {
      entry: {
        value: '014a8b1e-508b-4209-b1d7-ae3adc1eb6c9',
        label: 'Химки',
      },
      parentValue: null,
      children: [
        'c0ce4c82-97a8-4faf-b681-0858fcf5cd2b',
        'd07185da-ff25-4b60-b354-99b7b4fde53c',
      ],
    },
  },
};
