import faker from 'faker';
import { Page } from 'services/Main/types.Page';

faker.locale = 'ru';

export const separateRowClickReactionPage: Page = {
  type: 'singleComponent',
  title: 'Разные rowClickReaction на разных row',
  component: {
    id: 404,
    businessComponentId: 35,
    type: 'table',
    props: {
      heading: 'Разные rowClickReaction на разных row',
      requestConfig: { url: '/api/table/simple' },
      columns: [{ title: 'Тип', name: 'type' }],
      rows: [
        {
          id: faker.datatype.uuid(),
          type: 'Обычный редирект на /issues',
          rowClickReaction: {
            type: 'redirect',
            redirectTo: '/issues',
          },
        },
        {
          id: faker.datatype.uuid(),
          type: 'dynamicRedirect на /issues/:id',
          rowClickReaction: {
            type: 'dynamicRedirect',
            redirectTo: '/issues/:id',
          },
        },
        {
          id: faker.datatype.uuid(),
          type: 'fallback. Отредиректит на /high-level-components/table/with-row-click-reaction/:id',
        },
        {
          id: faker.datatype.uuid(),
          type: 'fallback. Отредиректит на /high-level-components/table/with-row-click-reaction/:id',
        },
      ],
      options: {
        totalRows: 4,
        currentPage: 0,
        pageSize: 20,
        rowClickReaction: {
          type: 'dynamicRedirect',
          redirectTo:
            '/high-level-components/table/with-row-click-reaction/:id',
        },
      },
    },
  },
};
