import { formatISO } from 'date-fns';
import { Page } from 'services/Main/types.Page';
import { OptionsType } from '../../../types.Field';

export const arrayOfTableStubs: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами arrayOf полей в виде таблицы',
  component: {
    id: 100,
    businessComponentId: 24,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          color: 'primary',
          variant: 'contained',
          label: 'Отправить',
          icon: 'mui:Check',
          requestConfig: {
            url: '/api/fields/array-of',
            method: 'POST',
          },
          successResponseReaction: {
            type: 'reLoadModule',
            snackbar: { text: 'Удалось!', options: { variant: 'success' } },
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами arrayOf полей в виде таблицы' },
        fieldGroups: [
          {
            label: 'Array of *',
            fields: [
              {
                type: 'arrayOf',
                name: 'arrayOfTable1',
                viewStyle: 'table',
                label: 'ArrayOf (Табличное представление)',
                enableDragAndDrop: true,
                rowDefinition: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    options: [
                      { value: 'equipment', label: 'По оборудованию' },
                      { value: 'location', label: 'По тер. признаку' },
                      { value: 'category', label: 'По категории' },
                    ],
                    name: 'pickBy',
                    label: 'Признак',
                    mdCols: 3,
                  },
                  {
                    type: 'entryPicker',
                    name: 'entity',
                    label: 'Реквизит',
                    gridRequestConfig: {
                      url: '/api/entry-picker/grid',
                      method: 'POST',
                    },
                    searchRequestConfig: {
                      url: '/api/entry-picker/search',
                      method: 'GET',
                    },
                    multiple: false,
                    mdCols: 3,
                  },
                  {
                    type: 'comboBox',
                    name: 'toWriteOff',
                    label: 'Для списания',
                    options: [
                      {
                        label: 'Да',
                        value: 'yes',
                      },
                      {
                        label: 'Нет',
                        value: 'no',
                      },
                    ],
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    disableClearable: true,
                    mdCols: 1,
                  },
                  {
                    type: 'datePicker',
                    pickerType: 'dateTime',
                    name: 'date',
                    label: 'Дата, время',
                    initialFocusedDate: 'now',
                    mdCols: 2,
                  },
                  {
                    type: 'text',
                    name: 'guid',
                    label: 'versionGuid',
                    hidden: true,
                  },
                  {
                    type: 'formattedNumber',
                    name: 'quantity',
                    label: 'Кол-во',
                    formattedNumberType: 'flexibleNumber',
                    thousandSeparator: ' ',
                    decimalSeparator: ',',
                    allowNegative: false,
                    mdCols: 1,
                  },
                  {
                    type: 'text',
                    name: 'description',
                    label: 'Описание',
                    mdCols: 2,
                  },
                ],
              },
            ],
          },
          {
            label: 'Array of *',
            fields: [
              {
                type: 'arrayOf',
                name: 'arrayOfTable2',
                viewStyle: 'table',
                label: 'ArrayOf (Табличное представление) c defaultRows',
                enableDragAndDrop: true,
                defaultRows: [
                  [
                    {
                      name: 'pickBy',
                      defaultValue: {
                        value: 'equipment',
                        label: 'По оборудованию',
                      },
                    },
                    {
                      name: 'entity',
                      defaultValue: { value: 'auto', label: 'Автомобиль' },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Нет',
                        value: 'no',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 20,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 1',
                    },
                  ],
                  [
                    {
                      name: 'pickBy',
                      defaultValue: null,
                    },
                    {
                      name: 'entity',
                      defaultValue: { value: 'freezer', label: 'Холодильник' },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Да',
                        value: 'yes',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 21,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 2',
                    },
                  ],
                ],
                rowDefinition: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    options: [
                      { value: 'equipment', label: 'По оборудованию' },
                      { value: 'location', label: 'По тер. признаку' },
                      { value: 'category', label: 'По категории' },
                    ],
                    name: 'pickBy',
                    label: 'Признак',
                    mdCols: 3,
                  },
                  {
                    type: 'entryPicker',
                    name: 'entity',
                    label: 'Реквизит',
                    gridRequestConfig: {
                      url: '/api/entry-picker/grid',
                      method: 'POST',
                    },
                    searchRequestConfig: {
                      url: '/api/entry-picker/search',
                      method: 'GET',
                    },
                    multiple: false,
                    mdCols: 3,
                  },
                  {
                    type: 'comboBox',
                    name: 'toWriteOff',
                    label: 'Для списания',
                    options: [
                      {
                        label: 'Да',
                        value: 'yes',
                      },
                      {
                        label: 'Нет',
                        value: 'no',
                      },
                    ],
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    disableClearable: true,
                    mdCols: 1,
                  },
                  {
                    type: 'datePicker',
                    pickerType: 'dateTime',
                    name: 'date',
                    label: 'Дата, время',
                    initialFocusedDate: 'now',
                    mdCols: 2,
                  },
                  {
                    type: 'text',
                    name: 'guid',
                    label: 'versionGuid',
                    hidden: true,
                  },
                  {
                    type: 'formattedNumber',
                    name: 'quantity',
                    label: 'Кол-во',
                    formattedNumberType: 'flexibleNumber',
                    thousandSeparator: ' ',
                    decimalSeparator: ',',
                    allowNegative: false,
                    mdCols: 1,
                  },
                  {
                    type: 'text',
                    name: 'description',
                    label: 'Описание',
                    mdCols: 2,
                  },
                ],
              },
            ],
          },
          {
            label: 'Array of *',
            fields: [
              {
                type: 'arrayOf',
                name: 'arrayOfTable3',
                viewStyle: 'table',
                label:
                  'ArrayOf (Табличное представление) с максимальной высотой 400',
                maxHeight: 400,
                rowDefinition: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    options: [
                      { value: 'equipment', label: 'По оборудованию' },
                      { value: 'location', label: 'По тер. признаку' },
                      { value: 'category', label: 'По категории' },
                    ],
                    name: 'pickBy',
                    label: 'Признак',
                    mdCols: 3,
                  },
                  {
                    type: 'entryPicker',
                    name: 'entity',
                    label: 'Реквизит',
                    gridRequestConfig: {
                      url: '/api/entry-picker/grid',
                      method: 'POST',
                    },
                    searchRequestConfig: {
                      url: '/api/entry-picker/search',
                      method: 'GET',
                    },
                    multiple: false,
                    mdCols: 3,
                  },
                  {
                    type: 'comboBox',
                    name: 'toWriteOff',
                    label: 'Для списания',
                    options: [
                      {
                        label: 'Да',
                        value: 'yes',
                      },
                      {
                        label: 'Нет',
                        value: 'no',
                      },
                    ],
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    disableClearable: true,
                    mdCols: 1,
                  },
                  {
                    type: 'datePicker',
                    pickerType: 'dateTime',
                    name: 'date',
                    label: 'Дата, время',
                    initialFocusedDate: 'now',
                    mdCols: 2,
                  },
                  {
                    type: 'text',
                    name: 'guid',
                    label: 'versionGuid',
                    hidden: true,
                  },
                  {
                    type: 'formattedNumber',
                    name: 'quantity',
                    label: 'Кол-во',
                    formattedNumberType: 'flexibleNumber',
                    thousandSeparator: ' ',
                    decimalSeparator: ',',
                    allowNegative: false,
                    mdCols: 1,
                  },
                  {
                    type: 'text',
                    name: 'description',
                    label: 'Описание',
                    mdCols: 2,
                  },
                ],
                defaultRows: [
                  [
                    {
                      name: 'pickBy',
                      defaultValue: {
                        value: 'equipment',
                        label: 'По оборудованию',
                      },
                    },
                    {
                      name: 'entity',
                      defaultValue: { value: 'auto', label: 'Автомобиль' },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Нет',
                        value: 'no',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 20,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 1',
                    },
                  ],
                  [
                    {
                      name: 'pickBy',
                      defaultValue: null,
                    },
                    {
                      name: 'entity',
                      defaultValue: { value: 'freezer', label: 'Холодильник' },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Да',
                        value: 'yes',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 21,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 2',
                    },
                  ],
                  [
                    {
                      name: 'pickBy',
                      defaultValue: null,
                    },
                    {
                      name: 'entity',
                      defaultValue: { value: 'furnace', label: 'Печь' },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Нет',
                        value: 'no',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 22,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 3',
                    },
                  ],
                  [
                    {
                      name: 'pickBy',
                      defaultValue: null,
                    },
                    {
                      name: 'entity',
                      defaultValue: {
                        value: 'freezer2',
                        label: 'Холодильник 2',
                      },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Нет',
                        value: 'no',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 24,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 4',
                    },
                  ],
                  [
                    {
                      name: 'pickBy',
                      defaultValue: null,
                    },
                    {
                      name: 'entity',
                      defaultValue: {
                        value: 'freezer3',
                        label: 'Холодильник 3',
                      },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Нет',
                        value: 'no',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 25,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 5',
                    },
                  ],
                  [
                    {
                      name: 'pickBy',
                      defaultValue: null,
                    },
                    {
                      name: 'entity',
                      defaultValue: {
                        value: 'freezer4',
                        label: 'Холодильник 4',
                      },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Нет',
                        value: 'no',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 26,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 6',
                    },
                  ],
                  [
                    {
                      name: 'pickBy',
                      defaultValue: null,
                    },
                    {
                      name: 'entity',
                      defaultValue: {
                        value: 'freezer5',
                        label: 'Холодильник 5',
                      },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Нет',
                        value: 'no',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 27,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 7',
                    },
                  ],
                  [
                    {
                      name: 'pickBy',
                      defaultValue: null,
                    },
                    {
                      name: 'entity',
                      defaultValue: {
                        value: 'freezer6',
                        label: 'Холодильник 6',
                      },
                    },
                    {
                      name: 'toWriteOff',
                      defaultValue: {
                        label: 'Нет',
                        value: 'no',
                      },
                    },
                    {
                      name: 'date',
                      defaultValue: formatISO(new Date()),
                    },
                    {
                      name: 'guid',
                      defaultValue: 'c7f1a14b-c416-49f7-8d48-525681a71231',
                    },
                    {
                      name: 'quantity',
                      defaultValue: 28,
                    },
                    {
                      name: 'description',
                      defaultValue: 'Описание 8',
                    },
                  ],
                ],
              },
            ],
          },
          {
            label: 'Array of *',
            fields: [
              {
                type: 'arrayOf',
                name: 'arrayOfTable4',
                viewStyle: 'table',
                label: 'ArrayOf (Табличное представление) без нумерации',
                enableDragAndDrop: true,
                showAutoNumeration: false,
                rowDefinition: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    options: [
                      { value: 'equipment', label: 'По оборудованию' },
                      { value: 'location', label: 'По тер. признаку' },
                      { value: 'category', label: 'По категории' },
                    ],
                    name: 'pickBy',
                    label: 'Признак',
                    mdCols: 3,
                  },
                  {
                    type: 'entryPicker',
                    name: 'entity',
                    label: 'Реквизит',
                    gridRequestConfig: {
                      url: '/api/entry-picker/grid',
                      method: 'POST',
                    },
                    searchRequestConfig: {
                      url: '/api/entry-picker/search',
                      method: 'GET',
                    },
                    multiple: false,
                    mdCols: 3,
                  },
                  {
                    type: 'comboBox',
                    name: 'toWriteOff',
                    label: 'Для списания',
                    options: [
                      {
                        label: 'Да',
                        value: 'yes',
                      },
                      {
                        label: 'Нет',
                        value: 'no',
                      },
                    ],
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    disableClearable: true,
                    mdCols: 1,
                  },
                  {
                    type: 'datePicker',
                    pickerType: 'dateTime',
                    name: 'date',
                    label: 'Дата, время',
                    initialFocusedDate: 'now',
                    mdCols: 2,
                  },
                  {
                    type: 'text',
                    name: 'guid',
                    label: 'versionGuid',
                    hidden: true,
                  },
                  {
                    type: 'formattedNumber',
                    name: 'quantity',
                    label: 'Кол-во',
                    formattedNumberType: 'flexibleNumber',
                    thousandSeparator: ' ',
                    decimalSeparator: ',',
                    allowNegative: false,
                    mdCols: 1,
                  },
                  {
                    type: 'text',
                    name: 'description',
                    label: 'Описание',
                    mdCols: 2,
                  },
                ],
              },
            ],
          },
          {
            label: 'Array of *',
            fields: [
              {
                type: 'arrayOf',
                name: 'arrayOfTable5',
                viewStyle: 'table',
                label:
                  'ArrayOf (Табличное представление) с отключенным DragAndDrop',
                enableDragAndDrop: false,
                rowDefinition: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    options: [
                      { value: 'equipment', label: 'По оборудованию' },
                      { value: 'location', label: 'По тер. признаку' },
                      { value: 'category', label: 'По категории' },
                    ],
                    name: 'pickBy',
                    label: 'Признак',
                    mdCols: 3,
                  },
                  {
                    type: 'entryPicker',
                    name: 'entity',
                    label: 'Реквизит',
                    gridRequestConfig: {
                      url: '/api/entry-picker/grid',
                      method: 'POST',
                    },
                    searchRequestConfig: {
                      url: '/api/entry-picker/search',
                      method: 'GET',
                    },
                    multiple: false,
                    mdCols: 3,
                  },
                  {
                    type: 'comboBox',
                    name: 'toWriteOff',
                    label: 'Для списания',
                    options: [
                      {
                        label: 'Да',
                        value: 'yes',
                      },
                      {
                        label: 'Нет',
                        value: 'no',
                      },
                    ],
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    disableClearable: true,
                    mdCols: 1,
                  },
                  {
                    type: 'datePicker',
                    pickerType: 'dateTime',
                    name: 'date',
                    label: 'Дата, время',
                    initialFocusedDate: 'now',
                    mdCols: 2,
                  },
                  {
                    type: 'text',
                    name: 'guid',
                    label: 'versionGuid',
                    hidden: true,
                  },
                  {
                    type: 'formattedNumber',
                    name: 'quantity',
                    label: 'Кол-во',
                    formattedNumberType: 'flexibleNumber',
                    thousandSeparator: ' ',
                    decimalSeparator: ',',
                    allowNegative: false,
                    mdCols: 1,
                  },
                  {
                    type: 'text',
                    name: 'description',
                    label: 'Описание',
                    mdCols: 2,
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};
