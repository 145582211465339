import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import Link from '../Link';
import useStyles from './MenuModule.styles';

interface DividerLinkProps {
  to?: string;
}

const ConditionalWrapper = ({
  to,
  children,
}: PropsWithChildren<DividerLinkProps>) => {
  const classes = useStyles();

  if (!to) return <div className={classes.wrapper}>{children}</div>;

  return (
    <Link
      className={clsx(classes.link, classes.wrapper)}
      useNavLink
      to={to}
      tabIndex={0}
    >
      {children}
    </Link>
  );
};

export default ConditionalWrapper;
