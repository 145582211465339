import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Table as DevExpressTable } from '@devexpress/dx-react-grid-material-ui';
import { Reaction } from 'services/Main/types.Component';
import dynamicRedirect from 'reactions/dynamicRedirect';
import DefaultTableRow from './DefaultTableRow';
import useStyles from './getTableRow.styles';
import redirectReaction from '../../../../responseReactions/redirect';

const getTableRow =
  (rowClickReaction?: Reaction) =>
  ({
    children,
    tableRow,
    row,
    className,
    ...rest
  }: DevExpressTable.DataRowProps & any) => {
    const classes = useStyles();
    const actualRowClickReaction = row.rowClickReaction || rowClickReaction;

    const onClickHandler = determineIsHasClickHandler(
      actualRowClickReaction,
      row
    );

    const onAuxClickHandler = determineIsHasClickHandler(
      actualRowClickReaction,
      row,
      '_blank'
    );

    useEffect(() => {
      // Специально делаем не-консистентный возврат для того, чтобы остановить
      // всплытие.
      // eslint-disable-next-line consistent-return
      const handler = (e: MouseEvent) => {
        e.preventDefault();
        if (e.button === 1) return false;
      };

      const tbody = document.querySelectorAll('.dx-row')[0].parentElement;

      tbody?.addEventListener('mousedown', handler);

      return () => {
        tbody?.removeEventListener('mousedown', handler);
      };
    });

    return (
      <DefaultTableRow
        className={clsx(
          'dx-row',
          className,
          onClickHandler && classes.row
        )}
        row={row}
        tableRow={tableRow}
        onClick={onClickHandler}
        onAuxClick={onAuxClickHandler}
        {...rest}
      >
        {children}
      </DefaultTableRow>
    );
  };

function determineIsHasClickHandler(
  rowClickReaction: Reaction,
  row: any,
  target?: '_blank'
) {
  if (rowClickReaction) {
    switch (rowClickReaction.type) {
      case 'redirect':
        return () => redirectReaction(rowClickReaction, target);
      case 'dynamicRedirect':
        return () => dynamicRedirect(rowClickReaction, row, target);
      case 'asyncRedirect':
      case 'asyncShowComponentInModal':
      case 'downloadFile':
      case 'reLoadModule':
      case 'showAlertAndRedirect':
      default:
        console.error(`Тип реакции ${rowClickReaction.type} ещё не реализован`);
        return undefined;
    }
  }

  return undefined;
}

export default getTableRow;
