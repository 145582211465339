import faker from 'faker';
import { Page } from 'services/Main/types.Page';

faker.locale = 'ru';

const createRow = () => {
  return {
    id: faker.datatype.uuid(),
    name: faker.lorem.words(2),
    assignee: `${faker.name.lastName()} ${faker.name.firstName()}`,
  };
};

const tableLength = 3;

export const allWithRowClickReactionTablePage = Array.from(
  { length: tableLength },
  createRow
);

export const withRowClickReactionTablePage: Page = {
  type: 'singleComponent',
  title: 'Пример таблицы с возможностью клика на строку',
  component: {
    id: 200,
    businessComponentId: 41,
    type: 'table',
    props: {
      heading: 'Пример таблицы с возможностью клика на строку',
      requestConfig: {
        url: '/high-level-components/table/with-row-click-reaction',
      },
      columns: [
        {
          title: 'Тема',
          name: 'name',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        {
          title: 'Ответственный',
          name: 'assignee',
          options: { formatAs: 'cutTextWithTooltip' },
        },
      ],
      rows: allWithRowClickReactionTablePage.slice(0, tableLength),
      options: {
        totalRows: tableLength,
        currentPage: 0,
        pageSize: 20,
        sorting: [{ columnName: 'code', direction: 'desc' }],
        rowClickReaction: {
          type: 'dynamicRedirect',
          redirectTo:
            '/high-level-components/table/with-row-click-reaction/:id',
        },
      },
    },
  },
};
