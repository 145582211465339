import faker from 'faker';
import { format, getISOWeek, parse } from 'date-fns';
import { Color } from '@material-ui/lab';
import { Page } from 'services/Main/types.Page';
import { Paragraph, STMCell, TableRow } from '../../../types.Component';
import { generateSTMSequence } from './calendarSTMv2.STMSchedulerV2';
import { DATE_FORMAT } from '../../../../../commonConstants';
import { OptionsType } from '../../../types.Field';

faker.locale = 'ru';

const color: Color = faker.random.arrayElement([
  'success',
  'info',
  'warning',
  'error',
]);

export const allCalendarSTMPageRowsV2 = Array.from({ length: 15 }, createRow);
export const allCalendarSTMPageRowsV2Map = new Map(
  allCalendarSTMPageRowsV2.map((row) => [row.id, row])
);
export const calendarSTMPageV2: Page = {
  type: 'singleComponent',
  title: 'Создание ежегодного ППР',
  component: {
    id: 404,
    businessComponentId: 8,
    type: 'table',
    props: {
      actions: [
        {
          type: 'triggerEndpoint',
          label: 'Сформировать график',
          includeFilterState: true,
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/api/complex/v2-calendar-stm/save',
            method: 'GET',
          },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      heading: 'Создание ежегодного ППР',
      requestConfig: { url: '/api/complex/v2-calendar-stm' },
      groupingConfig: {
        defaultGrouping: [
          {
            columnName: 'counterparty',
          },
          {
            columnName: 'direction',
          },
          {
            columnName: 'category',
          },
        ],
      },
      columns: [
        {
          title: ' ',
          name: 'actions',
          options: { formatAs: 'actions' },
        },
        {
          title: 'Контрагент',
          name: 'counterparty',
          options: { formatAs: 'link' },
        },
        {
          title: 'Направление',
          name: 'direction',
          options: { formatAs: 'link' },
        },
        {
          title: 'Категория',
          name: 'category',
          options: { formatAs: 'link' },
        },
        {
          title: 'Оборудование',
          name: 'equipment',
          options: { formatAs: 'link' },
        },
        {
          title: 'Статус',
          name: 'status',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        { title: 'I', name: 'jan1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'jan2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'jan3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'jan4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'feb1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'feb2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'feb3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'feb4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'mar1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'mar2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'mar3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'mar4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'apr1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'apr2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'apr3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'apr4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'may1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'may2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'may3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'may4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'jun1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'jun2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'jun3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'jun4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'jul1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'jul2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'jul3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'jul4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'aug1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'aug2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'aug3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'aug4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'sep1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'sep2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'sep3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'sep4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'oct1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'oct2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'oct3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'oct4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'nov1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'nov2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'nov3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'nov4', options: { formatAs: 'STMCells' } },
        { title: 'I', name: 'dec1', options: { formatAs: 'STMCells' } },
        { title: 'II', name: 'dec2', options: { formatAs: 'STMCells' } },
        { title: 'III', name: 'dec3', options: { formatAs: 'STMCells' } },
        { title: 'IV', name: 'dec4', options: { formatAs: 'STMCells' } },
      ],
      columnExtensions: [
        { columnName: 'actions', width: 56 },
        { columnName: 'equipment', width: 330 },
        { columnName: 'status', width: 180 },
        { columnName: 'jan1', width: 124 },
        { columnName: 'jan2', width: 124 },
        { columnName: 'jan3', width: 124 },
        { columnName: 'jan4', width: 124 },
        { columnName: 'feb1', width: 124 },
        { columnName: 'feb2', width: 124 },
        { columnName: 'feb3', width: 124 },
        { columnName: 'feb4', width: 124 },
        { columnName: 'mar1', width: 124 },
        { columnName: 'mar2', width: 124 },
        { columnName: 'mar3', width: 124 },
        { columnName: 'mar4', width: 124 },
        { columnName: 'apr1', width: 124 },
        { columnName: 'apr2', width: 124 },
        { columnName: 'apr3', width: 124 },
        { columnName: 'apr4', width: 124 },
        { columnName: 'may1', width: 124 },
        { columnName: 'may2', width: 124 },
        { columnName: 'may3', width: 124 },
        { columnName: 'may4', width: 124 },
        { columnName: 'jun1', width: 124 },
        { columnName: 'jun2', width: 124 },
        { columnName: 'jun3', width: 124 },
        { columnName: 'jun4', width: 124 },
        { columnName: 'jul1', width: 124 },
        { columnName: 'jul2', width: 124 },
        { columnName: 'jul3', width: 124 },
        { columnName: 'jul4', width: 124 },
        { columnName: 'aug1', width: 124 },
        { columnName: 'aug2', width: 124 },
        { columnName: 'aug3', width: 124 },
        { columnName: 'aug4', width: 124 },
        { columnName: 'sep1', width: 124 },
        { columnName: 'sep2', width: 124 },
        { columnName: 'sep3', width: 124 },
        { columnName: 'sep4', width: 124 },
        { columnName: 'oct1', width: 124 },
        { columnName: 'oct2', width: 124 },
        { columnName: 'oct3', width: 124 },
        { columnName: 'oct4', width: 124 },
        { columnName: 'nov1', width: 124 },
        { columnName: 'nov2', width: 124 },
        { columnName: 'nov3', width: 124 },
        { columnName: 'nov4', width: 124 },
        { columnName: 'dec1', width: 124 },
        { columnName: 'dec2', width: 124 },
        { columnName: 'dec3', width: 124 },
        { columnName: 'dec4', width: 124 },
      ],
      columnBands: [
        {
          title: 'Январь',
          children: [
            { columnName: 'jan1' },
            { columnName: 'jan2' },
            { columnName: 'jan3' },
            { columnName: 'jan4' },
          ],
        },
        {
          title: 'Февраль',
          children: [
            { columnName: 'feb1' },
            { columnName: 'feb2' },
            { columnName: 'feb3' },
            { columnName: 'feb4' },
          ],
        },
        {
          title: 'Март',
          children: [
            { columnName: 'mar1' },
            { columnName: 'mar2' },
            { columnName: 'mar3' },
            { columnName: 'mar4' },
          ],
        },
        {
          title: 'Апрель',
          children: [
            { columnName: 'apr1' },
            { columnName: 'apr2' },
            { columnName: 'apr3' },
            { columnName: 'apr4' },
          ],
        },
        {
          title: 'Май',
          children: [
            { columnName: 'may1' },
            { columnName: 'may2' },
            { columnName: 'may3' },
            { columnName: 'may4' },
          ],
        },
        {
          title: 'Июнь',
          children: [
            { columnName: 'jun1' },
            { columnName: 'jun2' },
            { columnName: 'jun3' },
            { columnName: 'jun4' },
          ],
        },
        {
          title: 'Июль',
          children: [
            { columnName: 'jul1' },
            { columnName: 'jul2' },
            { columnName: 'jul3' },
            { columnName: 'jul4' },
          ],
        },
        {
          title: 'Август',
          children: [
            { columnName: 'aug1' },
            { columnName: 'aug2' },
            { columnName: 'aug3' },
            { columnName: 'aug4' },
          ],
        },
        {
          title: 'Сентябрь',
          children: [
            { columnName: 'sep1' },
            { columnName: 'sep2' },
            { columnName: 'sep3' },
            { columnName: 'sep4' },
          ],
        },
        {
          title: 'Октябрь',
          children: [
            { columnName: 'oct1' },
            { columnName: 'oct2' },
            { columnName: 'oct3' },
            { columnName: 'oct4' },
          ],
        },
        {
          title: 'Ноябрь',
          children: [
            { columnName: 'nov1' },
            { columnName: 'nov2' },
            { columnName: 'nov3' },
            { columnName: 'nov4' },
          ],
        },
        {
          title: 'Декабрь',
          children: [
            { columnName: 'dec1' },
            { columnName: 'dec2' },
            { columnName: 'dec3' },
            { columnName: 'dec4' },
          ],
        },
      ],
      fixedColumns: {
        leftColumns: ['actions', 'equipment'],
      },
      rows: allCalendarSTMPageRowsV2.slice(0, 15),
      filterComponent: {
        type: 'tableFilter',
        id: 10000,
        businessComponentId: 23,
        props: {
          requestOn: 'change',
          fieldGroups: [
            {
              fields: [
                {
                  type: 'comboBox',
                  optionsType: OptionsType.radioTree,
                  optionsLoader: 'sync',
                  label: 'Объект',
                  name: 'entity',
                  disableClearable: true,
                  defaultValue: {
                    parentValue: '9a724275-fd0f-41de-b6e9-155d3532bd83',
                    value: '47e98618-75cb-408a-900e-7124d3fc89e7',
                    label: '0004 Красногорск',
                    selectable: true,
                  },
                  options: [
                    {
                      parentValue: '11696440-50ad-4da7-bc61-f78d01970a43',
                      value: '88c72c23-1f0d-4677-b534-4761a8d8b865',
                      label: 'Поволжье',
                      selectable: true,
                    },
                    {
                      value: '11696440-50ad-4da7-bc61-f78d01970a43',
                      label: 'Регионы России',
                      parentValue: null,
                      selectable: true,
                    },
                    {
                      parentValue: '88c72c23-1f0d-4677-b534-4761a8d8b865',
                      value: '12950203-1b03-402a-b795-f727dbafc43f',
                      label: '0101 Самара',
                      selectable: true,
                    },
                    {
                      parentValue: '28aadbd4-d51c-44c7-ab3f-db379136e393',
                      value: 'e36461a0-e1e7-4c91-98bf-ec4cdd9f6365',
                      label: 'Юго-Запад',
                      selectable: true,
                    },
                    {
                      value: '28aadbd4-d51c-44c7-ab3f-db379136e393',
                      label: 'М и МО',
                      parentValue: null,
                      selectable: true,
                    },
                    {
                      parentValue: 'e36461a0-e1e7-4c91-98bf-ec4cdd9f6365',
                      value: '59d38ede-8c0f-485e-90f9-57489f3dc008',
                      label: '0001 Центральный офис',
                      selectable: true,
                    },
                    {
                      parentValue: '28aadbd4-d51c-44c7-ab3f-db379136e393',
                      value: '9a724275-fd0f-41de-b6e9-155d3532bd83',
                      label: 'Северо-Запад',
                      selectable: true,
                    },
                    {
                      parentValue: '9a724275-fd0f-41de-b6e9-155d3532bd83',
                      value: '47e98618-75cb-408a-900e-7124d3fc89e7',
                      label: '0004 Красногорск',
                      selectable: true,
                    },
                    {
                      parentValue: '9a724275-fd0f-41de-b6e9-155d3532bd83',
                      value: 'afe84c9a-ec8b-4803-8082-7f00d7b2726c',
                      label: '0075  Пушкино',
                      selectable: true,
                    },
                  ],
                  validationConfig: {
                    validationType: 'mixed',
                    validations: [
                      { type: 'required', params: ['Обязательное поле'] },
                    ],
                  },
                  mdCols: 6,
                },
                {
                  type: 'datePicker',
                  pickerType: 'year',
                  name: 'yearPicker',
                  label: 'Год',
                  disableClearable: true,
                  mdCols: 6,
                },
                {
                  type: 'datePicker',
                  pickerType: 'dateRange',
                  name: 'dateRange',
                  label: 'Date range',
                  initialFocusedDate: 'now',
                },
                {
                  type: 'datePicker',
                  pickerType: 'dateTimeRange',
                  name: 'dateTimeRange',
                  label: 'Date Time range',
                  initialFocusedDate: '2020-04-15T16:33:23.3004811+03:00',
                },
                { type: 'text', name: 'text', label: 'Text' },
                {
                  type: 'formattedNumber',
                  formattedNumberType: 'price',
                  name: 'formattedNumber',
                  label: 'Formatted number',
                  currencySign: '₽',
                  min: '0',
                },
                {
                  type: 'select',
                  optionsLoader: 'sync',
                  selectType: 'single',
                  name: 'singleSelect',
                  label: 'Select single',
                  options: [
                    { value: 'Critical', label: 'Критичный' },
                    { value: 'High', label: 'Высокий' },
                    { value: 'Low', label: 'Низкий' },
                  ],
                },
                {
                  type: 'select',
                  optionsLoader: 'sync',
                  selectType: 'multi',
                  name: 'multiSelect',
                  label: 'Select multi',
                  options: [
                    { value: 'Draft', label: 'Черновик' },
                    { value: 'Pause', label: 'Отложена' },
                    { value: 'Closing', label: 'Сдача-приемка' },
                    { value: 'Canceled', label: 'Прекращена' },
                    { value: 'Done', label: 'Выполнено' },
                    { value: 'Approval', label: 'На согласовании' },
                    { value: 'InWork', label: 'В работе' },
                    { value: 'Queue', label: 'В очереди' },
                  ],
                },
              ],
            },
          ],
        },
      },
      options: {
        totalRows: allCalendarSTMPageRowsV2.length,
        currentPage: 0,
        allowClickOnEmptyCell: false,
        pageSize: allCalendarSTMPageRowsV2.length,
        pagingDisabled: true,
        cellClickReaction: {
          type: 'showAsyncModalOnCellClickReaction',
          fetchModalDataRequestConfig: {
            url: '/api/complex/calendar-stm/v2-get-modal-info/year',
            method: 'POST',
          },
          columnNames: [
            'jan1',
            'jan2',
            'jan3',
            'jan4',
            'feb1',
            'feb2',
            'feb3',
            'feb4',
            'mar1',
            'mar2',
            'mar3',
            'mar4',
            'apr1',
            'apr2',
            'apr3',
            'apr4',
            'may1',
            'may2',
            'may3',
            'may4',
            'jun1',
            'jun2',
            'jun3',
            'jun4',
            'jul1',
            'jul2',
            'jul3',
            'jul4',
            'aug1',
            'aug2',
            'aug3',
            'aug4',
            'sep1',
            'sep2',
            'sep3',
            'sep4',
            'oct1',
            'oct2',
            'oct3',
            'oct4',
            'nov1',
            'nov2',
            'nov3',
            'nov4',
            'dec1',
            'dec2',
            'dec3',
            'dec4',
          ],
        },
      },
    },
  },
};

function getRowViewConfig() {
  if (Math.random() * 1000 < 800) return undefined;

  const backgroundColorMap = {
    error: 'rgb(253, 236, 234)',
    warning: 'rgb(255, 244, 229)',
    info: 'rgb(232, 244, 253)',
    success: 'rgb(237, 247, 237)',
  };

  const colorMap = {
    error: 'rgb(97, 26, 21)',
    warning: 'rgb(102, 60, 0)',
    info: 'rgb(13, 60, 97)',
    success: 'rgb(30, 70, 32)',
  };

  return {
    backgroundColor: backgroundColorMap[color],
    color: colorMap[color],
  };
}

export function createRow(uuid?: string): TableRow {
  const rowId = uuid ?? faker.datatype.uuid();

  return {
    id: rowId,
    actions: [
      // <-------- !!!!!!!!
      {
        label: 'Обновить',
        variant: 'contained',
        type: 'triggerEndpoint',
        includeFilterState: true,
        requestConfig: {
          url: `/api/table/stm/show-form/update-row/${rowId}`,
        },
        icon: 'mui:Sync',
        // из ответа сервера забрать новые данные для row
        successResponseReaction: {
          type: 'asyncShowComponentInModal',
        },
      },
    ],
    counterparty: {
      href: `/counterparty/${faker.datatype.uuid()}`,
      label: faker.random.arrayElement([
        'БДФ-Восток, 000',
        'CТЕП-Сервис, 000',
        'He определено',
      ]),
    },
    category: {
      href: `/equipment-type/${faker.datatype.uuid()}`,
      label: faker.random.arrayElement(['Технологическое оборудование']),
    },
    direction: {
      href: `/equipment-category/${faker.datatype.uuid()}`,
      label: faker.random.arrayElement(['Кулинария', 'Сыры']),
    },
    equipment: {
      href: `/equipments/${faker.datatype.uuid()}`,
      label: faker.commerce.productName(),
    },
    status: faker.random.arrayElement([
      'Исправно',
      'Неисправно',
      'Неисправно (критично)',
      'Требуется ремонт',
      'Не эксплуатируется',
      'Планируется замена',
    ]),
    ...createSTMCellRow(),
    viewConfig: getRowViewConfig(),
  };
}

export function createSTMCellRow() {
  const months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];

  const STMScheduler = generateSTMSequence(
    faker.datatype.boolean(),
    faker.datatype.boolean(),
    faker.datatype.boolean(),
    faker.datatype.boolean(),
    faker.datatype.boolean()
  );

  let currentWeekIndex: number = -1;

  return months.reduce<Record<string, STMCell[] | null>>(
    (acc, month, monthIndex) => {
      return {
        ...acc,
        ...Object.fromEntries(
          Array.from({ length: 4 }, (_, weekIndex) => {
            currentWeekIndex += 1;

            const STMType = STMScheduler[currentWeekIndex];

            if (!STMType) {
              return [`${month}${weekIndex + 1}`, null];
            }

            const STMCells = [
              generateSTMCellValue(STMType, monthIndex + 1, weekIndex + 1),
              ...Array.from(
                {
                  length: faker.datatype.number({
                    min: 0,
                    max: 3,
                  }),
                },
                () => generateSTMCellValue(1, monthIndex + 1, weekIndex + 1)
              ),
            ];

            return [
              `${month}${weekIndex + 1}`,
              STMCells.sort((a, b) => {
                if (a.avatar && !b.avatar) {
                  return -1; // a с avatar будет первым
                }

                if (!a.avatar && b.avatar) {
                  return 1; // b с avatar будет первым
                }

                return 0; // порядок не меняется
              }),
            ];
          })
        ),
      };
    },
    {}
  );
}

export function generateSTMCellValue(
  type: number,
  month: number,
  week: number
): STMCell {
  const colorMap = ['#F9BF2A', '#F67C80', '#98D05A', '#a4a4a4', '#3FB0EE'];

  const employee = faker.random.arrayElement([
    'Игорь Петров',
    'Валентин Степанов',
    'Артём Акимов',
    'Игнатьев Савелий',
    'Громов Николай',
    'Логинов Олег',
    'Щукин Валерий',
    'Милонов Петр',
    'Рогов Владислав',
    'Бренчалов Федор',
  ]);

  const firstName = employee.split(' ')[0];
  const lastName = employee.split(' ')[1];

  const plannedDate = parse(`${month}.${week * 7}`, 'M.d', new Date());
  const plannedWeek = getISOWeek(plannedDate);
  const currentWeek = getISOWeek(new Date());

  const status = (() => {
    if (plannedWeek === currentWeek) {
      return faker.random.arrayElement([
        { label: 'Запланировано', icon: 'mui:Alarm' },
        { label: 'В работе', icon: 'mui:Build' },
      ]);
    }

    if (plannedWeek < currentWeek) {
      if (Math.random() > 0.65) {
        return { label: 'Отменено', icon: 'mui:TimerOff' };
      }

      return { label: 'Выполнено', icon: 'mui:CheckCircleOutline' };
    }

    return { label: 'Запланировано', icon: 'mui:Alarm' };
  })();

  const avatar = (() => {
    // Сделать рандомно для будущих исполнителя необязательным (95% шанс необязательно).
    if (plannedWeek > currentWeek && Math.random() < 0.95) {
      return undefined;
    }

    // Сделать рандомно для текущих работ 50% шанс не заполненного исполнителя.
    if (plannedWeek === currentWeek && Math.random() < 0.5) {
      return undefined;
    }

    return {
      firstName,
      lastName,
    };
  })();

  const tooltipContent: Paragraph[] = [
    {
      title: 'Тип ТО',
      text: type.toString(),
    },
    {
      title: 'Статус',
      text: status.label,
    },
    { title: 'Плановая дата', text: format(plannedDate, DATE_FORMAT) },
  ];

  if (avatar) {
    tooltipContent.push({
      title: 'Исполнитель',
      text: `${avatar.firstName} ${avatar.lastName}`,
    });
  }

  return {
    label: type.toString(),
    avatar,
    color: colorMap[type - 1],
    icon: status.icon,
    tooltip: {
      content: tooltipContent,
      backgroundColor: faker.datatype.boolean()
        ? colorMap[type - 1]
        : undefined,
    },
  };
}
