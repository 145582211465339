import { useCallback, useEffect, useState } from 'react';
import useTimelineContext from '../../hooks/useTimelineContext';

export default () => {
  const { timelineElements, timelineScrollManager } = useTimelineContext();
  const [shouldDisplay, setShouldDisplay] = useState<boolean>(false);
  const [hasUnreadMessage, setHasUnreadMessage] = useState<boolean>(false);

  const calculateShouldDisplay = useCallback(() => {
    if (timelineScrollManager?.current) {
      const newShouldDisplay =
        !timelineScrollManager.current.isScrolledToBottom;
      setShouldDisplay(newShouldDisplay);

      if (!newShouldDisplay) {
        setHasUnreadMessage(false);
      }
    }
  }, [timelineScrollManager]);

  useEffect(() => {
    setTimeout(() => {
      timelineScrollManager?.current?.container.addEventListener(
        'scroll',
        calculateShouldDisplay
      );
    }, 0); // workaround для избежания навешивания события на undefined.

    return () => {
      timelineScrollManager?.current?.container.removeEventListener(
        'scroll',
        calculateShouldDisplay
      );
    };
    // eslint-disable-next-line
  }, []);

  const timelineElementsLength = timelineElements?.length;
  useEffect(() => {
    calculateShouldDisplay();

    // setTimeout нужен для того, чтобы это не срабатывало
    // при работе с формой добавления свободного комментария.
    setTimeout(() => {
      if (
        timelineScrollManager?.current &&
        !timelineScrollManager?.current?.isScrolledToBottom
      ) {
        setHasUnreadMessage(true);
      }
    }, 0);
    // eslint-disable-next-line
  }, [timelineElementsLength]);

  return { shouldDisplay, hasUnreadMessage };
};
