import { useState, useEffect } from 'react';

/**
 * Хук useScrollbarWidth предназначен для определения ширины скролла в окне
 * браузера. Он вычисляет ширину скролла и автоматически обновляет ее при
 * изменении размеров окна.
 *
 * @returns {number} - Ширина скролла в пикселях.
 */
export const useScrollbarWidth = () => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const computeScrollbarWidth = () => {
    const outer = document.createElement('div') as HTMLElement;
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll';
    (outer.style as any).msOverflowStyle = 'scrollbar';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    outer.appendChild(inner);

    const width = outer.offsetWidth - inner.offsetWidth;

    outer.parentNode?.removeChild(outer);

    return width;
  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = computeScrollbarWidth();
      setScrollbarWidth(newWidth);
    };

    setScrollbarWidth(computeScrollbarWidth());

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return scrollbarWidth;
};
