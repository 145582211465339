import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useStyles from './LoginPage.styles';

export default () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.russianText}>
        <Typography className={classes.textHeader}>
          Облачное решение Компании, позволяющее:
        </Typography>
        <Typography className={classes.textDescription}>
          – Повысить прозрачность и прогнозируемость финансовых затрат;
        </Typography>
        <Typography className={classes.textDescription}>
          – Сделать управляемыми и эффективными бизнес-процессы;
        </Typography>
        <Typography className={classes.textDescription}>
          – Снизить повседневную нагрузку на человеческие ресурсы, передав
          контроль за регулярной технической эксплуатацией основного фонда
          Компании России собственной информационной системе.
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.textHeader}>
          The Company’s cloud solution, enabling:
        </Typography>
        <Typography className={classes.textDescription}>
          – Increase transparency and predictability of financial costs;
        </Typography>
        <Typography className={classes.textDescription}>
          – Make business processes manageable and efficient;
        </Typography>
        <Typography className={classes.textDescription}>
          – Reduce the daily burden on human resources, transferring control
          over the regular technical maintenance of the Company’s Fixed Assets
          in Russia to own information system.
        </Typography>
      </Box>
    </>
  );
};
