import faker from 'faker';

export function generateSTMSequence(
  has1STM: boolean,
  has2STM: boolean,
  has3STM: boolean,
  has4STM: boolean,
  has5STM: boolean
): (number | undefined)[] {
  const SMTList: number[] = [];
  const defaultLimit: Record<number, number> = {
    5: 1, // Раз в год
    4: 2, // Раз в пол года
    3: 4, // Раз в квартал
    2: 12, // Раз в месяц
    1: 48, // Раз в неделю
  };

  const limit: Record<number, number> = {
    ...defaultLimit,
  };

  if (has5STM) {
    SMTList.push(5);
  }

  if (has4STM) {
    SMTList.push(4);
  }

  if (has3STM) {
    SMTList.push(3);
  }

  if (has2STM) {
    SMTList.push(2);
  }

  if (has1STM) {
    SMTList.push(1);
  }

  SMTList.reverse();

  // Если все STM отключены, то возвращаем пустой из 48 undefined
  if (SMTList.length === 0) {
    return Array.from({ length: 48 }, () => undefined);
  }

  if (SMTList.length === 1 && has1STM) {
    return Array.from({ length: 48 }, () => 1);
  }

  const available: Record<number, number> = {};

  const getSMTType = (arr: number[]): number | undefined => {
    const SMTType = faker.random.arrayElement(arr);

    if (SMTType === 0) {
      return undefined;
    }

    SMTList.forEach((item) => {
      if (item <= SMTType) {
        limit[SMTType] -= 1;
      }

      if (defaultLimit[SMTType] % 12 !== 0) {
        if (item > SMTType) {
          available[item] = 12 / -defaultLimit[SMTType];
        }

        if (item <= SMTType && defaultLimit[item] % 12 !== 0) {
          available[item] = 12 / -defaultLimit[item];
        }
      }
    });

    return SMTType;
  };

  // 1. Генерируем на год по месяцам
  const SMTListForYear = [...SMTList];

  // Если нет ежемесячных, то пушим 0
  // 0 как флаг, что пусто
  if (!has2STM && !has2STM && !has1STM) {
    SMTListForYear.push(0);
  }

  const SMTMonthlyScheduler = Array.from({ length: 12 }, (_, monthIndex) => {
    if (monthIndex === 0) {
      return getSMTType(SMTListForYear);
    }

    const agreementOfTheRequirements = SMTList.reduce<number[]>((acc, item) => {
      if (limit[item] > 0 && (!available[item] || available[item] >= 0)) {
        acc.push(item);
      }

      return acc;
    }, []);

    Object.entries(available).forEach(([key, value]) => {
      if (value < 0) {
        available[+key] += 1;
      }
    });

    return getSMTType(agreementOfTheRequirements);
  });

  // 2. Рандомным образом определяем стартовую неделю
  // Как неделя будет стартовой для начало годового календаря SMT
  const startSMTSequenceWeek = faker.datatype.number(3);
  const SMTListForWeek: number[] = [];

  // Если нет еженедельных, то пушим 0
  // 0 как флаг, что пусто
  if (!has1STM) {
    SMTListForWeek.push(0);
  } else {
    SMTListForWeek.push(1);
  }

  // 3. На основе стартовой неделе строим на год по неделям
  return SMTMonthlyScheduler.reduce<(number | undefined)[]>((acc, SMTMonth) => {
    return [
      ...acc,
      ...Array.from({ length: 4 }, (_, weekIndex) => {
        if (weekIndex === startSMTSequenceWeek) {
          return SMTMonth;
        }

        return getSMTType(SMTListForWeek);
      }),
    ];
  }, []);
}
