import React from 'react';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { TextWidget } from '../../../services/Main/types.Component';
import useStyles from './TextWidget.styles';
import Trend from './Trend';
import EmptyWidgetDataText from '../EmptyWidgetDataText';
import Link from '../Link';
import { TextWidgetState } from './types';

interface AdditionalProps {
  showLoader?: boolean;
}

export default ({
  title,
  description,
  metric,
  trend,
  showLoader,
}: TextWidget & TextWidgetState & AdditionalProps) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.cardContent}>
        {title && (
          <Typography variant="h6" gutterBottom>
            {title}
            {showLoader && (
              <CircularProgress size={12} style={{ marginLeft: 8 }} />
            )}
          </Typography>
        )}
        {metric ? (
          <>
            <Typography className={classes.metric} variant="h4" component="h2">
              {metric.href ? (
                <Link to={metric.href}>{metric.label}</Link>
              ) : (
                metric.label
              )}
            </Typography>
            <Typography variant="body2">
              {trend && <Trend {...trend} />}
              <span className={classes.description}>{description}</span>
            </Typography>
          </>
        ) : (
          <EmptyWidgetDataText height="100%" width="100%" fontSize="2.5rem" />
        )}
      </div>
    </Card>
  );
};
