import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useState,
} from 'react';
import throttle from 'lodash.throttle';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '../Icon/Icon';
import useStyles from './SearchInput.styles';
import { useFormatMessage } from '../../../locale';

export type SearchInputProps = TextFieldProps & {
  query?: string;
  onQueryChange?: (query: string) => void;
  debounceDelay?: number;
};

const SearchInput = ({
  query,
  onQueryChange = () => {},
  debounceDelay = 250,
  ...textFieldProps
}: SearchInputProps) => {
  const classes = useStyles(!!query)();
  const formatMessage = useFormatMessage();

  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  const throttledOnQueryChange = useCallback(
    throttle(onQueryChange, debounceDelay),
    [onQueryChange, debounceDelay]
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.persist();
    throttledOnQueryChange(e.target.value);
  };

  const handleClearClick = () => {
    throttledOnQueryChange('');
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 27) {
      e.preventDefault();
      setIsSearchOpen(false);
    }
  };

  return (
    <>
      <IconButton
        onClick={() => {
          setIsSearchOpen(true);
        }}
      >
        {query && query.length > 0 ? (
          <Badge
            className={classes.badge}
            badgeContent={`${query.slice(0, 2)}...`}
            color="error"
          >
            <Icon definition="mui:Search" />
          </Badge>
        ) : (
          <Icon definition="mui:Search" />
        )}
      </IconButton>
      <Drawer
        anchor="top"
        className={classes.drawer}
        open={isSearchOpen}
        onClose={() => {
          setIsSearchOpen(false);
        }}
        onKeyDown={handleKeyDown}
      >
        <Box
          className={classes.inputWrapper}
          pt={2}
          pb={1}
          px={1}
          display="flex"
          justifyContent="center"
        >
          <TextField
            placeholder={`${formatMessage('search')}...`}
            fullWidth
            InputProps={{
              endAdornment: (
                <>
                  <InputAdornment
                    className={classes.endAdornment}
                    position="end"
                    onClick={handleClearClick}
                  >
                    <Icon definition="mui:Clear" />
                  </InputAdornment>
                  <InputAdornment position="start">
                    <Icon definition="mui:Search" />
                  </InputAdornment>
                </>
              ),
            }}
            value={query || ''}
            onChange={handleChange}
            {...textFieldProps}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default SearchInput;
