import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { File } from '../../../../../services/Main/types.Field';
import { supportedFileViewType } from '../../../../lowLevel/FileUpload/FileViewer/plugins';
import useStyles from './Attachment.styles';
import FileIcon from '../../../../../svg/File';
import FileDownloadIcon from '../../../../../svg/FileDownload';
import FilePreviewIcon from '../../../../../svg/FilePreview';
import { getFileSize } from '../../../../lowLevel/FileListItem/helpers';
import FileViewer from '../../../../lowLevel/FileUpload/FileViewer';
import { useFormatMessage } from '../../../../../locale';
import getFileType from './getFileType';

interface Props {
  attachment: File;
}

function Attachment({ attachment }: Props) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { name, size, url, mimeType } = attachment;
  const isSupportedFileView = supportedFileViewType.includes(mimeType);

  const [isPreviewModeOpen, setIsPreviewModeOpen] = useState<boolean>(false);
  const handlePreviewClick = () => {
    setIsPreviewModeOpen(true);
  };

  const handlePreviewClose = () => {
    setIsPreviewModeOpen(false);
  };

  return (
    <Box className={classes.container}>
      <div className={classes.hoverButtonGroupContainer}>
        <ButtonGroup variant="outlined" fullWidth>
          {isSupportedFileView && (
            <Tooltip title={`${formatMessage('preview')}: ${name}`} arrow>
              <Button className={classes.button} onClick={handlePreviewClick}>
                <FilePreviewIcon />
              </Button>
            </Tooltip>
          )}
          <Tooltip title={`${formatMessage('download')}: ${name}`} arrow>
            <Button
              className={classes.button}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <FileDownloadIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      </div>
      <FileIcon className={classes.icon}>{getFileType(mimeType)}</FileIcon>
      <div className={classes.fileInfo}>
        <Typography className={classes.name}>{name}</Typography>
        <Typography className={classes.size}>{getFileSize(+size)}</Typography>
      </div>
      {isSupportedFileView && (
        <FileViewer
          file={attachment}
          open={isPreviewModeOpen}
          handleClose={handlePreviewClose}
        />
      )}
    </Box>
  );
}

export default Attachment;
