import React, { MouseEvent, useContext, useState } from 'react';
import {
  Menu,
  MenuItem,
  Tooltip,
  Checkbox,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import { useFormatMessage } from '../../../locale';
import { TableContext } from '../../highLevel/Table';
import Icon from '../Icon/Icon';
import useStyles from './TableColumnVisibilityButton.styles';

const TableColumnVisibilityButton = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const tableContext = useContext(TableContext);

  const isMenuOpen = Boolean(dropdownAnchorEl);

  const handleIconClick = (event: MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setDropdownAnchorEl(null);
  };

  const handleHiddenColumnNameChange = (columnName: string) => {
    return () => {
      if (!tableContext) return;

      tableContext.setHiddenColumnNames((prevState) =>
        prevState.includes(columnName)
          ? prevState.filter((name) => name !== columnName)
          : [...prevState, columnName]
      );
    };
  };

  return (
    <>
      <Tooltip title={formatMessage('columnVisibilityControl')}>
        <IconButton onClick={handleIconClick}>
          <Icon definition="mui:VisibilityOff" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={dropdownAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
      >
        {tableContext?.columns.map(({ name, title }, index) => (
          <MenuItem
            key={`${name}_${index}`}
            onClick={handleHiddenColumnNameChange(name)}
            className={classes.menuItem}
            dense
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                name={name}
                className={classes.checkbox}
                size="small"
                checked={!tableContext?.hiddenColumnNames.includes(name)}
                color="primary"
              />
            </ListItemIcon>
            <ListItemText>{title || name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TableColumnVisibilityButton;
