import React, { useState } from 'react';
import {
  Dialog,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { EntryPickerTable } from '../EntryPickerTable';
import { EntryPickerProps } from '../../types';
import EntryPickerTableProvider from '../EntryPickerTable/components/EntryPickerTableProvider';
import EntryPickerTableFooter from '../EntryPickerTable/components/EntryPickerTableFooter';
import EntryPickerTableHeader from '../EntryPickerTable/components/EntryPickerTableHeader';
import PagingPanel from '../EntryPickerTable/components/PagingPanel';
import useStyles from './EntryPickerDialog.styles';
import EntryPickerLinearProgress from '../EntryPickerTable/components/EntryPickerLinearProgress';

interface Props {
  entryPickerProps: EntryPickerProps;
  open: boolean;
  onClose: () => void;
  onEntryChoose: (chosenValues: (string | number)[]) => void;
}

const EntryPickerDialog = ({
  entryPickerProps,
  open,
  onClose,
  onEntryChoose,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const toggleFullScreen = () => {
    setIsFullScreen((prevState) => !prevState);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      fullScreen={isFullScreen}
      classes={{
        paper: classes.paper,
      }}
    >
      <EntryPickerTableProvider
        onEntryChoose={onEntryChoose}
        onCancel={onClose}
        entryPickerProps={entryPickerProps}
      >
        <Box className={classes.actionsContainer} mb={1}>
          <Box mr={1}>
            <IconButton
              aria-label="toggle-full-screen"
              onClick={toggleFullScreen}
              size={isSmDown ? 'small' : 'medium'}
            >
              {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            size={isSmDown ? 'small' : 'medium'}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <EntryPickerTableHeader />
        <EntryPickerLinearProgress />
        <Box className={classes.dialogContent} mb={3}>
          <EntryPickerTable />
        </Box>
        <Box className={classes.actionsContainer} mb={2}>
          <PagingPanel />
        </Box>
        <Box className={classes.actionsContainer}>
          <EntryPickerTableFooter />
        </Box>
      </EntryPickerTableProvider>
    </Dialog>
  );
};

export default EntryPickerDialog;
