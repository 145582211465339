import faker from 'faker';
import { Page } from 'services/Main/types.Page';
import { Select } from 'services/Main/types.Field';

const createOption = () => {
  return {
    value: faker.datatype.uuid(),
    label: faker.lorem.word(),
    disabled: Math.random() * 100 < 10,
  };
};

const someOptions = Array.from({ length: 25 }, createOption);

export const simpleSingleSelect: Select = {
  type: 'select',
  optionsLoader: 'sync',
  selectType: 'single',
  name: 'select1',
  label: 'Простой селект',
  options: Array.from({ length: 5 }, createOption),
  highlightColor: 'warning',
};

export const selectFieldFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами select field',
  component: {
    id: 100,
    businessComponentId: 26,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          color: 'primary',
          variant: 'contained',
          label: 'Отправить',
          icon: 'mui:Check',
          requestConfig: {
            url: '/api/fields/select',
            method: 'POST',
          },
          successResponseReaction: {
            type: 'reLoadModule',
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами select field' },
        fieldGroups: [
          {
            label: 'Select',
            fields: [
              simpleSingleSelect,
              {
                type: 'select',
                selectType: 'multi',
                name: 'select2',
                label: 'Мульти селект',
                options: Array.from({ length: 15 }, createOption),
                highlightColor: 'success',
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'select5',
                label: 'Селект с дефолтным значением',
                defaultValue: someOptions[10],
                options: someOptions,
              },
              {
                type: 'select',
                selectType: 'multi',
                name: 'select6',
                label: 'Мульти селект с дефолтным значением',
                defaultValue: [someOptions[2], someOptions[6]],
                options: someOptions,
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'select9',
                label: 'Любой селект можно задисейблить',
                disabled: true,
                defaultValue: someOptions[12],
                options: someOptions,
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'select10',
                label: 'Селекты можно компоновать',
                mdCols: 6,
                options: someOptions,
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'select11',
                label: 'Для красоты лучше не ставить в один ряд мультиселекты',
                mdCols: 6,
                options: someOptions,
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'select12',
                label: 'Обязательный*',
                options: someOptions,
                validationConfig: {
                  validationType: 'mixed',
                  validations: [
                    { type: 'required', params: ['Обязательное поле.'] },
                  ],
                },
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'select15',
                label: 'Селект со ссылкой',
                options: [
                  {
                    value: 'ebda21bf-51c5-49be-9dd5-7dbce3f06e51',
                    label: 'Ссылка на табс',
                    href: '/page-views/tabs',
                  },
                  {
                    value: 'efda21bf-51c5-49be-9dd5-7dbce3f06e00',
                    label: 'Ссылка на виджеты',
                    href: '/page-views/widgets',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};
