import React, { CSSProperties } from 'react';
import {
  Table as DevExpressTable,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';

interface OtherProps {
  className?: string;
  onClick?: () => void;
  styles?: CSSProperties;
}

export default ({
  children,
  tableRow,
  row,
  className,
  ...rest
}: DevExpressTable.DataRowProps & OtherProps) => {
  return (
    <VirtualTable.Row
      className={className}
      row={row}
      tableRow={tableRow}
      {...rest}
      style={{
        ...rest.styles,
        backgroundColor: row.viewConfig?.backgroundColor,
        color: row.viewConfig?.color,
        fontWeight: row.viewConfig?.bold ? 700 : undefined,
      }}
    >
      {children}
    </VirtualTable.Row>
  );
};
