import { baseURL } from 'services/Api';

export const getFileUri = (fileUrl?: string, token?: string | null) => {
  if (!token) {
    throw new Error('Не удалось получить токен пользователя');
  }

  if (!fileUrl) {
    throw new Error(
      `Не удалось определить ссылку на файл. Пришел url: ${fileUrl}`
    );
  }

  const url = new URL(fileUrl, baseURL);
  url.searchParams.append('token', token);

  return url.toString();
};
