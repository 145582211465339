import { styled } from '@material-ui/core';
import { Label } from 'recharts';

const ChartsAxisLabel = styled(Label)(({ theme }) => ({
  fill: '#99a6b7',
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(11),
}));

/**
 * Под капотом библиотека recharts опирается на displayName при отрисовки
 * компонентов, поэтому переопределяем displayName на displayName из библиотеки.
 */
ChartsAxisLabel.displayName = Label.displayName;

export default ChartsAxisLabel;
