import React, { MouseEvent } from 'react';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { TableSortLabel, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './TableHeaderTooltip.styles';

const ENTER_KEY_CODE = 13;
const SPACE_KEY_CODE = 32;

const onClick =
  (onSort: TableHeaderRow.SortLabelProps['onSort']) =>
  (e: (MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLSpanElement>) & any) => {
    const isActionKeyDown =
      e.keyCode === ENTER_KEY_CODE || e.keyCode === SPACE_KEY_CODE;
    const isMouseClick = e.keyCode === undefined;

    const cancelSortingRelatedKey = e.metaKey || e.ctrlKey;
    const direction =
      (isMouseClick || isActionKeyDown) && cancelSortingRelatedKey
        ? null
        : undefined;
    const keepOther = e.shiftKey || cancelSortingRelatedKey;

    e.preventDefault();
    onSort({ direction, keepOther });
  };

/**
 * Компонент взят из репозитория:
 * https://github.com/DevExpress/devextreme-reactive/blob/v2.6.2/packages/dx-react-grid-material-ui/src/templates/table-header-cell/sort-label.jsx
 *
 * Tooltip не отображался у ячеек, потому что TableSortLabel disabled={true}.
 * Под капотом TableSortLabel - кнопка, а если у кнопки сделать disabled=true,
 * то всплывающая подсказка не будет отображаться.
 */
export const TableHeaderTooltip = (
  props: TableHeaderRow.SortLabelProps & any
) => {
  const classes = useStyles();
  const {
    column,
    align,
    direction,
    children,
    onSort,
    disabled,
    className,
    ...restProps
  } = props;

  return (
    <div className={clsx(classes.root, className)} {...restProps}>
      <Tooltip
        title={column.title || column.name}
        placement={align === 'right' ? 'bottom-end' : 'bottom-start'}
        enterDelay={300}
        classes={{
          tooltip: classes.tooltipRoot,
        }}
      >
        <TableSortLabel
          active={!!direction}
          direction={direction === null ? undefined : direction}
          onClick={disabled ? undefined : onClick(onSort)}
          classes={{
            root: clsx(classes.sortLabelRoot, {
              [classes.sortLabelRight]: align === 'right',
              disabled,
            }),
            active: classes.sortLabelActive,
          }}
        >
          {children}
        </TableSortLabel>
      </Tooltip>
    </div>
  );
};

export default TableHeaderTooltip;
