import React from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { OptionsType } from '../../../../services/Main/types.Field';

export default function getCheckboxTreeIcons(
  optionsType: OptionsType.radioTree | OptionsType.checkboxTree
) {
  const icons = {
    expandClose: <KeyboardArrowRightIcon fontSize="small" />,
    expandOpen: <KeyboardArrowDownIcon fontSize="small" />,
  };

  if (optionsType === OptionsType.checkboxTree)
    return {
      ...icons,
      check: <CheckBoxIcon fontSize="small" color="primary" />,
      uncheck: <CheckBoxOutlineBlankIcon fontSize="small" />,
      halfCheck: <IndeterminateCheckBoxIcon fontSize="small" color="primary" />,
    };

  return {
    ...icons,
    check: <RadioButtonCheckedIcon fontSize="small" color="primary" />,
    uncheck: <RadioButtonUncheckedIcon fontSize="small" />,
  };
}
