import TransparentRect from 'svg/TransparentRect';
import getMuiIcon from './getMuiIcon';
import getLocalIcon from './getLocalIcon';

export default function (nameTemplate: string) {
  const [type, name] = nameTemplate.split(':');

  if (!type || !name || (type !== 'local' && type !== 'mui'))
    return TransparentRect;

  return type === 'mui'
    ? getMuiIcon(name) || TransparentRect
    : getLocalIcon(name) || TransparentRect;
}
