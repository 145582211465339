import React from 'react';
import { ListItemText, Popover, PopoverProps } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'store';
import ConditionalWrapper from './ConditionalWrapper';
import MainMenu from '../../template/PrivateRoutesTemplate/MainMenu/MainMenu';
import { MenuItem, ModuleMenuItem } from '../../../services/Main/types';
import { useIsCompactMenuView } from '../../../utils/hooks/useIsCompactMenuView';
import { childrenMenuItemsSelector } from '../../../store/mainMenu/selectors';
import useStyles from './MenuModule.styles';

interface MenuModuleDropdownMenuProps
  extends Omit<PopoverProps, 'id'>,
    Pick<ModuleMenuItem, 'label' | 'to' | 'id'> {}

const MenuModuleDropdownMenu = ({
  open,
  anchorEl,
  onClose,
  to,
  label,
  id,
}: MenuModuleDropdownMenuProps) => {
  const classes = useStyles();
  const isCompactMenuView = useIsCompactMenuView();
  const childrenMenuItems = useSelector(childrenMenuItemsSelector(id));

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: -8 }}
      classes={{
        paper: classes.dropdownMenu,
      }}
    >
      <ConditionalWrapper to={to}>
        <ListItemText
          primary={label}
          classes={{
            primary: clsx(classes.label, {
              compactView: isCompactMenuView,
            }),
          }}
        />
      </ConditionalWrapper>
      <MainMenu items={childrenMenuItems as MenuItem[]} isSubModule />
    </Popover>
  );
};

export default MenuModuleDropdownMenu;
