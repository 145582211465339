import { parse } from 'query-string';
import { useMemo } from 'react';

export default () => {
  return useMemo(getGetParams, []);
};

export function getGetParams() {
  const { payload: tableStateJSON } = parse(window.location.search);

  if (!tableStateJSON || typeof tableStateJSON !== 'string') return null;

  let parsedTableState;

  try {
    parsedTableState = JSON.parse(tableStateJSON);
  } catch (e) {
    console.error('Error via decoding JSON table state.');
    console.error(e);
    return null;
  }

  return parsedTableState;
}

export function deserializePayload(payloadJSON: string | null) {
  if (!payloadJSON) return null;

  let parsedTableState;

  try {
    parsedTableState = JSON.parse(payloadJSON);
  } catch (e) {
    console.error('Error via decoding JSON table state.');
    console.error(e);
    return null;
  }

  return parsedTableState;
}
