import {
  addMonths,
  endOfMonth,
  formatISO,
  parse,
  startOfMonth,
} from 'date-fns';
import { DATE_FORMAT } from 'commonConstants';
import {
  DialogWithComponent,
  RequestConfig,
  STMCell,
} from '../../../types.Component';
import { OptionsType } from '../../../types.Field';

type Props = {
  requestConfig: RequestConfig;
  params: any;
};

const workTypeOptions = [
  { value: '184ec504-7bf7-47b8-9293-8cda494309c8', label: '0' },
  { value: 'a9e7189e-9b5a-46bd-b1de-edd51df6a42d', label: '1' },
  { value: '0791cf2b-321c-479c-8233-ff9a2bced8ee', label: '2' },
  { value: 'eeb1c377-3bbf-4bbb-8337-e6bf8f9ef39d', label: '3' },
  { value: 'c1eebeee-2d8d-4274-96c3-797468762931', label: '4' },
];

const statusOptions = [
  { value: 'f5ec6671-863f-48bc-bca3-1f3ef73242dc', label: 'Запланировано' },
  { value: '41d0b078-c20a-405f-8173-2647de4f2b4e', label: 'В работе' },
  { value: '0851eaf4-ec82-4ea1-83ca-edb7bbf6ffd4', label: 'Отменено' },
  { value: '90976ba8-a78d-49fb-acd7-d5852a4a63c9', label: 'Выполнено' },
];

const counterpartyOptions = [
  { value: 'f90b3ad7-3a27-417f-99d0-f6aeb4b78db7', label: 'БДФ-Восток, 000' },
  { value: '518937a8-f36d-48bc-94bf-0da5926ee383', label: 'CТЕП-Сервис, 000' },
  { value: 'f88cc33c-4817-442a-9fc6-2c49b5d3a4d1', label: 'He определено' },
];

const assigneeOptions = [
  { value: 'd880544e-e319-4e0b-9bee-d8c29b0c6422', label: 'Игорь Петров' },
  { value: '4e05eb00-a729-4e2f-bbe8-5e05a2e0735e', label: 'Валентин Степанов' },
  { value: '23338c64-6e72-46e5-8bc3-bef1f0579765', label: 'Артём Акимов' },
  { value: '2c613bec-51cb-4903-9ace-8316abe2c5b7', label: 'Игнатьев Савелий' },
  { value: '4cd4afed-7681-4ea8-9069-28db20cd8a8f', label: 'Громов Николай' },
  { value: '7a973b18-9de0-4070-bb63-149ecd6d3334', label: 'Логинов Олег' },
  { value: 'e0024a45-8fbc-49e2-8b03-ce1f4967c98e', label: 'Щукин Валерий' },
  { value: 'f26f7a73-bad7-412d-8fa0-301df136b4fb', label: 'Милонов Петр' },
  {
    value: 'f0c58b0e-0747-4614-8d60-6e40f3f21463',
    label: 'Рогов Владислав',
  },
  { value: 'd86bb070-47dd-4c25-b6c6-153b7baf2589', label: 'Бренчалов Федор' },
];

export const getEditCellV2 = ({
  requestConfig,
  params,
}: Props): DialogWithComponent => {
  const chips: STMCell[] = params?.row[params?.column?.name];
  const value = params.STMCell as STMCell;
  const chipIndex = chips.findIndex((chip) => chip === value);

  const status = value?.tooltip?.content.find(
    ({ title }) => title === 'Статус'
  );
  const worker = value?.tooltip?.content.find(
    ({ title }) => title === 'Исполнитель'
  );
  const planedDate = value?.tooltip?.content.find(
    ({ title }) => title === 'Плановая дата'
  );

  return {
    viewStyle: 'modal',
    component: {
      id: 100,
      businessComponentId: 53,
      type: 'form',
      props: {
        actions: [
          {
            type: 'closeDialog',
            label: 'Закрыть',
          },
          {
            type: 'submitForm',
            label: 'Сохранить',
            variant: 'contained',
            color: 'primary',
            requestConfig: {
              url: `/api/complex/v2-calendar-stm/edit-cell&row-id=${
                params.row.id
              }&column-name=${params.column.name}&smt-type=${
                requestConfig.url.includes('monthly') ? 'monthly' : 'year'
              }&index=${chipIndex}`,
              method: 'POST',
            },
            successResponseReaction: { type: 'reLoadCells' },
          },
        ],
        actionsYPosition: 'bottom',
        form: {
          type: 'markup',
          header: {
            heading: `Редактирование ${params?.column?.title || 'ячейки'}`,
          },
          fieldGroups: [
            {
              fields: [
                {
                  type: 'comboBox',
                  optionsType: OptionsType.flat,
                  optionsLoader: 'sync',
                  multiple: false,
                  name: 'workType',
                  label: 'Тип обслуживания',
                  options: [],
                  defaultValue: workTypeOptions.find(
                    (option) => option.label === value?.label
                  ),
                  disabled: true,
                },
                {
                  type: 'comboBox',
                  optionsType: OptionsType.flat,
                  optionsLoader: 'sync',
                  multiple: false,
                  name: 'status',
                  label: 'Статус',
                  options: [],
                  defaultValue: statusOptions.find(
                    (option) => option.label === status?.text
                  ),
                  disabled: true,
                },
                {
                  type: 'comboBox',
                  optionsType: OptionsType.flat,
                  optionsLoader: 'sync',
                  multiple: false,
                  name: 'serviceOrganization',
                  label: 'Обслуживающая организация',
                  options: counterpartyOptions,
                  defaultValue:
                    counterpartyOptions.find(
                      (option) =>
                        option.label === params?.row?.counterparty?.label
                    ) || counterpartyOptions[0],
                  disabled: true,
                },
                {
                  type: 'comboBox',
                  optionsType: OptionsType.flat,
                  optionsLoader: 'sync',
                  multiple: false,
                  name: 'assignee',
                  label: 'Исполнитель',
                  options: assigneeOptions,
                  defaultValue: assigneeOptions.find(
                    (option) => option.label === worker?.text
                  ),
                },
                {
                  type: 'datePicker',
                  name: 'planedDate',
                  label: 'Плановая дата',
                  pickerType: 'date',
                  defaultValue: planedDate?.text
                    ? formatISO(parse(planedDate.text, DATE_FORMAT, new Date()))
                    : undefined,
                  initialFocusedDate: planedDate?.text
                    ? formatISO(parse(planedDate.text, DATE_FORMAT, new Date()))
                    : undefined,
                  disabled: requestConfig.url.includes('year'),
                  minDate: requestConfig.url.includes('monthly')
                    ? formatISO(startOfMonth(addMonths(new Date(), 1)))
                    : undefined,
                  maxDate: requestConfig.url.includes('monthly')
                    ? formatISO(endOfMonth(addMonths(new Date(), 1)))
                    : undefined,
                },
              ],
            },
          ],
        },
      },
    },
  };
};
