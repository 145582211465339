import { Page } from 'services/Main/types.Page';
import { OptionsType } from '../../../types.Field';

const TITLE = 'Форма со скрытыми группами';

export const hideFieldGroupsFormPage: Page = {
  type: 'singleComponent',
  title: TITLE,
  component: {
    id: 100,
    businessComponentId: 23,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          color: 'primary',
          variant: 'contained',
          label: 'Отправить',
          icon: 'mui:Check',
          requestConfig: {
            url: '/api/fields/select',
            method: 'POST',
          },
          successResponseReaction: {
            type: 'reLoadModule',
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: TITLE },
        fieldGroups: [
          {
            label: 'Пример с some. Срабатывает на Химки и Самару',
            fields: [
              {
                type: 'comboBox',
                optionsType: OptionsType.flat,
                name: 'field17',
                label: 'Магазин',
                multiple: false,
                options: [
                  {
                    value: '1',
                    label: 'Красногорск',
                  },
                  {
                    value: '2',
                    label: 'Химки',
                  },
                  {
                    value: '3',
                    label: 'Самара',
                  },
                  {
                    value: '4',
                    label: 'Ривьера',
                  },
                ],
              },
              {
                type: 'text',
                name: 'field18',
                label: 'Комментарий',
                renderCondition: {
                  dependOn: 'field17',
                  condition: 'some',
                  value: ['2', '3'],
                },
              },
            ],
          },
          {
            label: 'Пример с пустой группой 1 (подписка)',
            fields: [
              {
                type: 'text',
                name: 'field19',
                label: 'Комментарий',
                renderCondition: {
                  dependOn: 'field17',
                  condition: 'some',
                  value: ['2', '3'],
                },
              },
            ],
          },
          {
            label: 'Пример с пустой группой 2 (просто пусто)',
            fields: [],
          },
        ],
      },
    },
  },
};
