import React, { useContext } from 'react';
import { Typography, Box } from '@material-ui/core';
import { TableColumnVisibility as TableColumnVisibilityBase } from '@devexpress/dx-react-grid';
import { useFormatMessage } from '../../../../../../../locale';
import useStyles from '../../EntryPickerTable.styles';
import EntryPickerTableContext from '../../EntryPickerTable.context';

const EntryPickerTableColumnVisibilityMessage = ({
  getMessage,
}: TableColumnVisibilityBase.EmptyMessageProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const { resetColumnVisibilityToDefault } = useContext(
    EntryPickerTableContext
  );

  const noColumnsMessage = getMessage('noColumns');

  const handleResetColumnVisibilityToDefaultClick = () => {
    resetColumnVisibilityToDefault();
  };

  return (
    <Box className={classes.messageContainer}>
      <Typography>{noColumnsMessage}</Typography>
      <u
        className={classes.pseudoLink}
        onMouseDown={handleResetColumnVisibilityToDefaultClick}
      >
        {formatMessage('resetColumnVisibilityToDefault')}
      </u>
    </Box>
  );
};

export default EntryPickerTableColumnVisibilityMessage;
