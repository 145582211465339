import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../../../theme';

export default makeStyles<Theme>(() =>
  createStyles({
    inputRoot: {
      '&[class*="MuiInput-root"]': {
        paddingTop: 5,
        paddingBottom: 6,
        '& $input, $input:first-child': {
          // Дефолтный padding для input[type="text" i]
          padding: '1px 2px',
        },
      },
    },

    // Отключает minWidth: 30 при disableClearable
    '&.disableClearable $input': {
      minWidth: 0,
    },
    /* Styles applied to the input element. */
    input: {},
    root: {
      // Убирает в disabled представление резервирование места под иконки развернуть и очистить поле.
      '&.disabled': {
        '& .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot':
          {
            paddingRight: 0,
          },
        // Отключает minWidth: 30 при disabled
        '& $input': {
          minWidth: 0,
        },
      },
    },
    option: {
      '&:has(.checkAllButton)': {
        background: 'none !important',
      },
    },
  })
);
