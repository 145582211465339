import faker from 'faker';
import { Page } from 'services/Main/types.Page';

faker.locale = 'ru';

const createRow = () => {
  const rowId = faker.datatype.uuid();
  return {
    id: rowId,
    code: `${faker.datatype.number({
      min: 1000,
      max: 9999,
    })}-${faker.datatype.number({
      min: 100,
      max: 999,
    })}/${faker.datatype.number({
      min: 10,
      max: 99,
    })}`,
    type: faker.lorem.word(),
    author: `${faker.name.lastName()} ${faker.name.firstName()}`,
    actions: [
      // <-------- !!!!!!!!
      {
        label: 'Обновить',
        variant: 'contained',
        type: 'triggerEndpoint',
        requestConfig: {
          url: `/api/table/update-row/${rowId}`,
        },
        icon: 'mui:Sync',
        // из ответа сервера забрать новые данные для row
        successResponseReaction: {
          type: 'reLoadCells',
        },
      },
    ],
  };
};

export const allSimpleTablePageRows = Array.from({ length: 5 }, createRow);

export const tableWithRowActionsPage: Page = {
  type: 'singleComponent',
  title: 'Таблица с rowActions',
  component: {
    id: 404442,
    businessComponentId: 31230,
    type: 'table',
    props: {
      heading: 'Таблица с rowActions',
      requestConfig: { url: '/api/table/simple' },
      columns: [
        { title: ' ', name: 'actions', options: { formatAs: 'actions' } }, // <-------- !!!!!!!!
        { title: 'Номер', name: 'code' },
        { title: 'Тип', name: 'type' },
        { title: 'Инициатор', name: 'author' },
      ],
      rows: allSimpleTablePageRows.slice(0, 5),
      options: {
        totalRows: allSimpleTablePageRows.length,
        currentPage: 0,
        pageSize: 20,
      },
    },
  },
};
