import faker from 'faker';
import { Page, Tab } from 'services/Main/types.Page';

const Tab1: Tab = {
  label: 'Таб с формой',
  component: {
    id: 300,
    businessComponentId: 44,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        disabled: true,
        header: {
          heading: 'Заголовок формы',
        },
        fieldGroups: [
          {
            label: 'Группа полей 1',
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
                defaultValue: 'Какое-то дефолтное значение',
              },
            ],
          },
          {
            label: 'Группа полей 2',
            fields: [
              {
                type: 'text',
                name: 'bar',
                label: 'Эмоции',
                defaultValue: '🤪🤯😱',
              },
            ],
          },
        ],
      },
    },
  },
};

const createRow = () => ({
  id: faker.datatype.uuid(),
  createdAt: faker.date.past().toISOString(),
  type: faker.lorem.word(),
  comment: faker.lorem.words(6),
  status: faker.lorem.word(),
});

export const allTabsViewRows = Array.from({ length: 2 }, createRow);

const Tab2: Tab = {
  label: 'Таб с таблицей',
  component: {
    id: 404,
    businessComponentId: 23,
    type: 'table',
    props: {
      heading: 'Заголовок таблицы',
      requestConfig: {
        url: '/page-views/tabs',
      },
      columns: [
        {
          title: 'Создано',
          name: 'createdAt',
          options: {
            formatAs: 'dateTime',
          },
        },
        {
          title: 'Тип задания',
          name: 'type',
        },
        { title: 'Тема', name: 'comment' },
        {
          title: 'Статус',
          name: 'status',
        },
      ],
      rows: allTabsViewRows,
      options: {
        totalRows: allTabsViewRows.length,
        currentPage: 0,
        pageSize: 20,
      },
    },
  },
};

export const tabsViewExamplePage: Page = {
  type: 'tabsView',
  tabs: [Tab1, Tab2],
  title: 'Пример страницы с табами',
};
