import React from 'react';
import { BarChartWidgetProps, HiddenBarKeys } from './types';
import VerticalBarChartWidget from './VerticalBarChartWidget';
import HorizontalBarChartWidget from './HorizontalBarChartWidget';

export default (props: BarChartWidgetProps & HiddenBarKeys) => {
  const { orientation } = props;

  if (orientation === 'vertical') return <VerticalBarChartWidget {...props} />;

  return <HorizontalBarChartWidget {...props} />;
};
