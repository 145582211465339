import React from 'react';
import useStyles from './SystemTimelineElement.styles';
import { SystemTimelineElement } from '../../../../../../services/Main/types.Component';
import Bubble from '../../Bubble';
import RobotFace from '../../../../../../svg/RobotFace';
import MessageBody from '../../MessageBody';
import MessageAttachments from '../../MessageAttachments';
import MessageInfo from '../../MessageInfo';
import Avatar from '../../Avatar';
import MessageHeader from '../../MessageHeader';
import MessageContent from '../../MessageContent';
import MessageQuote from '../../MessageQuote';
import getAuthorColor from '../helpers/getAuthorColor';
import getAuthorFullName from '../helpers/getAuthorFullName';

export default (props: SystemTimelineElement) => {
  const classes = useStyles();

  const { body, attachments, isPrivate, createdAt, quote } = props;

  const color = getAuthorColor(props);
  const authorFullName = getAuthorFullName(props);

  return (
    <Bubble side="left" className={classes.bubble}>
      <MessageContent>
        <MessageHeader
          avatar={
            <Avatar color={color}>
              <RobotFace />
            </Avatar>
          }
          color={color}
          title={authorFullName}
          subheader={
            <MessageInfo isPrivate={isPrivate} createdAt={createdAt} />
          }
        />
        {quote && <MessageQuote {...quote} />}
        <MessageBody html={body.html} plainText={body.plainText} />
      </MessageContent>
      <MessageAttachments attachments={attachments} />
    </Bubble>
  );
};
