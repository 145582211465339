import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    card: {
      background: 'linear-gradient(#ffffff, #ffffff 45%,#f0f0f0 75%, #f0f0f0)',
    },
    title: {
      fontSize: theme.typography.pxToRem(18),
      color: '#334D6E',
      padding: theme.spacing(2),
      fontWeight: 550,
      textAlign: 'center',
    },
    marginBottom: {
      marginBottom: theme.spacing(4),
    },
    content: {
      padding: theme.spacing(2, 6),
    },
    infoTextHeader: {
      fontWeight: 'bold',
      color: '#90A0B7',
      marginBottom: theme.spacing(2),
    },
    infoText: {
      color: '#90A0B7',
    },
    alertText: {
      padding: theme.spacing(2),
      color: '#90A0B7',
      backgroundColor: 'rgba(144,160,183,0.15)',
    },
    alertTitle: {
      background: 'transparent',
      justifyContent: 'center',
    },
  })
);
