import React from 'react';
import { styled } from '@material-ui/core/styles';
import { TableCell, Checkbox } from '@material-ui/core';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import clsx from 'clsx';
import { TableSelection as TableSelectionBase } from '@devexpress/dx-react-grid';

const PREFIX = 'TableSelectAllCell';
export const classes = {
  cell: `${PREFIX}-cell`,
  checkbox: `${PREFIX}-checkbox`,
  alignWithRowSpan: `${PREFIX}-alignWithRowSpan`,
  pointer: `${PREFIX}-pointer`,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${classes.cell}`]: {
    overflow: 'visible',
    paddingRight: 0,
    paddingLeft: theme.spacing(1),
    textAlign: 'center',
  },
  [`&.${classes.pointer}`]: {
    cursor: 'pointer',
  },
  [`&.${classes.alignWithRowSpan}`]: {
    verticalAlign: 'bottom',
    paddingBottom: theme.spacing(0.5),
  },
  [`& .${classes.checkbox}`]: {
    padding: theme.spacing(1),
  },
}));

const TableSelectAllCell = ({
  allSelected,
  someSelected,
  disabled,
  onToggle,
  className,
  tableRow,
  tableColumn,
  rowSpan,
  forwardedRef,
  ...restProps
}: TableSelectionBase.HeaderCellProps & any) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;

    e.stopPropagation();
    onToggle();
  };

  return (
    <StyledTableCell
      padding="checkbox"
      className={clsx(className, {
        [classes.cell]: true,
        [classes.pointer]: !disabled,
        [classes.alignWithRowSpan]: rowSpan > 1,
      })}
      rowSpan={rowSpan}
      ref={forwardedRef}
      {...restProps}
    >
      <Checkbox
        checked={allSelected}
        className={classes.checkbox}
        indeterminate={someSelected}
        indeterminateIcon={<IndeterminateCheckBoxIcon color="primary" />}
        disabled={disabled}
        color="primary"
        onClick={handleClick}
      />
    </StyledTableCell>
  );
};

export default TableSelectAllCell;
