import { Page } from 'services/Main/types.Page';

export const groupOfTextWidgetPage: Page = {
  type: 'grid',
  title: 'Страница с текстовыми виджетами',
  header: { heading: 'Страница с текстовыми виджетами' },
  markup: [
    {
      lgCols: 8,
      component: {
        id: 100,
        businessComponentId: 50,
        type: 'groupOfTextWidget',
        props: [
          {
            title: 'Закрыто',
            requestConfig: {
              url: '/api/widgets/text-widget/100101',
              method: 'GET',
            },
          },
          {
            title: 'В работе',
            requestConfig: {
              url: '/api/widgets/text-widget/100102',
              method: 'GET',
            },
          },
          {
            title: 'Закрыто',
            requestConfig: {
              url: '/api/widgets/text-widget/100103',
              method: 'GET',
            },
          },
          {
            title: 'Закрыто',
            requestConfig: {
              url: '/api/widgets/text-widget/100104',
              method: 'GET',
            },
          },
          {
            title: 'С ошибкой',
            requestConfig: {
              url: '/api/widgets/text-widget/error',
              method: 'GET',
            },
          },
          {
            title: 'Без данных',
            requestConfig: {
              url: '/api/widgets/text-widget/empty',
              method: 'GET',
            },
          },
        ],
      },
    },
    {
      lgCols: 4,
      component: {
        id: 100,
        businessComponentId: 23,
        type: 'groupOfTextWidget',
        props: [
          {
            title: 'В работе',
            requestConfig: {
              url: '/api/widgets/text-widget/100105',
              method: 'GET',
            },
          },
          {
            title: 'Закрыто',
            requestConfig: {
              url: '/api/widgets/text-widget/100106',
              method: 'GET',
            },
          },
          {
            title: 'В работе',
            requestConfig: {
              url: '/api/widgets/text-widget/100107',
              method: 'GET',
            },
          },
          {
            title: 'Закрыто',
            requestConfig: {
              url: '/api/widgets/text-widget/100108',
              method: 'GET',
            },
          },
        ],
      },
    },
    {
      component: {
        id: 100,
        businessComponentId: 23,
        type: 'groupOfTextWidget',
        props: [
          {
            title: 'В работе',
            requestConfig: {
              url: '/api/widgets/text-widget/100109',
              method: 'GET',
            },
          },
          {
            title: 'Закрыто',
            requestConfig: {
              url: '/api/widgets/text-widget/100110',
              method: 'GET',
            },
          },
          {
            title: 'Закрыто',
            requestConfig: {
              url: '/api/widgets/text-widget/100111',
              method: 'GET',
            },
          },
          {
            title: 'Закрыто',
            requestConfig: {
              url: '/api/widgets/text-widget/100112',
              method: 'GET',
            },
          },
        ],
      },
    },
  ],
};
