import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import mainService from 'services/Main';
import { mainMenuActions } from 'store/mainMenu';
import { routesActions } from 'store/routes';
import { userActions } from 'store/user';
import authService from '../../services/Auth';
import { UnexpectedDataError } from '../errors/UnexpectedDataError';
import { metaActions } from '../../store/meta';
import { versionActions } from '../../store/version';
import GlobalSocketService from '../../services/GlobalSocket';
import { PASSWORD_CLIENT_ROUTE } from '../../services/Password/Password.constants';

const passwordClientRoutes = Object.values(PASSWORD_CLIENT_ROUTE);

export const useInitialAppData = () => {
  const dispatch = useDispatch();
  const [uniqueName, setUniqueName] = useState(authService.uniqueName);
  const [globalSocketConnection, setGlobalSocketConnection] =
    useState<GlobalSocketService | null>(null);
  const unsubscribe = useMemo(
    () =>
      authService.subscribe(({ uniqueName: userUniqueName }: any) => {
        setUniqueName(userUniqueName);
      }),
    []
  );

  useEffect(() => {
    // TODO переписать весь workflow загрузки приложения.
    if (
      !(
        window.location.pathname.includes('login') ||
        window.location.pathname.includes('password')
      )
    ) {
      dispatch(metaActions.showLoadingScreen());
    }

    const unsubscribeCallback = () => {
      unsubscribe();
    };

    // Здесь начинается workflow авторизации
    if (!authService.isAuthenticated()) {
      if (passwordClientRoutes.includes(window.location.pathname)) {
        return unsubscribeCallback;
      }

      authService.processLogin();
    } else {
      mainService
        .fetchRootData()
        .then((rootResponse) => {
          dispatch(mainMenuActions.set(rootResponse.mainMenu));
          dispatch(routesActions.set(rootResponse.routes));
          dispatch(userActions.set(rootResponse.user));
          dispatch(versionActions.set(rootResponse.keepUpVersion));
          dispatch(metaActions.hideLoadingScreen());
          setGlobalSocketConnection(
            new GlobalSocketService(rootResponse.globalSocketConfig)
          );
        })
        .catch(() => {
          throw new UnexpectedDataError(
            'При попытке получить основные данные для приложения произошла ошибка.'
          );
        });
    }

    return unsubscribeCallback;
  }, [dispatch, uniqueName, unsubscribe]);

  return { globalSocketConnection };
};
