import React, { PropsWithChildren, useContext } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { TableCell } from '../../../../../../services/Main/types.Component';
import TableDragAndDropCellContext from '../TableCellDragAndDrop.context';

interface DraggableItemProps {
  id: string;
  rowId: string;
  columnName: string;
  value: TableCell;
}

const DraggableItem = ({
  id,
  children,
  value,
  rowId,
  columnName,
}: PropsWithChildren<DraggableItemProps>) => {
  const tableDragAndDropCellContext = useContext(TableDragAndDropCellContext);

  const { attributes, listeners, setNodeRef, isDragging, active } =
    useDraggable({
      id,
      data: {
        rowId,
        columnName,
        element: children,
        value,
      },
      disabled: tableDragAndDropCellContext?.loadingCellIds.includes(id),
    });

  const style = {
    opacity:
      (isDragging && active?.id === id) ||
      tableDragAndDropCellContext?.loadingCellIds.includes(id)
        ? 0.5
        : undefined,
    zIndex: 2,
  };

  return (
    <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
      {children}
    </div>
  );
};

export default DraggableItem;
