import { useContext } from 'react';
import { ConfirmDialog } from '../../../services/Main/types.Component';
import { DialogAlertContext } from '../DialogAlert/DialogAlert';
import { useFormatMessage } from '../../../locale';

export function useShowConfirmDialog() {
  const formatMessages = useFormatMessage();
  const { setDialogState } = useContext(DialogAlertContext);

  return (confirmDialogConfig: ConfirmDialog) =>
    new Promise((resolve) => {
      setDialogState({
        heading: formatMessages('confirmAction'),
        body: confirmDialogConfig.body,
        actions: [
          {
            label: formatMessages('confirm'),
            onClick: () => {
              setDialogState(null);
              resolve();
            },
          },
        ],
      });
    });
}
