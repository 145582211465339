import React from 'react';
import DialogFormAction from '../FormAction/DialogFormAction';
import { useHandleClickAction } from './handlers';
import { TableActionProps } from './types';
import {
  ShowComponentAction,
  ShowFormAction,
  CloseDialogAction as CloseDialogActionProps,
  ShowHistoryAction,
} from '../../../services/Main/types.Component';
import DialogComponentAction from '../FormAction/DialogComponentAction';
import CloseDialogAction from '../CloseDialogAction';
import FormHistory from '../FormHistory';

export default (props: TableActionProps) => {
  const { action, onReactionExecute, renderAction, onClick } = props;
  const { type, label, color, icon, variant, ...rest } = action;

  const handleClickAction = useHandleClickAction({
    type,
    onReactionExecute,
    ...rest,
  });

  const handleClick = () => {
    onClick && onClick();
    handleClickAction();
  };

  // Показать форму, со своими экшнами в модалке, выполнить реакцию по сабмиту.
  if (type === 'showForm') {
    return (
      <DialogFormAction
        renderButton={renderAction}
        action={action as ShowFormAction}
      />
    );
  }

  if (type === 'closeDialog') {
    return (
      <CloseDialogAction
        action={action as CloseDialogActionProps}
        renderButton={renderAction}
      />
    );
  }

  if (type === 'showComponent') {
    return (
      <DialogComponentAction
        renderButton={renderAction}
        action={action as ShowComponentAction}
      />
    );
  }

  if (type === 'showHistory') {
    return (
      <FormHistory
        action={action as ShowHistoryAction}
        onClick={onClick}
        renderButton={renderAction}
      />
    );
  }

  return renderAction({
    action,
    onClick: handleClick,
  });
};
