import React from 'react';
import {
  Select,
  MultiSelect as MultiSelectProps,
  SingleSelect as SingleSelectProps,
  AsyncSelect as AsyncSelectProps,
  AsyncMultiSelect as AsyncMultiSelectProps,
} from 'services/Main/types.Field';
import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';
import AsyncSingleSelect from './AsyncSingleSelect';
import AsyncMultiSelect from './AsyncMultiSelect';

export default ({ selectType, optionsLoader = 'sync', ...rest }: Select) => {
  switch (`${selectType}_${optionsLoader}`) {
    case 'multi_sync':
      return <MultiSelect {...(rest as MultiSelectProps)} />;
    case 'single_sync':
      return <SingleSelect {...(rest as SingleSelectProps)} />;
    case 'single_async':
      return <AsyncSingleSelect {...(rest as AsyncSelectProps)} />;
    case 'multi_async':
      return <AsyncMultiSelect {...(rest as AsyncMultiSelectProps)} />;
    default:
      throw new Error('Не удалось опредилить тип Select-компонента');
  }
};
