import faker from 'faker';
import {
  ChipWithValueAndLabel,
  DialogWithComponent,
  RequestConfig,
} from '../../../types.Component';
import { OptionsType } from '../../../types.Field';
import { createAlert } from '../highLevelComponents/Form/WithAlerts';

type Props = {
  requestConfig: RequestConfig;
  params: any;
};

export const metricOptions = [
  { value: 'f90b3ad7-3a27-417f-99d0-f6aeb4b78db7', label: 'шт.' },
  { value: '518937a8-f36d-48bc-94bf-0da5926ee383', label: 'кип' },
  { value: 'f88cc33c-4817-442a-9fc6-2c49b5d3a4d1', label: 'т.' },
  { value: 'a12ad33c-23a7-423b-9fc6-2c49asd3a4d1', label: 'килограмм' },
];

export const getEditCell = ({ params }: Props): DialogWithComponent => {
  const cell: ChipWithValueAndLabel | null = params?.row[params?.column?.name];

  return {
    viewStyle: 'modal',
    component: {
      id: 100,
      businessComponentId: 53,
      type: 'form',
      props: {
        alerts: Array.from({ length: faker.datatype.number(1) }, createAlert),
        actions: [
          {
            type: 'closeDialog',
            label: 'Закрыть',
          },
          {
            type: 'submitForm',
            label: 'Сохранить',
            variant: 'contained',
            color: 'primary',
            requestConfig: {
              url: `/api/complex/chip-value-metric/edit-cell&row-id=${params.row.id}&column-name=${params.column.name}`,
              method: 'POST',
            },
            successResponseReaction: { type: 'reLoadCells' },
          },
        ],
        actionsYPosition: 'bottom',
        form: {
          type: 'markup',
          header: {
            heading: `Редактирование ${params?.column?.title || 'ячейки'}`,
          },
          fieldGroups: [
            {
              fields: [
                {
                  type: 'formattedNumber',
                  label: 'Значение',
                  name: 'value',
                  formattedNumberType: 'flexibleNumber',
                  allowNegative: false,
                  decimalScale: 3,
                  thousandSeparator: ' ',
                  decimalSeparator: ',',
                  defaultValue: cell ? cell.value : undefined,
                  validationConfig: {
                    validationType: 'number',
                    validations: [
                      {
                        type: 'required',
                        params: ['Обязательное поле!'],
                      },
                      {
                        type: 'min',
                        params: [0, 'Минимальное значение 0'],
                      },
                    ],
                  },
                },
                {
                  type: 'comboBox',
                  optionsType: OptionsType.flat,
                  optionsLoader: 'sync',
                  multiple: false,
                  name: 'metric',
                  label: 'Единицы измерения',
                  options: metricOptions,
                  defaultValue: cell
                    ? metricOptions.find((o) => o.label === cell.label)
                    : undefined,
                  validationConfig: {
                    validationType: 'mixed',
                    validations: [
                      {
                        type: 'required',
                        params: ['Обязательное поле!'],
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      },
    },
  };
};
