import { Page } from 'services/Main/types.Page';
import { Checkbox } from 'services/Main/types.Field';
import { simpleText } from './TextField';

export const simpleCheckbox: Checkbox = {
  type: 'checkbox',
  name: 'checkbox1',
  label: 'Простой checkbox',
};

export const checkboxFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами Checkbox полей',
  component: {
    id: 100,
    businessComponentId: 14,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами Checkbox полей' },
        fieldGroups: [
          {
            label: 'Checkbox',
            fields: [
              simpleCheckbox,
              {
                type: 'checkbox',
                name: 'checkbox2',
                label: 'Error color',
                labelPlacement: 'end',
                defaultValue: true,
                highlightColor: 'error',
              },
              {
                type: 'checkbox',
                name: 'checkbox3',
                label: 'Info color',
                defaultValue: false,
                highlightColor: 'info',
              },
              {
                type: 'checkbox',
                name: 'checkbox4',
                label: 'Disabled checkbox',
                labelPlacement: 'top',
                disabled: true,
              },
              {
                type: 'checkbox',
                name: 'checkbox5',
                label: 'Disabled checkbox',
                labelPlacement: 'top',
                defaultValue: true,
                disabled: true,
              },
              { ...simpleText, mdCols: 8 },
              {
                type: 'checkbox',
                name: 'checkbox6',
                label: 'mdCols: 2',
                labelPlacement: 'top',
                highlightColor: 'success',
                mdCols: 2,
              },
              {
                type: 'checkbox',
                name: 'checkbox6',
                label: 'mdCols: 2',
                labelPlacement: 'top',
                highlightColor: 'warning',
                mdCols: 2,
              },
            ],
          },
        ],
      },
    },
  },
};
