const faker = require('faker');

const BREAK_ORDER_CHANCE = 0; // percent
const CHANCE_TO_GENERATE_MESSAGE_WITH_PRIVACY = 0; // percent
const TABLE_GENERATION_CHANCE = 50; // percent
const WITHOUT_USER_CHANCE = 40; // percent
let messageCount = {
  current: 0,
  next: 1,
  broken: false,
};
export const currentUser = generateUser();
export const otherUser1 = generateUser();
export const otherUser2 = generateUser();
export const privacyOptions = [
  { value: 'ac856f98-f56e-4570-b725-986791d5a982', label: 'Всем' },
  {
    value: '9e661560-4690-480b-9659-98977a1fb03e',
    label: 'Сотрудникам контрагента',
  },
  { value: 'eba4d166-d0ed-4b92-996c-35bea0f3999a', label: 'Сотрудникам Ашана' },
];

function Timeline() {
  let list = [];
  const subscribers = [];

  return {
    get: () => list,
    clear: () => {
      list = [];
    },
    generate: (type, isAddedManually, user) => {
      let element;

      switch (type) {
        case 'currentUser':
          element = generateCurrentUserTimelineElement(isAddedManually);
          break;
        case 'otherUser':
          element = generateOtherTimelineElement(isAddedManually, user);
          break;
        case 'system':
        default:
          element = generateSystemTimelineElement();
          break;
      }

      list = [...list, element];

      return element;
    },
    push: (value) => {
      value.sort = list[list.length - 1].sort + 1;
      value.type = 'currentUser';
      value.isAddedManually = true;
      value.id = faker.datatype.uuid();
      value.body = {
        html: value.comment,
        plainText: value.comment,
      };
      value.createdAt = new Date();
      value.privacy =
        value.privacy && value.privacy.value !== privacyOptions[0].value
          ? value.privacy
          : undefined;

      list = [...list, value];

      subscribers.forEach((cb) => {
        cb(value);
      });
      return list;
    },
    seedInitialMessagesForUser: async (user) => {
      list = [
        generateOtherTimelineElement(false, otherUser1),
        generateOtherTimelineElement(false, otherUser1),
        generateOtherTimelineElement(false, otherUser1),
        generateOtherTimelineElement(false, otherUser1),
        generateOtherTimelineElement(false, otherUser1),
        generateOtherTimelineElement(false, otherUser1),
      ];

      list = [
        ...list,
        generateOtherTimelineElement(false, otherUser1),
        generateOtherTimelineElement(false, otherUser1),
      ];
      return list;
    },
    subscribeOnPushNewElement: (cb) => {
      subscribers.push(cb);
    },
  };
}

export const formHistoryGenerator = new Timeline();

function generateSystemTimelineElement() {
  return {
    ...generateBaseTimelineElement('system', false),
    type: 'system',
  };
}

function generateOtherTimelineElement(isAddedManually, author) {
  const element = {
    ...generateAdvancedTimelineElement('otherUser', isAddedManually, author),
    type: 'otherUser',
    header: `${author.lastName} ${author.firstName}`,
  };

  const withoutUser = Math.random() * 100 < WITHOUT_USER_CHANCE;

  if (withoutUser) {
    element.header = undefined;
    element.author = undefined;
  }

  return element;
}

function generateCurrentUserTimelineElement(isAddedManually) {
  return {
    ...generateAdvancedTimelineElement(
      'currentUser',
      isAddedManually,
      currentUser
    ),
    type: 'currentUser',
  };
}

function generateAdvancedTimelineElement(type, isAddedManually, author) {
  return {
    ...generateBaseTimelineElement(type, isAddedManually),
    isAddedManually,
    author,
  };
}

function generateBaseTimelineElement(type, isAddedManually) {
  return {
    id: faker.datatype.uuid(),
    sort: getNextTimelineElementOrder(),
    body: generateTimelineElementBody(type, isAddedManually),
    createdAt: faker.date.past().toISOString(),
    quote: generateQuote(),
    // attachments: generateFiles(),
    privacy: generatePrivacy(),
  };
}

function getNextTimelineElementOrder() {
  const shouldBreak = Math.random() * 100 <= BREAK_ORDER_CHANCE;

  if (shouldBreak && !messageCount.broken) {
    messageCount = {
      current: messageCount.next + 1,
      next: messageCount.next,
      broken: true,
    };
  } else {
    if (messageCount.broken) {
      messageCount = {
        current: messageCount.next,
        next: messageCount.next + 2,
        broken: false,
      };
    } else {
      messageCount = {
        current: messageCount.next,
        next: messageCount.next + 1,
        broken: false,
      };
    }
  }

  return messageCount.current;
}

function generateTimelineElementBody(type, isAddedManually) {
  if (isAddedManually) return generateManuallyAddedBody();

  switch (type) {
    case 'currentUser':
    case 'otherUser':
      return generateAdvancedBody();
    case 'system':
    default:
      return generateSystemBody();
  }
}

function generateAdvancedBody() {
  const data = {
    task: faker.lorem.words(3),
    result: faker.lorem.words(3),
  };

  const formattedText = [
    {
      text: 'Task:',
      bold: true,
      spacingRight: 0.5,
    },
    {
      text: data.task,
      shouldBreakAtEnd: true,
    },
    {
      text: 'Result:',
      bold: true,
      spacingRight: 0.5,
    },
    {
      text: data.result,
    },
  ];

  const table = generateTable();

  return {
    html: `<p><b>Task:</b> ${data.task}</p><p><b>Result:</b> ${data.result}</p>${table}`,
    plainText: `Task: ${data.task}. Result: ${data.result}`,
    formattedText,
  };
}

function generateTable() {
  const shouldGenerate = Math.random() * 100 <= TABLE_GENERATION_CHANCE;

  if (!shouldGenerate) return '';

  const rows = [];
  const columns = [];
  const headers = [];

  for (let i = 0; i < Math.random() * 5 + 1; i++) {
    columns.push(faker.lorem.words(3));
    headers.push(faker.lorem.words(3));
  }

  for (let i = 0; i < Math.random() * 5 + 1; i++) {
    const row = [];
    for (let j = 0; j < columns.length; j++) {
      row.push(faker.lorem.words(3));
    }
    rows.push(row);
  }

  return `<table> <tr> ${headers
    .map((header) => `<th>${header}</th>`)
    .join('')} </tr> ${rows
    .map(
      (row) => `<tr> ${row.map((cell) => `<td>${cell}</td>`).join('')} </tr>`
    )
    .join('')} </table>`;
}

function generateSystemBody() {
  const data = {
    href: faker.internet.url(),
    text: faker.lorem.words(5),
  };

  const formattedText = [
    {
      text: data.text,
      href: data.href,
    },
  ];

  return {
    html: `<a href="${data.href}">${data.text}</a>`,
    plainText: data.text,
    formattedText,
  };
}

function generateManuallyAddedBody() {
  const text = Array.from({ length: Math.random() * 3 + 1 })
    .map(() => faker.lorem.words(Math.random() * 30))
    .join('\n');

  return {
    html: text,
    plainText: text,
    formattedText: [{ text }],
  };
}

function generateUser() {
  return {
    id: faker.datatype.uuid(),
    firstName: faker.name.firstName(),
    secondName: faker.name.middleName(),
    lastName: faker.name.lastName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber(),
    position: faker.name.jobTitle(),
    department: faker.commerce.department(),
    organization: faker.company.companyName(),
  };
}

// function generateFiles() {
//   const count = Math.round(Math.random() * 4);
//
//   if (count === 0) return undefined;
//
//   return Array.from({ length: count }).map(generateFile);
// }

// function generateFile() {
//   const fileName = faker.hacker.adjective();
//   const fileExtension = faker.random.arrayElement([
//     'jpg',
//     'docx',
//     'pdf',
//     'png',
//   ]);
//
//   return {
//     id: faker.datatype.uuid(),
//     size: Math.round(Math.random() * 500000),
//     name: `${fileName}.${fileExtension}`,
//     MIMEType: faker.system.mimeType(),
//   };
// }

function generatePrivacy() {
  if (Math.random() * 100 <= CHANCE_TO_GENERATE_MESSAGE_WITH_PRIVACY)
    return faker.random.arrayElement(privacyOptions);

  return undefined;
}

function generateQuote() {
  const list = formHistoryGenerator.get();

  if (!list || list.length === 0) return undefined;
  if (Math.random() * 100 >= 50) return undefined;
  const elementToQuote = faker.random.arrayElement(list);

  return {
    quotedElementId: elementToQuote.id,
    header: elementToQuote.author
      ? `${elementToQuote.author.lastName} ${elementToQuote.author.firstName}`
      : undefined,
    body: elementToQuote.body,
  };
}
