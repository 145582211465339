import faker from 'faker';
import produce from 'immer';
import { Page, SingleComponentPage } from 'services/Main/types.Page';
import {
  CheckboxTreeSelectOption,
  ComboBoxOption,
  OptionsType,
  RadioTreeSelectOption,
  TreeSelectOption,
} from '../../../types.Field';
import { withFiltersTablePage } from '../highLevelComponents/Table/WithFilters';
import { showFormVar1ActionPage } from '../actions/ShowForm.variant1';

faker.locale = 'ru';

export function generateSelectOptions(quantity: number): ComboBoxOption[] {
  const options: ComboBoxOption[] = [];

  for (let i = 0; i < quantity; i += 1) {
    const option: ComboBoxOption = {
      label: faker.random.arrayElement([
        `${faker.commerce.productMaterial()} ${faker.commerce.color()} ${faker.commerce.product()}`,
        `${faker.commerce.color()} ${faker.commerce.product()}`,
        `${faker.commerce.product()}`,
      ]),
      value: faker.datatype.uuid(),
      href: faker.random.word(),
    };

    options.push(option);
  }

  return options;
}

const options = generateSelectOptions(100).sort((a, b) =>
  a.label.localeCompare(b.label)
);

function generateCheckboxTreeOption(
  parentValue: string | null = null
): TreeSelectOption {
  return {
    label: `${faker.commerce.productMaterial()} ${faker.commerce.product()}`,
    parentValue,
    value: faker.datatype.uuid(),
    href: Math.random() > 0.2 ? faker.random.word() : undefined,
  };
}

function generateRadioTreeOption(
  parentValue: string | null = null
): RadioTreeSelectOption {
  return {
    ...generateCheckboxTreeOption(parentValue),
    selectable: Math.random() > 0.2,
  };
}

/**
 * Цель - сгенерировать дерево с N уровней вложенности.
 *
 * Итерации:
 * 1. Генерируем корневой элемент.
 * 2. Сгенерировать ему от 1 до 3 дочерних элементов и указать ему parentValue из п.1.
 * 3. Повторить п.2 до N - 1 раз.
 */

function generateFlatTree(
  depth: number,
  isRadio: boolean = false
): TreeSelectOption[] {
  const generateOption = isRadio
    ? generateRadioTreeOption
    : generateCheckboxTreeOption;
  const root: TreeSelectOption = (() => {
    if (!isRadio) {
      return {
        ...generateOption(),
        isSelectableBranch: faker.datatype.boolean(),
      };
    }

    return generateOption();
  })();

  return [root, ...generateLevel(depth, root.value, isRadio)];
}

function generateLevel(
  depth: number,
  parentValue: string,
  isRadio: boolean = false
): TreeSelectOption[] {
  const generateOption = isRadio
    ? generateRadioTreeOption
    : generateCheckboxTreeOption;
  if (depth === 0) return [];

  const levelCount = faker.datatype.number({ min: depth <= 1 ? 0 : 1, max: 6 });
  const level = Array.from({ length: levelCount }, () =>
    generateOption(parentValue)
  );

  return level.reduce((acc, item) => {
    const subOptions = generateLevel(depth - 1, item.value);

    if (!isRadio && subOptions.length > 0) {
      return [
        {
          ...item,
          isSelectableBranch: faker.datatype.boolean(),
        },
        ...subOptions,
      ];
    }

    return [item, ...acc];
  }, [] as TreeSelectOption[]);
}

const unsortedCheckboxTreeOptions = Array.from(
  {
    length: faker.datatype.number({ min: 1, max: 5 }),
  },
  () => generateFlatTree(faker.datatype.number({ min: 2, max: 3 }))
).flat();

export const checkboxTreeOptions = unsortedCheckboxTreeOptions.sort((a, b) =>
  a.label.localeCompare(b.label)
);

export const radioTreeOptions = Array.from(
  {
    length: faker.datatype.number({ min: 1, max: 5 }),
  },
  () => generateFlatTree(faker.datatype.number({ min: 2, max: 3 }), true)
)
  .flat()
  .sort((a, b) => a.label.localeCompare(b.label));

export const comboBoxFieldFormPage: Page = {
  type: 'tabsView',
  title: 'Форма с вариантами comboBox',
  tabs: [
    {
      label: 'Form',
      component: {
        id: 100,
        businessComponentId: 15,
        type: 'form',
        props: {
          actions: [
            {
              type: 'submitForm',
              color: 'primary',
              variant: 'contained',
              label: 'Отправить',
              icon: 'mui:Check',
              requestConfig: {
                url: '/api/fields/comboBox',
                method: 'POST',
              },
              successResponseReaction: {
                type: 'reLoadModule',
              },
            },
            produce(
              showFormVar1ActionPage as SingleComponentPage,
              (draft: any) => {
                draft.component.props.actions[0].form.fieldGroups[0].fields.push(
                  getExampleComboBox('comboBoxInModal')
                );
              }
              // @ts-ignore
            ).component.props.actions[0],
          ],
          form: {
            type: 'markup',
            header: { heading: 'Форма с вариантами comboBox' },
            fieldGroups: [
              {
                label: 'FlatComboBoxSingle',
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Оборудование (Пустое)',
                    name: 'flatComboBoxSingle',
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Оборудование (Обязательное)',
                    name: 'flatComboBoxSingleRequired',
                    options,
                    validationConfig: {
                      validationType: 'mixed',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Оборудование (С DefaultValue)',
                    name: 'flatComboBoxSingleDefaultValue',
                    defaultValue: options[1],
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    label: 'Оборудование (Disabled)',
                    name: 'flatComboBoxSingleDisabled',
                    options,
                    disabled: true,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    label: 'Оборудование (Disabled с DefaultValue)',
                    name: 'flatComboBoxSingleDisabledDefaultValue',
                    defaultValue: options[2],
                    options,
                    disabled: true,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Оборудование (Без опшинов)',
                    name: 'flatComboBoxSingleNoOptions',
                    options: [],
                  },
                ],
              },
              {
                label: 'FlatComboBoxMultiple',
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    label: 'Список оборудования (Пустое)',
                    name: 'flatComboBoxMultiple',
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    label: 'Список оборудования (Обязательное)',
                    name: 'flatComboBoxMultipleRequired',
                    options,
                    validationConfig: {
                      validationType: 'array',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    label: 'Оборудование (С DefaultValue)',
                    name: 'flatComboBoxMultipleDefaultValue',
                    defaultValue: [options[1], options[2]],
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    label: 'Список оборудования (Disabled)',
                    name: 'flatComboBoxMultipleDisabled',
                    disabled: true,
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    label: 'Список оборудования (Disabled с DefaultValue)',
                    name: 'flatComboBoxMultipleDisabledDefaultValue',
                    defaultValue: [options[3], options[4]],
                    disabled: true,
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    label: 'Список оборудования (Disabled с DefaultValue)',
                    name: 'flatComboBoxMultipleNoOptions',
                    options: [],
                  },
                ],
              },
              {
                label: 'ComboBox with warning text',
                accordion: {
                  enabled: true,
                  defaultExpanded: false,
                },
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    label: 'Список оборудования',
                    name: 'flatComboBoxMultipleWithWarningText',
                    defaultValue: [options[1], options[2]],
                    warningText: 'Обратите внимание!',
                    options,
                  },
                ],
              },
              {
                label: 'FlatComboBoxSingle (DisableClearable)',
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Оборудование (С defaultValue)',
                    name: 'flatComboBoxSingleDisableClearableWithDefaultValue',
                    disableClearable: true,
                    defaultValue: options[1],
                    options,
                    validationConfig: {
                      validationType: 'array',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Оборудование (Без defaultValue)',
                    name: 'flatComboBoxSingleDisableClearableWithOutDefaultValue',
                    disableClearable: true,
                    options,
                    validationConfig: {
                      validationType: 'array',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'RadioTreeComboBox (DisableClearable)',
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Оборудование (С defaultValue)',
                    name: 'radioTreeComboBoxDisableClearableWithDefaultValue',
                    disableClearable: true,
                    defaultValue: (
                      radioTreeOptions as RadioTreeSelectOption[]
                    ).filter(({ selectable }) => selectable)[2],
                    options: radioTreeOptions as RadioTreeSelectOption[],
                    validationConfig: {
                      validationType: 'mixed',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Оборудование (Без defaultValue)',
                    name: 'radioTreeComboBoxDisableClearableWithOutDefaultValue',
                    disableClearable: true,
                    options: radioTreeOptions as RadioTreeSelectOption[],
                    validationConfig: {
                      validationType: 'mixed',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'RadioTree comboBox',
                // accordion: {
                //   enabled: true,
                //   defaultExpanded: false,
                // },
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Пустое)',
                    name: 'radioTreeComboBox',
                    options: radioTreeOptions as RadioTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Обязательное)',
                    name: 'radioTreeComboBoxRequired',
                    options: radioTreeOptions as RadioTreeSelectOption[],
                    validationConfig: {
                      validationType: 'mixed',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (C DefaultValue)',
                    name: 'radioTreeComboBoxDefaultValue',
                    defaultValue: faker.random.arrayElement(
                      radioTreeOptions as RadioTreeSelectOption[]
                    ),
                    options: radioTreeOptions as RadioTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Disabled)',
                    name: 'radioTreeComboBoxDisabled',
                    disabled: true,
                    options: radioTreeOptions as RadioTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Disabled с DefaultValue)',
                    name: 'radioTreeComboBoxDisabledDefaultValue',
                    disabled: true,
                    defaultValue: faker.random.arrayElement(
                      radioTreeOptions as RadioTreeSelectOption[]
                    ),
                    options: radioTreeOptions as RadioTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Без опшинов)',
                    name: 'radioTreeComboBoxNoOptions',
                    options: [],
                  },
                ],
              },
              {
                label: 'CheckboxTree comboBox',
                // accordion: {
                //   enabled: true,
                //   defaultExpanded: false,
                // },
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Пустое)',
                    name: 'checkboxTreeComboBox',
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Обязательное)',
                    name: 'checkboxTreeComboBoxRequired',
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                    validationConfig: {
                      validationType: 'array',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (C DefaultValue)',
                    name: 'checkboxTreeComboBoxDefaultValue',
                    defaultValue: faker.random
                      .arrayElements(
                        checkboxTreeOptions as CheckboxTreeSelectOption[],
                        faker.datatype.number({ min: 0, max: 15 })
                      )
                      .filter(
                        (o) =>
                          !checkboxTreeOptions.some(
                            (o2) => o2.parentValue === o.value
                          )
                      ),
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Disabled)',
                    name: 'checkboxTreeComboBoxDisabled',
                    disabled: true,
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Disabled с DefaultValue)',
                    name: 'checkboxTreeComboBoxDisabledDefaultValue',
                    disabled: true,
                    defaultValue: faker.random
                      .arrayElements(
                        checkboxTreeOptions as CheckboxTreeSelectOption[],
                        faker.datatype.number({ min: 0, max: 15 })
                      )
                      .filter(
                        (o) =>
                          !checkboxTreeOptions.some(
                            (o2) => o2.parentValue === o.value
                          )
                      ),
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    label: 'Список оборудования (Без опшинов)',
                    name: 'checkboxTreeComboBoxNoOptions',
                    options: [],
                  },
                ],
              },
              {
                label: 'Multiline ComboBox',
                accordion: {
                  enabled: true,
                  defaultExpanded: false,
                },
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    multiline: true,
                    label:
                      'Список оборудования (Flat ComboBoxMultiple Multiline)',
                    name: 'flatComboBoxMultipleMultiline',
                    defaultValue: [options[1], options[2]],
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    multiline: true,
                    label: 'Список оборудования (Tree ComboBox Multiline)',
                    name: 'checkboxTreeComboBoxMultiline',
                    defaultValue: faker.random
                      .arrayElements(
                        checkboxTreeOptions as CheckboxTreeSelectOption[],
                        faker.datatype.number({ min: 12, max: 15 })
                      )
                      .filter(
                        (o) =>
                          !checkboxTreeOptions.some(
                            (o2) => o2.parentValue === o.value
                          )
                      ),
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                  },
                ],
              },
              {
                label: 'ArrayOf ComboBox',
                accordion: {
                  enabled: true,
                  defaultExpanded: false,
                },
                fields: [
                  {
                    type: 'arrayOf',
                    name: 'workList',
                    label: 'Заполните работу',
                    helperText: 'Провести следующие работы / мероприятия',
                    rowDefinition: [
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: false,
                        label: 'Оборудование (flatComboBoxSingle)',
                        name: 'flatComboBoxSingle',
                        options,
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: true,
                        label: 'Список оборудования (flatComboBoxMultiple)',
                        name: 'flatComboBoxMultiple',
                        options,
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.radioTree,
                        optionsLoader: 'sync',
                        label: 'Оборудования (radioTreeComboBox)',
                        name: 'radioTreeComboBox',
                        options: radioTreeOptions as RadioTreeSelectOption[],
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.checkboxTree,
                        optionsLoader: 'sync',
                        label: 'Список оборудования (checkboxTreeComboBox)',
                        name: 'checkboxTreeComboBox',
                        options:
                          checkboxTreeOptions as CheckboxTreeSelectOption[],
                      },
                    ],
                    defaultRows: [
                      [
                        {
                          name: 'flatComboBoxSingle',
                          defaultValue: options[1],
                        },
                        {
                          name: 'flatComboBoxMultiple',
                          defaultValue: [options[0], options[1]],
                        },
                        {
                          name: 'radioTreeComboBox',
                          defaultValue: faker.random.arrayElement(
                            radioTreeOptions as RadioTreeSelectOption[]
                          ),
                        },
                        {
                          name: 'checkboxTreeComboBox',
                          defaultValue: faker.random
                            .arrayElements(
                              checkboxTreeOptions as CheckboxTreeSelectOption[],
                              faker.datatype.number({ min: 12, max: 15 })
                            )
                            .filter(
                              (o) =>
                                !checkboxTreeOptions.some(
                                  (o2) => o2.parentValue === o.value
                                )
                            ),
                        },
                      ],
                      [
                        {
                          name: 'flatComboBoxSingle',
                          defaultValue: options[1],
                        },
                        {
                          name: 'flatComboBoxMultiple',
                          defaultValue: [],
                        },
                        {
                          name: 'radioTreeComboBox',
                          defaultValue: faker.random.arrayElement(
                            radioTreeOptions as RadioTreeSelectOption[]
                          ),
                        },
                        {
                          name: 'checkboxTreeComboBox',
                          defaultValue: faker.random
                            .arrayElements(
                              checkboxTreeOptions as CheckboxTreeSelectOption[],
                              faker.datatype.number({ min: 12, max: 15 })
                            )
                            .filter(
                              (o) =>
                                !checkboxTreeOptions.some(
                                  (o2) => o2.parentValue === o.value
                                )
                            ),
                        },
                      ],
                      [
                        {
                          name: 'flatComboBoxSingle',
                          defaultValue: null,
                        },
                        {
                          name: 'flatComboBoxMultiple',
                          defaultValue: [],
                        },
                        {
                          name: 'radioTreeComboBox',
                          defaultValue: null,
                        },
                        {
                          name: 'checkboxTreeComboBox',
                          defaultValue: faker.random
                            .arrayElements(
                              checkboxTreeOptions as CheckboxTreeSelectOption[],
                              faker.datatype.number({ min: 12, max: 15 })
                            )
                            .filter(
                              (o) =>
                                !checkboxTreeOptions.some(
                                  (o2) => o2.parentValue === o.value
                                )
                            ),
                        },
                      ],
                    ],
                  },
                ],
              },
              {
                label: 'Chip ComboBox',
                fields: [
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Flat ComboBoxSingle',
                    defaultValue: options[1],
                    name: 'chipView_flatComboBoxSingle',
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    disabled: true,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Flat ComboBoxSingle (Disabled)',
                    defaultValue: options[1],
                    name: 'chipView_flatComboBoxSingleDisabled',
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    disableClearable: true,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Flat ComboBoxSingle DisableClearable',
                    defaultValue: options[1],
                    name: 'chipView_flatComboBoxSingleDisableClearableChipView',
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Flat ComboBoxMultiple',
                    name: 'chipView_chipFlatComboBoxMultipleMultiline2',
                    defaultValue: [options[1], options[2]],
                    options: [
                      {
                        value: '12',
                        label: 'Дерево',
                        href: 'Дерево12',
                      },
                      {
                        value: '13',
                        label: 'Дерево',
                        href: 'Дерево13',
                      },
                      ...options,
                    ],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    disabled: true,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Flat ComboBoxMultiple (Disabled)',
                    name: 'chipView_chipFlatComboBoxMultipleMultiline2Disabled',
                    defaultValue: [options[1], options[2]],
                    options: [
                      {
                        value: '12',
                        label: 'Дерево',
                        href: 'Дерево12',
                      },
                      {
                        value: '13',
                        label: 'Дерево',
                        href: 'Дерево13',
                      },
                      ...options,
                    ],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    multiline: true,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Flat ComboBoxMultiple Multiline',
                    name: 'chipView_chipFlatComboBoxMultipleMultiline',
                    defaultValue: [
                      options[1],
                      options[2],
                      options[3],
                      options[4],
                      options[5],
                      options[6],
                    ],
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: true,
                    multiline: true,
                    disabled: true,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Flat ComboBoxMultiple Multiline (Disabled)',
                    name: 'chipView_chipFlatComboBoxMultipleMultilineDisabled',
                    defaultValue: [
                      options[1],
                      options[2],
                      options[3],
                      options[4],
                      options[5],
                      options[6],
                    ],
                    options,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    multiple: false,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Radio Tree ComboBox',
                    name: 'chipView_radioTreeComboBox',
                    defaultValue: (
                      radioTreeOptions as RadioTreeSelectOption[]
                    ).filter(({ selectable }) => selectable)[2],
                    options: radioTreeOptions as RadioTreeSelectOption[],
                    validationConfig: {
                      validationType: 'mixed',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    multiple: false,
                    disabled: true,
                    chipTagViewStyle: 'chip',
                    label:
                      'Chip Radio Tree ComboBox DisableClearable (Disabled)',
                    name: 'chipView_radioTreeComboBoxDisabled',
                    defaultValue: (
                      radioTreeOptions as RadioTreeSelectOption[]
                    ).filter(({ selectable }) => selectable)[2],
                    options: radioTreeOptions as RadioTreeSelectOption[],
                    validationConfig: {
                      validationType: 'mixed',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    multiple: false,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Radio Tree ComboBox DisableClearable',
                    name: 'chipView_radioTreeComboBoxDisableClearableWithDefaultValue',
                    disableClearable: true,
                    defaultValue: (
                      radioTreeOptions as RadioTreeSelectOption[]
                    ).filter(({ selectable }) => selectable)[2],
                    options: radioTreeOptions as RadioTreeSelectOption[],
                    validationConfig: {
                      validationType: 'mixed',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.radioTree,
                    optionsLoader: 'sync',
                    multiple: false,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Radio Tree ComboBox (Disabled)',
                    disabled: true,
                    name: 'chipView_radioTreeComboBoxDisableClearableWithDefaultValueDisabled',
                    disableClearable: true,
                    defaultValue: (
                      radioTreeOptions as RadioTreeSelectOption[]
                    ).filter(({ selectable }) => selectable)[2],
                    options: radioTreeOptions as RadioTreeSelectOption[],
                    validationConfig: {
                      validationType: 'mixed',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    chipTagViewStyle: 'chip',
                    label: 'Chip Checkbox Tree ComboBox',
                    name: 'chipView_CheckboxTreeComboBox',
                    defaultValue: faker.random
                      .arrayElements(
                        checkboxTreeOptions as CheckboxTreeSelectOption[],
                        faker.datatype.number({ min: 12, max: 15 })
                      )
                      .filter(
                        (o) =>
                          !checkboxTreeOptions.some(
                            (o2) => o2.parentValue === o.value
                          )
                      ),
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    chipTagViewStyle: 'chip',
                    label: 'Chip Checkbox Tree ComboBox (Disabled)',
                    name: 'chipView_CheckboxTreeComboBoxDisabled',
                    disabled: true,
                    defaultValue: faker.random
                      .arrayElements(
                        checkboxTreeOptions as CheckboxTreeSelectOption[],
                        faker.datatype.number({ min: 12, max: 15 })
                      )
                      .filter(
                        (o) =>
                          !checkboxTreeOptions.some(
                            (o2) => o2.parentValue === o.value
                          )
                      ),
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    multiline: true,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Checkbox Tree ComboBox Multiline',
                    name: 'chipView_chipCheckboxTreeComboBoxMultiline',
                    defaultValue: faker.random
                      .arrayElements(
                        checkboxTreeOptions as CheckboxTreeSelectOption[],
                        faker.datatype.number({ min: 12, max: 15 })
                      )
                      .filter(
                        (o) =>
                          !checkboxTreeOptions.some(
                            (o2) => o2.parentValue === o.value
                          )
                      ),
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.checkboxTree,
                    optionsLoader: 'sync',
                    multiline: true,
                    chipTagViewStyle: 'chip',
                    label: 'Chip Checkbox Tree ComboBox Multiline (Disabled)',
                    name: 'chipView_chipCheckboxTreeComboBoxMultilineDisabled',
                    disabled: true,
                    defaultValue: faker.random
                      .arrayElements(
                        checkboxTreeOptions as CheckboxTreeSelectOption[],
                        faker.datatype.number({ min: 12, max: 15 })
                      )
                      .filter(
                        (o) =>
                          !checkboxTreeOptions.some(
                            (o2) => o2.parentValue === o.value
                          )
                      ),
                    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
                  },
                ],
              },
            ],
          },
        },
      },
    },
    {
      label: 'Form ArrayOf',
      component: {
        id: 100,
        businessComponentId: 16,
        type: 'form',
        props: {
          actions: [
            {
              type: 'submitForm',
              color: 'primary',
              variant: 'contained',
              label: 'Отправить',
              icon: 'mui:Check',
              requestConfig: {
                url: '/api/fields/comboBox',
                method: 'POST',
              },
              successResponseReaction: {
                type: 'reLoadModule',
              },
            },
            produce(
              showFormVar1ActionPage as SingleComponentPage,
              (draft: any) => {
                draft.component.props.actions[0].form.fieldGroups[0].fields.push(
                  getExampleComboBox('comboBoxInModal')
                );
              }
              // @ts-ignore
            ).component.props.actions[0],
          ],
          form: {
            type: 'markup',
            header: { heading: 'Форма с вариантами comboBox' },
            fieldGroups: [
              {
                label: 'ArrayOf ComboBox',
                fields: [
                  {
                    type: 'arrayOf',
                    name: 'workList',
                    label: 'Заполните работу',
                    helperText: 'Провести следующие работы / мероприятия',
                    rowDefinition: [
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: false,
                        label: 'Оборудование (flatComboBoxSingle)',
                        name: 'flatComboBoxSingle',
                        options,
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: true,
                        label: 'Список оборудования (flatComboBoxMultiple)',
                        name: 'flatComboBoxMultiple',
                        options,
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.radioTree,
                        optionsLoader: 'sync',
                        label: 'Оборудования (radioTreeComboBox)',
                        name: 'radioTreeComboBox',
                        options: radioTreeOptions as RadioTreeSelectOption[],
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.checkboxTree,
                        optionsLoader: 'sync',
                        label: 'Список оборудования (checkboxTreeComboBox)',
                        name: 'checkboxTreeComboBox',
                        options:
                          checkboxTreeOptions as CheckboxTreeSelectOption[],
                      },
                    ],
                    defaultRows: [
                      [
                        {
                          name: 'flatComboBoxSingle',
                          defaultValue: options[1],
                        },
                        {
                          name: 'flatComboBoxMultiple',
                          defaultValue: [options[0], options[1]],
                        },
                        {
                          name: 'radioTreeComboBox',
                          defaultValue: faker.random.arrayElement(
                            radioTreeOptions as RadioTreeSelectOption[]
                          ),
                        },
                        {
                          name: 'checkboxTreeComboBox',
                          defaultValue: faker.random
                            .arrayElements(
                              checkboxTreeOptions as CheckboxTreeSelectOption[],
                              faker.datatype.number({ min: 12, max: 15 })
                            )
                            .filter(
                              (o) =>
                                !checkboxTreeOptions.some(
                                  (o2) => o2.parentValue === o.value
                                )
                            ),
                        },
                      ],
                      [
                        {
                          name: 'flatComboBoxSingle',
                          defaultValue: options[1],
                        },
                        {
                          name: 'flatComboBoxMultiple',
                          defaultValue: [],
                        },
                        {
                          name: 'radioTreeComboBox',
                          defaultValue: faker.random.arrayElement(
                            radioTreeOptions as RadioTreeSelectOption[]
                          ),
                        },
                        {
                          name: 'checkboxTreeComboBox',
                          defaultValue: faker.random
                            .arrayElements(
                              checkboxTreeOptions as CheckboxTreeSelectOption[],
                              faker.datatype.number({ min: 12, max: 15 })
                            )
                            .filter(
                              (o) =>
                                !checkboxTreeOptions.some(
                                  (o2) => o2.parentValue === o.value
                                )
                            ),
                        },
                      ],
                      [
                        {
                          name: 'flatComboBoxSingle',
                          defaultValue: null,
                        },
                        {
                          name: 'flatComboBoxMultiple',
                          defaultValue: [],
                        },
                        {
                          name: 'radioTreeComboBox',
                          defaultValue: null,
                        },
                        {
                          name: 'checkboxTreeComboBox',
                          defaultValue: faker.random
                            .arrayElements(
                              checkboxTreeOptions as CheckboxTreeSelectOption[],
                              faker.datatype.number({ min: 12, max: 15 })
                            )
                            .filter(
                              (o) =>
                                !checkboxTreeOptions.some(
                                  (o2) => o2.parentValue === o.value
                                )
                            ),
                        },
                      ],
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    },
    {
      label: 'Table',
      component: produce(
        withFiltersTablePage as SingleComponentPage,
        (draft: any) => {
          draft.component.props.filterComponent.props.fieldGroups[0].fields.push(
            getExampleComboBox('comboBoxInTableFilters')
          );
        }
      ).component,
    },
  ],
};

function getExampleComboBox(name: string) {
  return {
    type: 'comboBox',
    label: 'Список оборудования',
    name,
    optionsType: OptionsType.checkboxTree,
    optionsLoader: 'sync',
    defaultValue: [],
    options: checkboxTreeOptions as CheckboxTreeSelectOption[],
  };
}
