import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../../../theme';

export default makeStyles<Theme>((theme) =>
  createStyles({
    messageContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    pseudoLink: {
      color: theme.palette.text.primary,
      cursor: 'pointer',
    },
  })
);
