import { Page } from 'services/Main/types.Page';

export const withValidationFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с валидацией',
  component: {
    id: 100,
    businessComponentId: 33,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          label: 'Отправить',
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/api/high-level-components/form/with-validation',
          },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      alerts: [{ body: [{ text: 'Static alert text' }] }],
      form: {
        historyConfig: {
          requestConfig: {
            url: '/api/high-level-components/form/with-validation/history',
          },
        },
        type: 'markup',
        header: { heading: 'Форма с валидацией' },
        fieldGroups: [
          {
            label: 'Обычные',
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    { type: 'required', params: ['Обязательное поле!'] },
                  ],
                },
              },
              {
                type: 'text',
                name: 'description',
                label: 'Описание неисправности',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    { type: 'required', params: ['Обязательное поле!'] },
                    {
                      type: 'min',
                      params: [
                        15,
                        'Описание должно быть не меньше 15 символов!',
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            label: 'Условная с чекбоксами',
            fields: [
              { type: 'checkbox', name: 'checkboxField', label: 'Выполнено?' },
              {
                type: 'text',
                name: 'textField',
                label: 'Условно обязательное',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    {
                      type: 'when',
                      params: [
                        'checkboxField',
                        {
                          is: false,
                          then: [
                            {
                              type: 'required',
                              params: [
                                'Либо выполните работу, либо заполните комментарий',
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
          {
            label: 'Условная с селектами',
            fields: [
              {
                type: 'select',
                selectType: 'single',
                name: 'selectField',
                label: 'Какой-то селект',
                options: [
                  { value: 'foo', label: 'Foo' },
                  { value: 'bar', label: 'Bar' },
                  { value: 'baz', label: 'Baz' },
                ],
              },
              {
                type: 'text',
                name: 'textField2',
                label: 'Хитро обязательное',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    { type: 'required', params: ['Обязательно!'] },
                    {
                      type: 'when',
                      params: [
                        'selectField',
                        {
                          is: 'foo',
                          then: [
                            {
                              type: 'required',
                              params: [
                                'Вы выбрали "foo", теперь заполняйте поле',
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'when',
                      params: [
                        'selectField',
                        {
                          is: 'bar',
                          then: [
                            {
                              type: 'min',
                              params: [
                                5,
                                'Вы выбрали "bar", теперь заполняйте поле хотя бы 5 символами',
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'when',
                      params: [
                        'selectField',
                        {
                          is: 'baz',
                          then: [
                            {
                              type: 'max',
                              params: [
                                5,
                                'Вы выбрали "baz", теперь заполняйте поле до 5 символов',
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  },
};
