import React, { FunctionComponent } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Icon from 'components/lowLevel/Icon';
import useStyles from './IconTypeProvider.styles';

const Formatter: FunctionComponent<DataTypeProvider.ValueFormatterProps> = ({
  value,
}) => {
  const classes = useStyles();

  return (
    <span className={classes.iconContainer}>
      <Icon definition={value} />
    </span>
  );
};

interface IconTypeProviderProps {
  for: string[];
}

const IconTypeProvider = (props: IconTypeProviderProps) => (
  <DataTypeProvider formatterComponent={Formatter} {...props} />
);

export default IconTypeProvider;
