import React, { ReactNode } from 'react';
import { AutocompleteGetTagProps } from '@material-ui/lab';
import {
  ComboBoxOption,
  FlatComboBoxSingle,
} from '../../../../services/Main/types.Field';
import Tags from '../components/Tags';

export interface RenderTagsProps {
  multiline?: boolean;
  viewStyle?: FlatComboBoxSingle['chipTagViewStyle'];
  disabled?: boolean;
  disableClearable?: boolean;
}

export default (props: RenderTagsProps) => {
  // MUI: (value: T[], getTagProps: AutocompleteGetTagProps) => React.ReactNode
  return function renderTags(
    tags: ComboBoxOption[],
    getTagProps: AutocompleteGetTagProps
  ): ReactNode {
    return <Tags tags={tags} getTagProps={getTagProps} {...props} />;
  };
};
