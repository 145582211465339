import { Page } from 'services/Main/types.Page';
import { FileUpload } from 'services/Main/types.Field';

export const simpleFileUpload: FileUpload = {
  type: 'file',
  name: 'simpleFileUploader',
  label: 'Обычый File Uploader',
  disabled: false,
  defaultValue: [],
};

export const fileUploadFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами file upload полей',
  component: {
    id: 100,
    businessComponentId: 23,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами file upload полей' },
        fieldGroups: [
          {
            label: 'Офисные документы',
            accordion: {
              defaultExpanded: true,
              enabled: true,
            },
            fields: [
              {
                type: 'file',
                name: 'simpleFileUploaderWithFileDocument',
                label: 'Офисные документы',
                disabled: false,
                defaultValue: [
                  {
                    id: '//lidev.ru/files/Microsoft%20Excel%20XLS.xls',
                    url: '//lidev.ru/files/Microsoft%20Excel%20XLS.xls',
                    name: 'Microsoft Excel XLS.xls',
                    size: 37376,
                    mimeType: 'application/vnd.ms-excel',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Excel%20XLSX.xlsx',
                    url: '//lidev.ru/files/Microsoft%20Excel%20XLSX.xlsx',
                    name: 'Microsoft Excel XLSX.xlsx',
                    size: 12230,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20PowerPoint%20PPT.ppt',
                    url: '//lidev.ru/files/Microsoft%20PowerPoint%20PPT.ppt',
                    name: 'Microsoft PowerPoint PPT.ppt',
                    size: 555008,
                    mimeType: 'application/vnd.ms-powerpoint',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20PowerPoint%20PPTX.pptx',
                    url: '//lidev.ru/files/Microsoft%20PowerPoint%20PPTX.pptx',
                    name: 'Microsoft PowerPoint PPTX.pptx',
                    size: 493064,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Word%20DOC.doc',
                    url: '//lidev.ru/files/Microsoft%20Word%20DOC.doc',
                    name: 'Microsoft Word DOC.doc',
                    size: 35328,
                    mimeType: 'application/msword',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Word%20DOCX.docx',
                    url: '//lidev.ru/files/Microsoft%20Word%20DOCX.docx',
                    name: 'Microsoft Word DOCX.docx',
                    size: 326961,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  },
                ],
              },
              {
                type: 'file',
                name: 'clipFileUploaderWithFileDocument',
                label: 'Офисные документы',
                disabled: false,
                helperText: 'Это подсказка для поля',
                viewConfig: {
                  viewStyle: 'textFieldWithClip',
                },
                defaultValue: [
                  {
                    id: '//lidev.ru/files/Microsoft%20Excel%20XLS.xls',
                    url: '//lidev.ru/files/Microsoft%20Excel%20XLS.xls',
                    name: 'Microsoft Excel XLS.xls',
                    size: 37376,
                    mimeType: 'application/vnd.ms-excel',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Excel%20XLSX.xlsx',
                    url: '//lidev.ru/files/Microsoft%20Excel%20XLSX.xlsx',
                    name: 'Microsoft Excel XLSX.xlsx',
                    size: 12230,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20PowerPoint%20PPT.ppt',
                    url: '//lidev.ru/files/Microsoft%20PowerPoint%20PPT.ppt',
                    name: 'Microsoft PowerPoint PPT.ppt',
                    size: 555008,
                    mimeType: 'application/vnd.ms-powerpoint',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20PowerPoint%20PPTX.pptx',
                    url: '//lidev.ru/files/Microsoft%20PowerPoint%20PPTX.pptx',
                    name: 'Microsoft PowerPoint PPTX.pptx',
                    size: 493064,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Word%20DOC.doc',
                    url: '//lidev.ru/files/Microsoft%20Word%20DOC.doc',
                    name: 'Microsoft Word DOC.doc',
                    size: 35328,
                    mimeType: 'application/msword',
                  },
                  {
                    id: '//lidev.ru/files/Microsoft%20Word%20DOCX.docx',
                    url: '//lidev.ru/files/Microsoft%20Word%20DOCX.docx',
                    name: 'Microsoft Word DOCX.docx',
                    size: 326961,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  },
                ],
              },
            ],
          },
          {
            label: 'Изображения',
            accordion: {
              defaultExpanded: false,
              enabled: true,
            },
            fields: [
              {
                type: 'file',
                name: 'simpleFileUploaderWithFileImage',
                label: 'Фотоподтверждение',
                disabled: false,
                defaultValue: [
                  {
                    id: '//lidev.ru/files/bmp.bmp',
                    url: '//lidev.ru/files/bmp.bmp',
                    name: 'bmp.bmp',
                    size: 3687062,
                    mimeType: 'image/bmp',
                  },
                  {
                    id: '//lidev.ru/files/jpeg.jpeg',
                    url: '//lidev.ru/files/jpeg.jpeg',
                    name: 'jpeg.jpeg',
                    size: 1036131,
                    mimeType: 'image/jpeg',
                  },
                  {
                    id: '//lidev.ru/files/jpg.jpg',
                    url: '//lidev.ru/files/jpg.jpg',
                    name: 'jpg.jpg',
                    size: 232607,
                    mimeType: 'image/jpeg',
                  },
                  {
                    id: '//lidev.ru/files/png.png',
                    url: '//lidev.ru/files/png.png',
                    name: 'png.png',
                    size: 153826,
                    mimeType: 'image/png',
                  },
                  {
                    id: '//lidev.ru/files/svg.svg',
                    url: '//lidev.ru/files/svg.svg',
                    name: 'svg.svg',
                    size: 24446,
                    mimeType: 'image/svg+xml',
                  },
                ],
              },
              {
                type: 'file',
                name: 'clipFileUploaderWithFileImage',
                label: 'Фотоподтверждение',
                disabled: false,
                viewConfig: {
                  viewStyle: 'textFieldWithClip',
                },
                defaultValue: [
                  {
                    id: '//lidev.ru/files/bmp.bmp',
                    url: '//lidev.ru/files/bmp.bmp',
                    name: 'bmp.bmp',
                    size: 3687062,
                    mimeType: 'image/bmp',
                  },
                  {
                    id: '//lidev.ru/files/jpeg.jpeg',
                    url: '//lidev.ru/files/jpeg.jpeg',
                    name: 'jpeg.jpeg',
                    size: 1036131,
                    mimeType: 'image/jpeg',
                  },
                  {
                    id: '//lidev.ru/files/jpg.jpg',
                    url: '//lidev.ru/files/jpg.jpg',
                    name: 'jpg.jpg',
                    size: 232607,
                    mimeType: 'image/jpeg',
                  },
                  {
                    id: '//lidev.ru/files/png.png',
                    url: '//lidev.ru/files/png.png',
                    name: 'png.png',
                    size: 153826,
                    mimeType: 'image/png',
                  },
                  {
                    id: '//lidev.ru/files/svg.svg',
                    url: '//lidev.ru/files/svg.svg',
                    name: 'svg.svg',
                    size: 24446,
                    mimeType: 'image/svg+xml',
                  },
                ],
              },
            ],
          },
          {
            label: 'Сканы, неструктурированные документы',
            accordion: {
              defaultExpanded: false,
              enabled: true,
            },
            fields: [
              {
                type: 'file',
                name: 'simpleFileUploaderWithFileScan',
                label: 'Сканы, неструктурированные документы',
                disabled: false,
                defaultValue: [
                  {
                    id: '//lidev.ru/files/PDF.pdf',
                    url: '//lidev.ru/files/PDF.pdf',
                    name: 'PDF.pdf',
                    size: 8524442,
                    mimeType: 'application/pdf',
                  },
                  {
                    id: '//lidev.ru/files/tiff.tiff',
                    url: '//lidev.ru/files/tiff.tiff',
                    name: 'tiff.tiff',
                    size: 1131930,
                    mimeType: 'image/tiff',
                  },
                  {
                    id: '//lidev.ru/files/pdf2page.tiff',
                    url: '//lidev.ru/files/pdf2page.tiff',
                    name: 'multiPageTiff.tiff',
                    size: 528680,
                    mimeType: 'image/tiff',
                  },
                ],
              },
              {
                type: 'file',
                name: 'clipFileUploaderWithFileScan',
                label: 'Сканы, неструктурированные документы',
                disabled: false,
                viewConfig: {
                  viewStyle: 'textFieldWithClip',
                },
                defaultValue: [
                  {
                    id: '//lidev.ru/files/PDF.pdf',
                    url: '//lidev.ru/files/PDF.pdf',
                    name: 'PDF.pdf',
                    size: 8524442,
                    mimeType: 'application/pdf',
                  },
                  {
                    id: '//lidev.ru/files/tiff.tiff',
                    url: '//lidev.ru/files/tiff.tiff',
                    name: 'tiff.tiff',
                    size: 1131930,
                    mimeType: 'image/tiff',
                  },
                  {
                    id: '//lidev.ru/files/pdf2page.tiff',
                    url: '//lidev.ru/files/pdf2page.tiff',
                    name: 'multiPageTiff.tiff',
                    size: 528680,
                    mimeType: 'image/tiff',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};
