import { SocketConfig, TimelineElement } from '../Main/types.Component';
import SocketServiceIo from '../Socket/SocketService.io';
import SocketServiceSignalR from '../Socket/SocketService.signalR';
import authService from '../Auth';

const TIMELINE_INITIALIZED = 'timelineInitialized';
const TIMELINE_ELEMENT_ADDED = 'timelineElementAdded';

class TimelineService {
  client: SocketServiceIo | SocketServiceSignalR;

  config: SocketConfig;

  constructor(config: SocketConfig) {
    this.config = config;

    authService.subscribe(() => {
      this.client.close();
      this.client = this.initializeConnection();
    });

    this.client = this.initializeConnection();
  }

  initializeConnection = () => {
    if (!this.config)
      throw new Error('Не обнаружен config для старта Socket-соединения');

    return this.config.client === 'io'
      ? new SocketServiceIo(this.config)
      : new SocketServiceSignalR(this.config);
  };

  subscribeOnTimelineInitialized = (
    cb: (timelineElement: TimelineElement[]) => void
  ) => {
    this.client.connection.on(TIMELINE_INITIALIZED, cb);
  };

  subscribeOnTimelineElementAdded = (
    cb: (timelineElement: TimelineElement) => void
  ) => {
    this.client.connection.on(TIMELINE_ELEMENT_ADDED, cb);
  };
}

export default TimelineService;
