import React, { createContext, PropsWithChildren, ReactElement } from 'react';
import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import hash from 'object-hash';
import { GridItem } from '../../../services/Main/types.GridItem';
import useStyles from './GridBuilder.styles';

interface GridBuilderProps<T extends GridItem> {
  markup: T[];
  renderGridItem: (
    rest: Omit<T, 'mdCols' | 'mdOffset' | 'lgCols'>
  ) => ReactElement;
  spacing?: GridSpacing;
}

export const GridItemContext = createContext<Pick<
  GridItem,
  'mdCols' | 'mdOffset' | 'lgCols'
> | null>(null);

const GridBuilder = <T extends GridItem>({
  markup,
  renderGridItem,
  spacing,
}: PropsWithChildren<GridBuilderProps<T>>) => {
  const classes = useStyles();

  return (
    // 26.04.2021 Здесь раньше был xs={12}, но он конфликтует с spacing.
    // spacing делает width: calc(100% + {spacing * 8}), а
    // xs={12} добавляет свойство max-width: 100%.
    <Grid container item spacing={spacing || 1}>
      {markup.map(({ mdCols, mdOffset, lgCols, ...rest }) => (
        <GridItemContext.Provider
          value={{ mdCols, mdOffset, lgCols }}
          key={hash(rest)}
        >
          {mdOffset && (
            <Grid
              item
              md={mdOffset}
              implementation="css"
              smDown
              component={Hidden}
            />
          )}
          <Grid
            item
            xs={12}
            md={mdCols || 12}
            lg={lgCols || mdCols || 12}
            hidden={rest.hidden}
            className={classes.gridItem}
          >
            {renderGridItem(rest)}
          </Grid>
        </GridItemContext.Provider>
      ))}
    </Grid>
  );
};

export default GridBuilder;
