import React, { useRef, Dispatch, SetStateAction, RefObject } from 'react';
import {
  GroupingState,
  IntegratedGrouping,
  SortingState as GridSortingState,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import {
  DragDropProvider,
  Grid,
  TableHeaderRow,
  TableBandHeader,
  TableFixedColumns,
  TableColumnResizing,
  TableColumnVisibility,
  Table,
  TableProps,
  TableColumnReordering,
  TableGroupRow,
} from '@devexpress/dx-react-grid-material-ui';
import LinearProgress from '@material-ui/core/LinearProgress';
import clsx from 'clsx';
import { useMediaQuery, useTheme } from '@material-ui/core';
import StickyTable from 'components/lowLevel/StickyTable';
import TableContainer from 'components/lowLevel/TableContainer';
import {
  DataTypeProvidersProps,
  DirtyFormValues,
  SortingState,
  TableComponentProps,
  TableFilterFormComponent,
} from '../../../services/Main/types.Component';
import DataTypeProviders from './TypeProviders/DataTypeProviders';
import useStyles from './Table.styles';
import TableModules, {
  TableFilterFormProps,
} from '../../lowLevel/TableModules';
import TableHeaderTooltip from '../../lowLevel/TableHeaderTooltip';
import { useFormatMessage } from '../../../locale';
import PagingPanel from './components/PagingPanel';
import TableColumnVisibilityMessage from './components/TableColumnVisibilityMessage';
import TableHeaderCell from './components/TableHeaderCell';
import TableHeaderCellContent from './components/TableHeaderCellContent';

const MIN_HEIGHT_FULL = 600;
const MIN_HEIGHT_COMPACT = 250;

interface TableComponentViewProps extends TableComponentProps {
  isLoading: boolean;
  tableProps: TableProps;
  loadRows: () => void;
  dataTypeProvidersProps: DataTypeProvidersProps;
  setSorting: Dispatch<SetStateAction<SortingState | undefined>>;
  sortingStateColumnExtensions: any[];
  groupingStateColumnExtensions: IntegratedGrouping.ColumnExtension[];
  someColumnSortable: boolean;
  someColumnHasTitle: boolean;
  setQuery: Dispatch<SetStateAction<string | undefined>>;
  filter: DirtyFormValues | undefined;
  setFilter: Dispatch<SetStateAction<DirtyFormValues | undefined>>;
  columnWidths: TableColumnWidthInfo[];
  setColumnWidths: Dispatch<SetStateAction<TableColumnWidthInfo[]>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
  query?: string;
  columnOrder: string[];
  setColumnOrder: Dispatch<SetStateAction<string[]>>;
  hiddenColumnNames: string[];
  tableContentRef: RefObject<HTMLDivElement>;
}

export default ({
  columns,
  columnBands,
  rows,
  isLoading,
  heading,
  header,
  showSearchbar,
  filterComponent,
  quickFilters,
  requestConfig,
  actions,
  alerts,
  showTotalRows,
  tableProps,
  loadRows,
  dataTypeProvidersProps,
  setSorting,
  sortingStateColumnExtensions,
  groupingStateColumnExtensions,
  fixedColumns,
  someColumnHasTitle,
  someColumnSortable,
  fullHeight = true,
  query,
  setQuery,
  filter,
  setFilter,
  columnWidths,
  setColumnWidths,
  setCurrentPage,
  setPageSize,
  enableColumnResizing,
  columnOrder,
  setColumnOrder,
  enableColumnReordering,
  columnVisibilityConfig,
  hiddenColumnNames,
  maxHeight,
  options: {
    totalRows,
    pageSize,
    currentPage,
    sorting,
    pageSizeOptions,
    pagingDisabled,
  },
  groupingConfig,
  tableContentRef,
}: TableComponentViewProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const rootRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleFilterChange = (newFilterState: DirtyFormValues | undefined) => {
    setCurrentPage(0);
    // Мерджим прошлое состояние и новое - это нужно для того чтобы
    // корректно работали две формы:
    // 1. Которая расположена возле чипсов
    // 2. В Drawer
    setFilter((prevState) => ({
      ...prevState,
      ...newFilterState,
    }));
  };

  const tableFilterFormProps:
    | Omit<TableFilterFormProps, 'filterChips' | 'setFilterChips'>
    | undefined = filterComponent && {
    ...(filterComponent as TableFilterFormComponent),
    filter,
    setFilter: handleFilterChange,
  };

  const minHeight = fullHeight ? MIN_HEIGHT_FULL : MIN_HEIGHT_COMPACT;

  return (
    <div
      className={classes.root}
      ref={rootRef}
      style={{
        height: fullHeight
          ? `calc(100vh - ${(rootRef.current?.offsetTop || 0) + 40}px)`
          : '100%',
        minHeight,
        maxHeight: maxHeight && maxHeight < minHeight ? minHeight : maxHeight,
      }}
    >
      <TableModules
        totalRows={totalRows}
        heading={heading}
        header={header}
        showSearchbar={showSearchbar}
        searchInputProps={{ query, onQueryChange: setQuery }}
        tableFilterFormProps={tableFilterFormProps}
        requestConfig={requestConfig}
        actions={actions}
        alerts={alerts}
        showTotalRows={showTotalRows}
        quickFilters={quickFilters}
      />
      <div
        className={classes.tableContent}
        style={{ marginBottom: theme.spacing(2) }}
        ref={tableContentRef}
      >
        <LinearProgress
          className={clsx(classes.progress, { active: isLoading })}
        />
        <Grid getRowId={(row) => row.id} rows={rows} columns={columns}>
          <GridSortingState
            sorting={sorting}
            columnExtensions={sortingStateColumnExtensions}
            onSortingChange={setSorting}
          />
          {groupingConfig && (
            <GroupingState defaultGrouping={groupingConfig.defaultGrouping} />
          )}
          <DataTypeProviders {...dataTypeProvidersProps} />
          {enableColumnReordering && <DragDropProvider />}
          {groupingConfig && (
            <IntegratedGrouping
              columnExtensions={groupingStateColumnExtensions}
            />
          )}
          <Table
            {...tableProps}
            messages={{
              noData: formatMessage('noData'),
            }}
            tableComponent={StickyTable}
            containerComponent={TableContainer}
          />
          {enableColumnReordering && (
            <TableColumnReordering
              order={columnOrder}
              onOrderChange={setColumnOrder}
            />
          )}
          {enableColumnResizing && (
            <TableColumnResizing
              columnWidths={columnWidths}
              onColumnWidthsChange={setColumnWidths}
            />
          )}
          {someColumnHasTitle && (
            <TableHeaderRow
              sortLabelComponent={TableHeaderTooltip}
              showSortingControls={someColumnSortable}
              cellComponent={TableHeaderCell}
              contentComponent={TableHeaderCellContent}
            />
          )}
          {columnVisibilityConfig?.enabled && (
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              messages={{
                noColumns: formatMessage('allColumnsAreHidden'),
              }}
              emptyMessageComponent={TableColumnVisibilityMessage}
            />
          )}
          {groupingConfig && <TableGroupRow />}
          {columnBands && <TableBandHeader columnBands={columnBands} />}
          {isMdUp && fixedColumns && <TableFixedColumns {...fixedColumns} />}
        </Grid>
      </div>
      {!pagingDisabled && (
        <PagingPanel
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageSizeOptions={pageSizeOptions}
          totalRows={totalRows}
          loadRows={loadRows}
        />
      )}
    </div>
  );
};
