import React from 'react';
import ErrorPage from 'components/lowLevel/ErrorController';
import NotFoundSVG from 'svg/Error404';
import { useFormatMessage } from '../../../locale';

const NotFoundPage = () => {
  const formatMessage = useFormatMessage();

  return (
    <ErrorPage
      heading={formatMessage('notFound')}
      description={formatMessage('notFoundDescription')}
      image={<NotFoundSVG />}
    />
  );
};

export default NotFoundPage;
