import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../../theme';

export default makeStyles<Theme>((theme) =>
  createStyles({
    textField: {
      '&.warning': {
        '& .MuiFormLabel-root:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)':
          {
            color: theme.palette.warning.main,
          },
        '& .MuiFormHelperText-root': {
          color: theme.palette.warning.dark,
        },

        '& .MuiInput-underline:not(.Mui-disabled):before': {
          borderBottomColor: theme.palette.warning.main,
        },
      },
    },
    inputRoot: {
      display: 'inline-flex',
      flexWrap: 'nowrap',
    },
  })
);
