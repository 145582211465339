import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    inputRoot: {
      display: 'inline-flex',
      flexWrap: 'nowrap',
    },
  })
);
