import LogBase from './Log.base';
import { LoggerRequest } from '../Main/types';

class LogLocal implements LogBase {
  write = (payload: LoggerRequest) => {
    console.log('ERROR', payload);
  };
}

export default LogLocal;
