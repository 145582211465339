import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    button: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        color: 'inherit',
      },
    },
  })
);
