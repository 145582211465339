import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let Shop: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16">
    <path
      d="M16.9874 4.06009L16.9875 4.06026C17.361 4.69746 17.3866 5.4706 17.1344 6.11062C16.8838 6.74691 16.3903 7.18667 15.7683 7.27576C15.6699 7.28979 15.5715 7.29688 15.4668 7.29688C14.8338 7.29688 14.2567 6.99997 13.8454 6.5116L13.3085 5.87402L12.7733 6.51306C12.366 6.99948 11.7898 7.29688 11.1533 7.29688C10.5192 7.29688 9.94026 6.99916 9.53317 6.51306L8.99651 5.87225L8.45985 6.51306C8.05248 6.99948 7.47632 7.29688 6.83976 7.29688C6.20572 7.29688 5.62676 6.99916 5.21967 6.51306L4.683 5.87225L4.14634 6.51306C3.73898 6.99948 3.16282 7.29688 2.52625 7.29688C2.42444 7.29688 2.32336 7.28978 2.22561 7.27589C1.60708 7.18575 1.11517 6.74597 0.865099 6.10902C0.613573 5.46838 0.639354 4.69566 1.01148 4.06009C1.0115 4.06005 1.01153 4.06001 1.01155 4.05997L2.90814 0.822589L2.90882 0.821413C2.96001 0.733644 3.03748 0.7 3.09613 0.7H14.9027C14.9614 0.7 15.0388 0.733644 15.09 0.821412L15.0907 0.822589L16.9874 4.06009ZM15.3105 9.69623C15.3641 9.69871 15.4182 9.7 15.4726 9.7C15.577 9.7 15.6805 9.69638 15.7838 9.68917V15C15.7838 15.2115 15.6358 15.3 15.5486 15.3H2.45612C2.36889 15.3 2.22094 15.2115 2.22094 15V9.68945C2.32295 9.6965 2.42507 9.7 2.52625 9.7C2.58075 9.7 2.63481 9.69863 2.68837 9.69605V12V12.7H3.38837H14.6105H15.3105V12V9.69623Z"
      fill="transparent"
      strokeWidth="1.4"
    />
  </SvgIcon>
);
Shop.muiName = 'SvgIcon';

Shop = React.memo(Shop);
Shop.displayName = 'Shop';

export default Shop;
