import { useRef, useLayoutEffect, useEffect, DependencyList } from 'react';

function useTableScrollManager(updatePositionLayoutEffectDeps: DependencyList) {
  const tableContentRef = useRef<HTMLDivElement>(null);
  const scrollTopRef = useRef<number>(0);
  const scrollLeftRef = useRef<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      if (tableContentRef.current) {
        scrollTopRef.current = tableContentRef.current.scrollTop;
        scrollLeftRef.current = tableContentRef.current.scrollLeft;
      }
    };

    const tableContentEl = tableContentRef.current;

    if (tableContentEl) {
      tableContentEl.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableContentEl) {
        tableContentEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (tableContentRef.current) {
      tableContentRef.current.scrollTop = scrollTopRef.current;
      tableContentRef.current.scrollLeft = scrollLeftRef.current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, updatePositionLayoutEffectDeps);

  const resetScrollPosition = () => {
    scrollTopRef.current = 0;
    scrollLeftRef.current = 0;

    if (tableContentRef.current) {
      tableContentRef.current.scrollLeft = 0;
      tableContentRef.current.scrollTop = 0;
    }
  };

  return { tableContentRef, resetScrollPosition };
}

export default useTableScrollManager;
