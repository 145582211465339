import React, { useContext } from 'react';
import { CircularProgress, useTheme, fade } from '@material-ui/core';
import TableDragAndDropCellContext from '../TableCellDragAndDrop.context';

interface LoadingCellIndicatorProps {
  id: string;
}

const LoadingCellIndicator = ({ id }: LoadingCellIndicatorProps) => {
  const theme = useTheme();
  const tableDragAndDropCellContext = useContext(TableDragAndDropCellContext);

  if (!tableDragAndDropCellContext) return null;

  const { loadingCellIds } = tableDragAndDropCellContext;

  if (!loadingCellIds.includes(id)) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: fade(
          theme.palette.action.hover,
          theme.palette.action.hoverOpacity
        ),
      }}
    >
      <CircularProgress size={16} />
    </div>
  );
};

export default LoadingCellIndicator;
