import faker from 'faker';
import {
  ChangePasswordRequestBody,
  ChangePasswordResponse,
  ForgotPasswordRequestBody,
  ForgotPasswordResponse,
  SetPasswordRequestBody,
  SetPasswordResponse,
  ValidateTokenRequestBody,
  ValidateTokenResponse,
} from './types';
import PasswordBase from './Password.base';

export default class PasswordServiceLocal implements PasswordBase {
  forgot(body: ForgotPasswordRequestBody): Promise<ForgotPasswordResponse> {
    const isSuccess = Math.random() > 0.5;

    if (isSuccess) {
      return Promise.resolve({
        isSuccess: true,
        payload: {
          title: 'Success',
          message: 'Email sent',
        },
      });
    }

    return Promise.resolve({
      isSuccess: false,
      payload: {
        message: 'Неправильная пара логин/email',
      },
    });
  }

  validateToken(
    body: ValidateTokenRequestBody
  ): Promise<ValidateTokenResponse> {
    return Promise.resolve({
      isSuccess: true,
      payload: {},
    });
  }

  set(body: SetPasswordRequestBody): Promise<SetPasswordResponse> {
    return Promise.resolve(
      faker.random.arrayElement([
        {
          isSuccess: true,
          payload: {
            title: 'Success',
            message: 'Успешно изменено, можете войти в систему',
          },
        },
        // {
        //   isSuccess: false,
        //   payload: {
        //     showForm: false,
        //     title: 'Ошибка 🫣',
        //     message: 'Ссылка протухла почему-то :)',
        //   },
        // },
        // {
        //   isSuccess: false,
        //   payload: {
        //     showForm: true,
        //     message: 'Серверая валидация сработала ололоэ',
        //   },
        // },
      ])
    );
  }

  change(body: ChangePasswordRequestBody): Promise<ChangePasswordResponse> {
    return Promise.resolve(
      faker.random.arrayElement([
        {
          isSuccess: false,
          payload: {
            message: faker.hacker.phrase(),
          },
        },
        {
          isSuccess: true,
          payload: {
            message: 'Пароль успешно изменен 🎉',
          },
        },
      ])
    );
  }
}
