import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import get from 'lodash.get';
import { useFormContext } from 'react-hook-form';
import { Text } from '../../../services/Main/types.Field';

export default ({
  hidden,
  mdCols,
  inputType,
  name,
  helperText,
  validationConfig,
  autoComplete,
  ...props
}: Text) => {
  const { errors } = useFormContext();
  const error = get(errors, name);

  return (
    <Box hidden={hidden}>
      <MuiTextField
        {...props}
        name={name}
        type={inputType === 'numeric' ? 'number' : inputType}
        helperText={error?.message || helperText}
        error={!!error}
        autoComplete={autoComplete ? 'on' : 'off'}
      />
    </Box>
  );
};
