import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import mainService from 'services/Main';
import {
  CloseDialogAction as CloseDialogActionProps,
  Reaction,
  RedirectReaction,
  RequestConfig,
  ShowComponentAction,
  ShowFormAction,
  ShowHistoryAction,
  SubmitFormAction,
  TriggerEndpointAction,
} from 'services/Main/types.Component';
import store from '../../../store';
import { PageContext } from '../../creational/PageLoader/PageLoader';
import ReactionController from '../../../responseReactions/ReactionController';
import { mapValuesToPlain } from '../FormBuilder/helpers';
import DialogFormAction from './DialogFormAction';
import { FormActionProps } from './types';
import redirectReaction from '../../../responseReactions/redirect';
import FormBuilderContext, {
  FormSubmitHandler,
} from '../FormBuilder/FormBuilderContext';
import { DialogAlertContext } from '../DialogAlert/DialogAlert';
import useIsSomeActionExecuting from './useIsSomeActionExecuting';
import history from '../../../utils/history';
import { alertsFromActionServerResponseActions } from '../../../store/alertsFromActionServerResponse';
import { metaActions } from '../../../store/meta';
import { ComponentContext } from '../../creational/ComponentLoader';
import useEnqueueSnackbar from '../../../utils/hooks/useEnqueueSnackbar';
import DialogComponentAction from './DialogComponentAction';
import { useShowConfirmDialog } from '../FormActions/useShowConfirmDialog';
import { dialogWithComponentActions } from '../../../store/dialogWithComponent';
import FormHistory from '../FormHistory';
import CloseDialogAction from '../CloseDialogAction';
import { TableContext } from '../../highLevel/Table';
import TableDialogWithComponentContext from '../../highLevel/Table/components/TableDialogWithComponent/TableDialogWithComponent.context';
import { makePreSubmitRequest } from '../../../utils/actionUtils';

export default (props: FormActionProps) => {
  const { action, onReactionExecute, renderAction, onClick } = props;
  const dispatch = useDispatch();
  const { getValues } = useFormContext();
  const { type, label, color, icon, variant, ...rest } = action;
  const pageContext = useContext(PageContext);
  const componentContext = useContext(ComponentContext);
  const { setIsSomeActionExecuting } = useIsSomeActionExecuting();
  const { setOnSubmitHandlers, setFiredSubmitAction, fields } =
    useContext(FormBuilderContext);
  const enqueueSnackbar = useEnqueueSnackbar();
  const dialogAlertContext = useContext(DialogAlertContext);
  const showConfirmDialog = useShowConfirmDialog();
  const tableContext = useContext(TableContext);
  const tableDialogWithComponentContext = useContext(
    TableDialogWithComponentContext
  );

  const onBeforeActionExecuteHandler = () => {
    const confirmDialogConfig = (rest as TriggerEndpointAction)?.confirmDialog;
    if (!confirmDialogConfig) return Promise.resolve();

    return showConfirmDialog(confirmDialogConfig);
  };

  const onActionSuccessfullyExecuted = () => {
    if (store.getState().dialogWithComponent) {
      dispatch(dialogWithComponentActions.close());
    }
    if (tableDialogWithComponentContext?.dialogWithComponent) {
      tableDialogWithComponentContext.setDialogWithComponent(null);
    }
  };

  // Показать форму, со своими экшнами в модалке, выполнить реакцию по сабмиту.
  if (type === 'showForm') {
    return (
      <DialogFormAction
        action={action as ShowFormAction}
        onClick={onClick}
        renderButton={renderAction}
      />
    );
  }

  if (type === 'showHistory') {
    return (
      <FormHistory
        action={action as ShowHistoryAction}
        onClick={onClick}
        renderButton={renderAction}
      />
    );
  }

  if (type === 'showComponent') {
    return (
      <DialogComponentAction
        action={action as ShowComponentAction}
        renderButton={renderAction}
      />
    );
  }

  if (type === 'closeDialog') {
    return (
      <CloseDialogAction
        action={action as CloseDialogActionProps}
        renderButton={renderAction}
      />
    );
  }

  const triggerEndpoint = async (params?: object) => {
    await onBeforeActionExecuteHandler();

    setIsSomeActionExecuting(true);

    if ((rest as TriggerEndpointAction).preSubmitRequestConfig) {
      await makePreSubmitRequest({
        preSubmitRequestConfig: (rest as TriggerEndpointAction)
          .preSubmitRequestConfig as RequestConfig,
        params,
        showConfirmDialog,
      });
    }

    mainService
      .makeActionRequest((rest as TriggerEndpointAction).requestConfig, params)
      .then(
        ({
          payload,
          snackbar,
          dialogAlert,
          preventSuccessResponseReactionReasons,
        }) => {
          if (snackbar) enqueueSnackbar(snackbar.text, snackbar.options);
          if (dialogAlert) dialogAlertContext.setDialogState(dialogAlert);

          const alertsId = `${componentContext.businessComponentId}_${componentContext.id}`;

          if (
            preventSuccessResponseReactionReasons &&
            Array.isArray(preventSuccessResponseReactionReasons)
          ) {
            dispatch(
              alertsFromActionServerResponseActions.setForPage({
                id: alertsId,
                alerts: preventSuccessResponseReactionReasons,
              })
            );
            dispatch(metaActions.setIsSomeActionExecuting(false));
            return;
          }

          if (
            store.getState().alertsFromActionServerResponse[alertsId]?.length >
            0
          ) {
            dispatch(alertsFromActionServerResponseActions.clear(alertsId));
          }

          onActionSuccessfullyExecuted();

          new ReactionController(
            (rest as TriggerEndpointAction).successResponseReaction,
            pageContext as PageContext,
            enqueueSnackbar,
            payload,
            tableContext,
            tableDialogWithComponentContext
          ).execute(onReactionExecute);
        }
      )
      .catch((error) => {
        if (error.response?.status === 422) {
          if (error.response?.data?.snackbar) {
            enqueueSnackbar(
              error.response?.data?.snackbar.text,
              error.response?.data?.snackbar.options
            );
          }

          if (error.response?.data?.dialogAlert) {
            if (error.response?.data?.dialogAlert)
              dialogAlertContext.setDialogState(
                error.response.data.dialogAlert
              );
          }

          if ((rest as TriggerEndpointAction).validationErrorReaction) {
            new ReactionController(
              (rest as TriggerEndpointAction)
                .validationErrorReaction as Reaction,
              pageContext as PageContext,
              enqueueSnackbar,
              error.response,
              tableContext,
              tableDialogWithComponentContext
            ).execute(onReactionExecute);
          }
        }

        console.log('Ошибка', error);
      })
      .finally(() => {
        setIsSomeActionExecuting(false);
      });
  };

  /** submitForm */
  // Получить урл,
  // сделать запрос с данными формы,
  // выполнить реакцию (колбек)
  useEffect(() => {
    if (type === 'submitForm') {
      // устанавливаем handleSubmit в rhf (см. FormBuilder)
      const handler: FormSubmitHandler = async (dirtyValues: any) => {
        await onBeforeActionExecuteHandler();

        setIsSomeActionExecuting(true);

        const mappedFormValues = mapValuesToPlain(dirtyValues, fields);

        if ((rest as SubmitFormAction).preSubmitRequestConfig) {
          await makePreSubmitRequest({
            preSubmitRequestConfig: (rest as TriggerEndpointAction)
              .preSubmitRequestConfig as RequestConfig,
            showConfirmDialog,
            params: mappedFormValues,
          });
        }

        mainService
          .makeActionRequest(
            (rest as SubmitFormAction).requestConfig,
            mappedFormValues
          )
          .then(
            ({
              payload,
              snackbar,
              dialogAlert,
              preventSuccessResponseReactionReasons,
            }) => {
              dispatch(metaActions.setIsSomeActionExecuting(false));

              if (snackbar) enqueueSnackbar(snackbar.text, snackbar.options);
              if (dialogAlert) dialogAlertContext.setDialogState(dialogAlert);

              const alertsId = `${componentContext.businessComponentId}_${componentContext.id}`;

              // TODO везде (здесь, ниже, в TableAction) сделать следующую проверку:
              // if (preventSuccessResponseReactionReasons && !Array.isArray(preventSuccessResponseReactionReasons)) {
              //   throw new Error('preventSuccessResponseReactionReasons must be an array');
              // }
              if (
                preventSuccessResponseReactionReasons &&
                Array.isArray(preventSuccessResponseReactionReasons)
              ) {
                dispatch(
                  alertsFromActionServerResponseActions.setForPage({
                    id: alertsId,
                    alerts: preventSuccessResponseReactionReasons,
                  })
                );
                return;
              }

              if (
                store.getState().alertsFromActionServerResponse[alertsId]
                  ?.length > 0
              ) {
                dispatch(alertsFromActionServerResponseActions.clear(alertsId));
              }

              onActionSuccessfullyExecuted();

              new ReactionController(
                (rest as SubmitFormAction).successResponseReaction,
                pageContext as PageContext,
                enqueueSnackbar,
                payload,
                tableContext,
                tableDialogWithComponentContext
              ).execute(onReactionExecute);
            }
          )
          .catch((error) => {
            if (error.response?.status === 422) {
              if (error.response?.data?.snackbar) {
                enqueueSnackbar(
                  error.response?.data?.snackbar.text,
                  error.response?.data?.snackbar.options
                );
              }

              if (error.response?.data?.dialogAlert) {
                if (error.response?.data?.dialogAlert)
                  dialogAlertContext.setDialogState(
                    error.response.data.dialogAlert
                  );
              }

              if ((rest as SubmitFormAction).validationErrorReaction) {
                new ReactionController(
                  (rest as SubmitFormAction)
                    .validationErrorReaction as Reaction,
                  pageContext as PageContext,
                  enqueueSnackbar,
                  error.response,
                  tableContext,
                  tableDialogWithComponentContext
                ).execute(onReactionExecute);
              }
            }

            console.log('Ошибка', error);
          })
          .finally(() => {
            setIsSomeActionExecuting(false);
          });
      };

      setOnSubmitHandlers((prevHandlers) => {
        return {
          ...prevHandlers,
          [(rest as SubmitFormAction).requestConfig.url]: handler,
        };
      });
    }
    // eslint-disable-next-line
  }, []);
  /** \\submitForm */

  const handleClickAction = (() => {
    switch (type) {
      // showForm обрабатываем выше.
      // case 'showForm':
      //   return () => console.log(type, rest);

      case 'submitForm':
        return () => {
          if ((rest as SubmitFormAction).skipValidation) {
            triggerEndpoint(mapValuesToPlain(getValues(), fields));
          } else {
            setFiredSubmitAction((rest as SubmitFormAction).requestConfig.url);
          }
        };

      // Получить урл, сделать запрос,
      // выполнить реакцию (колбек)
      case 'triggerEndpoint':
        return () => {
          triggerEndpoint();
        };

      case 'redirect':
        return () => {
          onActionSuccessfullyExecuted();

          redirectReaction(rest as RedirectReaction);
        };

      case 'goBack':
        return () => {
          onActionSuccessfullyExecuted();

          history.goBack();
        };

      default:
        return () => console.log('CLICK CLOCK');
    }
  })();

  const handleClick = () => {
    onClick && onClick();
    handleClickAction();
  };

  return renderAction({
    action,
    onClick: handleClick,
  });
};
