import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    OtherUserTimelineElementRoot: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      marginBottom: theme.spacing(1),

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    OtherUserTimelineElementContentWrapper: {
      width: '100%',

      [theme.breakpoints.up('md')]: {
        width: 'calc(100% - 56px)', // 56px - ширина аватарки
      },
    },
    OtherUserTimelineElementContainer: {
      marginRight: 'auto',
      padding: `${theme.spacing(1, 1.5)} !important`,
      flexGrow: 1,
      flexDirection: 'column',

      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    OtherUserTimelineElementAvatar: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        marginRight: theme.spacing(2),
      },
    },
    MobileOtherUserTimelineElementAvatar: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    OtherUserTimelineElementHeaderContainer: {
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
    OtherUserTimelineHeader: {
      [theme.breakpoints.down('md')]: {
        marginBottom: 'unset',
      },
    },
  })
);
