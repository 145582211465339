import {
  EntryPickerInterface,
  FetchOptionsResponsePayload,
  FetchTablePropsResponsePayload,
  FetchTablePropsRequestBody,
  FetchOptionsRequestBody,
} from './EntryPicker.interface';
import { RequestConfig } from '../Main/types.Component';
import { get, post } from '../Api';
import { ServerResponse } from '../Main/types';

class EntryPickerRemote implements EntryPickerInterface {
  fetchOptions = (
    { url, method }: RequestConfig,
    params: FetchOptionsRequestBody
  ) => {
    if (method === 'POST') {
      return post<ServerResponse<FetchOptionsResponsePayload>>(
        url,
        params
      ).then(({ data }) => data);
    }

    return get<ServerResponse<FetchOptionsResponsePayload>>(url, {
      params,
    }).then(({ data }) => data);
  };

  fetchTableProps = (
    { url, method }: RequestConfig,
    params?: FetchTablePropsRequestBody
  ) => {
    if (method === 'GET') {
      return get<ServerResponse<FetchTablePropsResponsePayload>>(url, {
        params,
      }).then(({ data }) => data);
    }

    return post<ServerResponse<FetchTablePropsResponsePayload>>(url, {
      params,
    }).then(({ data }) => data);
  };
}

export default EntryPickerRemote;
