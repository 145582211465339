import React from 'react';
import { Trend } from '../../../services/Main/types.Component';
import { colors, signs } from './helpers';
import useStyles from './Trend.styles';

export default ({ dynamic, value }: Trend) => {
  const classes = useStyles(colors[dynamic])();

  return (
    <span className={classes.trend}>
      {signs[dynamic]}
      {value}
    </span>
  );
};
