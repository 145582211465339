// InputComponent - это проп для BaseTextField, задающий свойства маски react-number-format

import React, { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';

interface NumberFormatMaskProps extends InputBaseComponentProps {
  defaultValue?: any;
  onChange?: any;
}

const InputComponent = (props: NumberFormatMaskProps) => {
  const { inputRef, onChange, ...rest } = props;
  const { min, max, decimalScale, thousandSeparator, allowNegative } = rest;

  const handleValueChange = useCallback(
    (values: { floatValue: any }) => {
      const { floatValue } = values;

      if (!onChange) return;

      onChange({
        target: {
          value: floatValue,
        },
      });
    },
    [onChange]
  );

  return (
    <NumberFormat
      decimalSeparator=","
      {...rest}
      getInputRef={inputRef}
      onValueChange={handleValueChange}
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      isAllowed={({ floatValue }) => {
        if (!floatValue) return true;

        if (min && max) return floatValue >= min && floatValue <= max;

        if (!min && max) return floatValue <= max;

        if (min && !max) return floatValue >= min;

        return true;
      }}
    />
  );
};

export default InputComponent;
