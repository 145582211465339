import faker from 'faker';
import { Color } from '@material-ui/lab';
import { Page } from 'services/Main/types.Page';
import { Chip } from '../../../types.Component';
import { createAlert } from '../highLevelComponents/Form/WithAlerts';

faker.locale = 'ru';

const color: Color = faker.random.arrayElement([
  'success',
  'info',
  'warning',
  'error',
]);

export const allCalendarSTMPageRows = Array.from({ length: 15 }, createRow);

export const calendarSTMPage: Page = {
  type: 'singleComponent',
  title: 'Создание ежегодного ППР',
  component: {
    id: 404,
    businessComponentId: 8,
    type: 'table',
    props: {
      actions: [
        {
          type: 'showForm',
          label: 'Оставить вопрос',
          viewStyle: 'modal',
          variant: 'outlined',
          color: 'primary',
          actions: [
            {
              type: 'submitForm',
              label: 'В путь!',
              variant: 'contained',
              color: 'primary',
              requestConfig: {
                url: '/api/complex/calendar-stm/ask-question',
                method: 'POST',
              },
              successResponseReaction: { type: 'reLoadModule' },
            },
          ],
          form: {
            type: 'markup',
            header: { heading: 'Задать вопрос' },
            fieldGroups: [
              {
                fields: [
                  { type: 'text', name: 'foo', label: 'Введите вопрос' },
                ],
              },
            ],
          },
        },
        {
          type: 'triggerEndpoint',
          label: 'Сохранить',
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/api/complex/calendar-stm/save',
            method: 'GET',
          },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      heading: 'Создание ежегодного ППР',
      requestConfig: { url: '/api/complex/calendar-stm' },
      columns: [
        {
          title: 'Оборудование',
          name: 'equipment',
          options: { formatAs: 'link' },
        },
        { title: 'I', name: 'jan1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'jan2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'jan3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'jan4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'feb1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'feb2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'feb3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'feb4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'mar1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'mar2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'mar3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'mar4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'apr1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'apr2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'apr3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'apr4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'may1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'may2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'may3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'may4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'jun1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'jun2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'jun3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'jun4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'jul1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'jul2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'jul3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'jul4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'aug1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'aug2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'aug3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'aug4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'sep1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'sep2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'sep3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'sep4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'oct1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'oct2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'oct3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'oct4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'nov1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'nov2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'nov3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'nov4', options: { formatAs: 'chip' } },
        { title: 'I', name: 'dec1', options: { formatAs: 'chip' } },
        { title: 'II', name: 'dec2', options: { formatAs: 'chip' } },
        { title: 'III', name: 'dec3', options: { formatAs: 'chip' } },
        { title: 'IV', name: 'dec4', options: { formatAs: 'chip' } },
      ],
      columnExtensions: [
        { columnName: 'equipment', width: 330 },
        { columnName: 'jan1', width: 57 },
        { columnName: 'jan2', width: 57 },
        { columnName: 'jan3', width: 57 },
        { columnName: 'jan4', width: 57 },
        { columnName: 'feb1', width: 57 },
        { columnName: 'feb2', width: 57 },
        { columnName: 'feb3', width: 57 },
        { columnName: 'feb4', width: 57 },
        { columnName: 'mar1', width: 57 },
        { columnName: 'mar2', width: 57 },
        { columnName: 'mar3', width: 57 },
        { columnName: 'mar4', width: 57 },
        { columnName: 'apr1', width: 57 },
        { columnName: 'apr2', width: 57 },
        { columnName: 'apr3', width: 57 },
        { columnName: 'apr4', width: 57 },
        { columnName: 'may1', width: 57 },
        { columnName: 'may2', width: 57 },
        { columnName: 'may3', width: 57 },
        { columnName: 'may4', width: 57 },
        { columnName: 'jun1', width: 57 },
        { columnName: 'jun2', width: 57 },
        { columnName: 'jun3', width: 57 },
        { columnName: 'jun4', width: 57 },
        { columnName: 'jul1', width: 57 },
        { columnName: 'jul2', width: 57 },
        { columnName: 'jul3', width: 57 },
        { columnName: 'jul4', width: 57 },
        { columnName: 'aug1', width: 57 },
        { columnName: 'aug2', width: 57 },
        { columnName: 'aug3', width: 57 },
        { columnName: 'aug4', width: 57 },
        { columnName: 'sep1', width: 57 },
        { columnName: 'sep2', width: 57 },
        { columnName: 'sep3', width: 57 },
        { columnName: 'sep4', width: 57 },
        { columnName: 'oct1', width: 57 },
        { columnName: 'oct2', width: 57 },
        { columnName: 'oct3', width: 57 },
        { columnName: 'oct4', width: 57 },
        { columnName: 'nov1', width: 57 },
        { columnName: 'nov2', width: 57 },
        { columnName: 'nov3', width: 57 },
        { columnName: 'nov4', width: 57 },
        { columnName: 'dec1', width: 57 },
        { columnName: 'dec2', width: 57 },
        { columnName: 'dec3', width: 57 },
        { columnName: 'dec4', width: 57 },
      ],
      columnBands: [
        {
          title: 'Январь',
          children: [
            { columnName: 'jan1' },
            { columnName: 'jan2' },
            { columnName: 'jan3' },
            { columnName: 'jan4' },
          ],
        },
        {
          title: 'Февраль',
          children: [
            { columnName: 'feb1' },
            { columnName: 'feb2' },
            { columnName: 'feb3' },
            { columnName: 'feb4' },
          ],
        },
        {
          title: 'Март',
          children: [
            { columnName: 'mar1' },
            { columnName: 'mar2' },
            { columnName: 'mar3' },
            { columnName: 'mar4' },
          ],
        },
        {
          title: 'Апрель',
          children: [
            { columnName: 'apr1' },
            { columnName: 'apr2' },
            { columnName: 'apr3' },
            { columnName: 'apr4' },
          ],
        },
        {
          title: 'Май',
          children: [
            { columnName: 'may1' },
            { columnName: 'may2' },
            { columnName: 'may3' },
            { columnName: 'may4' },
          ],
        },
        {
          title: 'Июнь',
          children: [
            { columnName: 'jun1' },
            { columnName: 'jun2' },
            { columnName: 'jun3' },
            { columnName: 'jun4' },
          ],
        },
        {
          title: 'Июль',
          children: [
            { columnName: 'jul1' },
            { columnName: 'jul2' },
            { columnName: 'jul3' },
            { columnName: 'jul4' },
          ],
        },
        {
          title: 'Август',
          children: [
            { columnName: 'aug1' },
            { columnName: 'aug2' },
            { columnName: 'aug3' },
            { columnName: 'aug4' },
          ],
        },
        {
          title: 'Сентябрь',
          children: [
            { columnName: 'sep1' },
            { columnName: 'sep2' },
            { columnName: 'sep3' },
            { columnName: 'sep4' },
          ],
        },
        {
          title: 'Октябрь',
          children: [
            { columnName: 'oct1' },
            { columnName: 'oct2' },
            { columnName: 'oct3' },
            { columnName: 'oct4' },
          ],
        },
        {
          title: 'Ноябрь',
          children: [
            { columnName: 'nov1' },
            { columnName: 'nov2' },
            { columnName: 'nov3' },
            { columnName: 'nov4' },
          ],
        },
        {
          title: 'Декабрь',
          children: [
            { columnName: 'dec1' },
            { columnName: 'dec2' },
            { columnName: 'dec3' },
            { columnName: 'dec4' },
          ],
        },
      ],
      fixedColumns: {
        leftColumns: ['equipment'],
      },
      rows: allCalendarSTMPageRows.slice(0, 15),
      alerts: Array.from({ length: faker.datatype.number(2) }, createAlert),
      options: {
        totalRows: allCalendarSTMPageRows.length,
        currentPage: 0,
        pageSize: 20,
        cellClickReaction: {
          type: 'showAsyncModalOnCellClickReaction',
          fetchModalDataRequestConfig: {
            url: '/api/complex/calendar-stm/get-modal-info',
            method: 'POST',
          },
          columnNames: [
            'jan1',
            'jan2',
            'jan3',
            'jan4',
            'feb1',
            'feb2',
            'feb3',
            'feb4',
            'mar1',
            'mar2',
            'mar3',
            'mar4',
            'apr1',
            'apr2',
            'apr3',
            'apr4',
            'may1',
            'may2',
            'may3',
            'may4',
            'jun1',
            'jun2',
            'jun3',
            'jun4',
            'jul1',
            'jul2',
            'jul3',
            'jul4',
            'aug1',
            'aug2',
            'aug3',
            'aug4',
            'sep1',
            'sep2',
            'sep3',
            'sep4',
            'oct1',
            'oct2',
            'oct3',
            'oct4',
            'nov1',
            'nov2',
            'nov3',
            'nov4',
            'dec1',
            'dec2',
            'dec3',
            'dec4',
          ],
        },
      },
    },
  },
};

function getRowViewConfig() {
  if (Math.random() * 1000 < 800) return undefined;

  const backgroundColorMap = {
    error: 'rgb(253, 236, 234)',
    warning: 'rgb(255, 244, 229)',
    info: 'rgb(232, 244, 253)',
    success: 'rgb(237, 247, 237)',
  };

  const colorMap = {
    error: 'rgb(97, 26, 21)',
    warning: 'rgb(102, 60, 0)',
    info: 'rgb(13, 60, 97)',
    success: 'rgb(30, 70, 32)',
  };

  return {
    backgroundColor: backgroundColorMap[color],
    color: colorMap[color],
  };
}

function createRow() {
  return {
    id: faker.datatype.uuid(),
    equipment: {
      href: `/equipments/${faker.datatype.uuid()}`,
      label: faker.commerce.productName(),
    },
    jan1: generateCellValue(),
    jan2: generateCellValue(),
    jan3: generateCellValue(),
    jan4: generateCellValue(),
    feb1: generateCellValue(),
    feb2: generateCellValue(),
    feb3: generateCellValue(),
    feb4: generateCellValue(),
    mar1: generateCellValue(),
    mar2: generateCellValue(),
    mar3: generateCellValue(),
    mar4: generateCellValue(),
    apr1: generateCellValue(),
    apr2: generateCellValue(),
    apr3: generateCellValue(),
    apr4: generateCellValue(),
    may1: generateCellValue(),
    may2: generateCellValue(),
    may3: generateCellValue(),
    may4: generateCellValue(),
    jun1: generateCellValue(),
    jun2: generateCellValue(),
    jun3: generateCellValue(),
    jun4: generateCellValue(),
    jul1: generateCellValue(),
    jul2: generateCellValue(),
    jul3: generateCellValue(),
    jul4: generateCellValue(),
    aug1: generateCellValue(),
    aug2: generateCellValue(),
    aug3: generateCellValue(),
    aug4: generateCellValue(),
    sep1: generateCellValue(),
    sep2: generateCellValue(),
    sep3: generateCellValue(),
    sep4: generateCellValue(),
    oct1: generateCellValue(),
    oct2: generateCellValue(),
    oct3: generateCellValue(),
    oct4: generateCellValue(),
    nov1: generateCellValue(),
    nov2: generateCellValue(),
    nov3: generateCellValue(),
    nov4: generateCellValue(),
    dec1: generateCellValue(),
    dec2: generateCellValue(),
    dec3: generateCellValue(),
    dec4: generateCellValue(),
    viewConfig: getRowViewConfig(),
  };
}

function generateCellValue(): Chip | null {
  const type = faker.datatype.number(3);
  const colorMap = ['#F9BF2A', '#F67C80', '#82bd3f', '#5a9dd0'];

  if (Math.random() * 100 > 50) return null;

  return {
    label: (type + 1).toString(),
    avatar: {
      fullName: faker.random.arrayElement([
        'Игорь Петров',
        'Валентин Степанов',
        'Артём Акимов',
        'Виктор Жуков',
      ]),
    },
    clickable: true,
    htmlColor: colorMap[type],
    size: 'small',
  };
}
