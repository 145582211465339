import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    gridItem: {
      // Скрывает grid элементы с пустым содержимым
      '&:empty': {
        display: 'none',
      },
    },
  })
);
