import { createStyles, fade, makeStyles } from '@material-ui/core';
import { Theme } from '../../../../../../theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    droppableItem: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(0.5),
      bottom: theme.spacing(1),
      left: theme.spacing(0.5),
      border: `2px dotted ${theme.palette.action.hover}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'inherit',
      backgroundColor: fade(
        theme.palette.action.hover,
        theme.palette.action.hoverOpacity
      ),

      '&.isOver': {
        backgroundColor: fade(
          theme.palette.primary.light,
          theme.palette.action.hoverOpacity
        ),
      },
    },
  })
);
