import React from 'react';
import MuiRatingField from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import useStyles from './RatingField.styles';
import { Rating, RatingFieldValue } from '../../../services/Main/types.Field';

export interface RatingFieldProps extends Rating {
  onChange: (value: number) => void;
  value: RatingFieldValue;
}

/**
 * В поле есть баг, невозможно выбрать нулевое значение с клавиатуры.
 *
 * https://github.com/mui/material-ui/issues/25533
 */
export default ({
  disabled,
  defaultValue,
  label,
  onChange,
  value,
  name,
}: RatingFieldProps) => {
  const classes = useStyles({ disabled });

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number | null
  ) => {
    // При установке "0 звезд" прилетает null
    if (newValue === null) {
      onChange(0);
      return;
    }

    if (typeof newValue !== 'number' || isNaN(newValue)) {
      onChange(0);
      console.warn(
        `В обработчик handleChange RatingField: ${name}, прилетели неожиданные данные. Преобразовали значение в 0`
      );
      return;
    }

    onChange(newValue);
  };

  return (
    <Box component="fieldset" className={classes.fieldset}>
      <Typography
        component="legend"
        className={clsx({ [classes.disabled]: disabled })}
      >
        {label}
      </Typography>
      <MuiRatingField
        precision={0.5}
        defaultValue={defaultValue}
        disabled={disabled}
        onChange={handleChange}
        value={value}
        name={name}
      />
    </Box>
  );
};
