import faker from 'faker';
import { Page } from 'services/Main/types.Page';

faker.locale = 'ru';

const createRow = () => {
  return {
    id: faker.datatype.uuid(),
    code: `${faker.datatype.number({
      min: 1000,
      max: 9999,
    })}-${faker.datatype.number({
      min: 100,
      max: 999,
    })}/${faker.datatype.number({
      min: 10,
      max: 99,
    })}`,
    type: faker.lorem.word(),
    author: `${faker.name.lastName()} ${faker.name.firstName()}`,
  };
};

export const allSimpleTablePageRows = Array.from({ length: 5 }, createRow);

export const headerTablePage: Page = {
  type: 'singleComponent',
  title: 'Таблица с наполненным header',
  component: {
    id: 404,
    businessComponentId: 40,
    type: 'table',
    props: {
      header: {
        heading: 'Таблица с наполненным заголовком',
        preHeadingText: faker.lorem.word(),
        preHeadingIcon: {
          type: 'icon',
          name: 'mui:DirectionsWalk',
          props: { color: 'error', fontSizeInPx: 18 },
        },
        subHeadings: [
          ['Страна', faker.address.country()],
          [
            'Создано',
            {
              type: 'date',
              value: '2020-04-15T16:33:23.3004811+03:00',
              formatAs: 'dateTime',
            },
          ],
          [
            null,
            {
              type: 'link',
              href: '/issues/916a3c9c-ea11-465b-9b23-5b53150e9726',
              label: 'Ссылка куда-то',
              icon: 'mui:Link',
            },
          ],
        ],
      },
      requestConfig: { url: '/api/table/simple' },
      columns: [
        { title: 'Номер', name: 'code' },
        { title: 'Тип', name: 'type' },
        { title: 'Инициатор', name: 'author' },
      ],
      rows: allSimpleTablePageRows.slice(0, 5),
      options: {
        totalRows: allSimpleTablePageRows.length,
        currentPage: 0,
        pageSize: 20,
      },
    },
  },
};
