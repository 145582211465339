import { isToday, isYesterday, parseISO } from 'date-fns';
import { formatDate } from '../../../../../../utils/formatDate';
import { FormatMessage } from '../../../../../../locale/hooks';
import isValidDate from '../../../../../../utils/isValidDate';

function getFormatDateTime(ISOString: string, formatMessage: FormatMessage) {
  const date = parseISO(ISOString);

  if (!isValidDate(date)) {
    return null;
  }

  if (isToday(date)) {
    return `${formatMessage('today')} ${formatDate(date, 'time')}`;
  }

  if (isYesterday(date)) {
    return `${formatMessage('yesterday')} ${formatDate(date, 'time')}`;
  }

  return formatDate(date, 'dateTime');
}

export default getFormatDateTime;
