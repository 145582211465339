import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    tooltipRoot: {
      fontWeight: 'unset',
    },
    chipContainer: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: 22,
      marginRight: theme.spacing(0.25),
      marginLeft: theme.spacing(0.25),
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 16,
      borderColor: 'inherit',
      cursor: 'pointer',
    },
    avatar: {
      margin: '2px 0 0 !important',
      width: 18,
      height: 18,
      fontSize: '0.5rem !important',
      color: theme.palette.text.primary,
    },
    label: {
      marginTop: '2px',
      lineHeight: 1.32,
    },
    icon: {
      margin: '0 0 3px !important',
      width: 16,
      height: 16,
    },
  })
);
