import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';
import { Grid, IconButton } from '@material-ui/core';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import clsx from 'clsx';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import useStyles from './ImageWrapper.styles';

const ImageWrapper = ({ children }: PropsWithChildren<unknown>) => {
  const classes = useStyles();

  const fileViewerToolbarContainer = document.getElementById(
    'fileViewerToolbar'
  ) as Element;

  const initialTransformScale = {
    maxScale: 8,
    minScale: 1,
  };

  return (
    <Grid container justify="flex-end">
      <TransformWrapper centerOnInit {...initialTransformScale}>
        {({ zoomIn, zoomOut, resetTransform, state: { scale } }) => (
          <>
            {createPortal(
              <>
                <IconButton
                  onClick={() => zoomIn()}
                  disabled={scale === initialTransformScale.maxScale}
                >
                  <ZoomInIcon />
                </IconButton>
                <IconButton
                  onClick={() => zoomOut()}
                  disabled={scale === initialTransformScale.minScale}
                >
                  <ZoomOutIcon />
                </IconButton>
                <IconButton
                  onClick={() => resetTransform()}
                  disabled={scale === initialTransformScale.minScale}
                >
                  <FindReplaceIcon />
                </IconButton>
              </>,
              fileViewerToolbarContainer
            )}
            <TransformComponent
              wrapperClass={classes.wrapper}
              contentClass={clsx(classes.content, { scaled: scale > 1 })}
            >
              {children}
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </Grid>
  );
};

export default ImageWrapper;
