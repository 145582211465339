import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    timelineContainer: {
      display: 'flex',
      padding: theme.spacing(2, 1),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(1.5, 1),
      },
    },
    alignRight: {
      justifyContent: 'flex-end',
      paddingLeft: theme.spacing(2),
    },
    alignLeft: {
      justifyContent: 'flex-start',
      paddingRight: theme.spacing(2),
    },
    highlight: {
      backgroundColor: 'rgba(16, 156, 241, 0.04)',
      userSelect: 'none',
    },
    menuItem: {
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1),
      },
    },
  })
);
