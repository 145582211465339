import { RequestConfig } from '../Main/types.Component';
import { ServerResponse } from '../Main/types';
import {
  FormHistoryInterface,
  FormHistoryResponse,
} from './FormHistory.interface';
import {
  formHistoryGenerator,
  currentUser,
} from './helpers/formHistoryGenerator';
import { issuesElementPageHeader } from '../Main/local/stubs/issuesElementPage';

formHistoryGenerator.seedInitialMessagesForUser(currentUser);

class FormHistoryRemote implements FormHistoryInterface {
  fetchFormHistory = (config: RequestConfig) => {
    return Promise.resolve<ServerResponse<FormHistoryResponse>>({
      isSuccess: true,
      payload: {
        header: issuesElementPageHeader,
        elements: formHistoryGenerator.get(),
      },
    });
  };
}

export default FormHistoryRemote;
