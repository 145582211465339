import React from 'react';
import {
  ButtonBase,
  ListItemIcon,
  MenuItem,
  Typography,
} from '@material-ui/core';
import Icon from '../Icon/Icon';
import {
  FormAction,
  TableAction,
} from '../../../services/Main/types.Component';
import useIsSomeActionExecuting from '../FormAction/useIsSomeActionExecuting';
import useStyles from './ExtraActions.styles';

interface ExtraActionsMenuItemProps {
  action: FormAction | TableAction;
  onClick: () => void;
}

const ExtraActionsMenuItem = ({
  action,
  onClick,
}: ExtraActionsMenuItemProps) => {
  const classes = useStyles();
  const { icon, label, type } = action;
  const { isSomeActionExecuting } = useIsSomeActionExecuting();

  return (
    <MenuItem
      disabled={isSomeActionExecuting}
      classes={{
        root: classes.menuItemRoot,
      }}
    >
      <ButtonBase
        className={classes.menuItemButton}
        type={type === 'submitForm' ? 'submit' : 'button'}
        onClick={onClick}
      >
        <ListItemIcon style={{ minWidth: 24 }}>
          {icon && <Icon definition={icon} />}
        </ListItemIcon>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </ButtonBase>
    </MenuItem>
  );
};

export default ExtraActionsMenuItem;
