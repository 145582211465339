import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { File } from '../../../../../services/Main/types.Field';
import { supportedFileViewType } from '../../../../lowLevel/FileUpload/FileViewer/plugins';
import useStyles from './Attachment.styles';
import FileIcon from '../../../../../svg/File';
import { getFileSize } from '../../../../lowLevel/FileListItem/helpers';
import FileViewer from '../../../../lowLevel/FileUpload/FileViewer';
import { useFormatMessage } from '../../../../../locale';
import getFileType from './getFileType';

interface Props {
  attachment: File;
}

function Attachment({ attachment }: Props) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { name, size, url, mimeType } = attachment;
  const isSupportedFileView = supportedFileViewType.includes(mimeType);

  const [isPreviewModeOpen, setIsPreviewModeOpen] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handlePreviewClick = () => {
    setIsPreviewModeOpen(true);
  };

  const handlePreviewClose = () => {
    setIsPreviewModeOpen(false);
  };

  const formattedSize = getFileSize(+size);

  return (
    <>
      <Box className={classes.container} onClick={handleOpen}>
        <FileIcon className={classes.icon}>{getFileType(mimeType)}</FileIcon>
        <div className={classes.fileInfo}>
          <Typography className={classes.name}>{name}</Typography>
          <Typography className={classes.size}>{formattedSize}</Typography>
        </div>
        {isSupportedFileView && (
          <FileViewer
            file={attachment}
            open={isPreviewModeOpen}
            handleClose={handlePreviewClose}
          />
        )}
      </Box>
      <Dialog open={isDialogOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Typography variant="h6" component="p" color="textPrimary">
            {name}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {formattedSize}
          </Typography>
        </DialogTitle>
        <DialogActions>
          {isSupportedFileView && (
            <Button onClick={handlePreviewClick} color="primary">
              {formatMessage('preview')}
            </Button>
          )}
          <Button
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            download
            color="primary"
            onClick={handleClose}
          >
            {formatMessage('download')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Attachment;
