import React from 'react';
import { DocRenderer } from 'react-doc-viewer';
import { useIntl } from 'react-intl';
import authService from 'services/Auth';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './MicrosoftRenderer.styles';
import { useFormatMessage } from '../../../../../../locale';
import { getFileUri } from '../../helpers';

const MicrosoftRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
  const formatMessage = useFormatMessage();
  const classes = useStyles();
  const { locale } = useIntl();

  if (!currentDocument) return null;

  const uri = getFileUri(currentDocument.uri, authService.token);

  return (
    <iframe
      title="msdoc-iframe"
      src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
        uri
      )}&ui=${locale}&rs=${locale}`}
      className={classes.wrapper}
    >
      <Grid container alignItems="center" justify="center">
        <Typography align="center" variant="body2">
          {formatMessage('browserNotSupportFileViewing')}
        </Typography>
      </Grid>
    </iframe>
  );
};

export const MicrosoftFileTypes = {
  doc: ['doc', 'application/msword'],
  docx: [
    'docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  xls: ['xls', 'application/vnd.ms-excel'],
  xlsx: [
    'xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  ppt: ['ppt', 'application/vnd.ms-powerpoint'],
  pptx: [
    'pptx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ],
};

MicrosoftRenderer.weight = 3;
MicrosoftRenderer.fileTypes = [
  ...MicrosoftFileTypes.doc,
  ...MicrosoftFileTypes.docx,
  ...MicrosoftFileTypes.xls,
  ...MicrosoftFileTypes.xlsx,
  ...MicrosoftFileTypes.ppt,
  ...MicrosoftFileTypes.pptx,
];
// Отключает дефолтную загрузку библиотеки
MicrosoftRenderer.fileLoader = ({ fileLoaderComplete }) => fileLoaderComplete();

export default MicrosoftRenderer;
