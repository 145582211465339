import faker from 'faker';
import { format } from 'date-fns';
import { Page } from 'services/Main/types.Page';
import { TableRow } from '../../../../types.Component';

faker.locale = 'ru';

const createRow = (): TableRow => {
  const equipmentCategory = faker.commerce.productMaterial();

  return {
    id: faker.datatype.uuid(),
    status: faker.random.arrayElement(['Исправно', 'Неисправно']),
    equipment: {
      href: `/equipments/${faker.datatype.uuid()}`,
      label: `${equipmentCategory} ${faker.commerce.color()} ${faker.commerce.product()}`,
    },
    shop: faker.random.arrayElement([
      {
        label: '004 Красногорск',
        href: `/shop/${faker.datatype.uuid()}`,
      },
      {
        label: '005 Химки',
        href: `/shop/${faker.datatype.uuid()}`,
      },
    ]),
    count: `${faker.datatype.number({ min: 1, max: 99 })} шт.`,
    entryDate: format(
      faker.date.between(
        '2020-01-01T00:00:00.000Z',
        '2021-01-01T00:00:00.000Z'
      ),
      'dd.MM.yyyy'
    ),
    equipmentCategory,
  };
};

const getTableRows = (length: number) => Array.from({ length }, createRow);

const rows = getTableRows(529).sort((a, b) =>
  a.equipment.label.localeCompare(b.equipment.label)
);

export const groupingTableStubs: Page = {
  type: 'singleComponent',
  title: 'Таблица с группировкой',
  component: {
    id: 404,
    businessComponentId: 36,
    type: 'table',
    props: {
      heading: 'Таблица с группировкой',
      requestConfig: { url: '/api/table/simple' },
      groupingConfig: {
        defaultGrouping: [
          {
            columnName: 'shop',
          },
          {
            columnName: 'equipmentCategory',
          },
          {
            columnName: 'status',
          },
        ],
      },
      columnVisibilityConfig: {
        enabled: true,
        defaultHiddenColumnNames: ['shop'],
      },
      columns: [
        {
          title: 'Магазин',
          name: 'shop',
          options: {
            formatAs: 'link',
          },
        },
        {
          title: 'Категория оборудования',
          name: 'equipmentCategory',
          options: {
            formatAs: 'cutTextWithTooltip',
          },
        },
        {
          title: 'Статус',
          name: 'status',
        },
        {
          title: 'Оборудование',
          name: 'equipment',
          options: {
            formatAs: 'link',
          },
        },
        {
          title: 'Дата ввода',
          name: 'entryDate',
        },
        {
          title: 'Количество',
          name: 'count',
        },
      ],
      rows,
      options: {
        totalRows: rows.length,
        currentPage: 0,
        pageSize: rows.length,
      },
    },
  },
};
