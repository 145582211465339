import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import Link from '../../Link';
import Icon from '../../Icon';
import { BreadcrumbItem as BreadcrumbItemProps } from '../../../../services/Main/types.Page';
import useStyles from './BreadcrumbItem.styles';

interface Props {
  breadcrumb: BreadcrumbItemProps;
}

const BreadcrumbItem = ({ breadcrumb }: Props) => {
  const classes = useStyles();
  const { url, label, icon } = breadcrumb;

  if (!label && !icon) {
    throw new Error(
      `Недостаточно данных для отображения элемента хлебной крошки. Требуется указать текст (label) или иконку (icon) или оба этих значения для корректного отображения элемента. Элемент: ${JSON.stringify(
        breadcrumb
      )}`
    );
  }

  const buttonProps: ButtonProps = {
    size: 'small',
    startIcon: icon && <Icon definition={icon} />,
    classes: {
      root: classes.item,
      startIcon: clsx(classes.startIcon, { noLabel: !label }),
    },
  };

  if (url) {
    return (
      <Link to={url}>
        <Tooltip title={label || ''} arrow>
          <Button {...buttonProps} color="primary">
            {label ? <span className={classes.label}>{label}</span> : null}
          </Button>
        </Tooltip>
      </Link>
    );
  }

  // Span нужен для того, чтобы корректно работал Tooltip.
  // Это решение соответствует документации mui.
  // https://v4.mui.com/components/tooltips/#disabled-elements
  return (
    <Tooltip title={label || ''} arrow>
      <span>
        <Button {...buttonProps} disabled>
          {label ? <span className={classes.label}>{label}</span> : null}
        </Button>
      </span>
    </Tooltip>
  );
};

export default BreadcrumbItem;
