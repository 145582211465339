import {
  DirtyFormValues,
  FormValues,
} from '../../../../services/Main/types.Component';
import { InteractiveFormState } from '../../../../services/GlobalSocket/GlobalSocketService';

/**
 * Input:
 * {
 *   fieldName1: '',
 *   fieldName2: {...},
 * }
 *
 * Output:
 * [
 *  {
 *    name: 'fieldName1',
 *    value: '',
 *  },
 *  {
 *    name: 'fieldName2',
 *    value: {...}
 *  }
 * ]
 */
export function mapValuesToInteractiveFormState(
  values: FormValues | DirtyFormValues
): InteractiveFormState[] {
  return Object.entries(values).map(([fieldName, fieldValue]) => {
    return {
      name: fieldName,
      value: fieldValue,
    };
  });
}
