import {
  IconDefinition,
  IconWithPropsDefinition,
  IconWithSvgDefinition,
} from 'services/Main/types.common';

export function getProps(
  definition: IconDefinition
): IconWithPropsDefinition | IconWithSvgDefinition {
  const fallbackDefinition: IconWithPropsDefinition = {
    type: 'icon',
    name: definition as string,
    props: { color: undefined },
  };

  return typeof definition === 'object' ? definition : fallbackDefinition;
}
