import React from 'react';
import { SnackbarKey } from 'notistack';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SnackbarCloseButton from '../SnackbarCloseButton';
import { Link as LinkProps } from '../../../../services/Main/types.Component';

interface SnackbarLinkProps {
  link: LinkProps;
}

const buttonStyles = {
  color: '#fff',
};

function SnackbarLink({ link }: SnackbarLinkProps) {
  return (snackbarId: SnackbarKey) => {
    return (
      <>
        <Button
          component={Link}
          to={link.href}
          target={link.external ? '_blank' : undefined}
          style={buttonStyles}
        >
          {link.label}
        </Button>
        {SnackbarCloseButton(snackbarId)}
      </>
    );
  };
}

export default SnackbarLink;
