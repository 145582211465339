import React from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';
import { useFormatMessage } from '../../../locale';
import useStyles from './PasswordForgotPage.styles';
import { ForgotPasswordResponse } from '../../../services/Password/types';

interface ForgotPasswordFormProps {
  isLoading: boolean;
  response: ForgotPasswordResponse | null;
  onSubmit: ({
    login,
    email,
  }: UnpackNestedValue<{ login: string; email: string }>) => void;
}

export default function PasswordForgotPageForm({
  isLoading,
  response,
  onSubmit,
}: ForgotPasswordFormProps) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const { register, handleSubmit, errors } = useForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card raised className={classes.card}>
        <Grid container>
          <Grid item xs={12} className={classes.marginBottom}>
            <Typography className={classes.title}>
              {formatMessage('restoringAccess')}
            </Typography>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justify="center"
            className={classes.marginBottom}
          >
            <Grid item xs={10} className={classes.marginBottom}>
              <Typography className={classes.infoTextHeader}>
                {formatMessage('forgotPassword')}
              </Typography>
              <Typography className={classes.infoText}>
                {formatMessage('forgotPasswordText')}
              </Typography>
            </Grid>
            <Grid item xs={10} className={classes.marginBottom}>
              <TextField
                size="small"
                color="primary"
                variant="outlined"
                name="login"
                label={
                  <>
                    <span style={{ color: 'red' }}>*</span>
                    {formatMessage('login')}
                  </>
                }
                fullWidth
                inputRef={register({
                  required: formatMessage('requiredField'),
                })}
                error={!!errors.login}
                helperText={errors.login && errors.login.message}
              />
            </Grid>
            <Grid item xs={10} className={classes.marginBottom}>
              <TextField
                size="small"
                color="primary"
                variant="outlined"
                name="email"
                label={
                  <>
                    <span style={{ color: 'red' }}>*</span>
                    Email
                  </>
                }
                placeholder="email@example.com"
                fullWidth
                inputRef={register({
                  required: formatMessage('requiredField'),
                })}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Grid item xs={12} container justify="center">
            <Grid item xs={10} container justify="flex-end">
              {response && !response.isSuccess && (
                // @ts-ignore fixed at MUI v5.
                <Alert severity="error" component={Box} mb={3} width="100%">
                  {response.payload.title && (
                    <AlertTitle>{response.payload.title}</AlertTitle>
                  )}
                  {response.payload.message}
                </Alert>
              )}
              <Grid item>
                <Box mb={2}>
                  <Button
                    disabled={isLoading}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {formatMessage('send')}
                    {isLoading && (
                      <Box ml={1}>
                        <CircularProgress size="0.9rem" />
                      </Box>
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </form>
  );
}
