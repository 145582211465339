import { createStyles, makeStyles } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.additionalPalette.title,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    quantity: {
      fontSize: '13px',
      color: grey[500],
    },
    [theme.breakpoints.down('sm')]: {
      searchInput: {
        width: '100%',
        marginBottom: theme.spacing(2),
      },
    },
    actionsContainer: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    tableModulesContainer: {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
    requiredFilterFormContainer: {
      display: 'none',
      position: 'relative',
      paddingRight: theme.spacing(4),

      [theme.breakpoints.up('md')]: {
        display: 'block',
      },

      '&.showDivider': {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          right: theme.spacing(2),
          transform: 'translateY(-50%)',
          width: '1px',
          height: '40%',
          background: theme.palette.divider,
        },
      },
    },
  })
);
