import React from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import get from 'lodash.get';
import { LinearProgressbar } from 'components/lowLevel/LinearProgressbar';
import { File, FileUpload as FileUploadProps } from 'services/Main/types.Field';
import FileListItem from '../../FileListItem';
import useStyles from './DropZoneFileUpload.styles';
import {
  createRemoveHandler,
  useOnDropHandler,
  useProgress,
} from './DropZoneFileUploader.handlers';
import { HandleChangePending } from './types';
import { useFormatMessage } from '../../../../locale';

interface OtherProps {
  value?: any;
  onChange?: any;
  handleChangePending?: HandleChangePending;
}

const DropZoneFileUpload = ({
  name,
  value,
  label,
  disabled,
  onChange,
  handleChangePending,
  accept,
}: FileUploadProps & OtherProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const { errors } = useFormContext();
  const error = get(errors, name);

  const files: File[] = value || [];

  const [
    progress,
    handleUploadProgress,
    resetProgress,
    cancelRequest,
    setCancelRequest,
  ] = useProgress();

  const onDrop = useOnDropHandler(
    files,
    name,
    !!disabled,
    onChange,
    handleUploadProgress,
    resetProgress,
    setCancelRequest,
    handleChangePending
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });
  const handleRemove = createRemoveHandler(files, onChange);

  return (
    <div>
      <div className={classes.label}>{label}</div>
      <div
        className={clsx(classes.root, {
          active: isDragActive,
          disabled,
          hidden: disabled,
          error: !!error,
        })}
        {...getRootProps({
          disabled,
        })}
      >
        <input
          name={name}
          accept={accept && accept.join(',')}
          {...getInputProps({ disabled })}
          multiple
        />
        {isDragActive && !disabled ? (
          <p>{formatMessage('dropFilesHere')}</p>
        ) : (
          <p>{formatMessage('dragAndDropFile')}</p>
        )}
      </div>
      {error?.message && <div className={classes.error}>{error.message}</div>}
      {progress && progress.percent < 100 && (
        <LinearProgressbar {...progress} cancelRequest={cancelRequest} />
      )}
      {files.length > 0 &&
        files.map((file: File) => {
          return (
            // TODO предусмотреть превент загрузки одинаковых файлов
            <FileListItem
              key={`${file.name}${file.id}`}
              file={file}
              handleRemove={
                disabled ? undefined : handleRemove(file.id.toString())
              }
            />
          );
        })}
    </div>
  );
};

export default DropZoneFileUpload;
