import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Alert, Paragraph } from 'services/Main/types.Component';

export default ({ title, body, ...rest }: Alert) => (
  <MuiAlert {...rest}>
    {title && <AlertTitle>{title}</AlertTitle>}
    {body.map(printParagraph)}
  </MuiAlert>
);

function printParagraph({ title, text }: Paragraph) {
  return (
    <div key={`${title}_${text}`}>
      {title ? <b>{`${title}: `}</b> : ''}
      {text}
    </div>
  );
}
