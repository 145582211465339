import React, {
  isValidElement,
  cloneElement,
  createElement,
  useContext,
  useCallback,
} from 'react';
import {
  FieldValues,
  useController,
  ControllerProps,
  useFormContext,
} from 'react-hook-form';
import FormBuilderContext, {
  SelectOptions,
} from 'components/lowLevel/FormBuilder/FormBuilderContext';
import { ComponentContext } from 'components/creational/ComponentLoader/ComponentLoader.context';
import { FormContext } from '../../../highLevel/Form/Form.context';
import GlobalSocketConnectionContext from '../../../../contexts/GlobalSocketConnectionContext';
import { useInterfaceBlocker } from '../../../../utils/hooks/useInterfaceBlocker';
import {
  mapValuesToPlain,
  convertFieldValueFromServerToClient,
} from '../../FormBuilder/helpers';
import { DirtyFormValue } from '../../../../services/Main/types.Component';
import { FormChangedResponse } from '../../../../services/GlobalSocket/GlobalSocketService';
import { Field } from '../../../../services/Main/types.Field';
import Record from '../../../../svg/Record';
import { mapValuesToInteractiveFormState } from './helpers';

type NativeInputs = 'input' | 'select' | 'textarea';

const Controller = <
  TAs extends React.ReactElement | React.ComponentType<any> | NativeInputs,
  TFieldValues extends FieldValues = FieldValues
>(
  props: ControllerProps<TAs, TFieldValues>
) => {
  const { globalSocketRules } = useContext(FormContext);
  const { fields, fieldsByName, setSelectOptions, selectOptions } =
    useContext(FormBuilderContext);
  const component = useContext(ComponentContext);
  const { connection } = useContext(GlobalSocketConnectionContext);
  const { getValues, setValue } = useFormContext();
  const { blockInterface, unblockInterface } = useInterfaceBlocker();

  const {
    rules,
    as,
    render,
    defaultValue,
    control,
    onFocus,
    onChange: onChangeFromProps,
    ...otherControllerProps
  } = props;
  const { field, meta } = useController(props);

  const { onChange } = field;
  const arrayOfDetectRegex = /\[\d+]/g;

  const handleResponse = useCallback(
    (response: FormChangedResponse) => {
      console.log('[WS-IF] response:', response);

      /**
       * Сортрируем response для того, чтобы избежать ситуации, при которой
       * конкретные строки arrayOf перезапишут инструкции для всего arrayOf
       *
       * Исходный response:
       * arrayOf
       * arrayOf[0].shop
       * arrayOf[1].equipment
       *
       * Отсортированный response:
       * arrayOf[0].shop
       * arrayOf[1].equipment
       * arrayOf
       */
      const sortedResponse = [...response].sort((a, b) =>
        b.name.localeCompare(a.name)
      );

      const { newSelectOptionsState, newValueState } = sortedResponse.reduce(
        (acc, fieldChangeState) => {
          const { options, value, name } = fieldChangeState;

          if (Array.isArray(options)) {
            acc.newSelectOptionsState[name] = options;
          }

          // Если прилетел value, то конвертируем и подставляем его
          if ('value' in fieldChangeState) {
            /**
             * Определяет имя поля которое изменилось.
             * Если поле дочернее от arrayOf, т.е
             * "*Имя arrayOf*.[*индекс строки*].*Дочернее имя поля*",
             * то убирает индекс - "*Имя arrayOf*.*Дочернее имя поля*".
             * Именно по такому ключу хранятся пропсы для дочерних полей arrayOf
             */
            const fieldName = arrayOfDetectRegex.test(otherControllerProps.name)
              ? otherControllerProps.name.replace(arrayOfDetectRegex, '')
              : otherControllerProps.name;

            acc.newValueState[name] = convertFieldValueFromServerToClient(
              fieldsByName[fieldName],
              value
            );

            // Ищем пропсы поля, для которого прилетел value
            const changeFieldProps: Field | undefined = fieldsByName[name];

            /**
             * Если они есть и тип === 'arrayOf' и value === [], то пробегаемся
             * по пулам в newSelectOptionsState и удаляем (устанавливаем null,
             * вместо [...])
             */
            if (
              changeFieldProps &&
              changeFieldProps.type === 'arrayOf' &&
              Array.isArray(value) &&
              value.length === 0
            ) {
              Object.keys(acc.newSelectOptionsState).forEach((fName) => {
                if (fName.match(arrayOfDetectRegex) && fName.includes(name)) {
                  acc.newSelectOptionsState[fName] = null;
                }
              });
            }
          }

          return acc;
        },
        { newSelectOptionsState: { ...selectOptions }, newValueState: {} } as {
          newSelectOptionsState: SelectOptions;
          newValueState: Record<string, DirtyFormValue>;
        }
      );

      setSelectOptions(newSelectOptionsState);

      Object.keys(newValueState).forEach((fieldName) => {
        setValue(fieldName, newValueState[fieldName]);
      });

      unblockInterface();
    },
    [
      arrayOfDetectRegex,
      fieldsByName,
      otherControllerProps,
      selectOptions,
      setSelectOptions,
      setValue,
      unblockInterface,
    ]
  );

  const onChangeProxy: (...args: any[]) => void = (...args) => {
    const prevFormState = getValues();
    onChangeFromProps && onChangeFromProps();
    onChange(...args);

    const fieldName = arrayOfDetectRegex.test(otherControllerProps.name)
      ? otherControllerProps.name.replace(arrayOfDetectRegex, '')
      : otherControllerProps.name;

    if (globalSocketRules?.interactiveFormFields?.includes(fieldName)) {
      const formState = getValues();

      const requestArgs = {
        prevFormState: mapValuesToInteractiveFormState(
          mapValuesToPlain(prevFormState, fields)
        ),
        formState: mapValuesToInteractiveFormState(
          mapValuesToPlain(formState, fields)
        ),
        componentId: component.id,
        businessComponentId: component.businessComponentId,
        changedField: otherControllerProps.name,
      };

      console.log('[WS-IF] request:', requestArgs);

      blockInterface();
      connection?.emitFormChanged(requestArgs).then(handleResponse);
    }
  };

  const componentProps = {
    ...otherControllerProps,
    ...field,
    onChange: onChangeProxy,
  };

  if (as) {
    if (isValidElement(as)) {
      return cloneElement(as, componentProps);
    }
    return createElement(as as NativeInputs, componentProps as any);
  }

  if (render) {
    return render(field, meta);
  }

  return null;
};

export default Controller;
