import faker from 'faker';
import { Color } from '@material-ui/lab';
import { Page } from 'services/Main/types.Page';
import { Alert, Paragraph } from '../../../../types.Component';

faker.locale = 'ru';

function createRow(): Paragraph {
  return {
    title: Math.random() * 10 < 5 ? faker.name.lastName() : undefined,
    text: faker.lorem.words(10),
  };
}

export function createAlert(): Alert {
  const color: Color = faker.random.arrayElement([
    'success',
    'info',
    'warning',
    'error',
  ]);

  return {
    title: faker.hacker.phrase(),
    body: Array.from({ length: faker.datatype.number(10) }, createRow),
    color,
    severity: color,
  };
}

export const withAlertsFormPage: Page = {
  type: 'singleComponent',
  title: 'Простая форма с алертом',
  component: {
    id: 100,
    businessComponentId: 32,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          label: 'Отправить',
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/api/form/response-alerts',
          },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Простая форма с алертом' },
        fieldGroups: [
          {
            label: 'Название группы',
            fields: [
              { type: 'text', name: 'comment', label: 'Комментарий' },
              { type: 'text', name: 'comment2', label: 'Комментарий 2' },
            ],
          },
        ],
      },
      alerts: Array.from(
        { length: faker.datatype.number({ min: 1, max: 1 }) },
        createAlert
      ),
    },
  },
};
