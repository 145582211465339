import React from 'react';
import clsx from 'clsx';
import MuiAvatar, { AvatarProps } from '@material-ui/core/Avatar';
import useStyles from './Avatar.styles';
import { getRandomColor } from './helpers';

interface Props extends AvatarProps {
  name: string;
}

export default ({ name, className, ...rest }: Props): React.ReactElement => {
  const nameArr = name.split(' ').slice(0, 2);
  const classes = useStyles(getRandomColor(`${name}`))();

  return (
    <MuiAvatar className={clsx(className, classes.root)} {...rest}>
      {nameArr.map((part) => part.substring(0, 1))}
    </MuiAvatar>
  );
};
