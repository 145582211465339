import { Page } from 'services/Main/types.Page';
import { simpleSingleSelect } from '../../fields/Select';
import { simpleText } from '../../fields/TextField';
import { simpleDatePicker } from '../../fields/Date';
import { simpleFormattedNumber } from '../../fields/FormattedNumber';
import { simpleRadioGroup } from '../../fields/RadioGroup';
import { simpleFileUpload } from '../../fields/FileUpload';
import { simpleArrayOf } from '../../fields/ArrayOf';
import { simpleCheckbox } from '../../fields/Checkbox';

export const allFieldsFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с всеми возможными полями',
  component: {
    id: 100,
    businessComponentId: 29,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        header: { heading: 'Форма с всеми возможными полями' },
        fieldGroups: [
          {
            label: '🍤',
            fields: [
              simpleText,
              simpleSingleSelect,
              simpleDatePicker,
              simpleFormattedNumber,
              simpleRadioGroup,
              simpleFileUpload,
              simpleArrayOf,
              simpleCheckbox,
            ],
          },
        ],
      },
    },
  },
};
