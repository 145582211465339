import React from 'react';
import { DragDropProvider as DragDropProviderBase } from '@devexpress/dx-react-grid';
import clsx from 'clsx';
import useStyles from '../../EntryPickerTable.styles';

const DragDropContainer = ({
  children,
  clientOffset,
  className,
  style,
  ...rest
}: DragDropProviderBase.ContainerProps & any) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(className, classes.dragDropContainer)}
      style={{
        transform: `translate(calc(${clientOffset.x}px - 50%), calc(${clientOffset.y}px - 50%))`,
        msTransform: `translateX(${clientOffset.x}px) translateX(-50%) translateY(${clientOffset.y}px) translateY(-50%)`,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default DragDropContainer;
