import isEmpty from 'lodash.isempty';
import { DirtyFormValue } from '../../../../services/Main/types.Component';
import { Field } from '../../../../services/Main/types.Field';

export const isEmptyFieldValue = (
  value: DirtyFormValue,
  fieldProps?: Field
): boolean => {
  // isEmpty не пропускает boolean/string/number, он считает пустым значением.
  // Поля type === 'checkbox' || 'radio' всегда заполнены (false)!
  if (
    fieldProps &&
    (fieldProps.type === 'radio' || fieldProps.type === 'checkbox')
  ) {
    return false;
  }

  if (
    fieldProps &&
    (fieldProps.type === 'text' || fieldProps.type === 'formattedNumber')
  ) {
    return !value;
  }

  return isEmpty(value);
};
