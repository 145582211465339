import { Page } from 'services/Main/types.Page';

export const matchesValidationPage: Page = {
  type: 'singleComponent',
  title: 'Валидация required + min 5 chars + matches',
  component: {
    id: 100,
    businessComponentId: 42,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          label: 'Отправить',
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/api/high-level-components/form/with-validation',
          },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      form: {
        type: 'markup',
        header: {
          heading: 'Валидация required + min 5 chars + matches',
          subHeadings: [
            [
              null,
              {
                type: 'link',
                href: 'https://github.com/jquense/yup#stringmatchesregex-regex-message-string--function-schema',
                label: 'Документация',
                icon: 'mui:Link',
                external: true,
              },
            ],
          ],
        },
        fieldGroups: [
          {
            label: 'Название группы',
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    {
                      type: 'required',
                      params: ['Обязательное поле'],
                    },
                    {
                      type: 'min',
                      params: [5, 'Минимум 5 символов'],
                    },
                    {
                      type: 'matches',
                      params: [
                        '/^[\\dA-Za-z!@#$%^&*()-=_+/]+$/',
                        'Можно вводить только латинские буквы, цифры и символы !@#$%^&*()-=_+/',
                      ],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  },
};
