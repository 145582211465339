import React, { MouseEvent, useState } from 'react';
import { Curve } from 'recharts';
import { Popper } from '@material-ui/core';
import ChartsTooltip from '../../../lowLevel/ChartsTooltip';
import transformChartPayloadToTooltipData from '../../../lowLevel/ChartsTooltip/transformChartPayloadToTooltipData';

// any поскольку в библиотеки recharts - нет четкой типизации для Cursor
export const LineTooltipCursor = (props: any) => {
  // Здесь используется стейт anchorElement, потому что при инициализации реф
  // всегда пустой, и его значение изменяется только позже, в то время как
  // стейт сразу применяет значение ref от элемента <svg>
  const [anchorElement, setAnchorElement] = useState<SVGGElement | null>(null);

  const { payload, points } = props;

  const tooltipData = transformChartPayloadToTooltipData(payload);

  const handleTooltipMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <g>
      <Curve {...props} />
      <g ref={setAnchorElement}>
        <path
          d={`M ${points[0]?.x || 0}, ${
            (points[0]?.y || 0) + (points[0]?.y || 0) * 3.5
          }`}
        />
      </g>
      <Popper
        open={Array.isArray(payload) && payload.length > 0}
        anchorEl={anchorElement}
        onMouseMove={handleTooltipMouseMove}
      >
        <ChartsTooltip {...tooltipData} />
      </Popper>
    </g>
  );
};
