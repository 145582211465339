import { createContext, Dispatch, SetStateAction } from 'react';
import { TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import {
  DirtyFormValues,
  FilterChip,
  FilterOptions,
  SortingState,
  TableRow,
} from '../../../../../services/Main/types.Component';
import {
  FetchTablePropsRequestBody,
  FetchTablePropsResponsePayload,
} from '../../../../../services/EntryPicker/EntryPicker.interface';
import { EntryPickerProps } from '../../types';

interface EntryPickerTableContext {
  onCancel: () => void;
  onEntryChoose: (chosenValues: (string | number)[]) => void;
  tableProps: FetchTablePropsResponsePayload | null;
  rows: TableRow[];
  setRows: Dispatch<SetStateAction<TableRow[]>>;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalRows: number;
  setTotalRows: Dispatch<SetStateAction<number>>;
  sorting?: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState | undefined>>;
  selectedRowsId: (number | string)[];
  setSelectedRowsId: Dispatch<SetStateAction<(number | string)[]>>;
  filter?: DirtyFormValues;
  setFilter: Dispatch<SetStateAction<DirtyFormValues | undefined>>;
  filterChips?: FilterChip[];
  setFilterChips: Dispatch<SetStateAction<FilterChip[] | undefined>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  loadRows: (params?: FetchTablePropsRequestBody) => void;
  loadTableProps: () => void;
  filterOptions?: FilterOptions;
  setFilterOptions: Dispatch<SetStateAction<FilterOptions | undefined>>;
  entryPickerProps: EntryPickerProps;
  columnWidths: TableColumnWidthInfo[];
  setColumnWidths: Dispatch<SetStateAction<TableColumnWidthInfo[]>>;
  resetColumnWidthsToDefault: () => void;
  hiddenColumnNames: string[];
  setHiddenColumnNames: Dispatch<SetStateAction<string[]>>;
  resetColumnVisibilityToDefault: () => void;
  columnOrder: string[];
  setColumnOrder: Dispatch<SetStateAction<string[]>>;
  resetColumnOrderToDefault: () => void;
}

export default createContext<EntryPickerTableContext>({
  onCancel: () => {},
  onEntryChoose: () => {},
  tableProps: null,
  rows: [],
  setRows: () => {},
  pageSize: 0,
  setPageSize: () => {},
  currentPage: 0,
  setCurrentPage: () => {},
  totalRows: 0,
  setTotalRows: () => {},
  selectedRowsId: [],
  setSelectedRowsId: () => {},
  sorting: undefined,
  setSorting: () => {},
  filter: undefined,
  setFilter: () => {},
  isLoading: true,
  setIsLoading: () => {},
  filterChips: [],
  setFilterChips: () => {},
  loadRows: () => {
    throw new Error('`loadRows` fn was not initialized!');
  },
  loadTableProps: () => {
    throw new Error('`loadTableProps` fn was not initialized!');
  },
  filterOptions: undefined,
  setFilterOptions: () => {},
  entryPickerProps: {} as EntryPickerProps,
  columnWidths: [],
  setColumnWidths: () => {},
  resetColumnWidthsToDefault: () => {
    throw new Error('`resetColumnWidthsToDefault` fn was not initialized!');
  },
  hiddenColumnNames: [],
  setHiddenColumnNames: () => {},
  resetColumnVisibilityToDefault: () => {
    throw new Error('`resetColumnVisibilityToDefault` fn was not initialized!');
  },
  columnOrder: [],
  setColumnOrder: () => {},
  resetColumnOrderToDefault: () => {
    throw new Error('`resetColumnOrderToDefault` fn was not initialized!');
  },
});
