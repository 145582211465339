import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { UnpackNestedValue } from 'react-hook-form/dist/types/form';
import passwordService from '../../../services/Password';
import {
  SetPasswordResponse,
  ValidateTokenResponse,
} from '../../../services/Password/types';
import { getQueryParam } from '../../../utils/getQueryParam';
import PasswordSetupPageForm from './PasswordSetupPage.Form';
import PasswordSetupPageCardWithAlert from './PasswordSetupPage.CardWithAlert';

/**
 * Сценарии:
 *
 * 1. + Пользователь заходит на страницу установки пароля.
 * 2. + Проверяем валидность токена (стучим на сервер).
 *  + Если ответ положительный, показываем форму установки пароля.
 *  + Если ответ отрицательный, показываем ошибку.
 * 3. + Показываем форму для установки пароля.
 * 4. + Пользователь вводит пароль и подтверждение пароля.
 * 5. + Пользователь нажимает кнопку Отправить.
 * 6. + Проверяем совпадение пароля.
 * 7. + Проверяем валидность пароля.
 * 8. + Отправляем запрос на установку пароля.
 *  + Если ответ положительный, показываем сообщение об успешной установке пароля.
 *  + Если ответ отрицательный, то узнаем причину:
 *    1. + Пароль не подходит по требованиям. Сработала серверная валидация, значит
 *    форму НЕ убираем, а выводим ошибку об этом пользователю. Введённые значения
 *    формы не сбрасываем. Ответ будет { isSuccess: false, payload: { showForm: true, message: '...' } }
 *    2. + Сервер посчитал ссылку не валидной. Тогда форму скрываем и показываем ошибку.
 *    Ответ будет { isSuccess: false, payload: { showForm: false, message: '...' } }
 */
const PasswordSetupPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [setPasswordResponse, setSetPasswordResponse] =
    useState<SetPasswordResponse | null>(null);
  const [validateTokenResponse, setValidateTokenResponse] =
    useState<ValidateTokenResponse | null>(null);
  const location = useLocation();
  const token = getQueryParam(location.search, 'token');
  const [validationInProcess, setValidationInProcess] = useState<boolean>(true);

  useEffect(() => {
    passwordService.validateToken({ token }).then((response) => {
      setValidateTokenResponse(response);
      setValidationInProcess(false);
    });
    // eslint-disable-next-line
  }, []);

  if (validationInProcess) return <LinearProgress />;

  if (!validateTokenResponse) throw new Error('Не получен ответ от сервера');

  if (!validateTokenResponse.isSuccess) {
    if (!validateTokenResponse.payload.message) {
      throw new Error(
        'В ответе validate-token пришло isSuccess: false, но не пришло message'
      );
    }

    return (
      <PasswordSetupPageCardWithAlert
        title={validateTokenResponse.payload.title}
        message={validateTokenResponse.payload.message}
        color="error"
      />
    );
  }

  // Типизация UnpackNestedValue<{ firstPassword: string, secondPassword: string }>
  // или UnpackNestedValue<Record<string, any>>
  const onSubmit = ({ password }: UnpackNestedValue<{ password: string }>) => {
    setIsLoading(true);

    if (typeof token !== 'string') {
      throw new Error('Не получен токен для восстановления пароля');
    }

    passwordService.set({ token, password }).then((response) => {
      setIsLoading(false);

      setSetPasswordResponse(response);
    });
  };

  if (
    (setPasswordResponse &&
      !setPasswordResponse.isSuccess &&
      !setPasswordResponse.payload.showForm) ||
    (setPasswordResponse && setPasswordResponse.isSuccess)
  ) {
    return (
      <PasswordSetupPageCardWithAlert
        title={setPasswordResponse.payload.title}
        message={setPasswordResponse.payload.message}
        color={setPasswordResponse.isSuccess ? 'success' : 'error'}
      />
    );
  }

  return (
    <Grid container justify="center">
      <Grid item xs={11} sm={8} md={6}>
        <PasswordSetupPageForm
          isLoading={isLoading}
          setPasswordResponse={setPasswordResponse}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default PasswordSetupPage;
