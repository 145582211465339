import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import PureLogo from 'svg/Logo';
import { useIsCompactMenuView } from '../../../utils/hooks/useIsCompactMenuView';
import useStyles from './Logo.styles';

const Logo = () => {
  const classes = useStyles();
  const isCompactMenuView = useIsCompactMenuView();

  return (
    <Link
      to={{
        pathname: '/',
        state: {
          fromMenu: true,
        },
      }}
      className={clsx(classes.logo, { compactView: isCompactMenuView })}
    >
      <Box className={classes.wrapper}>
        <PureLogo className={classes.logoSvg} />
      </Box>
    </Link>
  );
};

export default Logo;
