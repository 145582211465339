import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useSelector } from '../../../store';
import { snackbarsActions } from '../../../store/snackbars';
import useEnqueueSnackbar from '../../../utils/hooks/useEnqueueSnackbar';

let displayed: (number | string)[] = [];

// См. https://iamhosseindhv.com/notistack/demos#redux-/-mobx-example
const SnackbarReduxListener = () => {
  const dispatch = useDispatch();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { closeSnackbar } = useSnackbar();
  const snackbars = useSelector((state) => state.snackbars);

  const storeDisplayed = (id: number | string) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: number | string) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    snackbars.forEach(({ key, message, options = {} }) => {
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, k) => {
          if (options.onClose) {
            options.onClose(event, reason, k);
          }
        },
        onExited: (event, k) => {
          // remove this snackbar from redux store
          dispatch(snackbarsActions.removeSnackbar(k));
          removeDisplayed(k);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [snackbars, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default SnackbarReduxListener;
