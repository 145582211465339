import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    field: {
      '& .MuiOutlinedInput-root': {
        ...theme.typography.body2,
        borderRadius: theme.spacing(2.5),
      },
    },
  })
);
