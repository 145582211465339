import React, { useMemo } from 'react';
import { TextWidget } from '../../../services/Main/types.Component';
import TextWidgetComponent from './TextWidget';
import useWidgetDataQuery, {
  checkEmptyStateFn,
} from '../../../utils/hooks/useWidgetDataQuery';
import { TextWidgetState } from './types';
import WidgetContainer from '../WidgetContainer';
import WidgetSkeletonContainer from '../WidgetSkeletonContainer';
import TextWidgetSkeleton from '../../../svg/skeletons/TextWidgetSkeleton';
import useStyles from './TextWidget.styles';

const checkEmptyState: checkEmptyStateFn<TextWidgetState> = (payload) => {
  return !payload.metric;
};

const TextWidgetDataLoader = (props: TextWidget) => {
  const classes = useStyles();
  const { requestConfig } = props;

  const { isFetching, isFistTimeFetched, widgetState, alert } =
    useWidgetDataQuery<TextWidgetState>(requestConfig, checkEmptyState);

  const minHeight = 106;

  const skeleton = useMemo(
    () => (
      <TextWidgetSkeleton
        className={!isFistTimeFetched ? 'animate-flicker' : undefined}
        style={{ minHeight }}
      />
    ),
    [isFistTimeFetched]
  );

  const content = (() => {
    if (!isFistTimeFetched)
      return <WidgetSkeletonContainer skeleton={skeleton} />;

    // Информация для пользователя, если данные запросили некорректно.
    if (alert)
      return (
        <WidgetSkeletonContainer
          skeleton={skeleton}
          alert={alert}
          alertClassName={classes.alertWrapper}
        />
      );

    if (!widgetState) throw new Error('Неизвестная ошибка');

    return (
      <TextWidgetComponent
        {...props}
        {...widgetState}
        showLoader={isFetching}
      />
    );
  })();

  return (
    <WidgetContainer style={{ minHeight, height: '100%' }}>
      {content}
    </WidgetContainer>
  );
};

export default TextWidgetDataLoader;
