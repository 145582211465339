import React, { useCallback, useRef } from 'react';
import isEqual from 'lodash.isequal';
import hash from 'object-hash';
import theme from 'theme';
import {
  DirtyFormValues,
  FilterChip,
  FilterOptions,
  FormValues,
} from 'services/Main/types.Component';
import FormBuilder from '../FormBuilder';
import { TableFilterFormProps } from '../TableModules';
import mapFilterChips from '../TableFilters/mapFilterChips';
import { useFormatMessage } from '../../../locale';

const TableFilterForm = ({
  props,
  filter: propsFilter,
  setFilter,
  setFilterChips,
  formBuilderProps,
}: TableFilterFormProps) => {
  const formatMessage = useFormatMessage();

  const { fieldGroups } = props;
  const filterState = useRef<DirtyFormValues | undefined>();

  const handleChange = useCallback(
    (values: FormValues, dirtyValues: DirtyFormValues) => {
      const haveSomeValue = Object.values(values).some((v) => !!v || v === 0);
      const oldFiltersLength = filterState.current
        ? Object.values(filterState.current).filter((v) => !!v || v === 0)
            .length
        : 0;
      const newFiltersLength = Object.values(values).filter(
        (v) => !!v || v === 0
      ).length;

      const isInserting =
        !isEqual(filterState.current, values) && haveSomeValue;

      const isDeletingLastFilter =
        !isEqual(filterState.current, values) &&
        oldFiltersLength > 0 &&
        newFiltersLength === 0;

      // Проверка на то, что фильтр изменился реально.
      // Нужно, т.к. RHF watch метод срабатывает
      // чаще, чем нужно (при focusout, к примеру).
      if (isInserting || isDeletingLastFilter) {
        const filterChips: FilterChip[] = mapFilterChips(formatMessage)(
          fieldGroups[0].fields,
          dirtyValues
        );

        filterState.current = values;
        setFilter(dirtyValues);
        setFilterChips(filterChips);
      }
    },
    // eslint-disable-next-line
    [filterState, setFilter]
  );

  return (
    <FormBuilder
      {...formBuilderProps}
      fieldGroups={fieldGroups}
      padding={theme.spacing(0, 0)}
      disableBorder
      values={propsFilter}
      onChange={handleChange}
      key={hash(fieldGroups)}
    />
  );
};

export function mapValuesToFilterOptions(
  values: FormValues | DirtyFormValues
): FilterOptions {
  return Object.entries(values).reduce<any>((acc, [key, value]) => {
    const newAcc = { ...acc };

    // if (isEmptyArray) continue;
    if (Array.isArray(value) && value.length === 0) {
      return newAcc;
    }

    if (value || value === 0) {
      newAcc[key] = value;
    }

    return newAcc;
  }, {});
}

export default TableFilterForm;
