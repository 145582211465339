import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      color: theme.palette.text.primary,
    },
    chip: {
      cursor: 'inherit',
      minWidth: '50%',
      borderColor: theme.palette.text.primary,
    },
    label: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1,
      '& *': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'block',
      },
    },
  })
);
