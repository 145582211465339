import faker from 'faker';
import { Page } from '../../../types.Page';

faker.locale = 'ru';

const createRow = () => {
  return {
    id: faker.datatype.uuid(),
    code: `${faker.datatype.number({
      min: 1000,
      max: 9999,
    })}-${faker.datatype.number({
      min: 100,
      max: 999,
    })}/${faker.datatype.number({
      min: 10,
      max: 99,
    })}`,
    status: faker.random.arrayElement<any>([
      {
        type: 'icon',
        name: 'local:Record',
        props: {
          fill: '#00aa00',
          stroke: '#00aa00',
          fontSizeInPx: 16,
        },
      },
      {
        type: 'icon',
        name: 'local:Record',
        props: {
          fill: '#aa0000',
          stroke: '#aa0000',
          fontSizeInPx: 16,
        },
      },
      {
        type: 'icon',
        name: 'local:Record',
        props: {
          fill: '#555555',
          stroke: '#555555',
          fontSizeInPx: 16,
        },
      },
    ]),
    createdAt: faker.date.past().toISOString(),
    type: 'Заглушка',
    comment: faker.lorem.words(Math.random() * 20),
    author: `${faker.name.lastName()} ${faker.name.firstName()}`,
    assignee: `${faker.name.lastName()} ${faker.name.firstName()}`,
    deadline: faker.date.future().toISOString(),
  };
};

export const allIssuesRows = Array.from({ length: 150 }, createRow);

const pageSize = 25;
const currentPage = 0;

export const someTasksPage: Page = {
  type: 'singleComponent',
  title: 'Список заявок на ремонт',
  component: {
    id: 200,
    businessComponentId: 48,
    type: 'table',
    props: {
      actions: [
        {
          type: 'redirect',
          label: 'Создать',
          icon: 'mui:Add',
          variant: 'contained',
          color: 'primary',
          redirectTo: '/some-tasks/create',
        },
        {
          type: 'showForm',
          label: 'Подготовить деф. ведомость',
          variant: 'contained',
          color: 'primary',
          viewStyle: 'fullScreenModal',
          actions: [
            {
              type: 'submitForm',
              variant: 'contained',
              label: 'Согласовать с заказчиком',
              color: 'primary',
              requestConfig: {
                url: '/api/workflow/action/CreateDefectiveSheet?taskId=4ac09743-3b7b-41c8-879e-929563f1167b',
              },
              successResponseReaction: {
                type: 'reLoadModule',
              },
            },
          ],
          form: {
            type: 'markup',
            header: { heading: 'Создание дефектной ведомости' },
            fieldGroups: [
              {
                label: 'Информация о дефектах',
                fields: [
                  {
                    type: 'text',
                    name: 'defectList',
                    label: 'Введите дефекты',
                    helperText: 'Выявленные дефекты',
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'text',
                    name: 'defectReasonList',
                    label: 'Укажите причины',
                    helperText: 'Причины выявленных дефектов',
                    validationConfig: {
                      validationType: 'string',
                      validations: [
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'Требования для устранению дефектов',
                fields: [
                  {
                    type: 'arrayOf',
                    name: 'workList',
                    label: 'Заполните работу',
                    helperText: 'Провести следующие работы / мероприятия',
                    rowDefinition: [
                      {
                        type: 'text',
                        name: 'workList',
                        validationConfig: {
                          validationType: 'string',
                          validations: [
                            {
                              type: 'required',
                              params: ['Обязательное поле'],
                            },
                          ],
                        },
                      },
                    ],
                  },
                  {
                    type: 'arrayOf',
                    name: 'partsToReplaceList',
                    label: 'Укажите что нужно заменить',
                    helperText:
                      'Заменить следующие запасные части / материалы / оборудование',
                    rowDefinition: [
                      {
                        type: 'text',
                        name: 'partsToReplaceList',
                        validationConfig: {
                          validationType: 'string',
                          validations: [
                            {
                              type: 'required',
                              params: ['Обязательное поле'],
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
              {
                label: 'Плановые сроки',
                fields: [
                  {
                    type: 'text',
                    inputType: 'numeric',
                    name: 'partsDeliveryDeadline',
                    label: 'Укажите количество дней',
                    helperText:
                      'Поставка запчастей / материалов, календарных дней',
                    validationConfig: {
                      validationType: 'number',
                      validations: [
                        {
                          type: 'positive',
                          params: ['Введите число больше 0'],
                        },
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                  {
                    type: 'text',
                    inputType: 'numeric',
                    name: 'workDeadline',
                    label: 'Укажите количество дней',
                    helperText: 'Ремонтные работы, календарных дней',
                    validationConfig: {
                      validationType: 'number',
                      validations: [
                        {
                          type: 'positive',
                          params: ['Введите число больше 0'],
                        },
                        { type: 'required', params: ['Обязательное поле'] },
                      ],
                    },
                  },
                ],
              },
              {
                label: 'Приложенные файлы',
                fields: [
                  {
                    type: 'file',
                    name: 'files',
                    label: 'Файлы',
                  },
                ],
              },
            ],
          },
        },
      ],
      heading: 'Список заявок',
      requestConfig: { url: '/issues' },
      showSearchbar: true,
      showWithClosedButton: true,
      quickFilters: {
        options: [
          {
            value: '17d4a157-b9ec-4c4e-8146-35a08cef93f2',
            label: 'Мои на сегодня',
          },
          {
            value: '18de0369-0ea0-4004-9dba-f5d8a66d2f7f',
            label: 'Моей организации',
          },
          { value: 'ce32267c-c120-4684-8799-00b047899f5d', label: 'Все мои' },
        ],
      },
      columns: [
        {
          title: 'Статус',
          name: 'status',
          options: {
            formatAs: 'icon',
          },
        },
        { title: 'Номер', name: 'code', options: { sortable: true } },
        {
          title: 'Создано',
          name: 'createdAt',
          options: {
            formatAs: 'dateTime',
          },
        },
        { title: 'Тип', name: 'type' },
        {
          title: 'Тема',
          name: 'comment',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        {
          title: 'Инициатор',
          name: 'author',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        {
          title: 'Ответственный',
          name: 'assignee',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        {
          title: 'Срок выполнения',
          name: 'deadline',
          options: {
            formatAs: 'dateTime',
          },
        },
      ],
      columnVisibilityConfig: {
        enabled: true,
      },
      enableColumnReordering: true,
      columnExtensions: [
        { columnName: 'code', width: 130 },
        { columnName: 'status', width: 80 },
        { columnName: 'createdAt', width: 130 },
        { columnName: 'type', width: 90 },
        { columnName: 'author', width: 170 },
        { columnName: 'assignee', width: 170 },
        { columnName: 'deadline', width: 130 },
        { columnName: 'comment', width: 500 },
      ],
      enableColumnResizing: true,
      rows: allIssuesRows.slice(
        currentPage * pageSize,
        currentPage * pageSize + pageSize
      ),
      options: {
        totalRows: allIssuesRows.length,
        currentPage,
        pageSize,
        pageSizeOptions: [10, 25, 50, 100, 250, 500],
        rowClickReaction: {
          type: 'dynamicRedirect',
          redirectTo: '/some-tasks/:id',
        },
        sorting: [{ columnName: 'code', direction: 'desc' }],
      },
      filterComponent: {
        type: 'tableFilter',
        id: 10000,
        businessComponentId: 23,
        props: {
          requestOn: 'change',
          fieldGroups: [
            {
              fields: [
                {
                  type: 'datePicker',
                  pickerType: 'dateRange',
                  name: 'createdAt1',
                  label: 'Создано',
                  initialFocusedDate: 'now',
                },
                {
                  type: 'datePicker',
                  pickerType: 'dateTimeRange',
                  name: 'deadline1',
                  label: 'Срок выполнения',
                  initialFocusedDate: '2020-04-15T16:33:23.3004811+03:00',
                },
                { type: 'text', name: 'code', label: 'Номер' },
                {
                  type: 'select',
                  optionsLoader: 'sync',
                  selectType: 'single',
                  name: 'priority',
                  label: 'Приоритет',
                  options: [
                    { value: 'Critical', label: 'Критичный' },
                    { value: 'High', label: 'Высокий' },
                    { value: 'Low', label: 'Низкий' },
                  ],
                },
                {
                  type: 'select',
                  optionsLoader: 'sync',
                  selectType: 'multi',
                  name: 'status',
                  label: 'Статус',
                  options: [
                    { value: 'Draft', label: 'Черновик' },
                    { value: 'Pause', label: 'Отложена' },
                    { value: 'Closing', label: 'Сдача-приемка' },
                    { value: 'Canceled', label: 'Прекращена' },
                    { value: 'Done', label: 'Выполнено' },
                    { value: 'Approval', label: 'На согласовании' },
                    { value: 'InWork', label: 'В работе' },
                    { value: 'Queue', label: 'В очереди' },
                  ],
                },
                {
                  type: 'datePicker',
                  pickerType: 'dateTimeRange',
                  name: 'createdAt',
                  label: 'Создано',
                  initialFocusedDate: 'now',
                },
                {
                  type: 'select',
                  optionsLoader: 'sync',
                  selectType: 'multi',
                  name: 'type',
                  label: 'Тип',
                  options: [{ value: 'FooBar', label: 'Заглушка' }],
                },
                { type: 'text', name: 'comment', label: 'Тема' },
                {
                  type: 'select',
                  optionsLoader: 'sync',
                  selectType: 'multi',
                  options: Array.from(
                    { length: Math.floor(Math.random() * 7) + 1 },
                    () => ({
                      value: faker.datatype.uuid(),
                      label: faker.name.lastName(),
                    })
                  ),
                  name: 'author',
                  label: 'Инициатор',
                },
                {
                  type: 'datePicker',
                  pickerType: 'dateTimeRange',
                  name: 'deadline',
                  label: 'Срок выполнения',
                  initialFocusedDate: 'now',
                },
              ],
            },
          ],
        },
      },
    },
  },
};
