import React, { useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import {
  FormHistoryElement,
  Header as HeaderProps,
  ShowHistoryAction,
} from '../../../services/Main/types.Component';
import formHistoryService from '../../../services/FormHistory';
import Header from '../Header';
import DialogTitle from '../DialogTitle';
import FormHistoryElementComponent from '../FormHistoryElement';
import useStyles from './FormHistory.styles';

interface FormHistoryButtonProps {
  action: ShowHistoryAction;
  onClick: () => void;
}

interface FormHistoryProps {
  action: ShowHistoryAction;
  onClick?: () => void;
  renderButton: (props: FormHistoryButtonProps) => JSX.Element;
}

const FormHistory = ({ action, onClick, renderButton }: FormHistoryProps) => {
  const classes = useStyles();
  const { requestConfig } = action;
  const [header, setHeader] = useState<HeaderProps | null>(null);
  const [history, setHistory] = useState<FormHistoryElement[] | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const loadHistory = () => {
    formHistoryService.fetchFormHistory(requestConfig).then(({ payload }) => {
      const sortedElements = [...payload.elements]
        .sort((a, b) => a.sort - b.sort)
        .reverse();
      setHeader(payload.header);
      setHistory(sortedElements);
    });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    loadHistory();
    onClick && onClick();
  };

  return (
    <>
      {renderButton({
        action,
        onClick: handleClick,
      })}
      <Dialog fullWidth maxWidth="xl" open={isOpen} onClose={handleClose}>
        {header && (
          <DialogTitle onClose={handleClose}>
            <Header {...header} />
          </DialogTitle>
        )}
        <DialogContent className={classes.dialogContentRoot}>
          {history &&
            history.map((element) => (
              <FormHistoryElementComponent key={element.id} {...element} />
            ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FormHistory;
