import { createContext } from 'react';
import GlobalSocketService from '../services/GlobalSocket';

interface GlobalSocketConnectionContextState {
  connection: GlobalSocketService | null;
}

export default createContext<GlobalSocketConnectionContextState>({
  connection: null,
});
