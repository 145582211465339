import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Grid, IconButton, useTheme } from '@material-ui/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import useStyles from '../ArrayOf.styles';

interface SortableItemProps {
  isDragAndDropEnabled?: boolean;
  id: string;
}

const SortableItem = ({
  isDragAndDropEnabled,
  id,
  children,
}: PropsWithChildren<SortableItemProps>) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: transform
      ? CSS.Transform.toString({ ...transform, x: 0 })
      : undefined,
    transition,
    background: theme.palette.background.paper,
    zIndex: 2,
  };

  if (isDragAndDropEnabled) {
    return (
      <Grid
        item
        xs="auto"
        container
        wrap="nowrap"
        alignItems="stretch"
        ref={setNodeRef}
        style={style}
      >
        <Grid
          item
          container
          className={clsx(classes.tableCell, classes.tableServiceCell)}
        >
          <Grid item style={{ alignSelf: 'center' }}>
            <IconButton
              size="small"
              {...listeners}
              {...attributes}
              style={{
                cursor: isDragging ? 'grabbing' : 'grab',
              }}
            >
              <DragHandleIcon />
            </IconButton>
          </Grid>
        </Grid>
        {children}
      </Grid>
    );
  }

  return (
    <Grid item xs="auto" container wrap="nowrap" alignItems="stretch">
      {children}
    </Grid>
  );
};

export default SortableItem;
