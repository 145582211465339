import React from 'react';
import Grid from '@material-ui/core/Grid';
import { TableAction } from 'services/Main/types.Component';
import { OnReactionExecuteEvent } from '../../../responseReactions/ReactionController';
import TableActionComponent from '../TableAction/TableAction';
import { useClassifyActions } from '../ExtraActions/useClassifyActions';
import ExtraActions from '../ExtraActions';
import TableActionButton from '../TableAction/TableAction.Button';
import ExtraActionsMenuItem from '../ExtraActions/ExtraActions.MenuItem';

interface TableActionsProps {
  actions: TableAction[];
  onReactionExecute?: OnReactionExecuteEvent;
}

const TableActions = ({ actions, onReactionExecute }: TableActionsProps) => {
  const { mainActions, extraActions } =
    useClassifyActions<TableAction>(actions);

  return (
    <Grid container alignItems="center" justify="flex-end" spacing={1}>
      {mainActions.map((actionProps) => (
        <Grid item key={`main_${Object.values(actionProps).join('')}`}>
          <TableActionComponent
            action={actionProps}
            onReactionExecute={onReactionExecute}
            renderAction={TableActionButton}
          />
        </Grid>
      ))}
      {extraActions.length > 0 ? (
        <Grid item>
          <ExtraActions<TableAction>
            actions={extraActions}
            renderMenuItem={(actionProps, onClickMenuItem) => (
              <TableActionComponent
                key={`extraAction_${Object.values(actionProps).join('')}`}
                action={actionProps}
                onClick={onClickMenuItem}
                onReactionExecute={onReactionExecute}
                renderAction={ExtraActionsMenuItem}
              />
            )}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default TableActions;
