import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import debounce from 'lodash.debounce';
import { useFormContext } from 'react-hook-form';
import { mapValuesToPlain } from 'components/lowLevel/FormBuilder/helpers';
import {
  DirtyFormValues,
  FilterChip,
  FilterOptions,
  SortingState,
  TableRow,
} from '../../../../../../../services/Main/types.Component';
import EntryPickerTableContext from '../../EntryPickerTable.context';
import entryPickerService from '../../../../../../../services/EntryPicker';
import { useFormatMessage } from '../../../../../../../locale';
import useEnqueueSnackbar from '../../../../../../../utils/hooks/useEnqueueSnackbar';
import {
  FetchTablePropsRequestBody,
  FetchTablePropsResponsePayload,
} from '../../../../../../../services/EntryPicker/EntryPicker.interface';
import { EntryPickerProps } from '../../../../types';
import useColumnVisibilityState from '../../hooks/useColumnVisibilityState';
import useColumnResizingState from '../../hooks/useColumnResizingState';
import useColumnReordering from '../../hooks/useColumnReordering';
import FormBuilderContext from '../../../../../FormBuilder/FormBuilderContext';

interface EntryPickerTableProviderProps {
  onEntryChoose: (chosenValues: (string | number)[]) => void;
  onCancel: () => void;
  entryPickerProps: EntryPickerProps;
}

const EntryPickerTableProvider = ({
  onEntryChoose,
  onCancel,
  children,
  entryPickerProps,
}: PropsWithChildren<EntryPickerTableProviderProps>) => {
  const formatMessage = useFormatMessage();
  const enqueueSnackbar = useEnqueueSnackbar();
  const { fields } = useContext(FormBuilderContext);
  const { getValues } = useFormContext();

  const { gridRequestConfig, multiple, name, includeFormStateWithRequests } =
    entryPickerProps;

  // Состояние таблицы
  const [rows, setRows] = useState<TableRow[]>([]);
  const [totalRows, setTotalRows] = useState<number>(0);

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [tableProps, setTableProps] =
    useState<FetchTablePropsResponsePayload | null>(null);

  // Состояния сортировки
  const [sorting, setSorting] = useState<SortingState | undefined>();
  // Состояния выбранной строки
  const [selectedRowsId, setSelectedRowsId] = useState<(number | string)[]>([]);
  // Состояни фильтров
  const [filter, setFilter] = useState<DirtyFormValues | undefined>();
  const [filterOptions, setFilterOptions] = useState<
    FilterOptions | undefined
  >();
  // Состояниие чипсов
  const [filterChips, setFilterChips] = useState<FilterChip[] | undefined>();
  // Состояни загрузки таблицы
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const loadRows = (params?: FetchTablePropsRequestBody) => {
    setIsLoading(true);

    const requestArgs = (() => {
      if (includeFormStateWithRequests) {
        return {
          ...params,
          formState: mapValuesToPlain(getValues(), fields),
        };
      }

      return params ?? undefined;
    })();

    return entryPickerService
      .fetchTableProps(gridRequestConfig, {
        ...requestArgs,
        currentFieldName: name,
      })
      .then((response) => {
        const { payload } = response;

        // В single entry picker
        if (!multiple) {
          // Сбрасываем выбранную строку
          if (payload.rows.length > 0) {
            setSelectedRowsId([payload.rows[0].id]);
          } else {
            setSelectedRowsId([]);
          }
        }

        setRows(payload.rows);
        setTotalRows(payload.totalRows);

        return payload;
      })
      .catch(() => {
        enqueueSnackbar(formatMessage('errorOccurredWhileRequestingData'), {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadTableProps = () => {
    loadRows().then((payload) => {
      if (payload) {
        setTableProps(payload);
        setPageSize(payload.options.pageSize);
        setCurrentPage(payload.options.currentPage);
      }
    });
  };

  const debounceLoadRows = useCallback(debounce(loadRows, 250), []);

  // Хук управления порядком иконок
  const { columnOrder, setColumnOrder, resetColumnOrderToDefault } =
    useColumnReordering(
      name,
      tableProps?.columns || [],
      tableProps?.enableColumnReordering
    );

  // Хук управления шириной колонок
  const [columnWidths, setColumnWidths, resetColumnWidthsToDefault] =
    useColumnResizingState(
      name,
      tableProps?.columns || [],
      tableProps?.columnExtensions,
      tableProps?.enableColumnResizing
    );

  const [
    hiddenColumnNames,
    setHiddenColumnNames,
    resetColumnVisibilityToDefault,
  ] = useColumnVisibilityState(
    name,
    tableProps?.columns || [],
    tableProps?.columnVisibilityConfig
  );

  return (
    <EntryPickerTableContext.Provider
      value={{
        onEntryChoose,
        onCancel,
        tableProps,
        rows,
        setRows,
        pageSize,
        setPageSize,
        currentPage,
        setCurrentPage,
        totalRows,
        setTotalRows,
        sorting,
        setSorting,
        selectedRowsId,
        setSelectedRowsId,
        filter,
        setFilter,
        filterChips,
        setFilterChips,
        isLoading,
        setIsLoading,
        loadRows: debounceLoadRows,
        loadTableProps,
        filterOptions,
        setFilterOptions,
        entryPickerProps,
        hiddenColumnNames,
        setHiddenColumnNames,
        resetColumnVisibilityToDefault,
        columnWidths,
        setColumnWidths,
        resetColumnWidthsToDefault,
        columnOrder,
        setColumnOrder,
        resetColumnOrderToDefault,
      }}
    >
      {children}
    </EntryPickerTableContext.Provider>
  );
};

export default EntryPickerTableProvider;
