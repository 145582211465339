import React, { PropsWithChildren, useState } from 'react';
import TableDialogWithComponentContext from './TableDialogWithComponent.context';
import { DialogWithComponent } from '../../../../../services/Main/types.Component';

const TableDialogWithComponentProvider = ({
  children,
}: PropsWithChildren<{}>) => {
  const [dialogWithComponent, setDialogWithComponent] =
    useState<DialogWithComponent | null>(null);

  return (
    <TableDialogWithComponentContext.Provider
      value={{
        dialogWithComponent,
        setDialogWithComponent,
      }}
    >
      {children}
    </TableDialogWithComponentContext.Provider>
  );
};

export default TableDialogWithComponentProvider;
