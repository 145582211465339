import { Page } from 'services/Main/types.Page';
import { Text } from 'services/Main/types.Field';

export const simpleText: Text = { type: 'text', name: 'text1', label: 'text' };

export const textFieldFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами text field',
  component: {
    id: 100,
    businessComponentId: 28,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          variant: 'contained',
          color: 'primary',
          label: 'Сохранить',
          requestConfig: { url: '/api/fields/text', method: 'GET' },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами text field' },
        fieldGroups: [
          {
            label: 'Text Field',
            fields: [
              simpleText,
              {
                type: 'text',
                name: 'text2',
                defaultValue: 'Какой-то текст',
                label: 'text with defaultValue',
              },
              {
                type: 'text',
                name: 'text3',
                disabled: true,
                defaultValue: 'Задисейбленный текст',
                label: 'disabled text field',
              },
              {
                type: 'text',
                name: 'text4',
                helperText: 'helper text',
                label: 'text field with helper text (will be soon)',
              },
              {
                type: 'text',
                name: 'text5',
                mdCols: 6,
                label: '6 md cols text field',
              },
              {
                type: 'text',
                name: 'text6',
                mdCols: 3,
                label: '3 md cols text field',
              },
              {
                type: 'text',
                name: 'text7',
                mdCols: 3,
                label: '3 md cols text field',
              },
              {
                type: 'text',
                name: 'text8',
                inputType: 'numeric',
                label: 'number text field (will be soon)',
              },
              {
                type: 'text',
                name: 'text9',
                label: 'Hidden',
                hidden: true,
                defaultValue: '5f354449-f38b-46ad-b55b-9bf097788dc7',
              },
              {
                type: 'text',
                name: 'text10',
                label: 'Обязательное поле',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    { type: 'required', params: ['Обязательное поле.'] },
                  ],
                },
              },
              {
                type: 'text',
                name: 'text11',
                label: 'От 10 до 20 символов',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    { type: 'min', params: [10, 'Минимум 10 символов.'] },
                    { type: 'max', params: [20, 'Максимум 20 символов.'] },
                  ],
                },
              },
              {
                type: 'text',
                name: 'text12',
                label: 'Email',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    { type: 'required', params: ['Обязательное поле.'] },
                    { type: 'email', params: ['Введите корректный email.'] },
                  ],
                },
              },

              {
                type: 'text',
                name: 'text13',
                mdOffset: 3,
                mdCols: 3,
                label: '3 md cols 3 md offset',
              },
              {
                type: 'text',
                name: 'text14',
                mdOffset: 2,
                mdCols: 4,
                label: '4 md cols 2 md offset',
              },

              {
                type: 'text',
                name: 'text15',
                inputType: 'password',
                label: 'Пароль',
              },
            ],
          },
        ],
      },
    },
  },
};
