import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles<Theme>(() =>
  createStyles({
    block: {
      pointerEvents: 'none',
      userSelect: 'none',
    },
  })
);
