import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import useStyles from './ErrorController.styles';
import { useFormatMessage } from '../../../locale';

type ErrorControllerProps = {
  heading: string;
  description: string;
  image: ReactElement;
  onHomeClick?: () => void;
  renderButton?: () => ReactElement;
};

const ErrorController = ({
  heading,
  description,
  image,
  onHomeClick,
  renderButton,
}: ErrorControllerProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Paper>
      <Grid container justify="center">
        <Grid container item sm={11} className={classes.root}>
          <Grid item xs={12} sm={8} md={6} className={classes.logoGrid}>
            {image}
          </Grid>
          <Grid container item xs={12} md={4} className={classes.textBlock}>
            <Typography className={classes.typographyHeader}>
              {heading}
            </Typography>
            <Typography variant="body1" className={classes.typography}>
              {description}
            </Typography>
            {renderButton ? (
              renderButton()
            ) : (
              <Link to="/" className={classes.button} onClick={onHomeClick}>
                <Button variant="contained" color="primary">
                  {formatMessage('goBackToTheMainPage')}
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ErrorController;
