import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let ChartSkeleton: any = (props: any) => (
  <div
    style={{
      paddingLeft: 76,
      paddingBottom: 54,
      paddingTop: 16,
      paddingRight: 28,
      width: '100%',
      height: '100%',
    }}
  >
    <SvgIcon
      {...props}
      viewBox="0 0 655 277"
      fill="none"
      preserveAspectRatio="none"
      style={{ width: '100%', height: '100%', ...(props?.style || {}) }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2"
        y1="276"
        x2="655"
        y2="276"
        stroke="#959595"
        strokeWidth="2"
      />
      <line
        x1="-8.74228e-08"
        y1="1"
        x2="655"
        y2="0.999943"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="1"
        y1="275"
        x2="1.00001"
        y2="1"
        stroke="#959595"
        strokeWidth="2"
      />
      <line
        x1="37"
        y1="277"
        x2="37"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="90"
        y1="277"
        x2="90"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="143"
        y1="277"
        x2="143"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="196"
        y1="277"
        x2="196"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="249"
        y1="277"
        x2="249"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="302"
        y1="277"
        x2="302"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="355"
        y1="277"
        x2="355"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="408"
        y1="277"
        x2="408"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="462"
        y1="277"
        x2="462"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="515"
        y1="277"
        x2="515"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="567"
        y1="277"
        x2="567"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="621"
        y1="277"
        x2="621"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="654"
        y1="277"
        x2="654"
        y2="2"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="-8.74228e-08"
        y1="227"
        x2="655"
        y2="227"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="-8.74228e-08"
        y1="183"
        x2="655"
        y2="183"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="-8.74228e-08"
        y1="139"
        x2="655"
        y2="139"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="-8.74228e-08"
        y1="93"
        x2="655"
        y2="92.9999"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <line
        x1="-8.74228e-08"
        y1="48"
        x2="655"
        y2="47.9999"
        stroke="#EAEAEA"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
    </SvgIcon>
  </div>
);
ChartSkeleton.muiName = 'SvgIcon';

ChartSkeleton = React.memo(ChartSkeleton);
ChartSkeleton.displayName = 'ChartSkeleton';

const PureChartSkeleton = ChartSkeleton;

export default PureChartSkeleton;
