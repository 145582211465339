import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import isEmpty from 'lodash.isempty';
import FormBuilderContext from '../FormBuilder/FormBuilderContext';
import {
  Field,
  FieldRenderCondition,
} from '../../../services/Main/types.Field';
import getFieldValue from './getFieldValue';
import { getMasterFieldName } from './helpers';
import { getArrayOfFieldNames } from '../ComboBox/useSelectOptions';

export default function useShouldRender(
  renderCondition?: FieldRenderCondition
): boolean {
  const [isMasterFieldHidden, setIsMasterFieldHidden] =
    useState<boolean>(false);
  const { watch, control } = useFormContext();
  const { fieldsByName } = useContext(FormBuilderContext);

  if (!renderCondition) return true;

  // Проверяем на arrayOf. Если это поле дочерние, то
  // masterFieldName = arrayOfFieldName, иначе то что нам прилетело
  const masterFieldName = getMasterFieldName(renderCondition.dependOn);

  const masterFieldProps: Field | undefined = fieldsByName[masterFieldName];

  if (!masterFieldProps) {
    throw new Error(`Не удалось определить поле '${renderCondition.dependOn}'`);
  }

  const unpackedValue = watch(masterFieldName);

  const masterFieldValue = getFieldValue({
    field: masterFieldProps,
    unpackedValue,
    renderCondition,
  });

  // Исправляет проблему, когда поле подписывается на поле, которое тоже условное и имеет defaultValue.
  setTimeout(() => {
    const { type } = masterFieldProps;
    const { current: fieldsRef } = control.fieldsRef;

    const newIsMasterFieldHidden =
      type === 'arrayOf'
        ? !Object.entries(fieldsRef).some(([fieldName]) => {
            if (!fieldName.includes(masterFieldName)) return false;

            const { arrayOfName, childFieldName } =
              getArrayOfFieldNames(fieldName);
            return (
              `${arrayOfName}.${childFieldName}` === renderCondition.dependOn
            );
          })
        : !fieldsRef[masterFieldName];

    setIsMasterFieldHidden(newIsMasterFieldHidden);
  }, 4);

  if (isMasterFieldHidden) return false;

  switch (renderCondition.condition) {
    case 'empty':
      // renderCondition.value === string || boolean || string[] !!!!
      return isEmpty(masterFieldValue);

    case 'equals':
      // renderCondition.value === string || boolean !!!!
      return masterFieldValue === renderCondition.value;

    case 'except':
      // renderCondition.value === string || boolean !!!!
      return masterFieldValue !== renderCondition.value;

    case 'some':
      // renderCondition.value === string[] !!!!
      return (renderCondition.value as string[]).some((v) =>
        Array.isArray(masterFieldValue)
          ? masterFieldValue.includes(v)
          : v === masterFieldValue
      );

    case 'notEmpty':
      // renderCondition.value === string || string[] !!!!
      return !isEmpty(masterFieldValue);

    default:
      throw new Error('Не удалось определить тип условия');
  }
}
