import { Page } from '../../../types.Page';

export const someTasksCreateSubtaskPage: Page = {
  type: 'singleComponent',
  title: 'Создание подзадачи на ремонт',
  component: {
    id: 100,
    businessComponentId: 48,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          color: 'primary',
          variant: 'contained',
          label: 'Отправить',
          icon: 'mui:Check',
          requestConfig: {
            url: '/api/orders/repairs',
            method: 'POST',
          },
          successResponseReaction: {
            type: 'showAlertAndRedirect',
            alertText:
              'Заявка успешно создана! Вы будете перенаправлены на список заявок.',
            redirectTo: '/some-tasks/eb799284-6a84-4067-a5b8-ecf34fcf6c65#1',
          },
        },
      ],
      form: {
        type: 'markup',
        disabled: false,
        header: { heading: 'Создание подзадачи на ремонт' },
        fieldGroups: [
          {
            label: 'Основная информация',
            fields: [
              {
                type: 'select',
                selectType: 'single',
                name: 'equipment',
                label: 'Объект обслуживания',
                defaultValue: {
                  value: '8f56f9b5-87f3-4621-8af9-3ce8fbc69a6f',
                  label: 'Пароконвектомат',
                },
                options: [
                  {
                    value: '6493b4c9-df62-4bb0-96a3-2a18287fb49d',
                    label: 'Холодильник',
                  },
                  {
                    value: '9381d5ff-6fac-4eda-8f63-6d27e2cdd038',
                    label: 'Пресс для пиццы',
                  },
                ],
              },
              { type: 'text', name: 'comment', label: 'Комментарий' },
              { type: 'file', name: 'files', label: 'Файлы' },
            ],
          },
          {
            label: 'Участники',
            fields: [
              {
                type: 'select',
                selectType: 'single',
                name: 'author',
                label: 'Инициатор',
                defaultValue: {
                  value: '13c5265e-d3ab-4563-ba64-f91156bd2fa6',
                  label: 'Арапетян Сигизмунд',
                },
                options: [
                  {
                    value: '88560afd-fa18-4161-ac80-7a77de668792',
                    label: 'Смаков Руслан',
                  },
                  {
                    value: '5c464657-f2f7-4eec-a0de-db69ba2880c4',
                    label: 'Лепшоков Аслан',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};
