import React, { useContext } from 'react';
import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { TableCell } from '../../../../../../services/Main/types.Component';
import useStyles from './DroppableContainer.styles';
import TableDragAndDropCellContext from '../TableCellDragAndDrop.context';
import { useFormatMessage } from '../../../../../../locale';

interface DroppableItemProps {
  id: string;
  rowId: string;
  columnName: string;
  value: TableCell;
}

const DroppableContainer = ({
  id,
  value,
  rowId,
  columnName,
}: DroppableItemProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const tableDragAndDropCellContext = useContext(TableDragAndDropCellContext);

  const { setNodeRef, active, isOver, over } = useDroppable({
    id,
    data: {
      rowId,
      columnName,
      value,
    },
    disabled: tableDragAndDropCellContext?.loadingCellIds.includes(id),
  });

  // Не показываем дроп-контейнер если это НЕ текущая строка или ячейка уже изменяется
  if (
    !`${active?.id}`.includes(rowId) ||
    tableDragAndDropCellContext?.loadingCellIds.includes(id)
  ) {
    return null;
  }

  return (
    <Tooltip
      title={value && active?.id !== over?.id ? formatMessage('swap') : ''}
      arrow
      placement="top"
      open={isOver && value}
    >
      <div
        ref={setNodeRef}
        className={clsx(classes.droppableItem, {
          isOver,
        })}
      >
        {isOver && value && active?.id !== over?.id ? <SwapHorizIcon /> : null}
      </div>
    </Tooltip>
  );
};
export default DroppableContainer;
