import React, { useRef } from 'react';
import clsx from 'clsx';
import { Avatar, Chip } from '@material-ui/core';
import useStyles from './ClipFileList.styles';
import FileIcon from '../../../FileListItem/FileIcon';
import { File } from '../../../../../services/Main/types.Field';
import { useContentSwiper } from '../../../../../utils/hooks/useContentSwiper';

interface ChipFileListProps {
  value: File[];
  disabled?: boolean;
}

const ClipFileList = ({ disabled, value }: ChipFileListProps) => {
  const classes = useStyles();
  const tagsContainerRef = useRef<HTMLDivElement | null>(null);
  const { events, swiperContainerStyles } = useContentSwiper(tagsContainerRef);

  return (
    <div
      className={clsx(classes.fieldTabs, swiperContainerStyles)}
      ref={tagsContainerRef}
      {...events}
    >
      {value.map(({ id, url, name, mimeType }) => (
        <Chip
          key={id}
          size="small"
          className={clsx(classes.fileChip, { disabled })}
          avatar={
            <Avatar variant="square">
              <FileIcon
                url={url}
                name={name}
                mimeType={mimeType}
                classes={classes}
              />
            </Avatar>
          }
          label={name}
        />
      ))}
    </div>
  );
};

export default ClipFileList;
