import { Page } from 'services/Main/types.Page';

export const allWidgetTasksRows = [
  {
    id: 'faca0035-e390-4ec2-b383-fd58f4c5634f',
    jobType: {
      href: '/',
      label: 'Мои задания по ТО на выполнении',
    },
    count: {
      href: '/',
      label: '34',
    },
  },
  {
    id: 'b4443ccd-ce70-41c6-90c6-ca5d021a856c',
    jobType: {
      href: '/',
      label: 'Проконтроллировать выполнение просроченных заявок по ТО',
    },
    count: {
      href: '/',
      label: '20',
    },
  },
  {
    id: '387821b9-d6c3-46fc-bc8f-56cbe43efaaa',
    jobType: {
      href: '/',
      label: 'Согласовать ежемесячные ППР',
    },
    count: {
      href: '/',
      label: '12',
    },
  },
  {
    id: '5d37c204-44c4-4d9b-8ac5-3ee394968730',
    jobType: {
      href: '/',
      label: 'Согласовать ежегодные ППР',
    },
    count: {
      href: '/',
      label: '0',
    },
  },
];

export const widgetsExamplePage: Page = {
  type: 'grid',
  title: 'Страница с сеткой',
  header: { heading: 'Страница с сеткой виджетов' },
  markup: [
    {
      lgCols: 8,
      // @ts-ignore
      component: {
        id: 1,
        businessComponentId: 45,
        type: 'tableWidget',
        // @ts-ignore
        props: {
          header: { heading: 'Задания к выполнению' },
          requestConfig: {
            url: '/api/widgets/table/600200',
          },
          options: {
            totalRows: 4,
            currentPage: 0,
            pageSize: 4,
          },
          columns: [
            { name: 'jobType', options: { formatAs: 'link' } },
            { name: 'count', options: { formatAs: 'link' } },
          ],
          maxHeight: 250,
          columnExtensions: [{ columnName: 'count', width: 100 }],
          // rows: allWidgetTasksRows,
          showTotalRows: false,
          fullHeight: false,
        },
      },
    },
    {
      lgCols: 4,
      component: {
        id: 100,
        businessComponentId: 23,
        type: 'groupOfTextWidget',
        props: [
          {
            title: 'В работе',
            requestConfig: {
              url: '/api/widgets/text-widget/100113',
              method: 'GET',
            },
          },
          {
            title: 'Закрыто',
            requestConfig: {
              url: '/api/widgets/text-widget/100114',
              method: 'GET',
            },
          },
        ],
      },
    },
    {
      lgCols: 8,
      component: {
        id: 400,
        businessComponentId: 23,
        type: 'barChartWidget',
        props: {
          heading: 'Выполнение заявок',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/bar-chart/100101',
            method: 'GET',
          },
        },
      },
    },
    {
      lgCols: 4,
      component: {
        id: 200,
        businessComponentId: 23,
        type: 'donutChartWidget',
        props: {
          heading: 'Процент выполнения',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/donut-chart/100101',
            method: 'GET',
          },
        },
      },
    },
  ],
};
