import { Page } from 'services/Main/types.Page';

export const submitFormActionPage: Page = {
  type: 'singleComponent',
  title: 'Форма с submitForm экшном',
  component: {
    id: 100,
    businessComponentId: 5,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          variant: 'contained',
          label: 'Сохранить',
          color: 'primary',
          requestConfig: {
            url: '/actions/submit-form',
          },
          successResponseReaction: {
            type: 'reLoadModule',
          },
        },
        {
          type: 'submitForm',
          variant: 'contained',
          label: 'С подтверждением на фронте',
          color: 'primary',
          requestConfig: {
            url: '/actions/submit-form-with-confirm-dialog',
          },
          successResponseReaction: {
            type: 'reLoadModule',
          },
          confirmDialog: {
            body: 'Вы действительно хотите сделать ЭТО? 😱',
          },
        },
        {
          type: 'submitForm',
          variant: 'contained',
          label: 'С динамическим подтвержением',
          color: 'primary',
          preSubmitRequestConfig: {
            url: '/actions/pre-submit-form',
          },
          requestConfig: {
            url: '/actions/submit-form-with-confirm-dialog',
          },
          successResponseReaction: {
            type: 'reLoadModule',
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с submitForm экшном' },
        fieldGroups: [
          {
            label: 'Название группы',
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
                defaultValue: 'foo',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    { type: 'required', params: ['Обязательное поле'] },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  },
};
