import React, { useState, MouseEvent, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Menu, MenuItem } from '@material-ui/core';
import Icon from '../Icon';
import { TableContext } from '../../highLevel/Table';
import { useFormatMessage } from '../../../locale';

const TableExtraActions = () => {
  const formatMessage = useFormatMessage();

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const tableContext = useContext(TableContext);

  const isMenuOpen = Boolean(dropdownAnchorEl);

  const handleIconClick = (event: MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setDropdownAnchorEl(null);
  };

  const handleResetColumnWidthToDefaultClick = () => {
    tableContext?.resetColumnWidthsToDefault();
    handleMenuClose();
  };

  const handleResetColumnOrderToDefaultClick = () => {
    tableContext?.resetColumnOrderToDefault();
    handleMenuClose();
  };

  const handleResetColumnVisibilityToDefaultClick = () => {
    tableContext?.resetColumnWidthToDefault();
    handleMenuClose();
  };

  const menuItems = (() => {
    const items = [];

    if (tableContext?.enableColumnResizing) {
      items.push({
        icon: 'mui:Restore',
        label: formatMessage('resetColumnWidthsToDefault'),
        onClick: handleResetColumnWidthToDefaultClick,
      });
    }

    if (tableContext?.enableColumnReordering) {
      items.push({
        icon: 'mui:Restore',
        label: formatMessage('resetColumnOrderToDefault'),
        onClick: handleResetColumnOrderToDefaultClick,
      });
    }

    if (tableContext?.columnVisibilityConfig?.enabled) {
      items.push({
        icon: 'mui:Restore',
        label: formatMessage('resetColumnVisibilityToDefault'),
        onClick: handleResetColumnVisibilityToDefaultClick,
      });
    }

    return items;
  })();

  if (menuItems.length === 0) return null;

  return (
    <Grid item>
      <IconButton onClick={handleIconClick}>
        <Icon definition="mui:MoreVert" />
      </IconButton>
      <Menu
        anchorEl={dropdownAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {menuItems.map(({ onClick, icon, label }) => (
          <MenuItem onClick={onClick}>
            <Icon definition={icon} style={{ marginRight: 10 }} />
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default TableExtraActions;
