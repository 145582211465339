import { Page } from 'services/Main/types.Page';
import { OptionsType } from '../../../types.Field';
import stubs from './InteractiveForm.stubs';

export const interactiveFormPage: Page = {
  type: 'tabsView',
  title: 'Форма с интерактивными полями',
  tabs: [
    {
      label: 'Форма',
      component: {
        id: 100,
        businessComponentId: 788,
        type: 'form',
        props: {
          form: {
            type: 'markup',
            header: { heading: 'Простая форма с одним полем' },
            globalSocketRules: {
              interactiveFormFields: ['shop', 'room', 'equipment'],
            },
            fieldGroups: [
              {
                label: 'Название группы',
                fields: [
                  { type: 'text', name: 'comment', label: 'Комментарий' },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Магазин',
                    name: 'shop',
                    options: stubs.data.shops,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Помещение',
                    name: 'room',
                    options: stubs.data.rooms,
                  },
                  {
                    type: 'comboBox',
                    optionsType: OptionsType.flat,
                    optionsLoader: 'sync',
                    multiple: false,
                    label: 'Оборудование',
                    name: 'equipment',
                    options: stubs.data.equipments,
                  },
                ],
              },
            ],
          },
        },
      },
    },
    {
      label: 'ArrayOf (Empty)',
      component: {
        id: 102,
        businessComponentId: 789,
        type: 'form',
        props: {
          form: {
            type: 'markup',
            header: { heading: 'Простая форма с одним полем' },
            globalSocketRules: {
              interactiveFormFields: [
                'arrayOf.shop',
                'arrayOf.room',
                'arrayOf.equipment',
              ],
            },
            fieldGroups: [
              {
                label: 'Название группы',
                fields: [
                  {
                    type: 'arrayOf',
                    label: 'array of',
                    name: 'arrayOf',
                    rowDefinition: [
                      { type: 'text', name: 'comment', label: 'Комментарий' },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: false,
                        label: 'Магазин',
                        name: 'shop',
                        options: stubs.data.shops,
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: false,
                        label: 'Помещение',
                        name: 'room',
                        options: stubs.data.rooms,
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: false,
                        label: 'Оборудование',
                        name: 'equipment',
                        options: stubs.data.equipments,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    },
    {
      label: 'ArrayOf (With defaultRows)',
      component: {
        id: 102,
        businessComponentId: 789,
        type: 'form',
        props: {
          form: {
            type: 'markup',
            header: { heading: 'Простая форма с одним полем' },
            globalSocketRules: {
              interactiveFormFields: [
                'arrayOf.shop',
                'arrayOf.room',
                'arrayOf.equipment',
              ],
            },
            fieldGroups: [
              {
                label: 'Название группы',
                fields: [
                  {
                    type: 'arrayOf',
                    label: 'array of',
                    name: 'arrayOf',
                    rowDefinition: [
                      { type: 'text', name: 'comment', label: 'Комментарий' },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: false,
                        label: 'Магазин',
                        name: 'shop',
                        options: stubs.data.shops,
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: false,
                        label: 'Помещение',
                        name: 'room',
                        options: stubs.data.rooms,
                      },
                      {
                        type: 'comboBox',
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        multiple: false,
                        label: 'Оборудование',
                        name: 'equipment',
                        options: stubs.data.equipments,
                      },
                    ],
                    defaultRows: [
                      [
                        {
                          name: 'comment',
                          defaultValue: 'Строка с пустыми полями',
                        },
                        {
                          name: 'shop',
                          defaultValue: null,
                        },
                        {
                          name: 'room',
                          defaultValue: null,
                        },
                        {
                          name: 'equipment',
                          defaultValue: null,
                        },
                      ],
                      [
                        {
                          name: 'comment',
                          defaultValue:
                            'Строка с defaultValue и options для полей',
                        },
                        {
                          name: 'shop',
                          defaultValue: {
                            value: '0fcdb972-1053-4403-9b6c-6aee48196940',
                            label: 'Красногорск',
                          },
                        },
                        {
                          name: 'room',
                          defaultValue: {
                            value: 'fb31e051-c0e6-4fbf-8084-9a834f0608db',
                            label: 'Торговый зал',
                          },
                          options: [
                            {
                              value: 'fb31e051-c0e6-4fbf-8084-9a834f0608db',
                              label: 'Торговый зал',
                            },
                            {
                              value: '22c8eb91-3992-45dd-a994-30a24fa8ed54',
                              label: 'Офисное помещение',
                            },
                          ],
                        },
                        {
                          name: 'equipment',
                          defaultValue: null,
                          options: [
                            {
                              value: 'd9815d4b-29ff-455a-8109-82f9f810b0b3',
                              label: 'Слайсер',
                            },
                            {
                              value: 'f0112a8d-70cd-4bcc-8112-422d20bc44f7',
                              label: 'Хлеборезка',
                            },
                          ],
                        },
                      ],
                    ],
                  },
                ],
              },
            ],
          },
        },
      },
    },
  ],
};
