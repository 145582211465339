import { CardHeader, CardHeaderProps, styled } from '@material-ui/core';
import { Theme } from '../../../../../theme';

const MessageHeader = styled(CardHeader)<Theme, CardHeaderProps>(
  ({ theme, color }) => ({
    padding: 0,
    marginBottom: theme.spacing(1.75),
    color,

    '& .MuiTypography-root': {
      lineHeight: 1.45,
    },

    '& .MuiCardHeader-avatar': {
      marginRight: theme.spacing(1.25),
    },
  })
);

export default MessageHeader;
