import { Page } from 'services/Main/types.Page';
import { OptionsType } from '../../types.Field';

export const issuesCreatePageStep1: Page = {
  type: 'singleComponent',
  title: 'Форма создания записи оборудования',
  component: {
    id: 100,
    businessComponentId: 53,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          variant: 'contained',
          label: 'Далее',
          color: 'primary',
          requestConfig: {
            url: '/issues/create?step=2',
          },
          successResponseReaction: {
            type: 'renderPage',
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма создания записи оборудования (шаг 1 из 3)' },
        fieldGroups: [
          {
            label: 'Выберите магазин',
            fields: [
              {
                type: 'comboBox',
                optionsType: OptionsType.flat,
                name: 'shop',
                label: 'Магазин',
                options: [
                  { value: '004 Красногорск', label: '004 Красногорск' },
                  { value: '005 Химки', label: '005 Химки' },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};

export const issuesCreatePageStep2: Page = {
  type: 'singleComponent',
  title: 'Форма создания записи оборудования',
  component: {
    id: 100,
    businessComponentId: 23,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          skipValidation: true,
          variant: 'contained',
          label: 'Назад',
          color: 'primary',
          requestConfig: {
            url: '/issues/create?step=1',
          },
          successResponseReaction: {
            type: 'renderPage',
          },
        },
        {
          type: 'submitForm',
          variant: 'contained',
          label: 'Далее',
          color: 'primary',
          requestConfig: {
            url: '/issues/create?step=3',
          },
          successResponseReaction: {
            type: 'renderPage',
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма создания записи оборудования (шаг 2 из 3)' },
        fieldGroups: [
          {
            label: 'Заполните данные',
            fields: [
              {
                type: 'text',
                name: 'name',
                label: 'Название',
                validationConfig: {
                  validationType: 'string',
                  validations: [
                    {
                      type: 'required',
                      params: ['Обязательное поле'],
                    },
                    {
                      type: 'min',
                      params: [10, 'Минимум 10 символов'],
                    },
                  ],
                },
              },
              {
                type: 'file',
                name: 'files',
                label: 'Файлы',
              },
            ],
          },
        ],
      },
    },
  },
};

export const issuesCreatePageStep3: Page = {
  type: 'singleComponent',
  title: 'Форма создания записи оборудования',
  component: {
    id: 100,
    businessComponentId: 23,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          skipValidation: true,
          variant: 'contained',
          label: 'Назад',
          color: 'primary',
          requestConfig: {
            url: '/issues/create?step=2',
          },
          successResponseReaction: {
            type: 'renderPage',
          },
        },
        {
          type: 'submitForm',
          variant: 'contained',
          label: 'Создать',
          color: 'primary',
          requestConfig: {
            url: '/issues/create?step=4',
          },
          successResponseReaction: {
            type: 'redirect',
            redirectTo: '/issues/08ee3564-167c-4348-80b0-68e3fd37c948',
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма создания записи оборудования (шаг 3 из 3)' },
        disabled: true,
        fieldGroups: [
          {
            label: 'Проверьте данные',
            fields: [
              {
                type: 'comboBox',
                optionsType: OptionsType.flat,
                name: 'shop',
                label: 'Магазин',
                defaultValue: {
                  value: '004 Красногорск',
                  label: '004 Красногорск',
                },
                options: [
                  { value: '004 Красногорск', label: '004 Красногорск' },
                  { value: '005 Химки', label: '005 Химки' },
                ],
              },
              {
                type: 'text',
                name: 'name',
                label: 'Название',
                defaultValue: 'Хлеборезка',
              },
              {
                type: 'file',
                name: 'files',
                label: 'Файлы',
                defaultValue: [
                  {
                    id: 'ebda21bf-51c5-49be-9dd5-7dbce3f06e52',
                    url: '/ebda21bf-51c5-49be-9dd5-7dbce3f06e52',
                    name: 'Фото.jpg',
                    size: 26696,
                    mimeType: 'image/jpeg',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};
