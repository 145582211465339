import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FetchTablePropsResponsePayload } from '../../../../../../services/EntryPicker/EntryPicker.interface';
import { ComponentContext } from '../../../../../creational/ComponentLoader';
import { useSelector } from '../../../../../../store';

export default (
  name: string,
  columns: FetchTablePropsResponsePayload['columns'],
  columnVisibilityConfig?: FetchTablePropsResponsePayload['columnVisibilityConfig']
): [string[], Dispatch<SetStateAction<string[]>>, () => void] => {
  const componentContext = useContext(ComponentContext);
  const user = useSelector((state) => state.user);
  const localStorageKey = `${user?.id}_formId_${componentContext?.id}_entryPickerTableColumnOrderState_${name}`;

  const defaultHiddenColumnNames =
    (columnVisibilityConfig?.enabled &&
      columnVisibilityConfig.defaultHiddenColumnNames) ||
    [];

  const localStorageJSONState =
    columnVisibilityConfig?.enabled && localStorage.getItem(localStorageKey);
  const localStorageState: string[] =
    localStorageJSONState && JSON.parse(localStorageJSONState);

  const initialHiddenColumnNames =
    localStorageState || defaultHiddenColumnNames;

  const [hiddenColumnNames, setHiddenColumnNames] = useState<string[]>(
    initialHiddenColumnNames
  );

  useEffect(() => {
    columnVisibilityConfig?.enabled &&
      localStorage.setItem(localStorageKey, JSON.stringify(hiddenColumnNames));
  }, [hiddenColumnNames, columnVisibilityConfig, localStorageKey]);

  useEffect(() => {
    setHiddenColumnNames(initialHiddenColumnNames);
    // eslint-disable-next-line
  }, [columns]);

  const resetColumnWidthToDefault = () => {
    setHiddenColumnNames(defaultHiddenColumnNames);
  };

  return [hiddenColumnNames, setHiddenColumnNames, resetColumnWidthToDefault];
};
