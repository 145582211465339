function measureSvgTextWidth(
  text: string,
  fontSize: string,
  fontFamily?: string
) {
  const svgNS = 'http://www.w3.org/2000/svg';
  const svg = document.createElementNS(svgNS, 'svg');
  const textElement = document.createElementNS(svgNS, 'text');

  textElement.setAttribute('x', '0');
  textElement.setAttribute('y', '0');
  textElement.setAttribute('font-size', fontSize);
  textElement.setAttribute(
    'font-family',
    fontFamily || window.getComputedStyle(document.body).fontFamily
  );
  textElement.textContent = text;

  svg.appendChild(textElement);
  document.body.appendChild(svg);

  const { width } = textElement.getBBox();

  document.body.removeChild(svg);

  return width;
}

export default measureSvgTextWidth;
