import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let FileArchive: any = (props: any) => (
  <SvgIcon
    {...props}
    width="30"
    height="40"
    viewBox="0 0 30 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.4531 8.20312L21.8047 0.546875C21.4531 0.195312 20.9766 0 20.4766 0H20V10H30V9.52344C30 9.03125 29.8047 8.55469 29.4531 8.20312ZM10.0312 26.25C8.63281 26.25 7.5 27.1953 7.5 28.3594C7.5 29.5312 8.64062 30.4688 10.0391 30.4688C11.4375 30.4688 12.5703 29.5234 12.5703 28.3594C12.5703 27.1953 11.4297 26.25 10.0312 26.25ZM17.5 10.625V0H12.5312V2.5H10.0312V0H1.875C0.835938 0 0 0.835938 0 1.875V38.125C0 39.1641 0.835938 40 1.875 40H28.125C29.1641 40 30 39.1641 30 38.125V12.5H19.375C18.3438 12.5 17.5 11.6562 17.5 10.625ZM7.49219 2.5H9.99219V5H7.49219V2.5ZM10.0156 32.5C7.42187 32.5 5.48438 30.125 6 27.5859L7.53125 20V17.5H10.0312V15H7.53125V12.5H10.0312V10H7.53125V7.5H10.0312V5H12.5312V7.5H10.0312V10H12.5312V12.5H10.0312V15H12.5312V17.5H10.0312V20H11.7578C12.2031 20 12.5938 20.3203 12.6797 20.7578L14.0312 27.6094C14.5313 30.1406 12.5938 32.5 10.0156 32.5Z"
      fill="#C2CFE0"
      strokeWidth="1.4"
    />
  </SvgIcon>
);
FileArchive.muiName = 'SvgIcon';

FileArchive = React.memo(FileArchive);
FileArchive.displayName = 'FileArchive';

export default FileArchive;
