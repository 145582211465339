import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let FilterAltOutlined: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M7 6h10l-5.01 6.3L7 6zm-2.75-.39C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39c.51-.66.04-1.61-.79-1.61H5.04c-.83 0-1.3.95-.79 1.61z" />
  </SvgIcon>
);
FilterAltOutlined.muiName = 'SvgIcon';

FilterAltOutlined = React.memo(FilterAltOutlined);
FilterAltOutlined.displayName = 'FilterAltOutlined';

const PureFilterAltOutlined = FilterAltOutlined;

export default PureFilterAltOutlined;
