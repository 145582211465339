import faker from 'faker';
import { Page } from 'services/Main/types.Page';
import { RadioGroup } from 'services/Main/types.Field';

const createOption = () => {
  return {
    value: faker.random.word(),
    label: faker.lorem.word(),
  };
};

const someOptions = Array.from({ length: 5 }, createOption);

export const simpleRadioGroup: RadioGroup = {
  type: 'radio',
  name: 'radio1',
  label: 'Простой Radio Group',
  options: Array.from({ length: 5 }, createOption),
};

export const radioGroupFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами radio полей',
  component: {
    id: 100,
    businessComponentId: 25,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами radio полей' },
        fieldGroups: [
          {
            label: 'Radio',
            fields: [
              simpleRadioGroup,
              {
                type: 'radio',
                name: 'radio2',
                label: 'Radio Group c иконкой',
                options: [
                  { value: '1', label: 'Good', icon: 'mui:Mood' },
                  { value: '2', label: 'Bad', icon: 'mui:MoodBad' },
                ],
              },
              {
                type: 'radio',
                name: 'radio3',
                label: 'Disabled Radio Group с дефолтным значением',
                options: someOptions,
                disabled: true,
                defaultValue: someOptions[2].value,
              },
            ],
          },
        ],
      },
    },
  },
};
