import React from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  LineChart,
  Line as LineComponent,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { LineChartWidgetProps } from '../../../services/Main/types.Component';
import { LineChartState } from './types';
import DotWithValueLabel from './DotWithValueLabel';
import { LineTooltipCursor } from './components/LineTooltipCursor';
import ChartsAxisLabel from '../../lowLevel/ChartsAxisLabel';
import { LINE_COLORS } from './constants';
import useChartTickFormatter from '../BarChartWidget/helpers/useChartTickFormatter';

type AdditionalProps = {
  hiddenLineKeys: string[];
};

function LineChartWidget(
  props: LineChartWidgetProps & LineChartState & AdditionalProps
) {
  const { lines, data, showTooltip, xAxisLabel, yAxisLabel, hiddenLineKeys } =
    props;

  const tickFormatter = useChartTickFormatter();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 15 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" padding={{ left: 24, right: 24 }}>
          {xAxisLabel && (
            <ChartsAxisLabel position="insideBottomRight" offset={-5}>
              {xAxisLabel}
            </ChartsAxisLabel>
          )}
        </XAxis>
        <YAxis padding={{ top: 24 }} tickFormatter={tickFormatter}>
          {yAxisLabel && (
            <ChartsAxisLabel position="top">{yAxisLabel}</ChartsAxisLabel>
          )}
        </YAxis>
        {showTooltip && (
          <Tooltip
            active={false}
            cursor={<LineTooltipCursor />}
            content={<div />}
          />
        )}
        {lines.map(({ key, name }, index) => (
          <LineComponent
            hide={hiddenLineKeys.includes(key)}
            key={key}
            dataKey={`${key}.value`}
            stroke={LINE_COLORS[index % LINE_COLORS.length]}
            fill={LINE_COLORS[index % LINE_COLORS.length]}
            name={name}
            dot={<DotWithValueLabel />}
            activeDot={<DotWithValueLabel active />}
            strokeWidth={3}
            connectNulls
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default LineChartWidget;
