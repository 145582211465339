import orderBy from 'lodash.orderby';
import {
  FetchTableDataOptions,
  FilterOptions,
  SortingState,
  TableComponentProps,
  TableRow,
} from '../../types.Component';
import { allIssuesRows } from './issuesPage';
import { allSimpleTablePageRows } from './highLevelComponents/Table/Simple';
import { allWithCellFormattersTableRows } from './highLevelComponents/Table/WithCellFormatters';
import { allWithFiltersTablePageRows } from './highLevelComponents/Table/WithFilters';
import { allWithRowClickReactionTablePage } from './highLevelComponents/Table/WithRowClickReaction';
import { allTabsViewRows } from './pageViews/TabsView';
import { allIssuesElementRows } from './issuesElementPage';
import { allCalendarSTMPageRows } from './complex/calendarSTM';
import { allWidgetTasksRows } from './pageViews/Widgets.stub';
import { allCalendarSTMPageRowsV2 } from './complex/calendarSTMv2';
import { calendarMonthlySTMRows } from './complex/calendarMonthlySTM';

export const getTableData = async (
  {
    filter,
    query,
    sort,
    currentPage = 0,
    pageSize = 10,
  }: Partial<FetchTableDataOptions>,
  url: string
): Promise<Partial<TableComponentProps>> => {
  // issues подходят для tasks
  let initialRows: any = allIssuesRows;
  let totalRows = allIssuesRows.length;

  if (url === '/api/table/simple') {
    initialRows = allSimpleTablePageRows;
    totalRows = allSimpleTablePageRows.length;
  }

  if (url === '/api/table/with-cell-formatters') {
    initialRows = allWithCellFormattersTableRows;
    totalRows = allWithCellFormattersTableRows.length;
  }

  if (url === '/high-level-components/table/with-filters') {
    initialRows = allWithFiltersTablePageRows;
    totalRows = allWithFiltersTablePageRows.length;
  }

  if (url === '/high-level-components/table/with-row-click-reaction') {
    initialRows = allWithRowClickReactionTablePage;
    totalRows = allWithRowClickReactionTablePage.length;
  }

  if (url === '/page-views/tabs') {
    initialRows = allTabsViewRows;
    totalRows = allTabsViewRows.length;
  }

  if (url === '/issues/f45cbdc8-531e-4513-a581-694dc61b75a4/tasks') {
    initialRows = allIssuesElementRows;
    totalRows = allIssuesElementRows.length;
  }

  if (url === '/api/complex/calendar-stm') {
    initialRows = allCalendarSTMPageRows;
    totalRows = allCalendarSTMPageRows.length;
  }

  if (url === '/api/complex/v2-calendar-stm') {
    initialRows = allCalendarSTMPageRowsV2;
    totalRows = allCalendarSTMPageRowsV2.length;
  }

  if (url === '/api/complex/monthly-calendar-stm') {
    initialRows = calendarMonthlySTMRows;
    totalRows = calendarMonthlySTMRows.length;
  }

  if (url === '/api/page-views/widgets/tasks') {
    initialRows = allWidgetTasksRows;
    totalRows = allWidgetTasksRows.length;
  }

  let rows: TableRow[] = [...initialRows];

  if (filter && Object.values(filter).length > 0) {
    rows = filterRows(rows, filter as FilterOptions);
    totalRows = rows.length;
  }

  if (query) {
    const queryFilter: { [key: string]: string } = {};

    Object.keys(initialRows[0]).forEach((key) => {
      queryFilter[key] = query;
    });

    rows = findRows(rows, queryFilter);
    totalRows = rows.length;
    // console.log(rows, 'QUERY');
  }

  if (sort) {
    rows = sortRows(rows, sort);
    // console.log(rows, 'SORT');
  }

  const start = currentPage * pageSize;
  const end = start + pageSize;

  rows = rows.slice(start, end);

  return {
    rows,
    options: {
      totalRows,
      pageSize,
      currentPage,
    },
  };
};

function filterRows(rows: TableRow[], filterList: FilterOptions) {
  return rows.filter((row) => {
    return Object.entries(filterList).every(([columnName, filter]) => {
      if (!row[columnName]) return true;

      switch (typeof filter) {
        case 'string': // вхождение
          if (filter.length === 0) return true;
          if (typeof row[columnName] !== 'string') return false;
          return row[columnName]
            .toLowerCase()
            .includes(mapFilter(filter).toLowerCase());
        case 'number': // сравнение
          return row[columnName] === filter;
        case 'object':
          if (Array.isArray(filter)) {
            if (filter.length === 0) return true;
            if (typeof row[columnName] !== 'string') return false;
            // варианты
            return filter.some((f) => row[columnName].includes(mapFilter(f)));
          }

          // DateRange
          if ('from' in filter && 'to' in filter) {
            // Если "До" пустое значение, но есть "От"
            if (!filter.to && filter.from) {
              return row[columnName] >= filter.from;
            }

            // Если "От" пустое значение, но есть "До"
            if (!filter.from && filter.to) {
              return row[columnName] <= filter.to;
            }

            if (filter.to && filter.from) {
              return (
                row[columnName] >= filter.from && row[columnName] <= filter.to
              );
            }

            // Если все значениея "От"/"До" пустые - пропускаем
            return true;
          }

          // Если ни одна из проверок не сработала пропускаем
          return true;
        default:
          throw new Error('Фу 🙈');
      }
    });
  });
}

function findRows(rows: TableRow[], filterList: { [key: string]: string }) {
  return rows.filter((row) =>
    Object.entries(filterList).some(([columnName, filter]) => {
      if (typeof row[columnName] !== 'string') return false;
      return row[columnName].toLowerCase().includes(filter.toLowerCase());
    })
  );
}

function mapFilter(f: string) {
  switch (f) {
    case 'InWork':
      return 'В работе';
    case 'Draft':
      return 'Черновик';
    case 'Pause':
      return 'Отложена';
    case 'Closing':
      return 'Сдача-приемка';
    case 'Canceled':
      return 'Прекращена';
    case 'Done':
      return 'Выполнено';
    case 'Approval':
      return 'На согласовании';
    case 'Queue':
      return 'В очереди';
    case 'Critical':
      return 'Критичный';
    case 'High':
      return 'Высокий';
    case 'Low':
      return 'Низкий';
    case 'FooBar':
      return 'Заглушка';

    default:
      return f;
  }
}

function sortRows(rows: TableRow[], sort: SortingState): TableRow[] {
  return orderBy(
    rows,
    sort.map((s) => s.columnName),
    sort.map((s) => s.direction)
  );
}
