import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    bubble: {
      borderRadius: theme.spacing(2),
      borderColor: theme.palette.divider,
      borderWidth: 1,
      borderStyle: 'solid',
      maxWidth: 768,
      minWidth: 220,
      width: 'auto',
      display: 'inline-block',
    },
    sideLeft: {
      borderTopLeftRadius: 0,
    },
    sideRight: {
      borderTopRightRadius: 0,
    },
  })
);
