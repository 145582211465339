import React, { Dispatch, SetStateAction, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { FilterSidebarProps } from '../../../../../services/Main/types.Page';
import TableFilterForm from '../../../TableFilterForm';
import TableFilterFormFooter from '../../../TableFilterForm/TableFilterForm.footer';
import {
  DirtyFormValues,
  FilterChip,
} from '../../../../../services/Main/types.Component';
import { mapValuesToFilterOptions } from '../../../TableFilterForm/TableFilterForm';
import { mapValuesToPlain } from '../../../FormBuilder/helpers';
import { useSidebarWrapperContext } from '../SidebarWrapper/SidebarWrapper.provider';
import { globalFilterAction } from '../../../../../store/globalFilter';

const WidgetFilterSidebar = (props: FilterSidebarProps) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<DirtyFormValues | undefined>(undefined);

  const { setCounter } = useSidebarWrapperContext();

  const handleFilterChange = (newFilterState?: DirtyFormValues) => {
    const preparedFilterOptions =
      newFilterState &&
      mapValuesToFilterOptions(
        mapValuesToPlain(
          newFilterState,
          props?.filterComponent?.props?.fieldGroups[0].fields || []
        )
      );

    // Игнорирует вызов loadRows при монтировании TableFilterForm
    // filter: undefined -> filter: { comment: '', shop: [], status: []}
    if (filter) {
      const targetState =
        preparedFilterOptions && Object.keys(preparedFilterOptions).length > 0
          ? preparedFilterOptions
          : null;

      dispatch(globalFilterAction.set(targetState));
    }

    setFilter(newFilterState);
  };

  const handleChangeCount = (chips?: FilterChip[]) => {
    if (Array.isArray(chips)) {
      setCounter(chips.length);
    }
  };

  return (
    <TableFilterForm
      type="tableFilter"
      props={props.filterComponent.props}
      filter={filter}
      setFilter={handleFilterChange}
      filterChips={undefined}
      setFilterChips={
        handleChangeCount as Dispatch<SetStateAction<FilterChip[] | undefined>>
      }
      businessComponentId={props.filterComponent.businessComponentId}
      id={props.filterComponent.id}
      formBuilderProps={{
        header: () => <Typography variant="h6">{props.header}</Typography>,
        footer: TableFilterFormFooter,
      }}
    />
  );
};

export default WidgetFilterSidebar;
