import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '../../../../../theme';

export default makeStyles<Theme>((theme) =>
  createStyles({
    box: {
      padding: theme.spacing(0.25, 2, 1.75),
      color: theme.palette.text.secondary,
      display: 'none',

      '&.displayMoreOptionsText': {
        display: 'block',
      },
    },
    pseudoLink: {
      cursor: 'pointer',
      paddingLeft: theme.spacing(0.5),
    },
  })
);
