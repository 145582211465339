import React, { FunctionComponent } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { TableAction } from '../../../../lowLevel/TableAction';
import {
  TableColumn,
  TableRow,
  TableRowAction,
} from '../../../../../services/Main/types.Component';
import TableActionIconButton from '../../../../lowLevel/TableAction/TableAction.IconButton';

const Formatter: FunctionComponent<DataTypeProvider.ValueFormatterProps> = ({
  value: actions,
  row,
  column,
}) => {
  if (!actions) return null;

  const { id: rowId } = row as TableRow;
  const { name: columnName } = column as TableColumn;

  return (
    <>
      {actions.map((action: TableRowAction, index: number) => {
        if (!action.icon) {
          throw new Error(
            `Невозможно отобразить иконку в ячейке строки с id: ${rowId} колонки ${columnName} с formatAs: 'actions', так как пришедший экшен не содержит icon. Прилетел: ${JSON.stringify(
              action
            )}`
          );
        }

        return (
          <TableAction
            key={`${columnName}_${rowId}_rowAction_${index}`}
            action={action}
            renderAction={TableActionIconButton}
          />
        );
      })}
    </>
  );
};

interface ActionTypeProviderProps {
  for: string[];
}

const ActionsTypeProvider = (props: ActionTypeProviderProps) => (
  <DataTypeProvider formatterComponent={Formatter} {...props} />
);

export default ActionsTypeProvider;
