import { useLayoutEffect, useRef } from 'react';
import ScrollManager from '../../../../utils/ScrollManager';

function useScrollManager() {
  const timelineScrollManager = useRef<ScrollManager>();
  const timelineElementsContainer = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (timelineElementsContainer.current) {
      timelineScrollManager.current = new ScrollManager(
        timelineElementsContainer.current,
        timelineElementsContainer.current.scrollHeight,
        'data-timeline-element-id'
      );

      // Передвигаем скролл после загрузки контента
      setTimeout(() => {
        timelineScrollManager?.current?.scrollToBottom();
      }, 0);
    }
    // eslint-disable-next-line
  }, [timelineElementsContainer.current]);

  return { timelineScrollManager, timelineElementsContainer };
}

export default useScrollManager;
