import { ArrayField } from 'react-hook-form';

export const getArrayOfFieldNames = (
  arrayOfName: string,
  fields: Partial<ArrayField<Record<string, any>, 'id'>>[]
): string[] => {
  return fields.reduce<string[]>((acc, { id, ...fieldValues }, rowIndex) => {
    acc.push(
      ...Object.keys(fieldValues).map(
        (fieldName) => `${arrayOfName}[${rowIndex}].${fieldName}`
      )
    );

    return acc;
  }, []);
};
