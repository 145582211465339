import React from 'react';
import { TimelineElementBody } from '../../../../../services/Main/types.Component';
import useStyles from './MessageBody.styles';

function MessageBody({ html }: TimelineElementBody) {
  const classes = useStyles();

  return (
    <div
      className={classes.body}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}

export default MessageBody;
