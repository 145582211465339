// О заявке
import { Page, Tab } from '../../types.Page';
import { Header } from '../../types.Component';

export const issuesElementPageHeader: Header = {
  heading: 'Привет, я тут 🌑',
  preHeadingIcon: {
    type: 'icon',
    name: 'local:Record',
    props: {
      fill: '#ff0000',
      stroke: '#ff0000',
    },
  },
  subHeadings: [
    ['Номер', '0077-004/20'],
    ['Создано', '2020-04-15T16:33:23.3004811+03:00'],
  ],
};

const Tab1: Tab = {
  label: 'О заявке',
  component: {
    id: 300,
    businessComponentId: 54,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        disabled: true,
        header: issuesElementPageHeader,
        historyConfig: {
          requestConfig: {
            url: 'http://localhost:3000/api/history',
            method: 'GET',
          },
        },
        fieldGroups: [
          {
            label: 'Информация по заявке',
            fields: [
              {
                type: 'select',
                selectType: 'single',
                name: 'object',
                label: 'Объект обслуживания',
                defaultValue: {
                  value: '43fdg4-df23h-gdf5v',
                  label: 'Печь для пиццы ярусная',
                },
                options: [
                  {
                    value: '43fdg4-df23h-gdf5v',
                    label: 'Печь для пиццы ярусная',
                  },
                ],
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'issueType',
                label: 'Тип заявки',
                defaultValue: { value: '0000-0000-000000', label: 'Заглушка' },
                options: [{ value: '0000-0000-000000', label: 'Заглушка' }],
                mdCols: 6,
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'shop',
                label: 'Магазин',
                defaultValue: {
                  value: '234fd-sadf32-a4gmfd',
                  label: 'Красногорск',
                },
                mdCols: 6,
                options: [
                  {
                    value: '234fd-sadf32-a4gmfd',
                    label: 'Красногорск',
                  },
                ],
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'room',
                label: 'Помещение',
                defaultValue: {
                  value: 'baaf69a1-1914-495d-acfd-0e9b078a00df',
                  label:
                    'Кондитерская - Производственная зона, 2 этаж 0-1 / b\'-b"',
                },
                options: [
                  {
                    value: 'baaf69a1-1914-495d-acfd-0e9b078a00df',
                    label:
                      'Кондитерская - Производственная зона, 2 этаж 0-1 / b\'-b"',
                  },
                ],
              },
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
                defaultValue: 'Привет, я тут 🌑',
              },
            ],
          },
          {
            label: 'Участники',
            accordion: { enabled: true, defaultExpanded: false },
            fields: [
              {
                type: 'select',
                selectType: 'single',
                name: 'author',
                label: 'Инициатор',
                defaultValue: {
                  value: 'd360ddae-da05-4d20-b4e7-988a4013a02f',
                  label: 'Титов Владимир',
                },
                options: [
                  {
                    value: 'd360ddae-da05-4d20-b4e7-988a4013a02f',
                    label: 'Титов Владимир',
                  },
                ],
              },
              {
                type: 'select',
                selectType: 'single',
                name: 'assignee',
                label: 'Ответственный',
                defaultValue: {
                  value: '22dc48cb-4f2e-4012-82d8-196fa2d2cd9a',
                  label: 'Малогин Андрей (ООО "БЕКАР-ЭКСПЛУАТАЦИЯ")',
                },
                options: [
                  {
                    value: '22dc48cb-4f2e-4012-82d8-196fa2d2cd9a',
                    label: 'Малогин Андрей (ООО "БЕКАР-ЭКСПЛУАТАЦИЯ")',
                  },
                ],
              },
            ],
          },
          {
            label: 'Прикрепленные файлы',
            fields: [
              {
                type: 'file',
                name: 'files',
                label: 'Файлы',
                defaultValue: [
                  {
                    id: '28e67ee9-3042-4f9d-a357-d6bdf6af4262',
                    url: '/file/28e67ee9-3042-4f9d-a357-d6bdf6af4262',
                    name: 'Estimate.docx',
                    size: 26696,
                    mimeType:
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
};

export const allIssuesElementRows = [
  {
    id: '966b64e5-2f8a-41c9-89f1-4a0fdfd99d8c',
    createdAt: '2020-04-15T16:33:23.3004811+03:00',
    type: 'Выполнение заявки',
    comment: 'Окей',
    status: 'Выполнено',
  },
  {
    id: 'df164bf4-d157-471f-850d-15a854a1b2f4',
    createdAt: '2020-04-15T15:34:48.483577+03:00',
    type: 'Создание дефектной ведомости',
    comment: 'Привет, я тут 🌑',
    status: 'На согласовании',
  },
];

// Задания
const Tab2: Tab = {
  label: 'Задания',
  component: {
    id: 404,
    businessComponentId: 23,
    type: 'table',
    props: {
      heading: 'Список заданий по заявке',
      requestConfig: {
        url: '/issues/f45cbdc8-531e-4513-a581-694dc61b75a4/tasks',
      },
      columns: [
        {
          title: 'Создано',
          name: 'createdAt',
          options: {
            formatAs: 'dateTime',
          },
        },
        {
          title: 'Тип задания',
          name: 'type',
        },
        { title: 'Тема', name: 'comment' },
        {
          title: 'Статус',
          name: 'status',
        },
      ],
      rows: allIssuesElementRows,
      options: {
        totalRows: allIssuesElementRows.length,
        currentPage: 0,
        pageSize: 20,
        rowClickReaction: {
          type: 'dynamicRedirect',
          redirectTo: '/tasks/:id',
        },
      },
    },
  },
};

// Взаимодействия
const Tab3: Tab = {
  label: 'Лента событий',
  component: {
    type: 'timeline',
    id: 412,
    businessComponentId: 23,
    props: {
      addCommentUrl: {
        url: 'https://auchan-timeline-backend.herokuapp.com/add-timeline-element',
        method: 'POST',
      },
      timelineSocketConfig: {
        uri: 'https://auchan-timeline-backend.herokuapp.com',
        client: 'io',
        // path: '',
        query: {
          some: 'params',
        },
      },
      header: { heading: 'Лента взаимодействий по Заявке' },
    },
  },
};

export const issuesElementPage: Page = {
  type: 'tabsView',
  tabs: [Tab1, Tab2, Tab3],
  title: 'Заявка на ремонт',
};
