import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let List: any = (props: any) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16">
    <path
      d="M4.54999 6.57143V9.42857C4.54999 9.5258 4.47528 9.58572 4.40624 9.58572H0.84375C0.774705 9.58572 0.7 9.5258 0.7 9.42857V6.57143C0.7 6.4742 0.774705 6.41429 0.84375 6.41429H4.40624C4.47528 6.41429 4.54999 6.4742 4.54999 6.57143ZM0.7 15.1429V12.2857C0.7 12.1885 0.774705 12.1286 0.84375 12.1286H4.40624C4.47528 12.1286 4.54999 12.1885 4.54999 12.2857V15.1429C4.54999 15.2401 4.47528 15.3 4.40624 15.3H0.84375C0.774704 15.3 0.7 15.2401 0.7 15.1429ZM0.84375 0.7H4.40624C4.47528 0.7 4.54999 0.759915 4.54999 0.857143V3.71429C4.54999 3.81151 4.47528 3.87143 4.40624 3.87143H0.84375C0.774705 3.87143 0.7 3.81151 0.7 3.71429V0.857143C0.7 0.759916 0.774705 0.7 0.84375 0.7ZM17.1562 15.3H7.21874C7.14969 15.3 7.07499 15.2401 7.07499 15.1429V12.2857C7.07499 12.1885 7.14969 12.1286 7.21874 12.1286H17.1562C17.2253 12.1286 17.3 12.1885 17.3 12.2857V15.1429C17.3 15.2401 17.2253 15.3 17.1562 15.3ZM7.07499 3.71429V0.857143C7.07499 0.759915 7.14969 0.7 7.21874 0.7H17.1562C17.2253 0.7 17.3 0.759916 17.3 0.857143V3.71429C17.3 3.81151 17.2253 3.87143 17.1562 3.87143H7.21874C7.14969 3.87143 7.07499 3.81151 7.07499 3.71429ZM17.1562 9.58572H7.21874C7.14969 9.58572 7.07499 9.5258 7.07499 9.42857V6.57143C7.07499 6.4742 7.14969 6.41429 7.21874 6.41429H17.1562C17.2253 6.41429 17.3 6.4742 17.3 6.57143V9.42857C17.3 9.5258 17.2253 9.58572 17.1562 9.58572Z"
      fill="transparent"
      strokeWidth="1.4"
    />
  </SvgIcon>
);
List.muiName = 'SvgIcon';

List = React.memo(List);
List.displayName = 'List';

const PureList = List;

export default PureList;
