import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import useStyles from '../TreeComboBox.styles';
import { useFormatMessage } from '../../../../../../locale';

interface CheckboxButtonProps {
  checked: boolean;
  indeterminate: boolean;
  onClick: () => void;
}

const TreeCheckboxButton = ({
  indeterminate,
  checked,
  onClick,
}: CheckboxButtonProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  const startIcon = useMemo(() => {
    if (indeterminate && !checked) {
      return <IndeterminateCheckBoxIcon color="primary" />;
    }

    if (checked) {
      return <CheckBoxIcon color="primary" />;
    }

    return <CheckBoxOutlineBlankIcon />;
  }, [indeterminate, checked]);

  const handleClick = () => {
    onClick();
  };

  return (
    <Button
      size="small"
      color="primary"
      className={clsx(classes.button, 'checkAllButton')}
      disableRipple
      startIcon={startIcon}
      onClick={handleClick}
    >
      {checked || indeterminate
        ? formatMessage('deselectAll')
        : formatMessage('selectAll')}
    </Button>
  );
};

export default TreeCheckboxButton;
