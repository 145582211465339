import { AsyncRedirectReaction } from 'services/Main/types.Component';
import history from 'utils/history';

type SomeObject = { [key: string]: unknown };

export default (
  { responseRedirectKey }: AsyncRedirectReaction,
  payload?: unknown
) => {
  // Или нужно потому, что typeof null === 'object'
  if (typeof payload !== 'object' || payload === null)
    throw new Error(
      'С сервера вернулся некорректный ответ для AsyncRedirectReaction.'
    );

  if (!payload.hasOwnProperty(responseRedirectKey))
    throw new Error(
      'Не удалось найти ссылку для редиректа в ответе сервера. Не найдено свойство объекта.'
    );

  if (typeof (payload as SomeObject)[responseRedirectKey] !== 'string')
    throw new Error('С сервера вернулся некорректный url для редиректа.');

  history.push((payload as SomeObject)[responseRedirectKey] as string);
};
