import React from 'react';
import ActionButton from '../ActionButton';
import Icon from '../Icon/Icon';
import { FormActionButtonProps } from './types';
import useIsSomeActionExecuting from './useIsSomeActionExecuting';

const FormActionButton = ({ action, onClick }: FormActionButtonProps) => {
  const { variant, color, type, icon, label } = action;

  const { isSomeActionExecuting } = useIsSomeActionExecuting();

  return (
    <ActionButton
      variant={variant}
      color={color}
      type={type === 'submitForm' ? 'submit' : 'button'}
      onClick={onClick}
      disabled={isSomeActionExecuting}
      startIcon={icon && <Icon definition={icon} />}
    >
      {label}
    </ActionButton>
  );
};

export default FormActionButton;
