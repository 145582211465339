import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import faker from 'faker';
import Divider from '@material-ui/core/Divider';

const LINE_HEIGHT = 16;

const lineContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: LINE_HEIGHT,
  marginBottom: LINE_HEIGHT,
};

function HeaderLine() {
  return (
    <div style={{ ...lineContainerStyles, marginTop: 16 }}>
      <Skeleton
        variant="rect"
        animation={false}
        width={40}
        height={LINE_HEIGHT / 2}
        style={{ marginLeft: 24 }}
      />
      <Skeleton
        variant="rect"
        animation={false}
        width={20}
        height={LINE_HEIGHT / 2}
      />
    </div>
  );
}

function Line() {
  return (
    <>
      <div style={lineContainerStyles}>
        <Skeleton
          variant="rect"
          animation={false}
          width={faker.datatype.number({ min: 30, max: 100 })}
          height={LINE_HEIGHT}
          style={{ marginLeft: 24 }}
        />
        <Skeleton
          variant="circle"
          animation={false}
          width={LINE_HEIGHT}
          height={LINE_HEIGHT}
        />
      </div>
      <Divider />
    </>
  );
}

function TableWidgetSkeleton(props: any) {
  return (
    <div
      className={props?.className}
      style={{ ...props?.style, width: '100%' }}
    >
      <HeaderLine />
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
      <Line />
    </div>
  );
}

export default TableWidgetSkeleton;
