import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import MuiGrid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import TableFilterForm from '../TableFilterForm';
import { TableFilterFormProps } from '../TableModules';
import useStyles from './TableFilters.styles';
import { useFormatMessage } from '../../../locale';
import TableFilterFormFooter from '../TableFilterForm/TableFilterForm.footer';
import TableFilterIconButton from './TableFilterIconButton';

interface TableFiltersProps {
  fieldsWithDisableClearableLength?: number;
}

const TableFilters = ({
  filter,
  filterChips,
  setFilter,
  fieldsWithDisableClearableLength = 0,
  ...rest
}: TableFilterFormProps & TableFiltersProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  const handleOpenFilter = () => {
    setIsFiltersOpen(true);
  };

  return (
    <>
      <MuiGrid item>
        <TableFilterIconButton
          filterCount={
            (filterChips?.length || 0) + fieldsWithDisableClearableLength
          }
          onClick={handleOpenFilter}
        />
        <Drawer
          anchor="right"
          open={isFiltersOpen}
          keepMounted
          onClose={() => setIsFiltersOpen(false)}
          classes={{
            paper: classes.paper,
          }}
        >
          <TableFilterForm
            filter={filter}
            setFilter={setFilter}
            {...rest}
            formBuilderProps={{
              header: () => (
                <Typography variant="h6">
                  {formatMessage('filterList')}
                </Typography>
              ),
              footer: TableFilterFormFooter,
            }}
          />
        </Drawer>
      </MuiGrid>
    </>
  );
};

export default TableFilters;
