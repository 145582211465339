import {
  TableFixedColumnsProps,
  Table,
} from '@devexpress/dx-react-grid-material-ui';
import { ColumnBands, Grouping } from '@devexpress/dx-react-grid';
import { AlertProps } from '@material-ui/lab/Alert';
import { Color, Size } from '@material-ui/core';
import { OptionsObject as SnackbarOptionsObject } from 'notistack';
// eslint-disable-next-line import/no-cycle
import {
  Field,
  SelectOption,
  DateRangeValue,
  File,
  ComboBoxOption,
  CheckboxTreeSelectOption,
  RadioTreeSelectOption,
} from './types.Field';
import { IconDefinition, ParsableDate } from './types.common';

/**
 * Высокоуровневый компонент фронта
 */
export type Component =
  | TimelineComponent
  | TableComponent
  | TableWidget
  | FormComponent
  | GroupOfTextWidget
  | DonutChartWidget
  | BarChartWidget
  | LineChartWidget
  | HTMLPrinter;

export type WidgetComponent =
  | TableWidget
  // | GroupOfTextWidget // TODO подключить сам виджет, а не группу.
  | DonutChartWidget
  | BarChartWidget
  | LineChartWidget;

export interface HTMLPrinter extends BaseComponent<'HTMLPrinter'> {
  props: HTMLPrinterProps;
}

export interface HTMLPrinterProps {
  html: string;
}

export interface LineChartWidget extends BaseComponent<'lineChartWidget'> {
  props: LineChartWidgetProps;
}

export interface LineChartWidgetProps extends BaseWidget {
  // Виджеты теперь только асинхронные.
  // data: ChartDatum[];
  // Виджеты теперь только асинхронные.
  // lines: Line[];
  xAxisLabel?: string;
  yAxisLabel?: string;
}

export interface Line {
  // системное названия свойства.
  key: string;
  // локализованное название свойства.
  name: string;
  // цвет линнии
  // color: string;
  // Если установлено false, точки не будут отображаться.
  // По умолчанию false.
  // dot?: boolean;
  // Ширина линии. По умолчанию 3
  // strokeWidth?: number;
}

export interface BarChartWidget extends BaseComponent<'barChartWidget'> {
  props: BarChartProps;
}

export interface BarChartProps extends BaseWidget {
  // Виджеты теперь только асинхронные.
  // data: ChartDatum[]; // Данные Bar'ов.
  // Виджеты теперь только асинхронные.
  // bars: Bar[]; // Конфиги Bar'ов.
  // Расположение bar'ов на графике.
  // По-умолчанию: 'horizontal'
  orientation?: 'horizontal' | 'vertical';

  // Можно явно задать высоту. Может потребоваться, когда ориентация графика
  // горизонтальна, а по оси Y много баров и лейблы для них не вмещаются
  // по высоте (рисуются через один или реже). В таком случае можно явно
  // задать высоту (как хардкодом, так и посчитать 60 * barsCount).
  //
  // Если не указать, то будет 300px.
  height?: number;
  xAxisLabel?: string;
  yAxisLabel?: string;
}

export interface ChartDatum {
  // Название одной ячейки по оси. Используем для подписей оси и, если не задан
  // description, то в Tooltip.
  // Рекомендуется до 5 символов.
  name: string;
  // Полное название одной ячейки по оси. Используем в Tooltip.
  // Без ограничений по длине строки.
  // Если не задан, в Tooltip будет выведен name.
  description?: string;
  // Кастомное значение "всего" для вывода в тултипе.
  // Если не передать, посчитается на основе данных.
  totalPrintValue?: string;
  // Ключ === key из Bar или Line
  [key: string]: ChartDatumValue | unknown;
}

export interface ChartDatumValue {
  // Значение - обязательно number!
  value: number;
  // Опциональная ссылка.
  href?: string;
  // Значение отображаемое в tooltip
  printValue?: string;
}

export interface Bar {
  key: string; // системное название свойства.
  name: string; // локализованное название свойства.
  fill: string; // HEX/rgba цвет заливки.
  stackId?: string | number; // произвольная строка или число для группировки нескольких значений в один стек.
}

export interface DonutChartWidget extends BaseComponent<'donutChartWidget'> {
  props: DonutChartProps;
}

export interface DonutChartProps extends BaseWidget {
  // Виджеты теперь только асинхронные.
  // data: DonutChartDatum[];
}

export interface DonutChartDatum {
  name: string;
  value: number; // Число‼️
  // Значение отображаемое в tooltip. Если не передать будет отображаться value
  printValue?: string;
  fill?: string; // html-цвет (hex или rgb или rgba) для заливки
  stroke?: string; // html-цвет (hex или rgb или rgba) для обводки
  // Опциональная ссылка.
  href?: string;
}

export interface GroupOfTextWidget extends BaseComponent<'groupOfTextWidget'> {
  props: TextWidget[];
}

/**
 * Цикл загрузки:
 * 1. Запрашиваем Page.
 * 2. Рисуем скелетоны для виджетов.
 * 3. Запрашиваем данные для виджетов (из requestConfig).
 * 4. Рисуем данные виджетов.
 *
 * Запрос requestConfig POST, с параметрами фильтрации (глобальные и локальные).
 *
 * Ответ ServerResponse<LineChartDataLoaderResponse>
 * Ответ ServerResponse<BarChartDataLoaderResponse>
 * Ответ ServerResponse<DonutChartDataLoaderResponse>
 * Ответ ServerResponse<TextWidgetDataLoaderResponse>
 *
 * interface TextWidgetDataLoaderResponse {
 *   metric?: Metric;
 *   trend?: Trend;
 * }
 *
 * interface BarChartDataLoaderResponse {
 *   data: ChartDatum[];
 *   bars?: Bar[];
 * }
 *
 * interface LineChartDataLoaderResponse {
 *   data: ChartDatum[];
 *   lines?: Line[];
 * }
 *
 * interface DonutChartDataLoaderResponse {
 *   data: DonutChartDatum[];
 * }
 *
 * Как прикрутить:
 * 1. (0) Добавить в json requestConfig. Убрать выдачу данных для виджетов, добавить requestConfig.
 * 2. (0) Прикрутить обертки, которые запрашивают данные для виджета.
 * 3. (0) Проработать состояние загрузки (скелетон).
 * 4. (1) Изменить логику перезапроса PlaneForm таким образом, чтобы дёргался новый URL.
 * 5. (1) Добавить возможность перезапрашивать данные при изменении Sidebar.
 * 6. (1) Проработать Table.
 *
 * TODO: Для Table нужно будет выделить TableWidget, отнаследовать его от BaseWidget, но пропы оставить обычные Table, т.е. над Table сделать обертку для получения изначальных данных.
 *
 */

export interface TextWidget {
  title: string; // заголовок (желательно не более 14 символов)
  // metric?: Metric; // показатель/метрика
  // trend?: Trend; // изменения в виде тренда
  description?: string; // надпись справа от тренда (желательно не более 8 символов)
  requestConfig: RequestConfig; // Забираем metric и trend
}

export interface Metric {
  // Значение метрики (желательно не более 8 символов)
  label: string;
  // Опциональная ссылка.
  href?: string;
}

export interface Trend {
  value: string; // показатель тренда
  dynamic: TrendDynamic; // позитивные изменения или отрицательные
}

export const ALL_TREND_DYNAMIC = [
  'positive',
  'negative',
  'withoutChanges',
] as const;

type TrendDynamicTuple = typeof ALL_TREND_DYNAMIC;
export type TrendDynamic = TrendDynamicTuple[number];

interface BaseWidget {
  heading?: string;
  showLegend?: boolean;
  showTooltip?: boolean;
  requestConfig: RequestConfig; // Перенес сюда.
}

export interface PeriodFormProps {
  defaultValue?: SelectOption;
  options: SelectOption[];
}

export interface PlaneFormComponent extends BaseComponent<'planeForm'> {
  props: {
    requestOn: 'change'; // в каких ситуациях применять фильтр
    fieldGroups: FieldGroup[];
  };
}

/**
 * TIMELINE
 */
export type TimelineAuthor = {
  id: string;
  firstName: string;
  secondName?: string;
  lastName: string;
  email: string;
  phone?: string;
  position?: string[]; // локализованные названия должностей.
  department?: string; // подразделение.
  organization?: string; // организация.
};

export interface SocketConfig {
  uri: string; // местонахождение хоста. Можно использовать `/` для same origin.
  client?: 'SignalR' | 'io' | null; // default 'SignalR'.
  path?: string | null; // нужно начинать с `/`. К примеру, `/timeline-hub`.
  query?: { [key: string]: string };
}

/**
 * {
 *   html: '<div>
 *     <p>Заявка взята в работу.</p>
 *     <p><b>Ответственный:</b> Малогин А.</p>
 *   </div>',
 *   plainText: 'Заявка взята в работу. Ответственный: Малогин А.'
 * }
 */
export interface TimelineElementBody {
  // собранный текст в HTML по шаблонам от аналитика
  html: string;
  // собранный текст в обычную строку (без переносов строк)
  plainText: string;
}

// <editor-fold desc="Timeline V2">
export interface TimelineComponent extends BaseComponent<'timeline'> {
  props: TimelineComponentProps;
}

export type TimelineComponentProps = {
  header: Header;
  // куда отослать свободный коммент (по HTTP)
  addCommentUrl: RequestConfig;
  // куда подписаться для обновлений
  timelineSocketConfig: SocketConfig;
  // ✖️ addTimelineElementForm: Field[]; // поля для формы добавления комментария
};

export type TimelineElement =
  | CurrentUserTimelineElement
  | OtherUserTimelineElement
  | SystemTimelineElement;

export interface CurrentUserTimelineElement
  extends AdvancedTimelineElement<'currentUser'> {}

export interface OtherUserTimelineElement
  extends AdvancedTimelineElement<'otherUser'> {
  // ✖️ header: string; // Заголовок сообщения. Обычно туда нужно писать ФИО автора
}

export interface SystemTimelineElement extends BaseTimelineElement<'system'> {}

interface AdvancedTimelineElement<T> extends BaseTimelineElement<T> {
  // Данные для построения модалки с инфой о авторе
  author: TimelineAuthor;
  // признак, что это вручную добавленный комментарий
  isAddedManually?: boolean;
}

interface BaseTimelineElement<T> {
  id: string | number;
  type: T;
  // для сортировки TimelineElement[] на фронте
  sort: number;
  // основной текст сообщения
  body: TimelineElementBody;
  createdAt: ParsableDate;
  // цитируемое сообщение
  quote?: QuotedTimelineElement;
  attachments?: File[];
  isPrivate?: boolean;
}

export type QuotedTimelineElement = {
  // ИД/гуид цитируемого сообщения (чтобы на фронте можно было к нему проскроллить)
  quotedElementId: string | number;
  body: TimelineElementBody;
};
// </editor-fold>

// <editor-fold desc="FormHistory">
export type FormHistoryElement = Pick<
  OtherUserFormHistoryTimelineElement,
  'id' | 'body' | 'createdAt' | 'sort'
> &
  Partial<Pick<OtherUserFormHistoryTimelineElement, 'header' | 'author'>>;

export interface OtherUserFormHistoryTimelineElement
  extends AdvancedFormHistoryTimelineElement<'otherUser'> {
  header: string; // Заголовок сообщения. Обычно туда нужно писать ФИО автора
}

interface AdvancedFormHistoryTimelineElement<T>
  extends BaseFormHistoryTimelineElement<T> {
  author: TimelineAuthor; // Данные для построения модалки с инфой о авторе
  isAddedManually?: boolean; // признак, что это вручную добавленный комментарий
}

interface BaseFormHistoryTimelineElement<T> {
  id: string | number;
  type: T;
  sort: number; // для сортировки TimelineElement[] на фронте
  body: TimelineElementBody; // основной текст сообщения
  createdAt: ParsableDate;
  quote?: QuotedTimelineElement; // цитируемое сообщение
  attachments?: File[];
  privacy?: SelectOption; // причина, по которой мы видим это сообщение (передавать, если не "всем")
}
// </editor-fold>
/**
 * \\ TIMELINE
 */

export type TableComponentProps = {
  requestConfig: RequestConfig; // куда и как стучать для фильтрации
  heading?: string;
  header?: Header;
  columns: TableColumn[];
  columnBands?: ColumnBands[];
  fixedColumns?: Pick<TableFixedColumnsProps, 'leftColumns' | 'rightColumns'>;
  dragNDropConfig?: DragNDropConfig;
  columnExtensions?: ColumnExtensions[];
  rows: TableRow[];
  options: TableOptions;
  summaryRow?: SummaryRow;
  filterComponent?: TableFilterFormComponent;
  quickFilters?: TableQuickFilters | null;
  showSearchbar?: boolean;
  alerts?: Alert[];
  actions?: TableAction[];
  showTotalRows?: boolean; // показывать ли счётчик строк. По-умолчанию "true".
  showWithClosedButton?: boolean; // показывать кнопку "отображать закрытые". По-умолчанию "false".
  fullHeight?: boolean; // на весь экран? По-умолчанию "true".
  // Позволяет ограничить высоту таблицы.
  // Минимальное значение – 250. Если указать значение ниже, будет применен минимальное значение.
  maxHeight?: number;
  /** Изменение размера колонок на фронте.
   * По-умолчанию работает как "false".
   * Если устанавливаете "true", то в `columnExtensions`
   * крайне желательно явно задать фиксированную ширину для каждой колонки. */
  enableColumnResizing?: boolean;
  /**
   * Включает изменения порядка колонок при помощи drag & drop.
   * По-умолчанию порядок будет взят на основе columns.
   */
  enableColumnReordering?: boolean;
  /**
   * Добавляет возможность пользователю скрывать имена столбцов.
   */
  columnVisibilityConfig?: ColumnVisibilityConfig;
  groupingConfig?: GroupingConfig;
};

type ColumnExtensions = {
  alignAll?: 'left' | 'right' | 'center';
} & Table.ColumnExtension;

export interface SummaryRow {
  cells: SummaryCell[];
}

export interface SummaryCell {
  columnName: string;
  label: string;
}

export interface DragNDropConfig {
  draggableColumns: string[];
  // Куда стучаться при drop. В ответе будем ждать UpdateCellsResponsePayload.
  requestConfig: RequestConfig;
  // Пока реализована только swap. Можно реализовать и move, и cancel.
  // (0) Swap. Изначальная ячейка и целевая меняют значения местами.
  // (2) Move. Целевая ячейка очищается. Изначальная заполняет целевую.
  // (2) DisallowDrop. Блокируем не пустые ячейки от дропа, не даём туда дропать (пункт 3d).
  // nonEmptyCellDropStrategy: 'swap' | 'replace' | 'forbidDrop';
}

export interface TableOnDropRequestBody {
  from: CellCoordinates;
  to: CellCoordinates;
}

export interface CellCoordinates {
  rowId: string;
  columnName: string;
  cell: TableCell;
}

export interface GroupingConfig {
  // TODO: ниже пример того, чем будет расширяться API.
  // Показывать ли пользователю интерфейс для управления группированием колонок.
  // По-умолчанию false.
  // showControls?: boolean;
  // defaultExpanded?: Grouping[]; // какие группы раскрыты по-умолчанию.
  defaultGrouping: Grouping[];
}

export type ColumnVisibilityConfig =
  | EnabledColumnVisibilityConfig
  | DisabledColumnVisibilityConfig;

interface DisabledColumnVisibilityConfig {
  enabled?: false;
}

interface EnabledColumnVisibilityConfig {
  enabled: true;
  /**
   * Массив имен столбцов (name), которые необходимо скрыть в таблице.
   * По-умолчанию, все столбцы отображаются.
   */
  defaultHiddenColumnNames?: string[];
}

export interface TableQuickFilters {
  options: TableQuickFilterOption[];
}

export interface TableQuickFilterOption {
  value: string;
  label: string;
}

export type DataTypeProvidersProps = {
  [key in FormatAs]?: string[];
};

type FilterOption =
  | string // вхождение подстроки
  | number // точное значение
  | string[] // точное значение, логическое ИЛИ
  | DateRangeValue; // рейндж

export type FilterOptions = {
  [key: string]: FilterOption;
};

export type SortingObject = {
  columnName: string;
  direction: 'asc' | 'desc';
};

export type SortingState = SortingObject[];

// В таком виде будут уходить данные на сервер
// по фильтрам/сортировкам/поиску/пагинации
export interface FetchTableDataOptions {
  requestConfig: RequestConfig; // куда и как стучать для фильтрации
  query?: string; // поиск
  filter?: FilterOptions; // фильтрация
  sort?: SortingState; // сортировка
  currentPage: number;
  pageSize: number; // строк на страницу
  quickFilters?: string[];
  withClosed?: boolean;
}

export interface TableWidget extends BaseComponent<'tableWidget'> {
  props: TableWidgetProps;
}

export type TableWidgetProps = Exclude<
  TableComponentProps,
  'rows' | 'summaryRow'
>;

interface TableComponent extends BaseComponent<'table'> {
  props: TableComponentProps;
}

export interface TableColumn {
  title?: string; // локализованное название колонки. Если не передать все - не отобразится ничего.
  name: string;
  options?: TableColumnOptions;
  tableColumnViewConfig?: TableColumnViewConfig;
}

export interface TableColumnViewConfig {
  // HEX color
  headerCellBackgroundColor?: string;
  // Выравнивание текста заголовка
  headerCellTextAlign?: 'left' | 'center' | 'right';
  // Заливка фона всей колонки. HEX color
  columnBackgroundColor?: string;
}

interface TableColumnOptions {
  disableLineBreak?: boolean;
  sortable?: boolean;
  formatAs?: FormatAs;
}

// см. https://stackoverflow.com/a/45486495
export const ALL_FORMATS = [
  'date',
  'dateTime',
  'cutTextWithTooltip',
  'icon',
  'chip',
  'STMCells',
  'link',
  'actions',
  'truckIconCheckbox',
  'chipWithValueAndLabel',
] as const;
type FormatAsTuple = typeof ALL_FORMATS;
export type FormatAs = FormatAsTuple[number];

export interface TableRow {
  id: string | number;
  viewConfig?: TableRowViewConfig;
  // Если задано тут, то применится отсюда, иначе rowClickReaction из TableOptions.
  rowClickReaction?: Reaction;

  [key: string]: TableCell;
}

export type TableCell =
  | IconDefinition
  | STMCell[]
  | Chip
  | Link
  | TableRowAction[]
  | ChipWithValueAndLabel
  | any;

export interface ChipWithValueAndLabel {
  // Цифровое значение, которое будет отображаться в ячейке (до 10 символов)
  value: number;
  // Метка значения. Например: т., кип., шт. (до 10 символов)
  label: string;
  // Опциональный параметр для задания цвета значения и рамки
  color?: string;
}

export interface Chip {
  label: string;
  avatar?: { fullName: string } | { src: string; alt: string };
  disabled?: boolean;
  color?: Exclude<Color, 'inherit'>;
  clickable?: boolean;
  icon?: IconDefinition | null;
  size?: Size;
  variant?: 'default' | 'outlined';
  htmlColor?: string;
}

export interface STMCell {
  label: string; // '4'
  /**
   * Список цветов:
   * ТО 1 - '#3498db',
   * ТО 2 - '#27ae60',
   * ТО 3 - '#f1c40f',
   * ТО 4 - '#d35400',
   * ТО 5 - '#c0392b'.
   */
  color: string; // HEX
  tooltip: Tooltip;
  /**
   * Список выбранных иконок:
   * Запланировано - 'mui:Alarm',
   * В работе - 'mui:Build',
   * Выполнено - 'mui:CheckCircleOutline',
   * Отменено - 'mui:TimerOff'.
   */
  icon?: IconDefinition;
  avatar?: Avatar;
}

interface Avatar {
  firstName: string;
  lastName: string;
  photoSrc?: string;
}

interface Tooltip {
  content: Paragraph[];
  backgroundColor?: string;
}

export interface TableRowViewConfig {
  bold?: boolean;
  backgroundColor?: string;
  color?: string;
}

interface TableOptions {
  // Всего строк
  totalRows: number;
  // Текущая страница
  currentPage: number;
  // Количество строк на страницу. По-умолчанию 10.
  // Сервер должен отдавать по 10 строк по-умолчанию.
  pageSize: number;
  // Массив с параметрами для селектора управления количеством строк на страницу.
  // По-умолчанию селектор не отображается.
  pageSizeOptions?: number[];
  // Возможность отключить пагинацию.
  // Если значение этого параметра установлено в true, то пагинация будет
  // отключена, все элементы будут отображаться на одной странице, а параметры,
  // связанные с пагинацией проигнорены.
  pagingDisabled?: boolean;
  // Если задано тут и в row, то применится из row, а это проигнорируется.
  rowClickReaction?: Reaction;
  cellClickReaction?: TableCellClickReaction;
  // Параметр определяет можно ли кликать по пустым ячейкам.
  // По-умолчанию: true
  allowClickOnEmptyCell?: boolean;
  sorting?: SortingState;
}

export interface Alert
  extends Pick<AlertProps, 'color' | 'severity' | 'variant'> {
  body: Paragraph[];
  title?: string;
}

export interface Paragraph {
  text: string;
  title?: string;
}

export type TableCellClickReaction =
  | ShowAsyncModalOnCellClickReaction
  | Reaction;

// Реакция, которая позволяет при клике на ячейку построить модалку на основе
// данных, которые придут с сервера.
export interface ShowAsyncModalOnCellClickReaction
  extends BaseReaction<'showAsyncModalOnCellClickReaction'> {
  fetchModalDataRequestConfig: RequestConfig; // куда стучать для получения данных модалки.
  columnNames: string[]; // для ячеек в каких колонках навешивать обработчики клика.
}

// 1. При клики на ячейку в обработчик упала информация о rowId и columnName. Постучали на бек по fetchModalDataRequestConfig и передали туда инфу о координатах ячейки (rowId и columnName).
// Пример запроса - '/api/v1.0/table/get-cell-modal?columnName="jan-4"&rowId="5dfcd7f1-0a83-449d-9799-4219bbed26f4'
//
// 2. После получения успешного ответа с сервера, из ответа выцепляем интерфейс ShowAsyncModalResponse.

// Ответ с сервера для постоения модалки.
export interface ShowAsyncModalResponse {
  component: Component;
}

export interface TableFilterFormComponent extends BaseComponent<'tableFilter'> {
  props: {
    requestOn: 'change'; // в каких ситуациях применять фильтр
    fieldGroups: FieldGroup[];
  };
}

export type FormComponentProps = {
  form: Form;
  actions?: FormAction[];
  actionsYPosition?: 'top' | 'bottom';
  alerts?: Alert[];
};

interface FormComponent extends BaseComponent<'form'> {
  props: FormComponentProps;
}

export interface Header {
  heading: string;
  preHeadingText?: string;
  preHeadingIcon?: IconDefinition | null;
  subHeadings?: SubHeadingElement[];
  headingClassName?: string;
}

type SubHeadingElement = [string | undefined | null, SubHeadingElementValue];

export type SubHeadingElementValue =
  | string // просто строка
  | number // просто число
  | ConvertibleValue;

type ConvertibleValue = ConvertibleDate | ConvertibleLink;

export type ConvertibleLink = BaseConvertibleValue<'link'> & Link;

interface ConvertibleDate extends BaseConvertibleValue<'date'> {
  value: string;
  formatAs?: 'date' | 'dateTime'; // 'date' by default.
}

interface BaseConvertibleValue<T> {
  type: T;
}

export type Link = {
  href: string;
  label: string;
  icon?: string | null;
  external?: true; // ссылка куда-то вне приложения (без react-router)
};

interface BaseComponent<T> {
  type: T;
  id: number;
  businessComponentId: number;
  props: unknown;
}

// Кнопки бывают:
// 1. Дёргают что-то на сервере и либо
//     обновляют модель данных, либо редиректят
//
// 2. Показывают модалку с формой,
//     после сабмита либо обновляют модель данных либо редиректят.
//
// 4. Переадресовывает на форму,
//     после сабмита либо обновляют модель данных либо редиректят.

export type TableRowAction = TableTriggerEndpointAction;

export type TableAction =
  | BaseAction
  | TableTriggerEndpointAction
  | ShowHistoryAction;

export type TableTriggerEndpointAction = TriggerEndpointAction & {
  /**
   * Определяет, должно ли быть отправлено состояние фильтров таблицы.
   * Если установлено значение true, состояние фильтров таблицы будет отправлено в действии.
   * Если параметр не указан или установлено значение false, состояние фильтров не будет отправлено.
   */
  includeFilterState?: boolean;
  // Добавить отправку текущего состояния rows с запросом.
  includeTableRows?: boolean;
  // Добавить отправку состояния сортировки с запросом.
  includeSortingState?: boolean;
};

export type FormAction =
  | SubmitFormAction
  | ShowHistoryAction
  | BaseAction
  | TriggerEndpointAction;

type BaseAction =
  | ShowFormAction
  | RedirectAction
  | GoBackAction
  | ShowComponentAction
  | CloseDialogAction;

/**
 * Экшны
 */
export interface GoBackAction extends BaseFormAction<'goBack'> {}
export interface CloseDialogAction extends BaseFormAction<'closeDialog'> {}

export interface ShowHistoryAction extends BaseFormAction<'showHistory'> {
  requestConfig: RequestConfig;
}

// Редиректит на указанный URL. URL может быть как внутренним,
// так и внешним.
export interface RedirectAction extends BaseFormAction<'redirect'> {
  redirectTo: string;
}

// Проводит сабмит формы (клиентская валидация, мап значений)
// и отправляет значения формы в теле запроса на сервер.
export interface SubmitFormAction extends RequestFormAction<'submitForm'> {
  skipValidation?: boolean;
}

// Вызывает запрос на сервер, без передачи каких-либо данных
// на сервер.
export interface TriggerEndpointAction
  extends RequestFormAction<'triggerEndpoint'> {}

// Показывает форму в модалке.
export interface ShowFormAction extends BaseFormAction<'showForm'> {
  actions: FormAction[];
  form: Form;
  viewStyle?: 'modal' | 'fullScreenModal';
  alerts?: Alert[];
}

// Показывает компонент в модалке
export interface ShowComponentAction extends BaseFormAction<'showComponent'> {
  component: Component;
  viewStyle?: 'modal' | 'fullScreenModal';
}

/**
 * \\ Экшны
 */

/**
 * Типы экшнов
 */
interface RequestFormAction<T> extends BaseFormAction<T> {
  /**
   * Если указано, то перед отправкой основного запроса с данными формы на сервер
   * будет отправлен дополнительный запрос с данными формы на указанный URL.
   *
   * В ответе будем ждать объект типа `PreSubmitResponse`.
   */
  preSubmitRequestConfig?: RequestConfig;
  requestConfig: RequestConfig;
  successResponseReaction: Reaction;
  confirmDialog?: ConfirmDialog;
  validationErrorReaction?: Reaction;
}

export interface PreSubmitResponse {
  // Если указано, то будет показан диалог с подтверждением действия.
  // Основной запрос выполнится после подтверждения действия.
  confirmDialog?: ConfirmDialog;
  // ⬇⬇⬇ НЕ РЕАЛИЗОВАНО, НО ЗАКЛАДЫВАЕМ РАСШИРЕНИЕ В ЭТУ СТОРОНУ
  // Если указано, то будет показан Alert, а основной запрос прервется.
  // alert?: Alert;
  // Если указано, то будет показан Snackbar, а основной запрос не прервется.
  // snackbar?: Snackbar;
}

interface BaseFormAction<T> {
  type: T;
  label: string; // название кнопки
  color?: 'inherit' | 'primary' | 'secondary' | 'default'; // цветовая тема кнопки
  variant?: 'text' | 'outlined' | 'contained'; // стиль кнопки
  icon?: IconDefinition | null;
  // Положение кнопок. По-умолчанию: 'main'.
  // 'main' - основная область,
  // 'extra' - в дополнительном меню (в трех точках).
  placement?: 'main' | 'extra';
}

/**
 * \\ Типы экшнов
 */

export interface ConfirmDialog {
  body: string;
}

export interface RequestConfig {
  url: string;
  method?: 'GET' | 'POST';
}

export type Reaction =
  | DownloadFileReaction
  | AsyncShowComponentInModalReaction
  | ShowAlertAndRedirectReaction
  | ReLoadModuleReaction
  | ReLoadCellsReaction
  | DynamicRedirectReaction
  | AsyncRedirectReaction
  | RedirectReaction
  | RenderPageReaction;

// Тип реакции, при начинается загрузка файла.
// В ответе сервера ожидается url для загрузки с правильно
// настроенными заголовками Content-Type и Content-Disposition
export interface DownloadFileReaction extends BaseReaction<'downloadFile'> {}

// Тип реакции, при которой показывается модалка с компонентом (любым).
// В ответе сервера ожидается интерфейс DialogWithComponent.
export interface AsyncShowComponentInModalReaction
  extends BaseReaction<'asyncShowComponentInModal'> {}

export interface DialogWithComponent {
  component: Component;
  viewStyle?: 'modal' | 'fullScreenModal';
}

export interface ShowAlertAndRedirectReaction
  extends BaseReaction<'showAlertAndRedirect'> {
  alertText: string;
  redirectTo: string;
}

interface ReLoadModuleReaction extends BaseReaction<'reLoadModule'> {}

interface ReLoadCellsReaction extends BaseReaction<'reLoadCells'> {}

// Тип редиректа, когда данные для построения url доступны на фронте и нужно
// указать что за ключ у базовой сущности нужно заменить.
//
// К примеру, если реакция описывает редирект, который должен произойти после
// клика по строке в таблице, то для построения урла карточки элемента, по
// которому произошёл клик понадобится его ID. ID хранится в этой строке.
//
// См. пример данных любого списка (список заявок/заданий на ремонт).
export interface DynamicRedirectReaction
  extends BaseReaction<'dynamicRedirect'> {
  // строка вида //api/domain/:entityProperty, где 'entityProperty'
  // это свойство сущности, от которой произошла реакция.
  redirectTo: string;
}

// Тип редиректа, когда url содержится в JSON-теле ответа сервера.
//
// Это бывает нужно, когда только после вызова эндпоинта становится понятно,
// куда нужно редиректить.
//
// К примеру, создалась новая сущность.
export interface AsyncRedirectReaction extends BaseReaction<'asyncRedirect'> {
  responseRedirectKey: string;
}

// Самый простой тип редиректа с явным указанием url.
export interface RedirectReaction extends BaseReaction<'redirect'> {
  redirectTo: string;
}

export interface RenderPageReaction extends BaseReaction<'renderPage'> {}

interface BaseReaction<T> {
  type: T;
  snackbar?: Snackbar;
}

export interface Snackbar {
  text: string;
  options?: SnackbarOptionsObject & { link?: Link };
  // action: any; // Будет потом, пока нет потребности
}

export type Form = PresetForm | MarkupForm;

interface PresetForm extends BaseForm<'preset'> {
  presetName: 'estimate';
}

interface MarkupForm extends BaseForm<'markup'> {
  fieldGroups: FieldGroup[];
}

interface BaseForm<T> {
  type: T;
  disabled?: boolean;
  header: Header;
  historyConfig?: FormHistoryConfig; // TODO: Выпилить, когда бэк освоит: https://auchan.atlassian.net/l/cp/u1RXZJ5u
  globalSocketRules?: GlobalSocketRules;
}

export interface FormHistoryConfig {
  requestConfig: RequestConfig;
}

export interface GlobalSocketRules {
  interactiveFormFields?: string[];
}

export interface FieldGroup {
  label?: string;
  header?: Header;
  accordion?: AccordionProps;
  fields: Field[];
}

export interface AccordionProps {
  enabled: boolean;
  defaultExpanded: boolean;
}

export interface FormValues {
  [key: string]: string | string[] | number | boolean | null;
}

export interface FilterChip {
  fieldName: string;
  printValue: string;
  dirtyValue: DirtyFormValue;
}

// Возможные варианты, которые лежат в состоянии формы
export type DirtyFormValue =
  | string
  | string[]
  | SelectOption
  | SelectOption[]
  | DateRangeValue
  | number
  | boolean
  | null
  | ComboBoxOption
  | ComboBoxOption[]
  | RadioTreeSelectOption
  | CheckboxTreeSelectOption[];

export interface DirtyFormValues {
  [key: string]: DirtyFormValue;
}
