import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    keyboardButton: {
      // Отключает отображение иконки в disabled-представлении
      '&.disabled': {
        display: 'none',
      },
    },
  })
);
