const BREAKING_SPACES = /[ \f\n\r\t\v\u2028\u2029]+/;

// Функция для получения самого длинного слова из строки, разделенной BREAKING_SPACES
function getLongestWord(str: string): string {
  return str
    .split(BREAKING_SPACES)
    .reduce(
      (longest, currentWord) =>
        longest.length >= currentWord.length ? longest : currentWord,
      ''
    );
}

export default getLongestWord;
