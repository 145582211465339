import { useEffect, useState } from 'react';
import { MAIN_SPACING } from '../../../template/PrivateRoutesTemplate/PrivateRoutesTemplate.styles';

const ADDITIONAL_SPACING_BETWEEN_HEADER_AND_PAPER = 12;

export default function useContainerHeight(timelineDOMId: string): string {
  const [heightToMinus, setHeightToMinus] = useState<number>(
    calculateHeightToMinus(document.getElementById(timelineDOMId)?.offsetTop)
  );
  const containerHeight = `calc(100vh - ${heightToMinus}px)`;

  useEffect(() => {
    function handleResize() {
      setHeightToMinus(
        calculateHeightToMinus(
          document.getElementById(timelineDOMId)?.offsetTop
        )
      );
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [timelineDOMId]);

  return containerHeight;
}

function calculateHeightToMinus(timelineContainerTopPosition: number = 0) {
  return (
    MAIN_SPACING * 2 +
    ADDITIONAL_SPACING_BETWEEN_HEADER_AND_PAPER +
    timelineContainerTopPosition
  );
}
