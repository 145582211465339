import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OptionsObject, SnackbarMessage } from 'notistack';

type Snackbar = {
  key: number | string;
  message: SnackbarMessage;
  options?: OptionsObject;
};

type SnackbarsState = Snackbar[];

const initialState: SnackbarsState = [];

export const { reducer, actions } = createSlice({
  name: 'snackbars',
  initialState,
  reducers: {
    enqueueSnackbar: (
      state,
      { payload }: PayloadAction<Omit<Snackbar, 'key'>>
    ) => {
      (state as Snackbar[]).push({
        ...payload,
        key: new Date().getTime() + Math.random(),
      });
    },
    removeSnackbar: (state, { payload }: PayloadAction<number | string>) => {
      return state.filter((s) => s.key !== payload);
    },
  },
});

export default reducer;
