import faker from 'faker';
import { Page } from 'services/Main/types.Page';
import { IconDefinition } from '../../../../types.common';

faker.locale = 'ru';

const createRow = () => {
  return {
    id: faker.datatype.uuid(),
    longtext: faker.lorem.words(25),
    date: faker.date.future().toISOString(),
    dateTime: faker.date.past().toISOString(),
    linkColumn: {
      label: faker.random.words(1),
      href: '/link',
    },
    icon: faker.random.arrayElement<IconDefinition>([
      'mui:Add',
      'mui:Replay',
      {
        type: 'icon',
        name: 'mui:NotificationImportant',
        props: {
          fill: faker.random.arrayElement(['#f00', '#0f0', '#00f']),
          fontSizeInPx: faker.random.arrayElement([10, 20, 30, 40]),
        },
      },
      {
        type: 'icon',
        name: 'mui:GridOn',
        props: {
          fill: faker.random.arrayElement(['#f00', '#0f0', '#00f']),
          fontSizeInPx: faker.random.arrayElement([10, 20, 30, 40]),
        },
      },
    ]),
  };
};

const tableLength = 20;

export const allWithCellFormattersTableRows = Array.from(
  { length: tableLength },
  createRow
);

export const withCellFormattersTablePage: Page = {
  type: 'singleComponent',
  title: 'Таблица с выводом отформатированных ячеек',
  component: {
    id: 404,
    businessComponentId: 38,
    type: 'table',
    props: {
      header: {
        heading: 'Таблица с выводом отформатированных ячеек',
        subHeadings: [
          ['Доп информация', faker.address.country()],
          ['Создано', '2020-04-15T16:33:23.3004811+03:00'],
        ],
      },

      requestConfig: { url: '/api/table/with-cell-formatters' },
      columns: [
        {
          title: 'Длинный текст',
          name: 'longtext',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        {
          title: 'Дата',
          name: 'date',
          options: {
            formatAs: 'date',
          },
        },
        {
          title: 'Дата и время',
          name: 'dateTime',
          options: {
            formatAs: 'dateTime',
          },
        },
        {
          title: 'Ссылка',
          name: 'linkColumn',
          options: {
            formatAs: 'link',
          },
        },
        {
          title: 'Иконка',
          name: 'icon',
          options: {
            formatAs: 'icon',
          },
        },
      ],
      rows: allWithCellFormattersTableRows.slice(0, tableLength),
      options: {
        totalRows: tableLength,
        currentPage: 0,
        pageSize: 20,
      },
    },
  },
};
