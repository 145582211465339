import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    field: {
      '& .MuiInputBase-root': {
        cursor: 'pointer',
      },
    },
    // Нужно для того, чтобы скрыть оригинальный input TextField, но не трогать
    // остальные стили (высоту и т.д.).
    fieldInput: {
      width: 0,
    },
    fieldStartAdornment: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    fieldHelperText: {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    fieldEndAdornment: {
      paddingLeft: theme.spacing(1),
      display: 'flex',

      '& .MuiChip-root': {
        cursor: 'pointer',
      },
    },
    iconButton: {
      padding: 0,
    },
  })
);
