import { AuthServiceBase } from '../AuthService.base';
import successServerResponse from './successServerResponse';

export default class AuthServiceLocal extends AuthServiceBase {
  login = async (login: string, password: string) => {
    console.log(
      `Trying to login with login: ${login} and password: ${password}`
    );

    return Promise.resolve(successServerResponse).then(
      this.handleLoginResponse
    );
  };

  loginWithGoogle = async (tokenId: string) => {
    console.log(`Trying to login with tokenId: ${tokenId}`);

    return Promise.resolve(successServerResponse).then(
      this.handleLoginResponse
    );
  };

  updateToken = async () => {
    return Promise.resolve(successServerResponse).then(
      this.handleLoginResponse
    );
  };
}
