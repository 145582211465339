import React from 'react';
import { Grid } from '@material-ui/core';
import { FormAction } from 'services/Main/types.Component';
import FormActionComponent from '../FormAction';
import { OnReactionExecuteEvent } from '../../../responseReactions/ReactionController';
import { useClassifyActions } from '../ExtraActions/useClassifyActions';
import ExtraActions from '../ExtraActions';
import FormActionButton from '../FormAction/FormAction.Button';
import ExtraActionsMenuItem from '../ExtraActions/ExtraActions.MenuItem';

interface FormActionsProps {
  actions: FormAction[];
  onReactionExecute?: OnReactionExecuteEvent;
}

const FormActions = ({ actions = [], onReactionExecute }: FormActionsProps) => {
  const { mainActions, extraActions } = useClassifyActions<FormAction>(actions);

  return (
    <Grid container alignItems="center" justify="flex-end" spacing={1}>
      {mainActions.length > 0 &&
        mainActions.map((actionProps) => (
          <Grid item>
            <FormActionComponent
              key={Object.values(actionProps).join('')}
              action={actionProps}
              onReactionExecute={onReactionExecute}
              renderAction={FormActionButton}
            />
          </Grid>
        ))}
      {extraActions.length > 0 ? (
        <Grid item>
          <ExtraActions<FormAction>
            actions={extraActions}
            renderMenuItem={(actionProps, onClickMenuItem) => (
              <FormActionComponent
                key={`extraAction_${Object.values(actionProps).join('')}`}
                action={actionProps}
                onClick={onClickMenuItem}
                onReactionExecute={onReactionExecute}
                renderAction={ExtraActionsMenuItem}
              />
            )}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default FormActions;
