import React, {
  MouseEvent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { ListItem, ListItemText, IconButton, Tooltip } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { ModuleMenuItem } from 'services/Main/types';
import Icon from '../Icon/Icon';
import ConditionalWrapper from './ConditionalWrapper';
import { useIsCompactMenuView } from '../../../utils/hooks/useIsCompactMenuView';
import useStyles from './MenuModule.styles';
import MenuModuleDropdownMenu from './MenuModule.dropdownMenu';
import { useSelector } from '../../../store';
import { linkMenuItemsGroupedByMenuModuleIdSelector } from '../../../store/mainMenu/selectors';
import useScrollIntoView from '../../../utils/hooks/useScrollIntoView';
import trimQueryParams from '../../../utils/trimQueryParams';

const MenuModule = ({ label, icon, to, id }: ModuleMenuItem) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const menuLinksGroupedByModuleId = useSelector(
    linkMenuItemsGroupedByMenuModuleIdSelector
  );
  const menuLinksByModule: string[] = useMemo(
    () =>
      menuLinksGroupedByModuleId[id].children.reduce<string[]>(
        (acc, menuItem) => {
          if ('to' in menuItem) {
            return [...acc, trimQueryParams(menuItem.to)];
          }
          return acc;
        },
        []
      ),
    [menuLinksGroupedByModuleId, id]
  );

  const isCompactMenuView = useIsCompactMenuView();

  const isMenuOpen = Boolean(dropdownAnchorEl);

  const handleIconClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setDropdownAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setDropdownAnchorEl(null);
  }, []);

  // Эффект, который закрывает dropdownMenu при смене pathname
  useEffect(() => {
    if (isMenuOpen) {
      setDropdownAnchorEl(null);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const isActive = isCompactMenuView
    ? menuLinksByModule.some((menuLink) => pathname.includes(menuLink))
    : pathname === to;

  const scrollIntoViewIfActive = useScrollIntoView<HTMLButtonElement>(isActive);

  return (
    <ListItem
      className={clsx(classes.listItem, { compactView: isCompactMenuView })}
      disableGutters
    >
      <ConditionalWrapper to={isCompactMenuView ? undefined : to}>
        <Tooltip
          title={label as String}
          placement="right"
          disableHoverListener={!isCompactMenuView}
          id={label}
        >
          <IconButton
            className={clsx(classes.iconButton, {
              fullView: !isCompactMenuView,
              menuOpen: isMenuOpen,
              active: isActive,
            })}
            ref={scrollIntoViewIfActive}
            tabIndex={isCompactMenuView ? 0 : -1}
            onClick={handleIconClick}
          >
            <Icon definition={icon} />
          </IconButton>
        </Tooltip>
        <MenuModuleDropdownMenu
          open={isMenuOpen}
          anchorEl={dropdownAnchorEl}
          onClose={handleMenuClose}
          label={label}
          id={id}
          to={to}
        />
        <ListItemText
          primary={label}
          classes={{
            primary: classes.label,
          }}
          hidden={isCompactMenuView}
        />
      </ConditionalWrapper>
    </ListItem>
  );
};

export default MenuModule;
