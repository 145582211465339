import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Icon from '../Icon/Icon';
import { ShowFormAction } from '../../../services/Main/types.Component';
import FormBuilder from '../FormBuilder';
import Header from '../Header';
import FormActions from '../FormActions';
import { useFormatMessage } from '../../../locale';
import Alerts from '../Alerts';
import ActionButton from '../ActionButton';

interface DialogFormActionButtonProps {
  action: ShowFormAction;
  onClick: () => void;
}

interface DialogFormActionProps {
  action: ShowFormAction;
  onClick?: () => void;
  renderButton?: (props: DialogFormActionButtonProps) => JSX.Element;
}

const DialogFormAction = ({
  action,
  onClick,
  renderButton,
}: DialogFormActionProps) => {
  const formatMessage = useFormatMessage();

  const { label, color, icon, variant, form, viewStyle, actions, alerts } =
    action;

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  useEffect(() => {
    console.warn(
      "Интерактивные формы не поддерживаются в экшене 'showForm'. Используйте: 'showComponent'"
    );
  }, []);

  const handleClick = () => {
    onClick && onClick();
    openDialog();
  };

  // TODO обработать
  if (form.type === 'preset') return null;

  return (
    <>
      {renderButton ? (
        renderButton({ action, onClick: handleClick })
      ) : (
        <Grid item>
          <ActionButton
            variant={variant}
            color={color}
            onClick={openDialog}
            startIcon={icon && <Icon definition={icon} />}
          >
            {label}
          </ActionButton>
        </Grid>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isDialogOpen}
        onClose={closeDialog}
        fullScreen={viewStyle === 'fullScreenModal'}
      >
        {form.header && (
          <DialogTitle>
            <Header {...form.header} />
          </DialogTitle>
        )}
        <DialogContent>
          <FormBuilder
            fieldGroups={form.fieldGroups}
            header={() => <Alerts staticAlerts={alerts} />}
            footer={() => {
              if (!actions) return <span />;

              return (
                <Grid container spacing={1} justify="flex-end">
                  <Grid item>
                    <ActionButton
                      variant="outlined"
                      color={color}
                      onClick={closeDialog}
                    >
                      {formatMessage('cancel')}
                    </ActionButton>
                  </Grid>
                  <Grid item>
                    <FormActions
                      actions={actions}
                      onReactionExecute={closeDialog}
                    />
                  </Grid>
                </Grid>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogFormAction;
