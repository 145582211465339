import { createStyles, makeStyles } from '@material-ui/core';

export default (htmlColor?: string) =>
  makeStyles(() =>
    createStyles({
      root: htmlColor
        ? {
            color: htmlColor,
            backgroundColor: `${htmlColor}55`,
          }
        : {},
    })
  );
