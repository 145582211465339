import { createSlice } from '@reduxjs/toolkit';
import { Route } from 'services/Main/types';

export type RoutesState = Route[];

export const { reducer, actions } = createSlice({
  name: 'routes',
  initialState: [] as RoutesState,
  reducers: {
    set: (state, { payload }) => payload,
  },
});

export default reducer;
