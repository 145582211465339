import React, { MouseEvent, useState } from 'react';
import { Rectangle } from 'recharts';
import { Popper, useTheme } from '@material-ui/core';
import ChartsTooltip from '../../../lowLevel/ChartsTooltip';
import transformChartPayloadToTooltipData from '../../../lowLevel/ChartsTooltip/transformChartPayloadToTooltipData';
import { BarChartProps } from '../../../../services/Main/types.Component';

// any поскольку в библиотеки recharts - нет четкой типизации для Cursor
type BarTooltipCursorProps = {
  orientation: BarChartProps['orientation'];
} & any;

export const BarTooltipCursor = (props: BarTooltipCursorProps) => {
  const theme = useTheme();
  // Здесь используется стейт anchorElement, потому что при инициализации реф
  // всегда пустой, и его значение изменяется только позже, в то время как
  // стейт сразу применяет значение ref от элемента <svg>
  const [anchorElement, setAnchorElement] = useState<SVGGElement | null>(null);

  const { payload, height, width, x, y, orientation } = props;

  const tooltipDate = transformChartPayloadToTooltipData(payload);

  const handleTooltipMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <g>
      <Rectangle {...props} fill={theme.palette.grey[200]} />
      <g ref={setAnchorElement}>
        <path
          d={`M ${x + Math.ceil(width / 2)}, ${
            orientation === 'vertical' ? y : (y + Math.ceil(height / 2)) * 0.65
          }`}
        />
      </g>
      <Popper
        open={Array.isArray(payload) && payload.length > 0}
        anchorEl={anchorElement}
        onMouseMove={handleTooltipMouseMove}
      >
        <ChartsTooltip {...tooltipDate} />
      </Popper>
    </g>
  );
};
