import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { SIDEBAR_WIDTH } from './constants';

export default makeStyles((theme) => ({
  backdrop: {
    // zIndex взят из исходников к drawer в варианте temporary
    zIndex: theme.zIndex.modal,
  },
  paper: {
    // Сбрасывает значение zIndex для компонента paper в режиме permanent.
    // Поскольку в компоненте SidebarWrapper мы используем Drawer в режиме
    // 'permanent' и эмулируем поведение 'temporary' на устройствах с разрешение
    // ниже large (lg), и поведение 'persistent' на устройствах с разрешением
    // больше large (lg).
    // По умолчанию эмулируется 'persistent', который шифтит контент, ему zIndex
    // не нужен.
    zIndex: 'auto',
    width: SIDEBAR_WIDTH.closed,
    overflowY: 'visible',

    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),

    '&.isSidebarOpen': {
      width: SIDEBAR_WIDTH.open,

      [theme.breakpoints.up('md')]: {
        width: SIDEBAR_WIDTH.openMdUp,
      },

      [theme.breakpoints.down('lg')]: {
        // zIndex взят из исходников к drawer в варианте temporary
        zIndex: theme.zIndex.modal,
      },
    },
  },
  drawerContentContainer: {
    position: 'relative',
  },
  buttonContainer: {
    position: 'absolute',
    transform: 'translateX(-50%) translateY(-10%)',
    left: 0,
    top: theme.spacing(2),

    '&.isSidebarOpen': {
      display: 'none',
    },

    [theme.breakpoints.up('md')]: {
      '&.isSidebarOpen': {
        display: 'block',
      },
    },
  },
  '@keyframes ripple': {
    '0%': {
      opacity: 0,
    },
    '10%': {
      opacity: 0.2,
    },
    '20%': {
      opacity: 0.4,
    },
    '30%': {
      opacity: 0.6,
    },
    '40%': {
      opacity: 0.8,
    },
    '50%': {
      opacity: 1,
      transform: 'translate(-50%, -50%) scale(1.25)',
    },
    '60%': {
      opacity: 0.8,
    },
    '70%': {
      opacity: 0.6,
    },
    '80%': {
      opacity: 0.4,
    },
    '90%': {
      opacity: 0.2,
    },
    '100%': {
      opacity: 0,
    },
  },
  viewButton: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    '&:hover': {
      background: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },

    '&.shouldShowCounter': {
      // background: theme.palette.error.main,
      // color: theme.palette.error.contrastText,
      // '&:hover': {
      //   background: theme.palette.error.dark,
      //   color: theme.palette.error.contrastText,
      // },

      '&::before': {
        content: "''",
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '150%',
        height: '150%',
        zIndex: -1,
        borderRadius: '50%',
        background: fade(
          theme.palette.primary.main,
          theme.palette.action.focusOpacity
        ),
        transform: 'translate(-50%, -50%) scale(1)',
        transition: 'transform 0.5s',
        animation: '$ripple 1s linear 3',
        opacity: 0,
      },
    },
  },
  viewButtonIcon: {
    fontSize: theme.typography.pxToRem(18),
  },
  viewButtonCounter: {
    fontSize: theme.typography.pxToRem(16),
    // Визуальная компенсация
    marginTop: 1,
  },
  content: {
    padding: theme.spacing(2, 2.5),
    display: 'none',

    // <editor-fold desc="Добавляет вертикальный скрол при переполнении">
    // Добавил сюда, а не выше, т.к. выше прописан overflowY: visible, чтобы
    // корректно отображалась кнопка скрытия/разворачивания.
    overflowY: 'auto',
    height: '100vh',
    // </editor-fold>

    '&.isSidebarOpen': {
      display: 'block',
    },
  },
}));
