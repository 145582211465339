import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    separator: {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
    separatorIcon: {
      fontSize: '0.85rem',
    },
  })
);
