import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },
    tableContent: {
      flexGrow: 1,
      overflow: 'auto',
      position: 'relative',
    },
    progress: {
      position: 'absolute',
      left: 0,
      top: 0,
      display: 'none',
      zIndex: 501,
      width: '100%',

      '&.active': {
        display: 'block',
      },
    },
    pagingPanelContainer: {
      justifyContent: 'flex-end',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
    },
    select: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      textAlign: 'right',
      // Align <select> on Chrome.
      textAlignLast: 'right',
    },
    input: {
      color: 'inherit',
      fontSize: 'inherit',
      flexShrink: 0,
    },
  })
);
