import { Page } from 'services/Main/types.Page';

export const singleComponentExamplePage: Page = {
  type: 'singleComponent',
  title: 'Страница с одним высокоуровневым компонентом',
  component: {
    id: 100,
    businessComponentId: 43,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        header: { heading: 'Страница с одним высокоуровневым компонентом' },
        fieldGroups: [
          {
            label: 'Название группы',
            fields: [{ type: 'text', name: 'comment', label: 'Комментарий' }],
          },
        ],
      },
    },
  },
};
