import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import useStyles from './ChartsTooltip.styles';
import { ChartsTooltipData } from './types';
import TooltipItem from './TooltipItem';
import { useFormatMessage } from '../../../locale';

const ChartsTooltip = ({
  total,
  label,
  payload,
  activeTooltipItemIndex,
}: ChartsTooltipData) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Paper elevation={2} className={classes.root}>
      {label ? <Typography>{label}</Typography> : null}
      {payload.map((item, index) => (
        <TooltipItem
          {...item}
          key={`${label}_${item.name}_${item.printValue}_${index}`}
          active={activeTooltipItemIndex === index}
        />
      ))}
      <Typography>{`${formatMessage('total')}: ${total}`}</Typography>
    </Paper>
  );
};

export default ChartsTooltip;
