import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

let DonutSkeleton: any = (props: any) => (
  <SvgIcon
    {...props}
    viewBox="0 0 184 238"
    style={{ width: '100%', height: '100%', ...(props?.style || {}) }}
    fill={props.fill || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="92"
      cy="92"
      r="86"
      stroke="#BDBDBD"
      strokeWidth="12"
      fill="none"
    />
    <path
      d="M178 91.9999C178 78.4282 174.788 65.0492 168.627 52.9568C162.465 40.8643 153.529 30.4018 142.55 22.4245C131.57 14.4473 118.858 9.18189 105.453 7.05881C92.0488 4.93573 78.332 6.01527 65.4245 10.2091C52.5171 14.403 40.7855 21.5922 31.1888 31.1888C21.5922 40.7854 14.403 52.5171 10.2091 65.4245C6.01526 78.3319 4.93573 92.0487 7.05881 105.453C9.1819 118.858 14.4473 131.57 22.4246 142.549"
      stroke="#E0E0E0"
      strokeWidth="12"
      strokeLinecap="square"
      strokeLinejoin="round"
      fill="none"
    />
    <circle cx="6" cy="213" r="6" fill="#D9D9D9" />
    <circle cx="6" cy="232" r="6" fill="#D9D9D9" />
    <rect x="23" y="207" width="161" height="12" fill="#E0E0E0" />
    <rect x="23" y="226" width="161" height="12" fill="#D9D9D9" />
  </SvgIcon>
);
DonutSkeleton.muiName = 'SvgIcon';

DonutSkeleton = React.memo(DonutSkeleton);
DonutSkeleton.displayName = 'DonutSkeleton';

const PureDonutSkeleton = DonutSkeleton;

export default PureDonutSkeleton;
