import React, { useEffect, useRef } from 'react';
import {
  BarChart,
  Bar as BarComponent,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Cell,
} from 'recharts';
import { BarChartWidgetProps, HiddenBarKeys } from '../types';
import useAxisWidthCalculate from '../helpers/useAxisWidthCalculate';
import { BarTooltipCursor } from '../components/BarTooltipCursor';
import ChartsAxisLabel from '../../../lowLevel/ChartsAxisLabel';
import { useScrollbarWidth } from '../../../../utils/hooks/useScrollbarWidth';
import useStyles from './VerticalBarChartWidget.styles';
import useScrollDetector from '../../../../utils/hooks/useScrollDetector';
import usePreparedDataByBarKey from '../helpers/usePreparedDataByBarKey';
import useChartTickFormatter from '../helpers/useChartTickFormatter';

/**
 * Выглядит так:
 *
 * y
 * |
 * |[][][][]
 * |[][][]
 * |
 * |[]
 * |[][]
 * |__________ x
 *
 */

const BAR_SIZE = 60;

const chartMargin = { top: 20, right: 30, left: 20, bottom: 15 };
const bottomChartHeight = 46;

export default ({
  data,
  bars,
  showTooltip,
  xAxisLabel,
  yAxisLabel,
  height,
  hiddenBarKeys,
}: BarChartWidgetProps & HiddenBarKeys) => {
  const classes = useStyles();

  const axisWidth = useAxisWidthCalculate(data);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const scrollWidth = useScrollbarWidth();
  const isScrolling = useScrollDetector(scrollContainerRef);

  const dataHeight = data.length * BAR_SIZE;

  const isDataHeightGreaterThanHeight = dataHeight > height;
  const topChartHeight = isDataHeightGreaterThanHeight
    ? dataHeight
    : height - bottomChartHeight;

  // Отключаем tooltip при скроллинге
  const chartRef = useRef<typeof BarChart | any>();
  useEffect(() => {
    if (isScrolling) {
      chartRef?.current?.setState({
        ...chartRef?.current?.state,
        isTooltipActive: false,
        activeTooltipIndex: -1,
        activePayload: [],
      });
    }
  }, [isScrolling]);

  const groupedPreparedDataByBarKey = usePreparedDataByBarKey({ data, bars });

  const tickFormatter = useChartTickFormatter();

  return (
    <div className={classes.container}>
      <div
        style={{
          height: `calc(100% - ${bottomChartHeight}px)`,
        }}
        className={classes.topBarChartContainer}
        ref={scrollContainerRef}
      >
        <ResponsiveContainer width="100%" height={topChartHeight}>
          <BarChart
            data={data}
            margin={{
              ...chartMargin,
              bottom: 0,
            }}
            layout="vertical"
            className={classes.topBarChart}
            ref={chartRef}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              // По-умолчанию тип оси X - category. Для вертикальной ориентации устанавливаем тип на number.
              // Подробнее тут: https://recharts.org/en-US/api/XAxis#type
              type="number"
              // TODO: комментарий зачем скрываем
              hide
            />
            <YAxis
              dataKey="name"
              // По-умолчанию тип оси Y - number. Для вертикальной ориентации устанавливаем тип на category.
              // Подробнее тут: https://recharts.org/en-US/api/YAxis#type
              type="category"
              width={axisWidth + 4}
            >
              {yAxisLabel && (
                <ChartsAxisLabel
                  position="top"
                  style={{
                    textAnchor: 'start',
                  }}
                >
                  {yAxisLabel}
                </ChartsAxisLabel>
              )}
            </YAxis>
            {showTooltip && (
              <Tooltip
                cursor={
                  <BarTooltipCursor
                    orientation="vertical"
                    scrollContainerRef={scrollContainerRef}
                  />
                }
                content={<div />}
              />
            )}
            {bars.map((bar) => {
              const barData = groupedPreparedDataByBarKey.get(bar.key);

              return (
                <BarComponent
                  key={bar.key}
                  hide={hiddenBarKeys.includes(bar.key)}
                  dataKey={`${bar.key}.value`}
                  stackId={bar.stackId}
                  fill={bar.fill}
                  name={bar.name}
                  barSize={30}
                  minPointSize={6}
                >
                  {Array.isArray(barData) &&
                    barData.map(({ value, isLastBarInStack }) => {
                      // Если значение в баре равно 0, то заполнение
                      // становится прозрачным, чтобы скрыть бар. Это помогает
                      // избежать отображения последнего нулевого бара,
                      // учитывая настройку minPointSize.
                      if (value === 0) {
                        return <Cell width={0} />;
                      }

                      // Добавляем скругления для последних в стеке
                      if (isLastBarInStack) {
                        return (
                          <Cell
                            // @ts-ignore: Под капотом библиотека принимает
                            // массив, однако типизация говорит об обратном.
                            // Игнорируем typescript для этого параметра
                            radius={[0, 6, 6, 0]}
                          />
                        );
                      }

                      return <Cell />;
                    })}
                </BarComponent>
              );
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
      <ResponsiveContainer
        width="100%"
        height={bottomChartHeight}
        className={classes.bottomBarChart}
      >
        <BarChart
          data={data}
          margin={{
            ...chartMargin,
            top: 0,
            right: isDataHeightGreaterThanHeight
              ? chartMargin.right + scrollWidth
              : chartMargin.right,
          }}
          layout="vertical"
        >
          <XAxis
            // По-умолчанию тип оси X - category. Для вертикальной ориентации устанавливаем тип на number.
            // Подробнее тут: https://recharts.org/en-US/api/XAxis#type
            type="number"
            tickFormatter={tickFormatter}
          >
            {xAxisLabel && (
              <ChartsAxisLabel position="insideBottomRight" offset={-5}>
                {xAxisLabel}
              </ChartsAxisLabel>
            )}
          </XAxis>
          <YAxis
            dataKey="name"
            // По-умолчанию тип оси Y - number. Для вертикальной ориентации устанавливаем тип на category.
            // Подробнее тут: https://recharts.org/en-US/api/YAxis#type
            type="category"
            width={axisWidth + 4}
          />
          {bars.map((bar) => (
            <BarComponent
              key={bar.key}
              dataKey={`${bar.key}.value`}
              stackId={bar.stackId}
              name={bar.name}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
