import faker from 'faker';
import { Page } from 'services/Main/types.Page';
import { ArrayOf, OptionsType } from 'services/Main/types.Field';

export const simpleArrayOf: ArrayOf = {
  type: 'arrayOf',
  name: 'arrayOf',
  label: 'Array of Text Fields',
  validationConfig: {
    validationType: 'array',
    validations: [
      { type: 'min', params: [3, 'Нужно хотя бы 3 строки!'] },
      {
        type: 'of',
        params: [
          [
            {
              name: 'fullName',
              validationConfig: {
                validationType: 'string',
                validations: [
                  { type: 'required', params: ['Обязательное поле'] },
                ],
              },
            },
            {
              name: 'friends',
              validationConfig: {
                validationType: 'array',
                validations: [
                  { type: 'required', params: ['Обязательное поле'] },
                  { type: 'min', params: [2, 'Хотя бы два друга'] },
                ],
              },
            },
          ],
        ],
      },
    ],
  },
  rowDefinition: [
    {
      type: 'text',
      name: 'fullName',
      label: 'full name',
      mdCols: 4,
    },
    {
      type: 'datePicker',
      pickerType: 'date',
      name: 'birthDate',
      label: 'date of birth',
      mdCols: 4,
    },
    {
      type: 'select',
      optionsLoader: 'sync',
      selectType: 'multi',
      options: Array.from(
        { length: Math.floor(Math.random() * 7) + 1 },
        () => ({
          value: faker.datatype.uuid(),
          label: faker.name.lastName(),
        })
      ),
      label: 'friends',
      name: 'friends',
      mdCols: 4,
    },
  ],
  defaultRows: [
    [
      {
        name: 'fullName',
        defaultValue: faker.name.findName(),
      },
      {
        name: 'birthDate',
        defaultValue: faker.date.past(80).toISOString(),
      },
      {
        name: 'friends',
        defaultValue: Array.from(
          { length: Math.floor(Math.random() * 2) + 1 },
          () => ({
            value: faker.datatype.uuid(),
            label: faker.name.lastName(),
          })
        ),
      },
    ],
    [
      {
        name: 'fullName',
        defaultValue: faker.name.findName(),
      },
      {
        name: 'birthDate',
        defaultValue: faker.date.past(80).toISOString(),
      },
      {
        name: 'friends',
        defaultValue: Array.from(
          { length: Math.floor(Math.random() * 4) + 1 },
          () => ({
            value: faker.datatype.uuid(),
            label: faker.name.lastName(),
          })
        ),
      },
    ],
  ],
};

const arrayOfWithMultiLine: ArrayOf = {
  type: 'arrayOf',
  name: 'arrayOf2',
  label: 'Array of Text Fields',
  rowDefinition: [
    {
      type: 'text',
      name: 'loremP',
      label: 'Абзац лорема',
      multiline: true,
      mdCols: 12,
    },
    {
      type: 'datePicker',
      pickerType: 'date',
      name: 'birthDate',
      label: 'date of birth',
      mdCols: 6,
    },
    {
      type: 'select',
      optionsLoader: 'sync',
      selectType: 'multi',
      options: Array.from(
        { length: Math.floor(Math.random() * 7) + 1 },
        () => ({
          value: faker.datatype.uuid(),
          label: faker.name.lastName(),
        })
      ),
      label: 'friends',
      name: 'friends',
      mdCols: 6,
    },
  ],
  defaultRows: [
    [
      {
        name: 'loremP',
        defaultValue: faker.lorem.paragraph(),
      },
      {
        name: 'fullName',
        defaultValue: faker.name.findName(),
      },
      {
        name: 'birthDate',
        defaultValue: faker.date.past(80).toISOString(),
      },
      {
        name: 'friends',
        defaultValue: Array.from(
          { length: Math.floor(Math.random() * 2) + 1 },
          () => ({
            value: faker.datatype.uuid(),
            label: faker.name.lastName(),
          })
        ),
      },
    ],
    [
      {
        name: 'loremP',
        defaultValue: faker.lorem.paragraph(),
      },
      {
        name: 'fullName',
        defaultValue: faker.name.findName(),
      },
      {
        name: 'birthDate',
        defaultValue: faker.date.past(80).toISOString(),
      },
      {
        name: 'friends',
        defaultValue: Array.from(
          { length: Math.floor(Math.random() * 4) + 1 },
          () => ({
            value: faker.datatype.uuid(),
            label: faker.name.lastName(),
          })
        ),
      },
    ],
  ],
};

const unmanagableArrayOf: ArrayOf = {
  type: 'arrayOf',
  name: 'arrayOf3',
  label: 'Array of Text Fields',
  rowDefinition: [
    {
      type: 'text',
      name: 'loremP',
      label: 'Пара фраз',
      mdCols: 12,
    },
    {
      type: 'datePicker',
      pickerType: 'date',
      name: 'birthDate',
      label: 'date of birth',
      mdCols: 6,
    },
    {
      type: 'select',
      optionsLoader: 'sync',
      selectType: 'multi',
      options: Array.from(
        { length: Math.floor(Math.random() * 7) + 1 },
        () => ({
          value: faker.datatype.uuid(),
          label: faker.name.lastName(),
        })
      ),
      label: 'friends',
      name: 'friends',
      mdCols: 6,
    },
  ],
  defaultRows: [
    [
      {
        name: 'fullName',
        defaultValue: faker.name.findName(),
      },
      {
        name: 'birthDate',
        defaultValue: faker.date.past(80).toISOString(),
      },
      {
        name: 'friends',
        defaultValue: Array.from(
          { length: Math.floor(Math.random() * 2) + 1 },
          () => ({
            value: faker.datatype.uuid(),
            label: faker.name.lastName(),
          })
        ),
      },
    ],
    [
      {
        name: 'fullName',
        defaultValue: faker.name.findName(),
      },
      {
        name: 'birthDate',
        defaultValue: faker.date.past(80).toISOString(),
      },
      {
        name: 'friends',
        defaultValue: Array.from(
          { length: Math.floor(Math.random() * 4) + 1 },
          () => ({
            value: faker.datatype.uuid(),
            label: faker.name.lastName(),
          })
        ),
      },
    ],
  ],
  disallowRowDeletion: true,
  disallowRowAddition: true,
};

const showNumberingArrayOf: ArrayOf = {
  type: 'arrayOf',
  name: 'arrayOf',
  label: 'Array of Text Fields',
  rowDefinition: [
    {
      type: 'text',
      name: 'fullName',
      label: 'full name',
      mdCols: 4,
    },
    {
      type: 'datePicker',
      pickerType: 'date',
      name: 'birthDate',
      label: 'date of birth',
      mdCols: 4,
    },
    {
      type: 'select',
      optionsLoader: 'sync',
      selectType: 'multi',
      options: Array.from(
        { length: Math.floor(Math.random() * 7) + 1 },
        () => ({
          value: faker.datatype.uuid(),
          label: faker.name.lastName(),
        })
      ),
      label: 'friends',
      name: 'friends',
      mdCols: 4,
    },
  ],
  defaultRows: [
    [
      {
        name: 'fullName',
        defaultValue: faker.name.findName(),
      },
      {
        name: 'birthDate',
        defaultValue: faker.date.past(80).toISOString(),
      },
      {
        name: 'friends',
        defaultValue: Array.from(
          { length: Math.floor(Math.random() * 2) + 1 },
          () => ({
            value: faker.datatype.uuid(),
            label: faker.name.lastName(),
          })
        ),
      },
    ],
    [
      {
        name: 'fullName',
        defaultValue: faker.name.findName(),
      },
      {
        name: 'birthDate',
        defaultValue: faker.date.past(80).toISOString(),
      },
      {
        name: 'friends',
        defaultValue: Array.from(
          { length: Math.floor(Math.random() * 4) + 1 },
          () => ({
            value: faker.datatype.uuid(),
            label: faker.name.lastName(),
          })
        ),
      },
    ],
  ],
  showAutoNumeration: true,
};

const arrayOfWithDefaultValueField: ArrayOf = {
  type: 'arrayOf',
  name: 'arrayOfWithDefaultValueField',
  label: 'Array of Text Fields',
  rowDefinition: [
    {
      type: 'comboBox',
      optionsType: OptionsType.flat,
      optionsLoader: 'sync',
      name: 'to',
      label: 'Тип ТО',
      mdCols: 2,
      options: [
        {
          label: 'ТО-0',
          value: '0',
        },
        {
          label: 'ТО-1',
          value: '1',
        },
        {
          label: 'ТО-2',
          value: '2',
        },
        {
          label: 'ТО-3',
          value: '3',
        },
        {
          label: 'ТО-4',
          value: '4',
        },
        {
          label: 'ТО-5',
          value: '5',
        },
      ],
    },
    {
      type: 'comboBox',
      optionsType: OptionsType.flat,
      optionsLoader: 'sync',
      name: 'need-photo',
      label: 'Требуется фото',
      mdCols: 2,
      defaultValue: {
        label: 'Нет',
        value: 'no',
      },
      options: [
        {
          label: 'Да',
          value: 'yes',
        },
        {
          label: 'Нет',
          value: 'no',
        },
      ],
    },
    {
      type: 'comboBox',
      optionsType: OptionsType.flat,
      optionsLoader: 'sync',
      name: 'need-acceptance',
      label: 'Требуется приемка',
      mdCols: 2,
      defaultValue: {
        label: 'Нет',
        value: 'no',
      },
      options: [
        {
          label: 'Нет',
          value: 'no',
        },
        {
          label: 'Да, с фото',
          value: 'yesWithPhoto',
        },
        {
          label: 'Да, без фото',
          value: 'yesWithoutPhoto',
        },
      ],
    },
    {
      type: 'text',
      name: 'work',
      label: 'Работа',
      mdCols: 6,
    },
  ],
  validationConfig: {
    validationType: 'array',
    validations: [
      {
        type: 'of',
        params: [
          [
            {
              name: 'need-photo',
              validationConfig: {
                validationType: 'mixed',
                validations: [
                  { type: 'required', params: ['Обязательное поле'] },
                ],
              },
            },
            {
              name: 'need-acceptance',
              validationConfig: {
                validationType: 'mixed',
                validations: [
                  { type: 'required', params: ['Обязательное поле'] },
                ],
              },
            },
          ],
        ],
      },
    ],
  },
};

const equipmentOptions = Array.from(
  { length: Math.floor(Math.random() * 30) + 1 },
  () => ({
    label: `${faker.commerce.productMaterial()} ${faker.commerce.color()} ${faker.commerce.product()}`,
    value: faker.datatype.uuid(),
    href: faker.random.word(),
  })
);

const arrayOfWithDefaultSorting: ArrayOf = {
  type: 'arrayOf',
  name: 'arrayOfWithDefaultSorting',
  label: 'Array of Text Fields',
  rowDefinition: [
    {
      type: 'comboBox',
      optionsType: OptionsType.flat,
      optionsLoader: 'sync',
      multiple: false,
      name: 'equipment',
      label: 'Оборудование',
      options: equipmentOptions,
      mdCols: 4,
    },
    {
      type: 'text',
      name: 'descriptionOfWorks',
      label: 'Работы',
      mdCols: 8,
    },
  ],
  defaultSorting: {
    columnName: 'descriptionOfWorks',
    direction: 'asc',
  },
  defaultRows: [
    [
      {
        name: 'equipment',
        defaultValue: faker.random.arrayElement(equipmentOptions),
      },
      {
        name: 'descriptionOfWorks',
        defaultValue: faker.lorem.paragraph(),
      },
    ],
    [
      {
        name: 'equipment',
        defaultValue: faker.random.arrayElement(equipmentOptions),
      },
      {
        name: 'descriptionOfWorks',
        defaultValue: faker.lorem.paragraph(),
      },
    ],
    [
      {
        name: 'equipment',
        defaultValue: faker.random.arrayElement(equipmentOptions),
      },
      {
        name: 'descriptionOfWorks',
        defaultValue: faker.lorem.paragraph(),
      },
    ],
    [
      {
        name: 'equipment',
        defaultValue: faker.random.arrayElement(equipmentOptions),
      },
      {
        name: 'descriptionOfWorks',
        defaultValue: faker.lorem.paragraph(),
      },
    ],
    [
      {
        name: 'equipment',
        defaultValue: faker.random.arrayElement(equipmentOptions),
      },
      {
        name: 'descriptionOfWorks',
        defaultValue: faker.lorem.paragraph(),
      },
    ],
    [
      {
        name: 'equipment',
        defaultValue: faker.random.arrayElement(equipmentOptions),
      },
      {
        name: 'descriptionOfWorks',
        defaultValue: faker.lorem.paragraph(),
      },
    ],
  ],
};

export const arrayOfFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с вариантами arrayOf полей',
  component: {
    id: 100,
    businessComponentId: 12,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          color: 'primary',
          variant: 'contained',
          label: 'Отправить',
          icon: 'mui:Check',
          requestConfig: {
            url: '/api/fields/array-of',
            method: 'POST',
          },
          successResponseReaction: {
            type: 'reLoadModule',
            snackbar: { text: 'Удалось!', options: { variant: 'success' } },
          },
        },
        {
          type: 'submitForm',
          color: 'primary',
          variant: 'contained',
          label: 'Отправить на согласование',
          icon: 'mui:Build',
          requestConfig: {
            url: '/api/fields/array-of-2',
            method: 'POST',
          },
          successResponseReaction: {
            type: 'reLoadModule',
            snackbar: { text: 'Отправлено!', options: { variant: 'success' } },
          },
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с вариантами arrayOf полей' },
        fieldGroups: [
          {
            label: 'Array of *',
            fields: [simpleArrayOf],
          },
          {
            label: 'Array of с переносами полей',
            fields: [arrayOfWithMultiLine],
          },
          {
            label:
              'Array of с отключенной возможностью добавлять/удалять поля. Можно отключать что-то одно.',
            fields: [unmanagableArrayOf],
          },
          {
            label: 'Array of с нумерацией',
            fields: [showNumberingArrayOf],
          },
          {
            label: 'Array of с сортировкой',
            fields: [arrayOfWithDefaultSorting],
          },
          {
            label: 'Array of с дефолтными значениями в полях',
            fields: [arrayOfWithDefaultValueField],
          },
        ],
      },
    },
  },
};
