import { createSelector } from '@reduxjs/toolkit';
import { LinkMenuItem, MenuItem, ModuleMenuItem } from 'services/Main/types';
import { RootState } from '../configureStore';

interface MenuModulesWithChildren extends ModuleMenuItem {
  children: LinkMenuItem[];
}

const mainMenuSelector = (state: RootState) => state.mainMenu;

/**
 * Input: '9573220d-f1dc-4517-b13c-b3272e28da6e';
 *
 * Output:
 * [
 *  { id: '650fd4e8-1d7c-4e21-aafd-aca5413eadd3', parentId: '9573220d-f1dc-4517-b13c-b3272e28da6e', label: 'Foo' },
 *  { id: 'b9e0de80-e6e7-427e-ade7-2f56e1011ab2', parentId: '9573220d-f1dc-4517-b13c-b3272e28da6e', label: 'Bar' },
 *  { id: '325e1b22-5ccf-49f3-b964-a5c7a42d05d1', parentId: '9573220d-f1dc-4517-b13c-b3272e28da6e', label: 'Baz' },
 * ]
 */
export const childrenMenuItemsSelector = (id: string | number) =>
  createSelector(mainMenuSelector, (menuItems) =>
    menuItems.filter((item) => item.type !== 'module' && item.parentId === id)
  );

/**
 *
 * Output:
 *
 * {
 *   'MenuModule Id': [
 *     MenuModule to,
 *     MenuLink to,
 *     MenuLink to,
 *     MenuLink to,
 *   ],
 *   'MenuGroup Id': [
 *     MenuLink to,
 *     MenuLink to,
 *     MenuLink to,
 *   ],
 * }
 *
 */

export const linkMenuItemsGroupedByMenuModuleIdSelector = createSelector(
  mainMenuSelector,
  (menuItems) => {
    /**
     *
     * interface MenuModuleWithChildren extends ModuleMenuItem {
     *   children: MenuItem[]
     * }
     *
     * Type:
     * Input: MenuItem[]
     * Output: MenuModuleWithChildren[]
     *
     * Алгоритм 2.2
     * 1. Пробегаемся по массиву menuItems, если тип текущего элемента = 'link' | 'group',
     * то:
     * 2. Берем item и рекурсивно бегаем ищем rootNode (она же menuModule):
     * - Если item = undefined, то возвращаем null и выходим из рекурсии;
     * - Если item.type = 'module', то возвращаем item и выходим из рекурсии;
     * - Если у item есть parentItem и parentItem.type = 'module', то возвращаем parentItem;
     * - Если мы parentItem.type не модуль, то - к пункту 2.
     *
     */
    const menuItemById: Map<string | number, MenuItem> = new Map(
      [...menuItems].map((item) => [item.id, item])
    );

    const getRootNode = (item?: MenuItem): ModuleMenuItem | undefined => {
      // Выход из рекурсии
      if (!item || item.type === 'module') return item;

      // Получаем родительский элемент, если такого нет вернут undefined
      const parentItem = menuItemById.get(item.parentId);

      if (parentItem && parentItem.type === 'module') {
        return parentItem;
      }

      return getRootNode(parentItem);
    };

    return menuItems.reduce<Record<string, MenuModulesWithChildren>>(
      (acc, menuItem) => {
        if (menuItem.type === 'link') {
          // Получаем родительский элемент, нам могут вернуть undefined или module
          const moduleItem = getRootNode(menuItem);

          if (moduleItem) {
            if (acc[moduleItem.id]) {
              acc[moduleItem.id].children.push(menuItem);

              return acc;
            }

            acc[moduleItem.id] = {
              ...moduleItem,
              children: [menuItem],
            };
          }
        }

        return acc;
      },
      {}
    );
  }
);
