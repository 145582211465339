import {
  ArrayOf,
  Field,
  FieldRenderCondition,
} from '../../../services/Main/types.Field';
import {
  DirtyFormValue,
  DirtyFormValues,
} from '../../../services/Main/types.Component';

interface GetFieldValueProps {
  field: Field;
  unpackedValue: DirtyFormValue | DirtyFormValues[];
  renderCondition: FieldRenderCondition;
}

export default function getFieldValue(props: GetFieldValueProps) {
  const { field, unpackedValue, renderCondition } = props;
  const { type, multiple, optionType, selectType } = field as any;

  switch (type) {
    case 'comboBox':
    case 'entryPicker':
      if (multiple || optionType === 'checkboxTree') {
        throw new Error(
          'Подписка на поле с множественным выбором не поддерживается в условных полях!'
        );
      }

      return getSingleSelectValue(unpackedValue);
    case 'radio':
      return getSingleSelectValue(unpackedValue);
    case 'select':
      if (selectType === 'multi') {
        throw new Error(
          'Подписка на поле с множественным выбором не поддерживается в условных полях!'
        );
      }

      return getSingleSelectValue(unpackedValue);
    case 'arrayOf': {
      const { rowDefinition } = field as ArrayOf;

      if (
        !Array.isArray(unpackedValue) ||
        (Array.isArray(unpackedValue) && unpackedValue.length === 0)
      ) {
        return [];
      }

      const [arrayOfFieldName, childFieldName] =
        renderCondition.dependOn.split('.');
      const childField = rowDefinition.find((f) => f.name === childFieldName);

      if (!childField) {
        throw new Error(
          `Не удалось найти дочернее поле '${childFieldName}' внутри arrayOf '${arrayOfFieldName}'`
        );
      }

      return (unpackedValue as DirtyFormValues[]).reduce((acc, row) => {
        const rowChildFieldName = row[childFieldName];
        if (rowChildFieldName) {
          const childFieldValue = getFieldValue({
            field: childField,
            unpackedValue: row[childFieldName],
            renderCondition,
          }) as string;

          return [...acc, childFieldValue];
        }
        return acc;
      }, [] as string[]);
    }
    case 'text':
      return unpackedValue as string;
    case 'checkbox':
      return unpackedValue as boolean;
    case 'datePicker':
    case 'file':
    case 'formattedNumber':
    case 'rating':
    default:
      throw new Error(`Подписка на поле с типом ${type} - не реализована`);
  }
}

const getSingleSelectValue = (unpackedValue: any): string =>
  unpackedValue?.value ?? '';
