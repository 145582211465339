import faker from 'faker';
import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  isWeekend,
  startOfMonth,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ruLocale from 'date-fns/locale/ru';
import { Color } from '@material-ui/lab';
import { Page } from 'services/Main/types.Page';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { STMCell, TableColumn, TableRow } from '../../../types.Component';
import { generateSTMCellValue } from './calendarSTMv2';

faker.locale = 'ru';

const color: Color = faker.random.arrayElement([
  'success',
  'info',
  'warning',
  'error',
]);

const startDate = startOfMonth(addMonths(new Date(), 1));
const daysArray = eachDayOfInterval({
  start: startDate,
  end: endOfMonth(startDate),
});

const title = `Ежемесячный ППР ${format(startDate, 'LLLL yyyy', {
  locale: ruLocale,
})}, 0075 Пушкино, “БДФ-Восток, ООО” (Технологическое оборудование)`;

export const calendarMonthlySTMRows = Array.from({ length: 15 }, createRow);
export const calendarMonthlySTMRowsMap = new Map(
  calendarMonthlySTMRows.map((row) => [row.id, row])
);
export const calendarMonthlySTMPage: Page = {
  type: 'singleComponent',
  title,
  component: {
    id: 404,
    businessComponentId: 8,
    type: 'table',
    props: {
      actions: [
        {
          type: 'showForm',
          label: 'Оставить вопрос',
          viewStyle: 'modal',
          variant: 'outlined',
          color: 'primary',
          actions: [
            {
              type: 'submitForm',
              label: 'В путь!',
              variant: 'contained',
              color: 'primary',
              requestConfig: {
                url: '/api/complex/calendar-stm/ask-question',
                method: 'POST',
              },
              successResponseReaction: { type: 'reLoadModule' },
            },
          ],
          form: {
            type: 'markup',
            header: { heading: 'Задать вопрос' },
            fieldGroups: [
              {
                fields: [
                  { type: 'text', name: 'foo', label: 'Введите вопрос' },
                ],
              },
            ],
          },
        },
        {
          type: 'triggerEndpoint',
          label: 'Сохранить',
          variant: 'contained',
          color: 'primary',
          requestConfig: {
            url: '/api/complex/monthly-calendar-stm/save',
            method: 'GET',
          },
          successResponseReaction: { type: 'reLoadModule' },
        },
      ],
      heading: title,
      requestConfig: { url: '/api/complex/monthly-calendar-stm' },
      groupingConfig: {
        defaultGrouping: [
          {
            columnName: 'category',
          },
        ],
      },
      filterComponent: {
        type: 'tableFilter',
        id: 10000,
        businessComponentId: 23,
        props: {
          requestOn: 'change',
          fieldGroups: [
            {
              fields: [
                {
                  type: 'datePicker',
                  pickerType: 'dateRange',
                  name: 'dateRange',
                  label: 'Date range',
                  initialFocusedDate: 'now',
                },
                {
                  type: 'datePicker',
                  pickerType: 'dateTimeRange',
                  name: 'dateTimeRange',
                  label: 'Date Time range',
                  initialFocusedDate: '2020-04-15T16:33:23.3004811+03:00',
                },
                { type: 'text', name: 'text', label: 'Text' },
                {
                  type: 'formattedNumber',
                  formattedNumberType: 'price',
                  name: 'formattedNumber',
                  label: 'Formatted number',
                  currencySign: '₽',
                  min: '0',
                },
                {
                  type: 'select',
                  optionsLoader: 'sync',
                  selectType: 'single',
                  name: 'singleSelect',
                  label: 'Select single',
                  options: [
                    { value: 'Critical', label: 'Критичный' },
                    { value: 'High', label: 'Высокий' },
                    { value: 'Low', label: 'Низкий' },
                  ],
                },
                {
                  type: 'select',
                  optionsLoader: 'sync',
                  selectType: 'multi',
                  name: 'multiSelect',
                  label: 'Select multi',
                  options: [
                    { value: 'Draft', label: 'Черновик' },
                    { value: 'Pause', label: 'Отложена' },
                    { value: 'Closing', label: 'Сдача-приемка' },
                    { value: 'Canceled', label: 'Прекращена' },
                    { value: 'Done', label: 'Выполнено' },
                    { value: 'Approval', label: 'На согласовании' },
                    { value: 'InWork', label: 'В работе' },
                    { value: 'Queue', label: 'В очереди' },
                  ],
                },
              ],
            },
          ],
        },
      },
      columns: [
        {
          title: 'Категория',
          name: 'category',
          options: { formatAs: 'link' },
        },
        {
          title: 'Оборудование',
          name: 'equipment',
          options: { formatAs: 'link' },
        },
        {
          title: 'Статус',
          name: 'status',
          options: { formatAs: 'cutTextWithTooltip' },
        },
        ...daysArray.reduce<TableColumn[]>(
          (acc, date, dayIndex) => [
            ...acc,
            {
              title: `${dayIndex + 1}`,
              name: `day${dayIndex + 1}`,
              options: { formatAs: 'STMCells' },
              tableColumnViewConfig: {
                headerCellTextAlign: 'center',
                headerCellBackgroundColor: isWeekend(date)
                  ? '#f2f2f2'
                  : undefined,
                columnBackgroundColor: isWeekend(date) ? '#fbfbfb' : undefined,
              },
            },
          ],
          []
        ),
      ],
      columnExtensions: [
        { columnName: 'equipment', width: 330 },
        { columnName: 'status', width: 180 },
        ...daysArray.reduce<Table.ColumnExtension[]>(
          (acc, _, dayIndex) => [
            ...acc,
            {
              columnName: `day${dayIndex + 1}`,
              width: 72,
            },
          ],
          []
        ),
      ],
      fixedColumns: {
        leftColumns: ['actions', 'equipment'],
      },
      rows: calendarMonthlySTMRows.slice(0, 15),
      options: {
        totalRows: calendarMonthlySTMRows.length,
        currentPage: 0,
        pageSize: 20,
        cellClickReaction: {
          type: 'showAsyncModalOnCellClickReaction',
          fetchModalDataRequestConfig: {
            url: '/api/complex/calendar-stm/v2-get-modal-info/monthly',
            method: 'POST',
          },
          columnNames: daysArray.map((_, dayIndex) => `day${dayIndex + 1}`),
        },
        allowClickOnEmptyCell: false,
      },
      dragNDropConfig: {
        draggableColumns: daysArray.map((_, dayIndex) => `day${dayIndex + 1}`),
        requestConfig: {
          url: '/api/complex/calendar-stm/monthly/drag-and-drop',
          method: 'GET',
        },
      },
    },
  },
};

function getRowViewConfig() {
  if (Math.random() * 1000 < 950) return undefined;

  const backgroundColorMap = {
    error: 'rgb(253, 236, 234)',
    warning: 'rgb(255, 244, 229)',
    info: 'rgb(232, 244, 253)',
    success: 'rgb(237, 247, 237)',
  };

  const colorMap = {
    error: 'rgb(97, 26, 21)',
    warning: 'rgb(102, 60, 0)',
    info: 'rgb(13, 60, 97)',
    success: 'rgb(30, 70, 32)',
  };

  return {
    backgroundColor: backgroundColorMap[color],
    color: colorMap[color],
  };
}

export function createRow(uuid?: string): TableRow {
  const rowId = uuid ?? faker.datatype.uuid();

  return {
    id: rowId,
    category: {
      href: `/equipment-type/${faker.datatype.uuid()}`,
      label: faker.random.arrayElement([
        'Технологическое оборудование',
        'Промышленное оборудование',
        'Общепит (кухонная техника)',
      ]),
    },
    equipment: {
      href: `/equipments/${faker.datatype.uuid()}`,
      label: faker.commerce.productName(),
    },
    status: faker.random.arrayElement([
      'Исправно',
      'Неисправно',
      'Неисправно (критично)',
      'Требуется ремонт',
      'Не эксплуатируется',
      'Планируется замена',
    ]),
    ...createMonthlySTMCellRow(),
    viewConfig: getRowViewConfig(),
  };
}

export function createMonthlySTMCellRow() {
  const bigSTMType = roulette<number>([
    // 70%
    {
      value: 2,
      chance: 0.7,
    },
    // 15%
    {
      value: 3,
      chance: 0.15,
    },
    // 10%
    {
      value: 4,
      chance: 0.1,
    },
    // 5%
    {
      value: 5,
      chance: 0.05,
    },
  ]);

  const startWeek = faker.datatype.number(3) + 1;

  return daysArray.reduce<Record<string, STMCell[] | null>>(
    (acc, _, dayIndex) => {
      if ((dayIndex + 1) % 7 === 0) {
        return {
          ...acc,
          [`day${dayIndex + 1}`]: [
            generateSTMCellValue(
              startWeek === (dayIndex + 1) / 7 ? bigSTMType : 1,
              startDate.getMonth() + 1,
              (dayIndex + 1) / 7
            ),
          ],
        };
      }

      return {
        ...acc,
        [`day${dayIndex + 1}`]: null,
      };
    },
    {}
  );
}

interface RouletteItem<V> {
  value: V;
  chance: number;
}

function roulette<V>(items: RouletteItem<V>[]): V {
  // Собираем сумму всех вероятностей
  const sum = items.reduce((acc, item) => acc + item.chance, 0);

  // Генерируем случайное число в диапазоне суммы вероятностей
  let random = Math.random() * sum;

  for (let i = 0; i < items.length; i += 1) {
    const option = items[i];

    // Если случайное число меньше вероятности элемента, то его значение
    // становится победителем
    if (random < option.chance) {
      return option.value;
    }

    // Вычитаем вероятность элемента из случайного числа. Если случайное число
    // больше суммы вероятностей, то этот цикл просто продолжится до конца
    // массива
    random -= option.chance;

    if (random < 0) {
      break;
    }
  }

  // Если ничего не вышло, возвращаем значение последнего элемента
  return items[items.length - 1].value;
}
