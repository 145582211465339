import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    root: {
      position: 'sticky',
      right: 0,
      bottom: 0,
      left: 0,
      background: theme.palette.background.paper,
    },
    cell: {
      borderBottom: 'none',
      color: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      padding: theme.spacing(2, 1),
      fontWeight: 600,
    },
  })
);
