import React from 'react';
import Box from '@material-ui/core/Box';
import {
  ConvertibleLink,
  SubHeadingElementValue,
} from '../../../services/Main/types.Component';
import { formatDateFromISOstring } from '../../../utils/formatDate';
import Link from '../Link';
import Icon from '../Icon/Icon';

export function getSubHeadingValue(sourceValue: SubHeadingElementValue) {
  if (typeof sourceValue === 'string' || typeof sourceValue === 'number') {
    return sourceValue;
  }

  switch (sourceValue.type) {
    case 'date':
      return formatDateFromISOstring(sourceValue.value, sourceValue.formatAs);

    case 'link':
      return (
        <Box display="block" width={320} component="span">
          {sourceValue?.external ? (
            <a
              href={sourceValue.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getLinkContent(sourceValue)}
            </a>
          ) : (
            <Link to={sourceValue.href}>{getLinkContent(sourceValue)}</Link>
          )}
        </Box>
      );

    default:
      console.error('Не удалось определить тип значения.');
      return '';
  }
}

function getLinkContent(sourceValue: ConvertibleLink) {
  return (
    <Box display="inline-flex" alignItems="center" component="span">
      {sourceValue.icon && (
        <Icon definition={sourceValue.icon} style={{ fontSize: '1.5rem' }} />
      )}
      {sourceValue.label}
    </Box>
  );
}
