import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Component,
  DonutChartProps,
  FormComponentProps,
  TimelineComponentProps,
  BarChartProps,
  TableComponentProps,
  TextWidget,
  HTMLPrinterProps,
  LineChartWidgetProps,
  TableWidgetProps,
} from 'services/Main/types.Component';
import Form from 'components/highLevel/Form';
import Table from 'components/highLevel/Table';
import { UnexpectedDataError } from 'utils/errors/UnexpectedDataError';
import GroupOfTextWidget from '../../highLevel/GroupOfTextWidget';
import DonutChartWidget from '../../highLevel/DonutChartWidget';
import BarChartWidget from '../../highLevel/BarChartWidget';
import HTMLPrinter from '../../highLevel/HTMLPrinter';
import Timeline from '../../highLevel/Timeline';
import TableWidget from '../../highLevel/TableWidget';
import { ComponentContext } from './ComponentLoader.context';
import LineChartWidget from '../../highLevel/LineChartWidget';
import { dialogWithComponentActions } from '../../../store/dialogWithComponent';

interface ComponentLoaderProps {
  component: Component;
}

const ComponentLoader = ({ component }: ComponentLoaderProps) => {
  const dispatch = useDispatch();
  const { type, props } = component;

  const getComponent = () => {
    switch (type) {
      case 'form':
        return <Form {...(props as FormComponentProps)} />;
      case 'table':
        return <Table {...(props as TableComponentProps)} />;
      case 'tableWidget':
        return <TableWidget {...(props as TableWidgetProps)} />;
      case 'timeline':
        return <Timeline {...(props as TimelineComponentProps)} />;
      case 'groupOfTextWidget':
        return <GroupOfTextWidget textWidgets={props as TextWidget[]} />;
      case 'donutChartWidget':
        return <DonutChartWidget {...(props as DonutChartProps)} />;
      case 'barChartWidget':
        return <BarChartWidget {...(props as BarChartProps)} />;
      case 'lineChartWidget':
        return <LineChartWidget {...(props as LineChartWidgetProps)} />;
      case 'HTMLPrinter':
        return <HTMLPrinter {...(props as HTMLPrinterProps)} />;
      default:
        throw new UnexpectedDataError(
          `Не удалось определить тип высокоуровневого компонента. Указан тип: ${type}.`
        );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(dialogWithComponentActions.close());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ComponentContext.Provider value={component}>
      {getComponent()}
    </ComponentContext.Provider>
  );
};

export default ComponentLoader;
