import React from 'react';
import { LinearProgress, IconButton } from '@material-ui/core';
import Icon from 'components/lowLevel/Icon';
import useStyles from './LinearProgressbar.styles';
import { useRemainingText } from './hooks';

export type LinearProgressbarProps = {
  percent: number;
  remainingTimeInSeconds?: number;
  cancelRequest?: () => void;
};

export const LinearProgressbar = ({
  percent,
  remainingTimeInSeconds,
  cancelRequest,
}: LinearProgressbarProps) => {
  const classes = useStyles();
  const remainingText = useRemainingText(remainingTimeInSeconds);

  return (
    <div className={classes.container}>
      <LinearProgress
        className={classes.progressBar}
        variant="determinate"
        value={percent}
      />
      <div className={classes.info}>
        <div>{`${percent}%`}</div>
        {remainingText}
      </div>
      {cancelRequest && (
        <IconButton
          onClick={cancelRequest}
          size="small"
          className={classes.cancelIcon}
        >
          <Icon definition="mui:HighlightOff" />
        </IconButton>
      )}
    </div>
  );
};
