import { createContext, MutableRefObject, RefObject } from 'react';
import {
  QuotedTimelineElement,
  TimelineElement,
} from '../../../services/Main/types.Component';
import ScrollManager from '../../../utils/ScrollManager';

export type QuoteState = QuotedTimelineElement | null;

type Props = {
  timelineElements: TimelineElement[];
  isTimelineInitialized: boolean;
  getTimelineElementById: (
    id: TimelineElement['id']
  ) => TimelineElement | undefined;
  timelineScrollManager: MutableRefObject<ScrollManager | undefined>;
  timelineElementsContainer: RefObject<HTMLDivElement>;
  quote: QuoteState;
  setQuote: (quote: QuoteState) => void;
};

export type TimelineContextValue = Props | null;
export const TimelineContext = createContext<TimelineContextValue>(null);
