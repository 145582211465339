import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import MainMenuContainer from 'components/template/PrivateRoutesTemplate/MainMenu';
import Drawer from 'components/lowLevel/Drawer';
import AppBar from 'components/lowLevel/AppBar';
import DrawerFooter from '../../lowLevel/DrawerFooter';
import DrawerHeader from '../../lowLevel/DrawerHeader';
import useStyles from './PrivateRoutesTemplate.styles';
import { useIsCompactMenuView } from '../../../utils/hooks/useIsCompactMenuView';

interface OtherProps {}

type TemplateProps = PropsWithChildren<OtherProps>;

const PrivateRoutesTemplate = ({ children }: TemplateProps) => {
  const classes = useStyles();
  const isCompactMenuView = useIsCompactMenuView();

  return (
    <>
      <AppBar />
      <Drawer
        classes={{
          paperAnchorLeft: clsx(classes.paperAnchorLeft, {
            compactView: isCompactMenuView,
          }),
        }}
      >
        <DrawerHeader />
        <div className={classes.content}>
          <MainMenuContainer />
        </div>
        <DrawerFooter />
      </Drawer>
      <main className={clsx(classes.main, { compactView: isCompactMenuView })}>
        {children}
      </main>
    </>
  );
};

export default PrivateRoutesTemplate;
