import { createStyles, darken, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      display: 'flex',
      gap: theme.spacing(1.5),
      justifyContent: 'flex-end',
    },
    clearQuoteButton: {
      color: theme.additionalPalette.title,
      fontSize: `${theme.typography.pxToRem(16)} !important`,
      cursor: 'pointer',

      '&:hover': {
        color: darken(theme.additionalPalette.title, 0.5),
      },
    },
  })
);
