import React, { FunctionComponent } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
// eslint-disable-next-line import/no-duplicates
import { format, parseISO } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ru } from 'date-fns/locale';

export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';

const DateFormatter: FunctionComponent<DataTypeProvider.ValueFormatterProps> = ({
  value,
}) => {
  if (!value) return null;

  return <>{format(parseISO(value), DATE_TIME_FORMAT, { locale: ru })}</>;
};

interface DateTypeProviderProps {
  for: string[];
}

const DateTimeTypeProvider = (props: DateTypeProviderProps) => (
  <DataTypeProvider formatterComponent={DateFormatter} {...props} />
);

export default DateTimeTypeProvider;
