import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.75),
      color: theme.palette.text.secondary,
      fontSize: theme.typography.pxToRem(12),
      '&.reverse': { flexDirection: 'row-reverse' },
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.75),
    },
  })
);
