import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    elementsContainer: {
      height: '100%',
      overflowY: 'auto',
      marginBottom: theme.spacing(2),
    },
    elementsList: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0),
    },
  })
);
