import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Header as HeaderProps } from 'services/Main/types.Component';
import Icon from '../Icon';
import useStyles from './Header.styles';
import { getSubHeadingValue } from './helpers';

const Header = ({
  heading,
  preHeadingIcon,
  preHeadingText,
  subHeadings,
  headingClassName,
}: HeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box className={classes.root}>
      <Box className={classes.headingRoot}>
        {preHeadingIcon && (
          <Icon
            className={classes.preHeadingIcon}
            definition={preHeadingIcon}
          />
        )}
        <Box className={classes.headingMainPart}>
          {preHeadingText && (
            <Typography variant="body2" className={classes.preHeadingText}>
              {preHeadingText}
            </Typography>
          )}
          <Tooltip
            title={heading}
            enterDelay={1000}
            arrow
            interactive
            disableHoverListener={isXs}
          >
            <Typography
              variant="h6"
              className={headingClassName}
              noWrap={!isXs}
            >
              {heading}
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      {subHeadings && (
        <Box className={classes.subHeadingRoot}>
          {subHeadings.map(([key, value]) => (
            <Box key={`${key}${value}`} className={classes.subHeading}>
              {key && (
                <Typography variant="body2" className={classes.subHeadingKey}>
                  {`${key}:`}
                </Typography>
              )}
              <Typography variant="body2" className={classes.subHeadingValue}>
                {getSubHeadingValue(value)}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Header;
