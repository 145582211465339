import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import Icon from '../Icon/Icon';
import useIsSomeActionExecuting from '../FormAction/useIsSomeActionExecuting';
import { TableActionButtonProps } from './types';
import useStyles from './TableAction.styles';

function TableActionIconButton({ action, onClick }: TableActionButtonProps) {
  const classes = useStyles();
  const { icon, label } = action;

  const { isSomeActionExecuting } = useIsSomeActionExecuting();

  if (!icon) {
    throw new Error(
      'При использовании TableActionIconButton - icon обязательный параметр'
    );
  }

  return (
    <Tooltip title={label ?? ''}>
      <IconButton
        className={classes.iconButton}
        onClick={onClick}
        disabled={isSomeActionExecuting}
      >
        <Icon definition={icon} />
      </IconButton>
    </Tooltip>
  );
}

export default TableActionIconButton;
