import React, { useState } from 'react';
import {
  Pie,
  PieChart,
  Legend,
  Customized,
  ResponsiveContainer,
} from 'recharts';
import { DonutChartProps } from '../../../services/Main/types.Component';
import { DonutChartState } from './types';
import ChartsLegend from '../../lowLevel/ChartsLegend';
import DonutSectorTooltip from './components/DonutSectorTooltip';
import DonutTotalValue from './components/DonutTotalValue';
import ActiveShape from './components/ActiveShape';

const DonutChartWidget = ({
  data,
  showLegend,
  showTooltip,
}: DonutChartProps & DonutChartState) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  const handleLegendEnter = (datum: any, index: number) => {
    setActiveIndex(index);
  };

  const handlePieLeave = () => {
    setActiveIndex(undefined);
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={<ActiveShape />}
          data={data}
          innerRadius="60%"
          outerRadius="70%"
          dataKey="value"
          minAngle={3}
        />
        <Customized component={<DonutTotalValue activeIndex={activeIndex} />} />
        {showTooltip && (
          <Customized
            component={
              <DonutSectorTooltip
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
            }
          />
        )}
        {showLegend && (
          <Legend
            content={<ChartsLegend />}
            wrapperStyle={{ bottom: 15 }}
            onMouseEnter={handleLegendEnter}
            onMouseLeave={handlePieLeave}
          />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DonutChartWidget;
