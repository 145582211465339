import { Page } from 'services/Main/types.Page';
import { OptionsType } from '../../../types.Field';

export const tinTestPage: Page = {
  type: 'tabsView',
  title: '',
  tabs: [
    {
      label: 'Внутри ArrayOf',
      component: {
        type: 'form',
        businessComponentId: 12,
        id: 12,
        props: {
          actions: [
            {
              type: 'submitForm',
              requestConfig: {
                url: 'component/v1.0/update/502/3b1a7635-11e8-4a5d-b1b1-9b3061133db5',
                method: 'POST',
              },
              successResponseReaction: {
                type: 'reLoadModule',
                snackbar: {
                  text: 'Изменения сохранены',
                  options: {
                    variant: 'success',
                  },
                },
              },
              label: 'Сохранить',
              color: 'primary',
              variant: 'contained',
            },
          ],
          form: {
            type: 'markup',
            header: {
              heading: 'Условная валидация внутри ArrayOf',
            },
            fieldGroups: [
              {
                label: 'Работы',
                fields: [
                  {
                    type: 'arrayOf',
                    rowDefinition: [
                      {
                        multiline: true,
                        autoFocus: false,
                        type: 'text',
                        inputType: 'text',
                        hidden: false,
                        name: 'maintenance-scheduled-work-work-form-field',
                        label: 'Работа',
                        mdCols: 12,
                      },
                      {
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        type: 'comboBox',
                        options: [
                          {
                            value: 'True',
                            label: 'Да',
                          },
                          {
                            value: 'False',
                            label: 'Нет',
                          },
                        ],
                        name: 'maintenance-scheduled-work-need-photo-form-field',
                        label: 'Требуется фото',
                        helperText: '',
                        mdCols: 2,
                      },
                      {
                        type: 'file',
                        defaultValue: [],
                        viewConfig: {
                          viewStyle: 'textFieldWithClip',
                        },
                        name: 'maintenance-scheduled-work-execution-photos-form-field',
                        label: 'Фотофиксация выполнения',
                        disabled: false,
                        mdCols: 3,
                      },
                      {
                        type: 'checkbox',
                        defaultValue: false,
                        labelPlacement: 'top',
                        name: 'maintenance-scheduled-work-is-done-form-field',
                        label: 'Выполнено',
                        helperText: '',
                        disabled: false,
                        mdCols: 1,
                      },
                      {
                        optionsType: OptionsType.flat,
                        optionsLoader: 'sync',
                        type: 'comboBox',
                        options: [
                          {
                            value: '0',
                            label: 'Нет',
                          },
                          {
                            value: '1',
                            label: 'Да, с фото',
                          },
                          {
                            value: '2',
                            label: 'Да, без фото',
                          },
                        ],
                        name: 'maintenance-scheduled-work-need-acceptance-form-field',
                        label: 'Требуется приемка',
                        helperText: '',
                        mdCols: 2,
                      },
                      {
                        type: 'file',
                        defaultValue: [],
                        viewConfig: {
                          viewStyle: 'textFieldWithClip',
                        },
                        name: 'maintenance-scheduled-work-acceptance-photos-form-field',
                        label: 'Фотофиксация приемки',
                        mdCols: 3,
                      },
                      {
                        type: 'checkbox',
                        defaultValue: false,
                        labelPlacement: 'top',
                        name: 'maintenance-scheduled-work-is-accepted-form-field',
                        label: 'Принято',
                        helperText: '',
                        disabled: false,
                        mdCols: 1,
                      },
                      {
                        multiline: true,
                        autoFocus: false,
                        type: 'text',
                        inputType: 'text',
                        hidden: false,
                        name: 'maintenance-scheduled-work-comment-form-field',
                        label: 'Комментарий к работе',
                        disabled: false,
                        mdCols: 12,
                      },
                      {
                        type: 'text',
                        inputType: 'text',
                        defaultValue: 'b7824310-ac2b-4604-8079-77f20ec831d5',
                        hidden: true,
                        name: 'guid',
                        label: 'VersionGuid',
                        helperText: '',
                        disabled: true,
                        mdCols: 3,
                      },
                    ],
                    defaultRows: [
                      [
                        {
                          name: 'maintenance-scheduled-work-work-form-field',
                          defaultValue: '123',
                        },
                        {
                          name: 'maintenance-scheduled-work-need-photo-form-field',
                          defaultValue: {
                            value: 'True',
                            label: 'Да',
                          },
                          options: [
                            {
                              value: 'True',
                              label: 'Да',
                            },
                            {
                              value: 'False',
                              label: 'Нет',
                            },
                          ],
                        },
                        {
                          name: 'maintenance-scheduled-work-execution-photos-form-field',
                          defaultValue: [],
                        },
                        {
                          name: 'maintenance-scheduled-work-is-done-form-field',
                          defaultValue: false,
                        },
                        {
                          name: 'maintenance-scheduled-work-need-acceptance-form-field',
                          defaultValue: {
                            value: '2',
                            label: 'Да, без фото',
                          },
                          options: [
                            {
                              value: '0',
                              label: 'Нет',
                            },
                            {
                              value: '1',
                              label: 'Да, с фото',
                            },
                            {
                              value: '2',
                              label: 'Да, без фото',
                            },
                          ],
                        },
                        {
                          name: 'maintenance-scheduled-work-acceptance-photos-form-field',
                          defaultValue: [],
                        },
                        {
                          name: 'maintenance-scheduled-work-is-accepted-form-field',
                          defaultValue: false,
                        },
                        {
                          name: 'maintenance-scheduled-work-comment-form-field',
                          defaultValue: '',
                        },
                        {
                          name: 'guid',
                          defaultValue: 'dde108fe-ad4b-40a1-816d-204b143ecb95',
                        },
                      ],
                      [
                        {
                          name: 'maintenance-scheduled-work-work-form-field',
                          defaultValue: '321',
                        },
                        {
                          name: 'maintenance-scheduled-work-need-photo-form-field',
                          defaultValue: {
                            value: 'False',
                            label: 'Нет',
                          },
                          options: [
                            {
                              value: 'True',
                              label: 'Да',
                            },
                            {
                              value: 'False',
                              label: 'Нет',
                            },
                          ],
                        },
                        {
                          name: 'maintenance-scheduled-work-execution-photos-form-field',
                          defaultValue: [],
                        },
                        {
                          name: 'maintenance-scheduled-work-is-done-form-field',
                          defaultValue: false,
                        },
                        {
                          name: 'maintenance-scheduled-work-need-acceptance-form-field',
                          defaultValue: {
                            value: '1',
                            label: 'Да, с фото',
                          },
                          options: [
                            {
                              value: '0',
                              label: 'Нет',
                            },
                            {
                              value: '1',
                              label: 'Да, с фото',
                            },
                            {
                              value: '2',
                              label: 'Да, без фото',
                            },
                          ],
                        },
                        {
                          name: 'maintenance-scheduled-work-acceptance-photos-form-field',
                          defaultValue: [],
                        },
                        {
                          name: 'maintenance-scheduled-work-is-accepted-form-field',
                          defaultValue: false,
                        },
                        {
                          name: 'maintenance-scheduled-work-comment-form-field',
                          defaultValue: '',
                        },
                        {
                          name: 'guid',
                          defaultValue: 'e9c38d3f-91db-4c11-b80e-d1d731caf9e3',
                        },
                      ],
                    ],
                    enableDragAndDrop: false,
                    showEmptyRowByDefault: true,
                    onlyUnique: [],
                    name: 'maintenance-scheduled-works-form-field',
                    label: 'maintenance-scheduled-works-form-field',
                    helperText: '',
                    disabled: false,
                    mdCols: 12,
                    validationConfig: {
                      validationType: 'array',
                      validations: [
                        {
                          type: 'of',
                          params: [
                            [
                              {
                                name: 'maintenance-scheduled-work-work-form-field',
                                validationConfig: {
                                  validationType: 'string',
                                  validations: [
                                    {
                                      type: 'required',
                                      params: ['Обязательное поле!'],
                                    },
                                  ],
                                },
                              },
                              {
                                name: 'maintenance-scheduled-work-need-photo-form-field',
                                validationConfig: {
                                  validationType: 'mixed',
                                  validations: [
                                    {
                                      type: 'required',
                                      params: ['Обязательное поле!'],
                                    },
                                  ],
                                },
                              },
                              {
                                name: 'maintenance-scheduled-work-execution-photos-form-field',
                                validationConfig: {
                                  validationType: 'array',
                                  validations: [
                                    {
                                      type: 'when',
                                      params: [
                                        'maintenance-scheduled-work-need-photo-form-field',
                                        {
                                          is: 'True',
                                          then: [
                                            {
                                              type: 'required',
                                              params: [
                                                'Обязательное поле, поскольку требуется фото!',
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },
                              {
                                name: 'maintenance-scheduled-work-acceptance-photos-form-field',
                                validationConfig: {
                                  validationType: 'array',
                                  validations: [
                                    {
                                      type: 'every',
                                      params: [
                                        [
                                          {
                                            name: 'maintenance-scheduled-work-need-acceptance-form-field',
                                            is: '1',
                                          },
                                          {
                                            name: 'maintenance-scheduled-work-is-accepted-form-field',
                                            is: true,
                                          },
                                        ],
                                        {
                                          then: [
                                            {
                                              type: 'required',
                                              params: [
                                                'Обязательное поле, поскольку принято и требуется приемка с фото!',
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              },
                              {
                                name: 'maintenance-scheduled-work-need-acceptance-form-field',
                                validationConfig: {
                                  validationType: 'mixed',
                                  validations: [
                                    {
                                      type: 'required',
                                      params: ['Обязательное поле!'],
                                    },
                                  ],
                                },
                              },
                              {
                                name: 'maintenance-scheduled-work-work-form-field',
                                validationConfig: {
                                  validationType: 'string',
                                  validations: [
                                    {
                                      type: 'required',
                                      params: ['Обязательное поле!'],
                                    },
                                  ],
                                },
                              },
                              {
                                name: 'maintenance-scheduled-work-need-photo-form-field',
                                validationConfig: {
                                  validationType: 'mixed',
                                  validations: [
                                    {
                                      type: 'required',
                                      params: ['Обязательное поле!'],
                                    },
                                  ],
                                },
                              },
                              {
                                name: 'maintenance-scheduled-work-need-acceptance-form-field',
                                validationConfig: {
                                  validationType: 'mixed',
                                  validations: [
                                    {
                                      type: 'required',
                                      params: ['Обязательное поле!'],
                                    },
                                  ],
                                },
                              },
                            ],
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        },
      },
    },
  ],
};
