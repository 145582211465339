import React from 'react';
import { useTheme } from '@material-ui/core';
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { TableHeaderRow as TableHeaderRowBase } from '@devexpress/dx-react-grid';
import { TableColumn } from '../../../../../services/Main/types.Component';

const TableHeaderCell = (props: TableHeaderRowBase.CellProps & any) => {
  const theme = useTheme();
  const { column, style, children } = props;

  const { tableColumnViewConfig } = column as TableColumn;

  return (
    <TableHeaderRow.Cell
      {...props}
      style={{
        ...style,
        transform: undefined,
        backgroundColor: `${
          tableColumnViewConfig?.headerCellBackgroundColor ||
          theme.palette.background.paper
        }`,
      }}
    >
      {children}
    </TableHeaderRow.Cell>
  );
};

export default TableHeaderCell;
