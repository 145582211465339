import { Page } from 'services/Main/types.Page';

const barchart1Id = 4000100;

export const barChartWidgetsStubs: Page = {
  type: 'grid',
  title: 'Столбчатый графики',
  header: { heading: 'Столбчатый графики' },
  markup: [
    {
      lgCols: 6,
      component: {
        id: barchart1Id,
        businessComponentId: 23,
        type: 'barChartWidget',
        props: {
          heading:
            'Открытые задания по объекту (ПТ-01) (Вертикальный с прокруткой)',
          showLegend: true,
          showTooltip: true,
          orientation: 'vertical',
          requestConfig: {
            url: `/api/widgets/bar/${barchart1Id}`,
            method: 'POST',
          },
          xAxisLabel: 'Тонн',
          yAxisLabel: 'Объект',
        },
      },
    },
    {
      lgCols: 6,
      component: {
        id: barchart1Id,
        businessComponentId: 23,
        type: 'barChartWidget',
        props: {
          heading:
            'Открытые задания по объекту (ПТ-01) (Вертикальный сгруппированный)',
          showLegend: true,
          showTooltip: true,
          orientation: 'vertical',
          requestConfig: {
            url: '/api/widgets/bar/4000101',
            method: 'POST',
          },
          xAxisLabel: 'Тонн',
        },
      },
    },
    {
      lgCols: 6,
      component: {
        id: barchart1Id,
        businessComponentId: 23,
        type: 'barChartWidget',
        props: {
          heading:
            'Открытые задания по объекту (ПТ-01) (Вертикальный с частичной группировкой)',
          showLegend: true,
          showTooltip: true,
          orientation: 'vertical',
          requestConfig: {
            url: '/api/widgets/bar/4000102',
            method: 'POST',
          },
          xAxisLabel: 'Тонн',
        },
      },
    },
    {
      lgCols: 6,
      component: {
        id: barchart1Id,
        businessComponentId: 23,
        type: 'barChartWidget',
        props: {
          heading:
            'Открытые задания по объекту (ПТ-01) (Горизонтальный с группировкой)',
          showLegend: true,
          showTooltip: true,
          orientation: 'horizontal',
          requestConfig: {
            url: '/api/widgets/bar/4000101',
            method: 'POST',
          },
          yAxisLabel: 'Тонн',
        },
      },
    },
    {
      lgCols: 6,
      component: {
        id: barchart1Id,
        businessComponentId: 23,
        type: 'barChartWidget',
        props: {
          heading:
            'Открытые задания по объекту (ПТ-01) (Горизонтальный с частичной группировкой)',
          showLegend: true,
          showTooltip: true,
          orientation: 'horizontal',
          requestConfig: {
            url: '/api/widgets/bar/4000102',
            method: 'POST',
          },
          yAxisLabel: 'Тонн',
        },
      },
    },
    {
      lgCols: 6,
      component: {
        id: 100,
        businessComponentId: 51,
        type: 'barChartWidget',
        props: {
          heading: 'Объем реализации вторсырья за месяц (УО-04)',
          showLegend: true,
          showTooltip: true,
          requestConfig: {
            url: '/api/widgets/bar-chart/volume-second-raw-by-month',
            method: 'GET',
          },
          xAxisLabel: 'Мес.',
          yAxisLabel: 'Тонн',
        },
      },
    },
    {
      mdCols: 6,
      component: {
        id: barchart1Id,
        businessComponentId: 23,
        type: 'barChartWidget',
        props: {
          heading: 'График без данных',
          showLegend: true,
          showTooltip: true,
          orientation: 'horizontal',
          requestConfig: {
            url: '/api/widgets/bar-chart/empty',
            method: 'POST',
          },
        },
      },
    },
    {
      mdCols: 6,
      component: {
        id: barchart1Id,
        businessComponentId: 23,
        type: 'barChartWidget',
        props: {
          heading: 'График с ошибкой',
          showLegend: true,
          showTooltip: true,
          orientation: 'horizontal',
          requestConfig: {
            url: '/api/widgets/bar-chart/error',
            method: 'POST',
          },
        },
      },
    },
  ],
};
