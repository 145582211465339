import React from 'react';
import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import useStyles from './PasswordForgotPage.styles';
import { useFormatMessage } from '../../../locale';

interface ForgotPasswordResultProps {
  title?: string;
  message: string;
}

export default function PasswordForgotPageSuccessResultDialog({
  title,
  message,
}: ForgotPasswordResultProps) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <Card raised className={classes.card}>
      <Grid container>
        {title && (
          <Grid item xs={12} className={classes.marginBottom}>
            <Alert
              className={clsx(classes.alertTitle, classes.title)}
              variant="standard"
              color="success"
              severity="success"
            >
              {title}
            </Alert>
            <Divider />
          </Grid>
        )}
        <Grid item container xs={12} justify="center">
          <Grid item xs={10}>
            <Box className={classes.marginBottom}>
              <Typography className={classes.alertText}>{message}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={12} justify="center">
          <Grid
            item
            xs={10}
            container
            justify="flex-end"
            className={classes.marginBottom}
          >
            <Button
              component={Link}
              to="/login"
              color="primary"
              size="large"
              type="submit"
              variant="contained"
            >
              {formatMessage('toLoginForm')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
