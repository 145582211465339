import { createStyles, darken, makeStyles } from '@material-ui/core';
import { Theme } from '../../../../../theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    timelineScrollToBottomContainer: {
      position: 'sticky',
      left: 0,
      bottom: 16,
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    timelineScrollToBottomButton: {
      width: 40,
      height: 40,
      marginRight: theme.spacing(1),
      position: 'relative',

      '& .hasUnreadMessage': {
        display: 'block',
        position: 'absolute',
        right: 0,
        top: 0,
        width: 16,
        height: 16,
        borderRadius: 8,
        backgroundColor: '#ff0000',
        boxShadow: theme.shadows[1],
        transition: theme.transitions.create(
          ['background-color', 'box-shadow', 'border-color'],
          {
            duration: theme.transitions.duration.short,
          }
        ),
      },

      '&:hover .hasUnreadMessage': {
        backgroundColor: darken('#ff0000', 0.2),
      },
    },
  })
);
