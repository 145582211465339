/**
 * Тип ошибки, которую мы выбрасываем в случае, если столкнулись с
 * неожиданными данными. К примеру, если с сервера пришел корявые данные.
 */
export class UnexpectedDataError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UnexpectedDataError';
  }
}
