import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default (viewSize?: 'small' | 'normal') =>
  makeStyles((theme: Theme) =>
    createStyles({
      link: {
        textDecoration: 'none',
      },
      listItem: {
        marginTop: theme.spacing(viewSize === 'small' ? 0.5 : 1),
        marginBottom: theme.spacing(viewSize === 'small' ? 0.5 : 1),
        padding: 0,
        border: `1px solid ${theme.additionalPalette.border}`,
        borderRadius: 4,
        overflow: 'hidden',
        minWidth: '100%',
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
          minWidth: 320,
        },
      },
      avatarRoot: {
        minWidth: viewSize === 'small' ? 50 : 70,
        '&.isSupportedFileView': {
          cursor: 'zoom-in',
        },
      },
      listItemText: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: 'rgba(0, 0, 0, 0.54)',
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(viewSize === 'small' ? 0.5 : 1),
        '&.withRightPadding': {
          paddingRight: viewSize === 'small' ? 25 : 50,
        },
      },
      fontItalic: {
        fontStyle: 'italic',
        fontSize: theme.typography.pxToRem(viewSize === 'small' ? 8 : 10),
      },
      iconContainer: {
        backgroundColor: '#f0f3f7',
        width: viewSize === 'small' ? 44 : 70,
        height: viewSize === 'small' ? 44 : 70,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      icon: {
        width: viewSize === 'small' ? 19 : 38,
        height: viewSize === 'small' ? 19 : 38,
      },
      image: {
        '&, & img': {
          maxWidth: viewSize === 'small' ? 44 : 70,
          maxHeight: viewSize === 'small' ? 44 : 70,
        },
        '& img': {
          width: viewSize === 'small' ? 44 : 70,
          height: viewSize === 'small' ? 44 : 70,
          objectFit: 'cover',
        },
      },
      imageView: {},
    })
  );
