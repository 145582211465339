import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const HomePage = () => {
  return (
    <Paper>
      <Box p={3}>
        <Grid container md={8} item>
          <Typography variant="subtitle1">
            Облачное решение Компании, позволяющее:
          </Typography>
          <ul>
            <li>Повысить прозрачность и прогнозируемость финансовых затрат;</li>
            <li>Сделать управляемыми и эффективными бизнес-процессы;</li>
            <li>
              Снизить повседневную нагрузку на человеческие ресурсы, передав
              контроль за регулярной технической эксплуатацией основного фонда
              Компании России собственной информационной системе.
            </li>
          </ul>

          <Typography variant="subtitle1">
            The Company’s cloud solution, enabling:
          </Typography>
          <ul>
            <li>
              Increase transparency and predictability of financial costs;
            </li>
            <li>Make business processes manageable and efficient;</li>
            <li>
              Reduce the daily burden on human resources, transferring control
              over the regular technical maintenance of the Company’s Fixed
              Assets in Russia to own information system.
            </li>
          </ul>
        </Grid>
      </Box>
    </Paper>
  );
};

export default HomePage;
