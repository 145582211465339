import faker from 'faker';
import { Page } from 'services/Main/types.Page';

export const headerFormPage: Page = {
  type: 'singleComponent',
  title: 'Форма с наполненным Header',
  component: {
    id: 100,
    businessComponentId: 30,
    type: 'form',
    props: {
      form: {
        type: 'markup',
        header: {
          heading: faker.lorem.text(60),
          preHeadingText: faker.lorem.word(),
          subHeadings: [
            ['Страна', faker.address.country()],
            [
              'Создано',
              {
                type: 'date',
                value: '2020-04-15T16:33:23.3004811+03:00',
                formatAs: 'dateTime',
              },
            ],
            ['id', '62'],
            [
              null,
              {
                type: 'link',
                href: '/issues/916a3c9c-ea11-465b-9b23-5b53150e9726',
                label: 'Ссылка куда-то',
                icon: 'mui:Link',
              },
            ],
          ],
        },
        fieldGroups: [
          {
            header: {
              heading: 'FieldGroup с наполненным label',
              preHeadingIcon: {
                type: 'icon',
                svg: '<svg viewBox="0 0 12 12" fill="#ff0000" stroke="#ff0000" width="12" height="12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="3.5" /><circle cx="6" cy="6" r="5.5" fill="none" /></svg>',
                props: { color: 'primary', fontSizeInPx: 18 },
              },
            },
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Обновляйте страницу, чтобы увидеть разные варианты 🔄',
              },
            ],
          },
          {
            header: {
              heading: 'c preHeadingText',
              preHeadingText: 'FieldGroup с наполненным label',
              preHeadingIcon: {
                type: 'icon',
                svg: '<svg viewBox="0 0 12 12" fill="#0000ff" stroke="#0000ff" width="12" height="12" xmlns="http://www.w3.org/2000/svg"><circle cx="6" cy="6" r="3.5" /><circle cx="6" cy="6" r="5.5" fill="none" /></svg>',
                props: { color: 'primary', fontSizeInPx: 18 },
              },
            },
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Обновляйте страницу, чтобы увидеть разные варианты 🔄',
              },
            ],
          },
          {
            label: 'FieldGroup с обычным label',
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Обновляйте страницу, чтобы увидеть разные варианты 🔄',
              },
            ],
          },
        ],
      },
    },
  },
};
