import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import hash from 'object-hash';
import { parse } from 'query-string';
import _isEqual from 'lodash.isequal';
import mainService from 'services/Main';
import { Page, PageId } from 'services/Main/types.Page';
import useIsSomeActionExecuting from '../../components/lowLevel/FormAction/useIsSomeActionExecuting';
import { UnexpectedDataError } from '../errors/UnexpectedDataError';
import axios from 'axios';

/**
 * Хук для получения данных о странице.
 * При изменении id стучится на сервер, забирает данные и
 * укладывает их в стор.
 *
 * Возвращает актуальные данные из стора.
 */
export const usePage = (
  id: PageId,
  path: string,
  params: object,
  triggerUpdate?: boolean,
  componentUrlPrefix?: string,
  method?: 'GET' | 'POST'
): [Page | null, Dispatch<SetStateAction<Page | null>>] => {
  const dispatch = useDispatch();
  const paramsHash = Object.keys(params).length > 0 ? `_${hash(params)}` : '';
  const [page, setPage] = useState<Page | null>(null);
  const query = parse(window.document.location.search);
  const { isSomeActionExecuting, setIsSomeActionExecuting } =
    useIsSomeActionExecuting();

  useEffect(() => {
    const { request, source } = mainService.fetchPageData(
      id,
      params,
      query,
      componentUrlPrefix,
      method
    );

    request
      .then((response) => {
        console.log('Page has been loaded with data:', response);

        const hasPage = !!page;
        const isSame = hasPage ? _isEqual(page, response) : false;

        if (!hasPage || !isSame) {
          setPage(response);
        }

        if (isSomeActionExecuting) {
          setIsSomeActionExecuting(false);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;

        throw new UnexpectedDataError(
          JSON.stringify(
            {
              error: 'Пользователь не смог загрузить страницу',
              href: window.location.href,
              getPageUrl: err.response.config.url,
              exceptionString: err.response.data.exceptionString,
            },
            null,
            2
          )
        );
      });

    return () => {
      source.cancel('Request canceled by user. 🙅🏼‍');
    };
    // eslint-disable-next-line
  }, [dispatch, id, path, triggerUpdate, paramsHash]);

  return [page, setPage];
};
