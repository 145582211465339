import { Page } from '../../types.Page';

export const redirectReactionPage: Page = {
  type: 'singleComponent',
  title: 'Проверка экшна redirectReaction',
  component: {
    id: 100,
    businessComponentId: 56,
    type: 'form',
    props: {
      actions: [
        {
          type: 'submitForm',
          color: 'primary',
          variant: 'contained',
          label: 'Отправить',
          icon: 'mui:Check',
          requestConfig: {
            url: '/api/orders/repairs',
            method: 'POST',
          },
          successResponseReaction: {
            type: 'redirect',
            redirectTo: '/issues',
          },
        },
      ],
      form: {
        type: 'markup',
        disabled: false,
        header: { heading: 'Заявка' },
        fieldGroups: [
          {
            label: 'Основная информация',
            fields: [{ type: 'text', name: 'comment', label: 'Комментарий' }],
          },
        ],
      },
    },
  },
};
