import { io, Socket } from 'socket.io-client';
import authService from '../Auth';
import { SocketConfig } from '../Main/types.Component';
import { SocketServiceBase } from './SocketService.base';

class SocketServiceIo extends SocketServiceBase {
  connection: Socket;

  constructor(config: SocketConfig) {
    super();

    const { uri, path, query } = config;

    this.connection = io(uri, {
      extraHeaders: {
        Authorization: `Bearer ${authService.token}`,
      },
      path: path || undefined,
      query,
    });
  }

  invoke = <R>(method: string, ...args: any[]) => {
    return new Promise<R>((resolve) => {
      this.connection.emit(method, ...args, (response: R) => {
        resolve(response);
      });
    });
  };

  close = () => {
    this.connection.close();
  };
}

export default SocketServiceIo;
