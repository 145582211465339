import React, { FunctionComponent } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import MuiChip from '@material-ui/core/Chip';
import { ChipWithValueAndLabel } from 'services/Main/types.Component';
import useStyles from './ChipWithValueAndLabelTypeProvider.styles';
import { useSelector } from '../../../../../store';

const Formatter: FunctionComponent<DataTypeProvider.ValueFormatterProps> = ({
  value: cell,
}) => {
  const classes = useStyles();
  const locale = useSelector((state) => state.user?.locale) || 'ru_RU';

  if (!cell) return null;

  const { label, color, value } = cell as ChipWithValueAndLabel;

  const formattedValue = (() => {
    try {
      return new Intl.NumberFormat(locale.replace('_', '-')).format(
        Number(value)
      );
    } catch (error) {
      console.error('Не удалось отформатировать число:', {
        error,
        cell,
      });

      return value;
    }
  })();

  return (
    <div className={classes.root}>
      <MuiChip
        className={classes.chip}
        label={
          <div className={classes.label}>
            <div>{formattedValue}</div>
            <div>{label}</div>
          </div>
        }
        variant="outlined"
        style={{
          borderColor: color,
          color,
        }}
      />
    </div>
  );
};

interface IconTypeProviderProps {
  for: string[];
}

const ChipWithValueAndLabelTypeProvider = (props: IconTypeProviderProps) => (
  <DataTypeProvider formatterComponent={Formatter} {...props} />
);

export default ChipWithValueAndLabelTypeProvider;
