import { useMemo } from 'react';
import {
  Bar,
  ChartDatum,
  ChartDatumValue,
} from '../../../../services/Main/types.Component';
import { PreparedChartDatumValue } from '../types';

interface Props {
  data: ChartDatum[];
  bars: Bar[];
}

/**
 * Группирует данные по ключу бара и вычисляет isLastBarInStack для последнего
 * ненулевого значения в каждом стеке
 */
function usePreparedDataByBarKey({
  data,
  bars,
}: Props): Map<string, PreparedChartDatumValue[]> {
  return useMemo(
    () =>
      data.reduce((acc, datum, dataIndex) => {
        // Map для хранения последнего ненулевого значения для каждого stackId.
        // Для баров у которых нет stackId применяется его ключ key.
        // Это нужно, чтобы понять key последнего не нулевого bar.
        const lastNotZeroValueBarKeyByStackId = new Map<
          string | undefined,
          string
        >();

        // Перебираем каждый бар из массива bars
        bars.forEach(({ key, stackId }) => {
          // Получаем данные по текущему ключу бара
          const chartDatumByBarKey = acc.get(key);
          const barData = datum[key] as ChartDatumValue;

          if (chartDatumByBarKey) {
            chartDatumByBarKey.push(barData);
          }

          // Если значение не равно нулю, записываем ключ как последний ненулевой
          // для текущего stackId или key
          if (barData.value !== 0) {
            lastNotZeroValueBarKeyByStackId.set(
              stackId?.toString() || key,
              key
            );
          }
        });

        // Итерируем по Map с последними ненулевыми значениями для каждого stackId
        lastNotZeroValueBarKeyByStackId.forEach((barKey) => {
          const chartDatumByBarKey = acc.get(barKey);

          if (chartDatumByBarKey) {
            // Устанавливаем флаг isLastBarInStack для последнего ненулевого
            // значения.
            chartDatumByBarKey[dataIndex] = {
              ...chartDatumByBarKey[dataIndex],
              isLastBarInStack: true,
            };
          }
        });

        return acc;
      }, new Map<string, PreparedChartDatumValue[]>(bars.map((b) => [b.key, []]))),
    [bars, data]
  );
}

export default usePreparedDataByBarKey;
