import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from 'theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    progressBar: {
      marginTop: theme.spacing(1),
      height: 24,
    },
    info: {
      position: 'absolute',
      left: 0,
      top: 0,
      lineHeight: '24px',
      color: '#fff',
      textShadow: '0 0 1px rgba(0, 0, 0, 0.3), 0 0 1px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      paddingRight: 25,

      '& div': {
        padding: theme.spacing(0, 2),

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0, 0.4),
          fontSize: theme.typography.pxToRem(12),
        },
      },
    },
    cancelIcon: {
      position: 'absolute',
      right: 0,
      top: 0,

      '&, & *': {
        color: '#fff',
        fill: '#fff',
      },
    },
  })
);
