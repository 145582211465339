import React from 'react';
import clsx from 'clsx';
import useStyles from './Tags.styles';
import { useContentSwiper } from '../../../../../utils/hooks/useContentSwiper';

interface TagsContainerProps {
  children: React.ReactNode;
  multiline?: boolean;
}
const TagsContainer = ({ children, multiline }: TagsContainerProps) => {
  const classes = useStyles();
  const tagsContainerRef = React.useRef<HTMLDivElement>(null);
  const { events, swiperContainerStyles } = useContentSwiper(tagsContainerRef);

  if (multiline) {
    return <div className={clsx(classes.tags, { multiline })}>{children}</div>;
  }

  return (
    <div
      className={clsx(classes.tags, swiperContainerStyles)}
      ref={tagsContainerRef}
      {...events}
    >
      {children}
    </div>
  );
};

export default TagsContainer;
