import React, { useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { TreeItem } from 'performant-array-to-tree';
import { Box, IconButton, Paper, Popper } from '@material-ui/core';
import { useAutocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/useAutocomplete/useAutocomplete';
import { TreeSelectOptionWithChildren } from '../../types';
import {
  prepareTreeSelectOption,
  transformLastChildrenToUndefined,
  useDefaultExpanded,
  useDefaultNodes,
  useTreeSplitter,
  useGroupedOptionsByValue, collectLeafNodes,
} from './treeHelpers';
import createRenderInput from '../../helpers/createRenderInput';
import renderTags from '../../helpers/renderTags';
import useStyles from './TreeComboBox.styles';
import getCheckboxTreeIcons from '../../helpers/getCheckboxTreeIcons';
import useLikeSearch from './useLikeSearch';
import { useFormatMessage } from '../../../../../locale';
import {
  AsyncCheckboxTreeComboBox,
  AsyncRadioTreeComboBox,
  CheckboxTreeComboBox,
  OptionsType,
  RadioTreeComboBox,
  SelectOption,
  TreeSelectOption,
} from '../../../../../services/Main/types.Field';
import TreeCheckboxButton from './TreeCheckboxButton';
import TreeExpandButton from './TreeExpandButton';
import useDidUpdateEffect from '../../../../../utils/hooks/useDidUpdateEffect';
import mainService from '../../../../../services/Main';
import FormBuilderContext from '../../../FormBuilder/FormBuilderContext';
import { ControllerProps } from '../../../../../utils/typeUtils';
import { Node } from './types';

type Props = (
  | CheckboxTreeComboBox
  | RadioTreeComboBox
  | AsyncCheckboxTreeComboBox
  | AsyncRadioTreeComboBox
) &
  ControllerProps<TreeSelectOption | TreeSelectOption[] | null> & {
    options: TreeSelectOption[];
  };

const TreeComboBox = (props: Props) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const {
    disabled,
    options: optionsFromProps,
    optionsType,
    name,
    value: initialValue,
    onChange,
    optionsLoader,
    chipTagViewStyle,
  } = props;
  const isCheckboxTree = optionsType === 'checkboxTree';

  const [isOptionsFetched, setIsOptionsFetched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setSelectOptions } = useContext(FormBuilderContext);

  const disableClearable =
    props.optionsType === OptionsType.radioTree && props.disableClearable;

  const options = useMemo(
    () => prepareTreeSelectOption(optionsFromProps),
    [optionsFromProps]
  );

  const value: TreeSelectOption[] = (() => {
    if (initialValue && Array.isArray(initialValue)) {
      return initialValue;
    }

    return initialValue ? [initialValue] : [];
  })();

  const defaultExpanded = useDefaultExpanded(value, options);
  const defaultNodes = useDefaultNodes(options);

  const [nodes, setNodes] = useState<TreeItem[]>(defaultNodes);
  const [expanded, setExpanded] = useState<string[] | undefined>(
    defaultExpanded
  );

  useDidUpdateEffect(() => {
    if (!options.some((o) => expanded?.includes(o.value))) {
      setExpanded(undefined);
    }
  }, [options]);

  const groupedOptionsByValue = useGroupedOptionsByValue(options);
  const { isTreeHidden, filterText, setFilterText } = useLikeSearch({
    options,
    defaultNodes,
    setExpanded,
    setNodes,
  });

  const { leaveOptions, branches } = useTreeSplitter(
    defaultNodes,
    groupedOptionsByValue
  );

  // В node лежит TreeSelectOption и дополнительные параметры от CheckboxTree.
  const handleCheck = (_: string[], node: Node) => {
    if (disabled) return;

    // Сброс текста фильтра
    setFilterText('');

    // Если это не дерево с чекбоксами, просто обновляем значение и выходим
    if (!isCheckboxTree) {
      onChange(groupedOptionsByValue[node.value] ?? null);
      return;
    }

    // 1. Собираем все листовые узлы для текущей ноды
    const leafs = collectLeafNodes(node);

    // 1.1 Извлекаем значения из собранных листовых узлов
    const leafValues = leafs.map((n) => n.value);

    // 2. Извлекаем текущие выбранные значения
    const currentValues = value.map((o) => o.value);

    // 3. Определяем, нужно ли добавлять или удалять значения
    // Если хотя бы одно из значений листьев уже выбрано, значит, мы будем удалять значения
    // Если ни одно из значений листьев не выбрано, значит, мы будем добавлять значения
    const shouldAdd = !leafValues.some((leafValue) => currentValues.includes(leafValue));

    // 4. Собираем новое значение для формы
    let newCurrentValues;
    if (shouldAdd) {
      // 4.1 Если добавляем, объединяем текущие значения и новые значения листьев
      newCurrentValues = [...currentValues, ...leafValues];
    } else {
      // 4.2. Иначе исключаем значения листьев из текущих значений
      newCurrentValues = currentValues.filter((v) => !leafValues.includes(v));
    }

    // 5. Преобразуем новые текущие значения в формат, необходимый для формы
    const newValue = newCurrentValues.map((v) => groupedOptionsByValue[v]);

    // 6. Обновляем состояние с новыми значениями
    onChange(newValue);
  };

  const handleClear = () => {
    if (disabled) return;

    setFilterText('');
    setExpanded(undefined);
    onChange(isCheckboxTree ? [] : null);
  };

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    inputValue: string,
    reason: AutocompleteInputChangeReason
  ) => {
    // Чтобы не очищалось при работе с чекбоксами/радио.
    if (reason === 'reset') return;

    setFilterText(inputValue);
  };

  const handleClose = () => {
    setFilterText('');
  };

  const handleCheckTree = () => {
    if (isCheckboxTree) {
      if (value.length === 0) {
        onChange(leaveOptions);
        return;
      }

      onChange([]);
    }
  };

  const handleExpandTree = () => {
    if (Array.isArray(expanded) && expanded.length > 0) {
      setExpanded(undefined);
      return;
    }

    setExpanded(branches);
  };

  const handleOpen = () => {
    if (optionsLoader === 'async') {
      const { searchOptionsRequestConfig } = props as unknown as
        | AsyncCheckboxTreeComboBox
        | AsyncRadioTreeComboBox;

      if (isOptionsFetched) return;

      setIsLoading(true);
      mainService
        .makeActionRequest<SelectOption[]>(searchOptionsRequestConfig)
        .then(({ payload: newOptions }) => {
          setIsLoading(false);

          setSelectOptions((prevState) => ({
            ...prevState,
            [name]: newOptions,
          }));
          setIsOptionsFetched(true);
        });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: TreeSelectOption | TreeSelectOption[] | null,
    reason: AutocompleteChangeReason
  ) => {
    if (disabled || disableClearable) return;

    if (reason === 'remove-option') {
      onChange(isCheckboxTree ? newValue : null);
    }
  };

  const {
    anchorEl,
    dirty,
    focused,
    focusedTag,
    id,
    popupOpen,
    getClearProps,
    getPopupIndicatorProps,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    setAnchorEl,
    getTagProps,
  } = useAutocomplete({
    multiple: true,
    disableClearable: disabled,
    options,
    filterOptions: (o) => o,
    value,
    inputValue: filterText,
    onInputChange: handleInputChange,
    onClose: handleClose,
    onOpen: handleOpen,
    onChange: handleChange,
  });

  return (
    <div>
      <div
        className={clsx(classes.root, classes.fullWidth, {
          [classes.hasPopupIcon]: !disabled,
          [classes.focused]: focused,
          [classes.hasClearIcon]: !disabled,
        })}
        {...getRootProps()}
      >
        {createRenderInput(props)({
          id,
          size: undefined,
          InputProps: {
            ref: setAnchorEl,
            className: classes.inputRoot,
            startAdornment:
              Array.isArray(value) &&
              value.length > 0 &&
              renderTags({
                multiline: (props as { multiline?: boolean })?.multiline,
                viewStyle: chipTagViewStyle,
                disableClearable,
                disabled,
              })(value, getTagProps),
            endAdornment: (
              <div className={classes.endAdornment}>
                {!disabled && (
                  <>
                    {!disableClearable && (
                      <IconButton
                        {...getClearProps()}
                        aria-label={formatMessage('clear')}
                        title={formatMessage('clear')}
                        className={clsx(classes.clearIndicator, {
                          [classes.clearIndicatorDirty]: dirty,
                        })}
                        onClick={handleClear}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton
                      {...getPopupIndicatorProps()}
                      disabled={disabled}
                      aria-label={
                        popupOpen
                          ? formatMessage('close')
                          : formatMessage('open')
                      }
                      title={
                        popupOpen
                          ? formatMessage('close')
                          : formatMessage('open')
                      }
                      className={clsx(classes.popupIndicator, {
                        [classes.popupIndicatorOpen]: popupOpen,
                      })}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </>
                )}
              </div>
            ),
          },
          InputLabelProps: getInputLabelProps(),
          inputProps: {
            className: clsx(classes.input, {
              [classes.inputFocused]: focusedTag === -1,
              disabled,
            }),
            ...getInputProps(),
          },
          disabled: !!disabled,
          fullWidth: true,
        })}
      </div>
      {popupOpen && anchorEl && (
        <Popper
          open
          anchorEl={anchorEl}
          className={clsx(classes.popper, {
            isTreeHidden: isTreeHidden || nodes.length === 0,
          })}
          role="presentation"
          style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}
        >
          <Paper className={classes.paper} {...getListboxProps()}>
            {isLoading && (
              <div className={clsx(classes.feedbackText, 'animate-flicker')}>
                {formatMessage('loading')}
              </div>
            )}
            {!isLoading && (isTreeHidden || nodes.length === 0) && (
              <div className={classes.feedbackText}>
                {formatMessage('noOptions')}
              </div>
            )}
            <Box hidden={isTreeHidden}>
              {nodes.length > 0 && (
                <>
                  <Box className={classes.buttonsContainer}>
                    <TreeExpandButton
                      expanded={Array.isArray(expanded) && expanded.length > 0}
                      onClick={handleExpandTree}
                    />
                    {isCheckboxTree ? (
                      <TreeCheckboxButton
                        onClick={handleCheckTree}
                        checked={value.length === leaveOptions.length}
                        indeterminate={value.length > 0}
                      />
                    ) : null}
                  </Box>
                </>
              )}
              <CheckboxTree
                name={name}
                noCascade={!isCheckboxTree}
                nodes={
                  nodes.map((node) =>
                    transformLastChildrenToUndefined(
                      node as TreeSelectOptionWithChildren
                    )
                  ) as any // тип не экспортируется из библиотеки
                }
                disabled={disabled}
                icons={getCheckboxTreeIcons(optionsType)}
                showNodeIcon={false}
                checked={value.map((o) => o.value)}
                expanded={expanded}
                checkModel="leaf"
                optimisticToggle={false}
                onCheck={handleCheck}
                onExpand={setExpanded}
              />
            </Box>
          </Paper>
        </Popper>
      )}
    </div>
  );
};

export default TreeComboBox;
