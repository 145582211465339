import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    badge: {
      display: 'inline-block',
      width: 10,
      height: 10,
      borderRadius: '50%',
    },
    text: {
      '&.active': {
        fontWeight: 'bold',
      },
    },
  })
);
