import { useFormContext } from 'react-hook-form';

/**
 * Кастомный хук, позволяющий работать с состоянием ошибок RHF.
 *
 * clearErrors(fieldNames?: string | string[]) - очистить у поля/полей ошибки.
 * Если ничего не передать очистит все ошибки.
 */
const useFormErrors = () => {
  const { control } = useFormContext();
  const { updateFormState, fieldsRef, formStateRef } = control;

  /**
   *  Данный функционал скопирован из RHF:
   *  https://github.com/react-hook-form/react-hook-form/blob/cffed3ae9adaf2b756c9adebbbd93a23ab13caaa/src/useForm.ts#L789
   *
   *  В него были внесены след. изменения:
   *  1. Удалена проверка на isKey https://github.com/react-hook-form/react-hook-form/blob/v6/src/utils/isKey.ts
   *  - Причина: она не пропускала след. формат строк `arrayOf[0].pickBy`;
   *
   */
  const clearErrors = (...fieldNames: string[]) => {
    let { errors } = formStateRef.current;

    if (fieldNames && Array.isArray(fieldNames)) {
      errors = fieldNames.reduce(
        (acc, fieldName) => {
          if (!(fieldName in fieldsRef.current)) return acc;

          const { [fieldName]: _, ...newAcc } = acc;

          return newAcc;
        },
        { ...formStateRef.current.errors }
      );
    }

    updateFormState({
      errors,
    });
  };

  return {
    clearErrors,
  };
};

export default useFormErrors;
