import React, { createContext, PropsWithChildren, useState } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogAlert } from '../../../services/Main/types';
import { useFormatMessage } from '../../../locale';
import useIsSomeActionExecuting from '../FormAction/useIsSomeActionExecuting';

interface DialogAlertProps {}

export interface DialogAlertContextState {
  dialogState: DialogAlert | null;
  setDialogState: (state: DialogAlert | null) => void;
}

export const DialogAlertContext = createContext<DialogAlertContextState>({
  dialogState: null,
  setDialogState: () => {},
});

export default ({ children }: PropsWithChildren<DialogAlertProps>) => {
  const formatMessage = useFormatMessage();
  const { setIsSomeActionExecuting } = useIsSomeActionExecuting();
  const [dialogState, setDialogState] = useState<DialogAlert | null>(null);

  const handleClose = () => {
    // При закрытии снимаем блокировку с кнопок
    if (dialogState) {
      setIsSomeActionExecuting(false);
    }

    setDialogState(null);
  };

  return (
    <DialogAlertContext.Provider value={{ dialogState, setDialogState }}>
      <Dialog open={!!dialogState} hidden={!dialogState} onClose={handleClose}>
        <DialogTitle>{dialogState?.heading}</DialogTitle>
        <DialogContent>
          {dialogState?.body.split('\n').map((p, index) => (
            <p key={index}>{p}</p>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {formatMessage('close')}
          </Button>
          {dialogState?.actions &&
            dialogState.actions.map(({ label, onClick }) => (
              <Button key={label} onClick={onClick} color="primary">
                {label}
              </Button>
            ))}
        </DialogActions>
      </Dialog>
      {children}
    </DialogAlertContext.Provider>
  );
};
