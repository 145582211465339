import React, { PropsWithChildren } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { UnexpectedDataError } from 'utils/errors/UnexpectedDataError';

interface OtherProps {
  show: boolean;
}

type PreLoaderScreenProps = PropsWithChildren<OtherProps>;

const PreLoaderScreen = ({ show, children }: PreLoaderScreenProps) => {
  if (!children)
    throw new UnexpectedDataError(
      'PreLoaderScreen component required children components'
    );

  return (
    <>
      {show && <h1>Loading...</h1>}
      <Hidden xsUp={show} implementation="css">
        {children}
      </Hidden>
    </>
  );
};

export default PreLoaderScreen;
