import {
  RefObject,
  useRef,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import clsx from 'clsx';
import useStyles from './useContentSwiper.styles';

export const useContentSwiper = (ref: RefObject<HTMLElement>) => {
  const classes = useStyles();
  const [isSwiping, setIsSwiping] = useState<boolean>(false);
  const onMouseDownState = useRef<{
    refScrollLeft: number;
    eventClientX: number;
  }>({
    refScrollLeft: 0,
    eventClientX: 0,
  });

  const mouseMoveHandler = useCallback(
    (event: { clientX: number }) => {
      if (!ref.current) return;
      if (ref.current.offsetWidth > ref.current.scrollWidth) return;

      const dx = event.clientX - onMouseDownState.current.eventClientX;
      ref.current.scrollLeft = onMouseDownState.current.refScrollLeft - dx;

      setIsSwiping(true);
    },
    [ref]
  );

  const mouseUpHandler = useCallback(() => {
    if (!ref.current) return;

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    setTimeout(() => {
      setIsSwiping(false);
    }, 0);
  }, [mouseMoveHandler, ref]);

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      if (!ref.current) return;
      if (ref.current.offsetWidth > ref.current.scrollWidth) return;

      onMouseDownState.current.refScrollLeft = ref.current.scrollLeft;
      onMouseDownState.current.eventClientX = event.clientX;

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    },
    [mouseMoveHandler, mouseUpHandler, ref]
  );

  const onClick = useCallback(
    (event: SyntheticEvent) => {
      // Игнорируем элементы с onClick и ссылкой при активном drag скролле
      if (!isSwiping) return;

      event.preventDefault();
      event.stopPropagation();
    },
    [isSwiping]
  );

  return {
    events: {
      onMouseDown,
      onClick,
    },
    swiperContainerStyles: clsx(classes.swiperContainer, {
      isSwiping,
    }),
  };
};
