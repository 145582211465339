import {
  FormAction,
  TableAction,
} from '../../../services/Main/types.Component';

export interface ClassifiedActions<T> {
  mainActions: T[];
  extraActions: T[];
}

export function useClassifyActions<T extends TableAction | FormAction>(
  actions: T[]
) {
  return actions.reduce<ClassifiedActions<T>>(
    (acc, action) => {
      if (action.placement === 'extra') {
        acc.extraActions.push(action);
        return acc;
      }

      acc.mainActions.push(action);

      return acc;
    },
    {
      mainActions: [],
      extraActions: [],
    }
  );
}
