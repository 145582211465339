import React from 'react';
import { ComboBoxOption } from '../../../../../services/Main/types.Field';
import Tag from './Tag';
import { RenderTagsProps } from '../../helpers/renderTags';
import TagsContainer from './Tags.container';

interface TextTagsProps extends RenderTagsProps {
  tags: ComboBoxOption[];
}

const TextTags = ({ tags, multiline }: TextTagsProps) => {
  return (
    <TagsContainer multiline={multiline}>
      {tags.map((tag) => {
        const { label, href } = tag;

        return <Tag key={`${label}_${href}`} {...tag} />;
      })}
    </TagsContainer>
  );
};

export default TextTags;
