import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {
  useMediaQuery,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import authService from 'services/Auth';
import Link from 'components/lowLevel/Link';
import useStyles from './LoginPage.styles';
import handleLoginError from './handleLoginError';
import InfoText from './InfoText';
import SignInWithGoogleButton from './components/SignInWithGoogleButton';
import theme from '../../theme';
import handleLoginSuccess from './handleLoginSuccess';
import { useFormatMessage } from '../../locale';
import { PASSWORD_CLIENT_ROUTE } from '../../services/Password/Password.constants';

const LoginPage = () => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm();
  const [errorText, setErrorText] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const onSubmit = ({ login, password }: any) => {
    setIsLoading(true);

    authService
      .login(login, password)
      .then(handleLoginSuccess(dispatch))
      .catch(handleLoginError({ formatMessage, setErrorText }))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleToggleVisiblePassword = useCallback(() => {
    setIsVisiblePassword((prevState) => !prevState);
  }, []);

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs={11} sm={8} md={5}>
        <InfoText />
      </Grid>
      <Grid item xs={11} sm={8} md={6} className={classes.loginBlock}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card raised className={classes.card}>
            <Grid container>
              <Grid item xs={12} className={classes.marginBottom}>
                <Typography className={classes.title}>
                  <span>Пожалуйста, авторизуйтесь.</span>
                  <span>Please sign-in.</span>
                </Typography>
                <Divider />
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="center"
                className={classes.marginBottom}
              >
                <Grid item xs={10} className={classes.marginBottom}>
                  <TextField
                    size="small"
                    color="primary"
                    variant="outlined"
                    name="login"
                    label={
                      <>
                        <span style={{ color: 'red' }}>*</span>
                        {formatMessage('login')}
                      </>
                    }
                    fullWidth
                    inputRef={register({
                      required: formatMessage('requiredField'),
                    })}
                    error={!!errors.login}
                    helperText={errors.login && errors.login.message}
                  />
                </Grid>
                <Grid item xs={10} className={classes.marginBottom}>
                  <Box mb={1}>
                    <TextField
                      size="small"
                      color="primary"
                      variant="outlined"
                      name="password"
                      label={
                        <>
                          <span style={{ color: 'red' }}>*</span>
                          {formatMessage('password')}
                        </>
                      }
                      type={isVisiblePassword ? 'text' : 'password'}
                      fullWidth
                      inputRef={register({
                        required: formatMessage('requiredField'),
                      })}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip
                              title={
                                isVisiblePassword
                                  ? formatMessage('hidePassword')
                                  : formatMessage('showPassword')
                              }
                            >
                              <IconButton
                                onClick={handleToggleVisiblePassword}
                                size="small"
                              >
                                {isVisiblePassword ? (
                                  <VisibilityOffIcon fontSize="small" />
                                ) : (
                                  <VisibilityIcon fontSize="small" />
                                )}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                    />
                  </Box>
                  <Box className={classes.forgotPassword}>
                    <Link to={PASSWORD_CLIENT_ROUTE.forgot}>
                      {formatMessage('forgotPassword')}
                    </Link>
                  </Box>
                </Grid>
                {errorText && (
                  <Grid item xs={10}>
                    <Alert severity="error" className={classes.alert}>
                      {errorText}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container className={classes.buttonGrid} justify="center">
                <Grid item xs={10} container justify="flex-end">
                  <Grid item>
                    <Box
                      order={isSmDown ? 3 : 1}
                      mb={2}
                      mr={3}
                      width={isSmDown ? '100%' : 'auto'}
                    >
                      <SignInWithGoogleButton setErrorText={setErrorText} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box order={2} mb={2} width={isSmDown ? '100%' : 'auto'}>
                      <Button
                        disabled={isLoading}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {formatMessage('signIn')}
                        {isLoading && (
                          <Box ml={1}>
                            <CircularProgress size="0.9rem" />
                          </Box>
                        )}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </form>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
