export default class ScrollManager {
  container: HTMLElement;

  dataIdName: string;

  constructor(
    container: HTMLElement,
    initialScrollTop: number,
    dataIdName: string
  ) {
    this.container = container;
    this.dataIdName = dataIdName;
    this.container.scrollTop = initialScrollTop;
  }

  scrollToElementWithId(id: string) {
    const element: HTMLElement | null = document.querySelector(
      `[${this.dataIdName}='${id}']`
    );

    if (element)
      this.container.scrollTop = element.offsetTop - this.container.offsetTop;
  }

  smoothScrollAndHighlightToElementWithId(id: string) {
    const element: HTMLElement | null = document.querySelector(
      `[${this.dataIdName}='${id}']`
    );

    if (element) {
      this.container.scrollTo({
        top: element.offsetTop - this.container.offsetTop,
        behavior: 'smooth',
      });

      let scrollTimer: NodeJS.Timeout;

      const highlightElement = () => {
        element.style.animation = 'highlight 2s ease';

        element.addEventListener('animationend', () => {
          element.style.animation = '';
        });
      };

      const endScrollHandler = () => {
        highlightElement();
        clearTimeout(scrollTimer);
        this.container.removeEventListener('scroll', scrollHandler);
      };

      const scrollHandler = () => {
        clearTimeout(scrollTimer);
        scrollTimer = setTimeout(endScrollHandler, 100);
      };

      if (
        element.offsetTop >= this.container.scrollTop &&
        element.offsetTop <=
          this.container.scrollTop + this.container.clientHeight
      ) {
        highlightElement();
      } else {
        this.container.addEventListener('scroll', scrollHandler);
      }
    }
  }

  smoothScrollToBottom() {
    this.container.scrollTo({
      top: this.container.scrollHeight,
      behavior: 'smooth',
    });
  }

  scrollToBottom() {
    this.container.scrollTop = this.container.scrollHeight;
  }

  // Считаем проскролленным, если проскроллено хотя бы до 10 пикселей снизу.
  get isScrolledToBottom() {
    return (
      this.container.scrollTop + this.container.clientHeight >=
      this.container.scrollHeight - 10
    );
  }
}
