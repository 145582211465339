import React from 'react';
import { Box } from '@material-ui/core';
import useStyles from './CurrentUserTimelineElement.styles';
import { CurrentUserTimelineElement } from '../../../../../../services/Main/types.Component';
import Bubble from '../../Bubble';
import MessageInfo from '../../MessageInfo';
import MessageBody from '../../MessageBody';
import MessageAttachments from '../../MessageAttachments';
import MessageHeader from '../../MessageHeader';
import MessageContent from '../../MessageContent';
import MessageQuote from '../../MessageQuote';

export default (props: CurrentUserTimelineElement) => {
  const classes = useStyles();

  const { isPrivate, createdAt, isAddedManually, body, attachments, quote } =
    props;

  return (
    <Bubble side="right" className={classes.bubble}>
      <MessageContent>
        <MessageHeader
          subheader={
            <MessageInfo
              isPrivate={isPrivate}
              createdAt={createdAt}
              isAddedManually={isAddedManually}
              reverse
            />
          }
        />
        {quote && (
          <Box mt={3}>
            <MessageQuote {...quote} />
          </Box>
        )}
        <MessageBody {...body} />
      </MessageContent>
      <MessageAttachments attachments={attachments} />
    </Bubble>
  );
};
