import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import mainService from '../../../services/Main';
import {
  Reaction,
  RedirectReaction,
  TriggerEndpointAction,
  TableAction,
  RequestConfig,
  TableTriggerEndpointAction,
} from '../../../services/Main/types.Component';
import ReactionController, {
  OnReactionExecuteEvent,
} from '../../../responseReactions/ReactionController';
import { PageContext } from '../../creational/PageLoader/PageLoader';
import redirectReaction from '../../../responseReactions/redirect/redirectReaction';
import { DialogAlertContext } from '../DialogAlert/DialogAlert';
import useIsSomeActionExecuting from '../FormAction/useIsSomeActionExecuting';
import history from '../../../utils/history';
import { alertsFromActionServerResponseActions } from '../../../store/alertsFromActionServerResponse';
import { metaActions } from '../../../store/meta';
import { ComponentContext } from '../../creational/ComponentLoader';
import store from '../../../store';
import useEnqueueSnackbar from '../../../utils/hooks/useEnqueueSnackbar';
import { useShowConfirmDialog } from '../FormActions/useShowConfirmDialog';
import { dialogWithComponentActions } from '../../../store/dialogWithComponent';
import { TableContext } from '../../highLevel/Table';
import { makePreSubmitRequest } from '../../../utils/actionUtils';
import TableDialogWithComponentContext from '../../highLevel/Table/components/TableDialogWithComponent/TableDialogWithComponent.context';

type TableActionPropsOmit = Omit<
  TableAction,
  'label' | 'color' | 'icon' | 'variant'
>;

interface OtherProps {
  onReactionExecute?: OnReactionExecuteEvent;
}

export const useHandleClickAction = ({
  type,
  onReactionExecute,
  ...rest
}: TableActionPropsOmit & OtherProps) => {
  const pageContext = useContext(PageContext);
  const tableContextValue = useContext(TableContext);
  const componentContext = useContext(ComponentContext);
  const dispatch = useDispatch();
  const enqueueSnackbar = useEnqueueSnackbar();
  const dialogAlertContext = useContext(DialogAlertContext);
  const tableDialogWithComponentContext = useContext(
    TableDialogWithComponentContext
  );
  const { setIsSomeActionExecuting } = useIsSomeActionExecuting();
  const showConfirmDialog = useShowConfirmDialog();

  const onBeforeActionExecuteHandler = () => {
    const confirmDialogConfig = (rest as TriggerEndpointAction)?.confirmDialog;
    if (!confirmDialogConfig) return Promise.resolve();

    return showConfirmDialog(confirmDialogConfig);
  };

  const onActionSuccessfullyExecuted = () => {
    if (store.getState().dialogWithComponent) {
      dispatch(dialogWithComponentActions.close());
    }
  };

  switch (type) {
    // showForm обрабатываем выше.
    // case 'showForm':
    //   return () => console.log(type, rest);

    // Получить урл, сделать запрос,
    // выполнить реакцию (колбек)
    case 'triggerEndpoint':
      return async () => {
        await onBeforeActionExecuteHandler();

        setIsSomeActionExecuting(true);

        if ((rest as TriggerEndpointAction).preSubmitRequestConfig) {
          await makePreSubmitRequest({
            preSubmitRequestConfig: (rest as TriggerEndpointAction)
              .preSubmitRequestConfig as RequestConfig,
            showConfirmDialog,
          });
        }

        let params = {};

        if ((rest as TableTriggerEndpointAction).includeFilterState) {
          params = {
            ...params,
            withClosedState: !!tableContextValue?.withClosed,
            filterState: tableContextValue?.filterState,
            quickFiltersState: tableContextValue?.quickFiltersState,
          };
        }

        if ((rest as TableTriggerEndpointAction).includeSortingState) {
          params = {
            ...params,
            sortingState: tableContextValue?.sortingState,
          };
        }

        if (
          (rest as TableTriggerEndpointAction).includeTableRows &&
          tableContextValue?.rowsFromState
        ) {
          params = {
            ...params,
            rows: tableContextValue.rowsFromState,
          };
        }

        mainService
          .makeActionRequest(
            (rest as TriggerEndpointAction).requestConfig,
            params
          )
          .then(
            ({
              payload,
              snackbar,
              dialogAlert,
              preventSuccessResponseReactionReasons,
            }) => {
              if (snackbar) enqueueSnackbar(snackbar.text, snackbar.options);
              if (dialogAlert) dialogAlertContext.setDialogState(dialogAlert);

              const alertsId = `${componentContext.businessComponentId}_${componentContext.id}`;

              if (
                preventSuccessResponseReactionReasons &&
                Array.isArray(preventSuccessResponseReactionReasons)
              ) {
                dispatch(
                  alertsFromActionServerResponseActions.setForPage({
                    id: alertsId,
                    alerts: preventSuccessResponseReactionReasons,
                  })
                );
                dispatch(metaActions.setIsSomeActionExecuting(false));
                return;
              }

              if (
                store.getState().alertsFromActionServerResponse[alertsId]
                  ?.length > 0
              ) {
                dispatch(alertsFromActionServerResponseActions.clear(alertsId));
              }

              onActionSuccessfullyExecuted();

              new ReactionController(
                (rest as TriggerEndpointAction).successResponseReaction,
                pageContext as PageContext,
                enqueueSnackbar,
                payload,
                tableContextValue,
                tableDialogWithComponentContext
              ).execute(onReactionExecute);
            }
          )
          .catch((error) => {
            if (error.response?.status === 422) {
              if (error.response?.data?.snackbar) {
                enqueueSnackbar(
                  error.response?.data?.snackbar.text,
                  error.response?.data?.snackbar.options
                );
              }

              if (error.response?.data?.dialogAlert) {
                if (error.response?.data?.dialogAlert)
                  dialogAlertContext.setDialogState(
                    error.response.data.dialogAlert
                  );
              }

              if ((rest as TriggerEndpointAction).validationErrorReaction) {
                new ReactionController(
                  (rest as TriggerEndpointAction)
                    .validationErrorReaction as Reaction,
                  pageContext as PageContext,
                  enqueueSnackbar,
                  error.response,
                  tableContextValue,
                  tableDialogWithComponentContext
                ).execute(onReactionExecute);
              }
            }

            console.log('Ошибка', error);
          })
          .finally(() => {
            // Добавляли с целью отмены блокировок кнопок в асинхронных модалках
            // Потенциально может привести к проблемам, так как не знаем как
            // повлияет на другие части системы
            setIsSomeActionExecuting(false);
          });
      };

    case 'redirect':
      return () => {
        onActionSuccessfullyExecuted();

        redirectReaction(rest as RedirectReaction);
      };

    case 'goBack':
      return () => {
        onActionSuccessfullyExecuted();

        history.goBack();
      };

    default:
      return () => console.log('CLICK CLOCK');
  }
};
