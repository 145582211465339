import React, { useContext } from 'react';
import { LinearProgress } from '@material-ui/core';
import EntryPickerTableContext from '../../EntryPickerTable.context';

const EntryPickerLinearProgress = () => {
  const { isLoading, tableProps } = useContext(EntryPickerTableContext);

  return (
    <LinearProgress
      style={{
        visibility: isLoading && tableProps ? 'visible' : 'hidden',
      }}
    />
  );
};

export default EntryPickerLinearProgress;
