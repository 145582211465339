import React from 'react';
import RouteComponent from 'components/lowLevel/Route';
import { Route } from 'services/Main/types';
import PageLoader from 'components/creational/PageLoader';

/**
 * Итоговый роутинг похож на эту структуру:
 *
 * <Switch>
     <Route path="/1">
        <PageLoader id={1} />
     </Route>
     <Route path="/2">
        <PageLoader id={2} />
     </Route>
     <Route path="/">
        <PageLoader id={3} />
     </Route>
     <Route path="*"> // дефолнтный кейс
        <HidePreLoader>
           <NotFound />
        </HidePreLoader>
     </Route>
   </Switch>
 * @param routes
 */
export default (routes: Route[]) =>
  routes.map(({ pageId, exact, path, componentUrlPrefix, method }) => (
    <RouteComponent key={`${pageId}${path}`} exact={exact} path={path}>
      <PageLoader
        id={pageId}
        path={path}
        componentUrlPrefix={componentUrlPrefix}
        method={method}
      />
    </RouteComponent>
  ));
