import { HEADING_HEIGHT, LEGEND_HEIGHT } from './constants';

export default function (
  defaultMinHeight: number,
  hasHeading: boolean,
  hasLegend: boolean
) {
  let minHeight = defaultMinHeight;

  if (hasHeading) minHeight += HEADING_HEIGHT;
  if (hasLegend) minHeight += LEGEND_HEIGHT;

  return minHeight;
}
