import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '../../theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      maxWidth: 500,
      minWidth: 500,
    },
    alert: {
      marginBottom: theme.spacing(4),
      width: '100%',
      whiteSpace: 'pre-line',
    },
    passwordField: {
      '& .MuiFormHelperText-root.Mui-error': {
        whiteSpace: 'pre-line',
      },
    },
  })
);
