import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './EmptyWidgetDataText.styles';
import { useFormatMessage } from '../../../locale';

interface EmptyWidgetDataTextProps {
  height: number | string;
  width: number | string;
  fontSize?: number | string;
}

const EmptyWidgetDataText = ({
  height,
  width,
  fontSize,
}: EmptyWidgetDataTextProps) => {
  const classes = useStyles();
  const formatMessage = useFormatMessage();

  return (
    <div style={{ height, width }} className={classes.root}>
      <Typography variant="h3" style={{ fontSize }}>
        {formatMessage('noData')}
      </Typography>
    </div>
  );
};

export default EmptyWidgetDataText;
