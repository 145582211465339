import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
    },
    topBarChartContainer: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    topBarChart: {
      '& .recharts-surface': {
        marginBottom: -10,
      },
    },
    bottomBarChart: {
      marginTop: -1,
    },
  })
);
