import { Page } from 'services/Main/types.Page';

export const goBackActionPage: Page = {
  type: 'singleComponent',
  title: 'Форма с goBack экшном',
  component: {
    id: 100,
    businessComponentId: 1,
    type: 'form',
    props: {
      actions: [
        {
          type: 'goBack',
          label: 'Назад',
          variant: 'contained',
          color: 'primary',
        },
      ],
      form: {
        type: 'markup',
        header: { heading: 'Форма с goBack экшном' },
        fieldGroups: [
          {
            label: 'Название группы',
            fields: [
              {
                type: 'text',
                name: 'comment',
                label: 'Комментарий',
              },
            ],
          },
        ],
      },
    },
  },
};
