import PasswordBase from './Password.base';

let PasswordService: any;

if (
  process.env.REACT_APP_ENV === 'development' &&
  process.env.REACT_APP_IS_REMOTE !== 'true'
) {
  PasswordService = require('./Password.local').default;
} else {
  PasswordService = require('./Password.remote').default;
}

const passwordService = new PasswordService() as PasswordBase;

export default passwordService;
